import { createAction, props } from '@ngrx/store';

import { Budget, BudgetDTO, BudgetFilters } from '../../commons/models/budget.model';

export const loadBudgets = createAction('[Budgets] Load Budgets', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: BudgetFilters, includes?: string[] }>());
export const loadBudgetsCompleted = createAction('[Budgets] Load Budgets Completed', props<{ budgets: BudgetDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: BudgetFilters, includes?: string[] }>());
export const loadBudgetsFailed = createAction('[Budgets] Load Budgets Failed', props<{ error: any }>());

export const changePage = createAction('[Budgets] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Budgets] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Budgets] Change filters', props<{ filters: BudgetFilters }>());

export const editBudget = createAction('[Budgets] Edit budget', props<{ budget?: Budget }>());
export const budgetDialogOpened = createAction('[Budgets] Detail dialog opened', props<{ dialogId: string }>());
export const closeBudgetDialog = createAction('[Budgets] Close detail dialog');

export const saveBudget = createAction('[Budgets] Save budget', props<{ budget: Budget }>());
export const saveBudgetCompleted = createAction('[Budgets] Save budget completed', props<{ budget: BudgetDTO }>());
export const saveBudgetFailed = createAction('[Budgets] Save budget failed', props<{ error: any }>());

export const deleteBudget = createAction('[Budgets] Delete budget', props<{ budget: BudgetDTO }>());
export const deleteBudgetCompleted = createAction('[Budgets] Delete budget completed', props<{ budget: BudgetDTO }>());
export const deleteBudgetCancelled = createAction('[Budgets] Delete budget cancelled');
export const deleteBudgetFailed = createAction('[Budgets] Delete budget failed', props<{ error: any }>());

export const selectBudget = createAction('[Budgets] Select budget', props<{ filters?: BudgetFilters }>());
export const selectionDialogOpened = createAction('[Budgets] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Budgets] Close selection dialog');
export const budgetSelected = createAction('[Budgets] Budget selected', props<{ budget: BudgetDTO }>());

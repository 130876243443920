import { createAction, props } from '@ngrx/store';
import { Offer } from 'src/app/commons/models/offer.model';

import { DaysDistribution, DaysDistributionDTO, DaysDistributionFilters } from '../../commons/models/days-distribution.model';

export const loadDaysDistributions = createAction('[DaysDistributions] Load daysDistributions', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: DaysDistributionFilters, includes?: string[] }>());
export const loadDaysDistributionsCompleted = createAction('[DaysDistributions] Load daysDistributions Completed', props<{ daysDistributions: DaysDistributionDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: DaysDistributionFilters, includes?: string[] }>());
export const loadDaysDistributionsFailed = createAction('[DaysDistributions] Load daysDistributions Failed', props<{ error: any }>());

export const changePage = createAction('[DaysDistributions] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[DaysDistributions] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[DaysDistributions] Change filters', props<{ filters: DaysDistributionFilters }>());

export const editDaysDistribution = createAction('[DayDistributions] Edit daysDistribution', props<{ daysDistribution?: DaysDistribution, offer?: Offer }>());
export const daysDistributionDialogOpened = createAction('[DayDistributions] Detail dialog opened', props<{ dialogId: string }>());
export const closeDaysDistributionDialog = createAction('[DayDistributions] Close detail dialog');

export const saveDaysDistribution = createAction('[DayDistributions] Save daysDistribution', props<{ daysDistribution: DaysDistribution }>());
export const saveDaysDistributionCompleted = createAction('[DayDistributions] Save daysDistribution completed', props<{ daysDistribution: DaysDistributionDTO }>());
export const saveDaysDistributionFailed = createAction('[DayDistributions] Save daysDistribution failed', props<{ error: any }>());

export const deleteDaysDistribution = createAction('[DayDistributions] Delete daysDistribution', props<{ daysDistribution: DaysDistributionDTO }>());
export const deleteDaysDistributionCompleted = createAction('[DayDistributions] Delete daysDistribution completed', props<{ daysDistribution: DaysDistributionDTO }>());
export const deleteDaysDistributionCancelled = createAction('[DayDistributions] Delete daysDistribution cancelled');
export const deleteDaysDistributionFailed = createAction('[DayDistributions] Delete daysDistribution failed', props<{ error: any }>());

export const selectDaysDistribution = createAction('[DayDistributions] Select daysDistribution', props<{ filters?: DaysDistributionFilters }>());
export const selectionDialogOpened = createAction('[DayDistributions] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[DayDistributions] Close selection dialog');
export const daysDistributionSelected = createAction('[DayDistributions] daysDistribution selected', props<{ daysDistribution: DaysDistributionDTO }>());

import { createSelector } from '@ngrx/store';

import { selectRiskAreaState } from '../reducers';
import { RiskAreaState } from '../reducers/risk-area.reducer';

// RiskArea Selectors

export const getRiskAreasTableState = createSelector(
  selectRiskAreaState,
  (state: RiskAreaState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getRiskAreas = createSelector(
  selectRiskAreaState,
  (state: RiskAreaState) => state.list
);

export const getTotalRiskAreas = createSelector(
  getRiskAreasTableState,
  (tableState) => tableState.total
);

export const getRiskAreasCurrentPage = createSelector(
  getRiskAreasTableState,
  (tableState) => tableState.currentPage
);

export const getRiskAreasPerPage = createSelector(
  getRiskAreasTableState,
  (tableState) => tableState.perPage
);

export const getRiskAreasOrder = createSelector(
  getRiskAreasTableState,
  (tableState) => tableState.order
);

export const getRiskAreasDirection = createSelector(
  getRiskAreasTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getRiskAreasTableState,
  (tableState) => tableState.filters
);

export const getRiskAreaDialogId = createSelector(
  selectRiskAreaState,
  (state: RiskAreaState) => state.dialogId
);

export const getSelectedRiskArea = createSelector(
  selectRiskAreaState,
  (state: RiskAreaState) => state.riskArea
);
export const getSelectionDialogId = createSelector(
  selectRiskAreaState,
  (state: RiskAreaState) => state.selectionDialogId
);

import { createSelector } from '@ngrx/store';

import { selectAuthState } from '../reducers';
import { AuthState } from '../reducers/auth.reducers';

export const getCurrentUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.currentUser
);

export const getAuthToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.token
);

export const getIsAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => !!state.currentUser
);


import { createSelector } from '@ngrx/store';
import { Expense } from 'src/app/commons/models/expense.model';
import { DailyExpense } from 'src/app/modules/home/expenses/daily-expenses/daily-expenses.component';

import { selectExpenseState } from '../reducers';
import { ExpenseState } from '../reducers/expense.reducer';

// Expense Selectors

export const getExpensesTableState = createSelector(
  selectExpenseState,
  (state: ExpenseState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getExpenses = createSelector(
  selectExpenseState,
  (state: ExpenseState) => state.list
);

export const getTotalExpenses = createSelector(
  getExpensesTableState,
  (tableState) => tableState.total
);

export const getExpensesCurrentPage = createSelector(
  getExpensesTableState,
  (tableState) => tableState.currentPage
);

export const getExpensesPerPage = createSelector(
  getExpensesTableState,
  (tableState) => tableState.perPage
);

export const getExpensesOrder = createSelector(
  getExpensesTableState,
  (tableState) => tableState.order
);

export const getExpensesDirection = createSelector(
  getExpensesTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getExpensesTableState,
  (tableState) => tableState.filters
);

export const getExpensesReportTableState = createSelector(
  selectExpenseState,
  (state: ExpenseState) => { return { total: state.totalReport, currentPage: state.currentPageReport, perPage: state.perPage, order: state.orderReport, direction: state.directionReport, filters: state.filtersReport } }
);

export const getExpensesReport = createSelector(
  selectExpenseState,
  (state: ExpenseState) => state.listReport
);

export const getTotalExpensesReport = createSelector(
  getExpensesReportTableState,
  (tableState) => tableState.total
);

export const getExpensesCurrentPageReport = createSelector(
  getExpensesReportTableState,
  (tableState) => tableState.currentPage
);

export const getExpensesPerPageReport = createSelector(
  getExpensesReportTableState,
  (tableState) => tableState.perPage
);

export const getExpensesOrderReport = createSelector(
  getExpensesReportTableState,
  (tableState) => tableState.order
);

export const getExpensesDirectionReport = createSelector(
  getExpensesReportTableState,
  (tableState) => tableState.direction
);

export const getFiltersReport = createSelector(
  getExpensesReportTableState,
  (tableState) => tableState.filters
);

export const getExpenseDialogId = createSelector(
  selectExpenseState,
  (state: ExpenseState) => state.dialogId
);

export const getSelectedExpense = createSelector(
  selectExpenseState,
  (state: ExpenseState) => state.expense
);
export const getSelectionDialogId = createSelector(
  selectExpenseState,
  (state: ExpenseState) => state.selectionDialogId
);

export const getSelectedExpenses = createSelector(
  selectExpenseState,
  (state: ExpenseState) => state.selectedExpenses
);

export const getSelectedExpensesCurrentPage = createSelector(
  selectExpenseState,
  (state: ExpenseState) => {
    const selectedIds = state.selectedExpenses.map(bl => bl.id);
    return state.list.filter(bl => selectedIds.includes(bl.id));
  }
);

export const getDailyExpenses = createSelector(
  selectExpenseState,
  (state: ExpenseState) => {
    if (state.list && state.list.length > 0) {
      const dailyExpenses: Map<string, DailyExpense> = new Map<string, DailyExpense>()
      const expenses = state.list.map(dto => new Expense(dto))
      expenses.forEach(expense => {
        const reducedExpenseId: string = expense.date.toString() + expense.projectId.toString()
        let dailyExpense: DailyExpense = dailyExpenses.get(reducedExpenseId)
        if (! dailyExpense) {
          dailyExpense = new DailyExpense(expense.date, expense.project)
        }
        dailyExpense.sumExpense(expense)
        dailyExpenses.set(reducedExpenseId, dailyExpense)
      })
      const dailyExpensesSorted = new Map([...dailyExpenses].sort(
        (firstRow, secondRow) => {
          const firstRowDate = new Date(firstRow[0].substring(0, 10))
          const secondRowDate = new Date(secondRow[0].substring(0, 10))
          if (firstRowDate < secondRowDate) return -1
          return 1
        }
      ))
      return Array.from(dailyExpensesSorted.values())
    }
    return []
  }
)

import { createSelector } from '@ngrx/store';

import { selectContactState } from '../reducers';
import { ContactState } from '../reducers/contact.reducer';

// Contact Selectors

export const getContactsTableState = createSelector(
  selectContactState,
  (state: ContactState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getContacts = createSelector(
  selectContactState,
  (state: ContactState) => state.list
);

export const getTotalContacts = createSelector(
  getContactsTableState,
  (tableState) => tableState.total
);

export const getContactsCurrentPage = createSelector(
  getContactsTableState,
  (tableState) => tableState.currentPage
);

export const getContactsPerPage = createSelector(
  getContactsTableState,
  (tableState) => tableState.perPage
);

export const getContactsOrder = createSelector(
  getContactsTableState,
  (tableState) => tableState.order
);

export const getContactsDirection = createSelector(
  getContactsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getContactsTableState,
  (tableState) => tableState.filters
);

export const getContactDialogId = createSelector(
  selectContactState,
  (state: ContactState) => state.dialogId
);

export const getSelectedContact = createSelector(
  selectContactState,
  (state: ContactState) => state.contact
);
export const getSelectionDialogId = createSelector(
  selectContactState,
  (state: ContactState) => state.selectionDialogId
);
export const getCurrentFormControlName = createSelector(
  selectContactState,
  (state: ContactState) => state.currentFormControlName
);

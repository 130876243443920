import { Action, createReducer, on } from '@ngrx/store';
import { NationDTO } from 'src/app/commons/models/nation.model';

import { DefaultValuesDTO } from '../../commons/models/default-values.model';
import * as DefaultValuesActions from '../actions/default-values.actions';

export interface DefaultValuesState {
  defaultValues: DefaultValuesDTO;
  nations: NationDTO[];
  regions: string[];
  dialogId: string;
}

const initialState: DefaultValuesState = {
  defaultValues: null,
  nations: [],
  regions: ['Abruzzo', 'Basilicata', 'Calabria', 'Campania', 'Emilia-Romagna', 'Friuli-Venezia Giulia', 'Lazio', 'Liguria', 'Lombardia', 'Marche', 'Molise', 'Piemonte', 'Puglia', 'Sardegna', 'Sicilia', 'Toscana', 'Trentino-Alto Adige', 'Umbria', "Valle d'Aosta", 'Veneto'],
  dialogId: null,
};

const defaultValuesReducer = createReducer(
  initialState,
  on(
    DefaultValuesActions.loadDefaultValuesCompleted,
    (state, { defaultValues }) => {
      return { ...state, defaultValues };
    }
  ),
  on(
    DefaultValuesActions.saveDefaultValuesCompleted,
    (state, { defaultValues }) => {
      return { ...state, defaultValues };
    }
  ),
  on(DefaultValuesActions.defaultValuesDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(DefaultValuesActions.loadNationsCompleted, (state, { nations }) => {
    return { ...state, nations };
  }),
);

export function reducer(state: DefaultValuesState | undefined, action: Action) {
  return defaultValuesReducer(state, action);
}

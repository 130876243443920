import { createAction, props } from '@ngrx/store';
import { NationDTO } from 'src/app/commons/models/nation.model';

import { DefaultValues, DefaultValuesDTO } from '../../commons/models/default-values.model';

export const loadDefaultValues = createAction(
  '[DefaultValues] Load defaultValues'
);
export const loadDefaultValuesCompleted = createAction(
  '[DefaultValues] Load defaultValues Completed',
  props<{ defaultValues: DefaultValuesDTO }>()
);
export const loadDefaultValuesFailed = createAction(
  '[DefaultValues] Load defaultValues Failed',
  props<{ error: any }>()
);

export const loadNations = createAction('[DefaultValues] Load nations');
export const loadNationsCompleted = createAction(
  '[DefaultValues] Load nations Completed',
  props<{ nations: NationDTO[] }>()
);
export const loadNationsFailed = createAction(
  '[DefaultValues] Load nations Failed',
  props<{ error: any }>()
);

export const editDefaultValues = createAction(
  '[DefaultValues] Edit defaultValues',
  props<{ defaultValues: DefaultValues }>()
);
export const defaultValuesDialogOpened = createAction(
  '[DefaultValues] Detail dialog opened',
  props<{ dialogId: string }>()
);
export const closeDefaultValuesDialog = createAction(
  '[DefaultValues] Close detail dialog'
);

export const saveDefaultValues = createAction(
  '[DefaultValues] Save defaultValues',
  props<{ defaultValues: DefaultValues }>()
);
export const saveDefaultValuesCompleted = createAction(
  '[DefaultValues] Save defaultValues completed',
  props<{ defaultValues: DefaultValuesDTO }>()
);
export const saveDefaultValuesFailed = createAction(
  '[DefaultValues] Save defaultValues failed',
  props<{ error: any }>()
);

export const deleteDefaultValues = createAction(
  '[DefaultValues] Delete defaultValues',
  props<{ defaultValues: DefaultValuesDTO }>()
);
export const deleteDefaultValuesCompleted = createAction(
  '[DefaultValues] Delete defaultValues completed',
  props<{ defaultValues: DefaultValuesDTO }>()
);
export const deleteDefaultValuesCancelled = createAction(
  '[DefaultValues] Delete defaultValues cancelled'
);
export const deleteDefaultValuesFailed = createAction(
  '[DefaultValues] Delete defaultValues failed',
  props<{ error: any }>()
);

export const saveArchiveDate = createAction(
  '[DefaultValues] Save archive date',
  props<{ date: Date }>()
);
export const saveArchiveDateCompleted = createAction(
  '[DefaultValues] Save archive date completed',
  props<{ defaultValues: DefaultValuesDTO }>()
);
export const saveArchiveDateFailed = createAction(
  '[DefaultValues] Save archive date failed',
  props<{ error: any }>()
);

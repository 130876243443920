import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { environment } from 'src/environments/environment';

import { DefaultValuesDTO } from '../../models/default-values.model';
import { NationDTO } from '../../models/nation.model';

@Injectable({
  providedIn: 'root',
})
export class LaravelDefaultValuesService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment.baseUrl + '/api/default_values',
      nations: environment.baseUrl + '/api/nations',
      store: environment.baseUrl + '/api/default_values',
      destroy: environment.baseUrl + '/api/default_values',
      archive_date: environment.baseUrl + '/api/archive_date',
    };
  }

  public list(): Observable<DefaultValuesDTO> {
    return this.httpClient.get<DefaultValuesDTO>(this.ROUTES.list);
  }

  public nations(): Observable<NationDTO[]> {
    return this.httpClient.get<NationDTO[]>(this.ROUTES.nations);
  }

  public upsert(default_value: DefaultValuesDTO): Observable<DefaultValuesDTO> {
    if (default_value.id) {
      return this.httpClient.put<DefaultValuesDTO>(`${this.ROUTES.store}`, {
        default_value,
      });
    } else {
      return this.httpClient.post<DefaultValuesDTO>(`${this.ROUTES.store}`, {
        default_value,
      });
    }
  }

  public delete(): Observable<any> {
    return this.httpClient.delete(this.ROUTES.destroy);
  }

  public setArchiveDate(date: Date): Observable<DefaultValuesDTO> {
    return this.httpClient.put<DefaultValuesDTO>(this.ROUTES.archive_date, { date: formatDateForBackend(date) })
  }
}

<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    Filtra
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Titolo</mat-label>
        <input type="text" matInput formControlName="search">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Da</mat-label>
        <input matInput [matDatepicker]="fromDatePicker" formControlName="from" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>A</mat-label>
        <input matInput [matDatepicker]="toDatePicker" formControlName="to" />
        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Stato</mat-label>
        <mat-select formControlName="state" multiple>
          <mat-select-trigger>
            <span *ngIf="filtersForm.value.state?.length == statuses.length">Tutti</span>
            <span *ngIf="filtersForm.value.state?.length < statuses.length">
              {{(filtersForm.value.state ? filtersForm.value.state[0] : '')}}
              <span *ngIf="filtersForm.value.state?.length > 1" class="additional-selection">
                (+{{filtersForm.value.state.length - 1}}
                {{filtersForm.value.state?.length === 2 ? 'altro' : 'altri'}})
              </span>
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let state of statuses" [value]="state">
            {{state}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Cliente</mat-label>
        <app-client-field formControlName="client"></app-client-field>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Sales Leader</mat-label>
        <app-user-field formControlName="salesLeader"></app-user-field>
      </mat-form-field>
    </div>

    <span class="u-flex-full">
      <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
        (click)="applyFilters()">
        Filtra
      </button>
      <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
        <mat-icon>replay</mat-icon>
      </button>
    </span>
  </form>
</mat-expansion-panel>
import { createAction, props } from '@ngrx/store';

import { BusinessOpportunity, BusinessOpportunityDTO, BusinessOpportunityFilters } from '../../commons/models/business-opportunity.model';

export const loadBusinessOpportunities = createAction('[BusinessOpportunities] Load businessOpportunities', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: BusinessOpportunityFilters, includes?: string[] }>());
export const loadBusinessOpportunitiesCompleted = createAction('[BusinessOpportunities] Load businessOpportunities Completed', props<{ businessOpportunities: BusinessOpportunityDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: BusinessOpportunityFilters, includes?: string[] }>());
export const loadBusinessOpportunitiesFailed = createAction('[BusinessOpportunities] Load businessOpportunities Failed', props<{ error: any }>());

export const showBusinessOpportunity = createAction('[BusinessOpportunitys] Show businessOpportunity', props<{ businessOpportunity: BusinessOpportunityDTO }>());
export const addBusinessOpportunity = createAction('[BusinessOpportunity] Add businessOpportunity');
export const loadBusinessOpportunity = createAction('[BusinessOpportunities] Load businessOpportunity', props<{ id: number }>());
export const loadBusinessOpportunityCompleted = createAction('[BusinessOpportunities] Load businessOpportunity Completed', props<{ businessOpportunity: BusinessOpportunityDTO }>());
export const loadBusinessOpportunityFailed = createAction('[BusinessOpportunities] Load businessOpportunity Failed', props<{ error: any }>());

export const changePage = createAction('[BusinessOpportunities] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[BusinessOpportunities] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[BusinessOpportunities] Change filters', props<{ filters: BusinessOpportunityFilters }>());

export const editBusinessOpportunity = createAction('[BusinessOpportunities] Edit businessOpportunity', props<{ businessOpportunity: BusinessOpportunity }>());
export const businessOpportunityDialogOpened = createAction('[BusinessOpportunities] Detail dialog opened', props<{ dialogId: string }>());
export const closeBusinessOpportunityDialog = createAction('[BusinessOpportunities] Close detail dialog');
export const duplicateBusinessOpportunity = createAction('[BusinessOpportunities] Duplicate businessOpportunity', props<{ businessOpportunity: BusinessOpportunity }>());

export const changeBillable = createAction('[BusinessOpportunities] Change billable', props<{ businessOpportunity: BusinessOpportunity }>());
export const changePayable = createAction('[BusinessOpportunities] Change payable', props<{ businessOpportunity: BusinessOpportunity }>());

export const saveBusinessOpportunity = createAction('[BusinessOpportunities] Save businessOpportunity', props<{ businessOpportunity: BusinessOpportunity }>());
export const saveBusinessOpportunityCompleted = createAction('[BusinessOpportunities] Save businessOpportunity completed', props<{ businessOpportunity: BusinessOpportunityDTO }>());
export const saveBusinessOpportunityFailed = createAction('[BusinessOpportunities] Save businessOpportunity failed', props<{ error: any }>());

export const deleteBusinessOpportunity = createAction('[BusinessOpportunities] Delete businessOpportunity', props<{ businessOpportunity: BusinessOpportunityDTO }>());
export const deleteBusinessOpportunityCompleted = createAction('[BusinessOpportunities] Delete businessOpportunity completed', props<{ businessOpportunity: BusinessOpportunityDTO }>());
export const deleteBusinessOpportunityCancelled = createAction('[BusinessOpportunities] Delete businessOpportunity cancelled');
export const deleteBusinessOpportunityFailed = createAction('[BusinessOpportunities] Delete businessOpportunity failed', props<{ error: any }>());

export const selectBusinessOpportunity = createAction('[BusinessOpportunities] Select businessOpportunity', props<{ filters?: BusinessOpportunityFilters }>());
export const selectionDialogOpened = createAction('[BusinessOpportunities] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[BusinessOpportunities] Close selection dialog');
export const businessOpportunitySelected = createAction('[BusinessOpportunities] BusinessOpportunity selected', props<{ businessOpportunity: BusinessOpportunityDTO }>());

<app-client-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-client-filters>
<mat-card class="u-center-text" *ngIf="!(clients | async).length">
  <h4>Nessun cliente trovato</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addClient()">
    Aggiungi
  </button>
</mat-card>
<div class="table-container" [hidden]="!(clients | async).length">
  <table mat-table matSort [dataSource]="clients" multiTemplateDataRows matSortDisableClear matSortActive="name"
    matSortDirection="asc">
    <ng-container matColumnDef="name" sticky>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome breve</th>
      <td mat-cell *matCellDef="let row">
        {{ row.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="company_name">
        Ragione sociale completa
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.companyName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="PEC">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>PEC</th>
      <td mat-cell *matCellDef="let row">
        {{ row.PEC }}
      </td>
    </ng-container>
    <ng-container matColumnDef="clientLeader">
      <th mat-header-cell *matHeaderCellDef>Client leaders</th>
      <td mat-cell *matCellDef="let row">
        <span *ngFor="let clientLeader of row.clientBonuses; let i = index">{{ clientLeader.user?.fullName
          }}<span *ngIf="i < row.clientBonuses.length - 1">,&nbsp;</span></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef>Note</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.note" [matTooltip]="row.note">note</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation(); addClient()">
          <mat-icon matTooltip="Aggiungi">playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button (click)="$event.stopPropagation(); editClient(row)">
            <mat-icon matTooltip="Modifica">create</mat-icon>
          </button>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectClient(row)">
          Seleziona
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail">
          <div class="element-info">

            <app-detail label="P.IVA" [value]="element.VAT"></app-detail>
            <app-detail label="Codice fiscale" [value]="element.identifier"></app-detail>
            <app-detail label="E-mai" [value]="element.email"></app-detail>
            <app-detail label="E-mail amministrazione" [value]="element.adminEmail"></app-detail>
            <app-detail label="Indirizzo" [value]="element.fullAddress"></app-detail>
            <app-detail *ngIf="!!element.bonusClient" label="Bonus cliente"
              [value]="element.bonusClient + '%'"></app-detail>
            <app-detail label="Valuta compensi" [value]="element.currency?.name"></app-detail>
            <app-detail label="Tipologia pagamento" [value]="element.payment?.code"></app-detail>
            <app-detail label="Regime IVA" [value]="element.ivaTypology?.code"></app-detail>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"
      (click)="expandedElement = expandedElement === element ? null : element"></tr>
    <tr mat-row *matRowDef="let element; columns: ['expandedDetail']"
      [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
<app-expense-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-expense-filters>
<mat-card class="u-center-text" *ngIf="!expenses.length">
  <h4>Nessuna spesa trovata</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addExpense()">
    Aggiungi
  </button>
</mat-card>
<div class="table-container" [hidden]="!expenses.length">
  <table mat-table matSort matSortDisableClear [dataSource]="expenses" multiTemplateDataRows>
    <ng-container matColumnDef="multipleSelection">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container *ngIf="!!selectionMode">
          <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
            [checked]="selectedExpenses?.length > 0  && isAllSelected()"
            [indeterminate]="selectedExpenses?.length > 0 && !isAllSelected()">
          </mat-checkbox>
        </ng-container>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="!!selectionMode">
          <mat-checkbox color="primary" (change)="toggleSelectedExpense(row)" [checked]="isSelected(row)"
            (click)="$event.stopPropagation()" [disabled]="!isSelectable(row)" matTooltipClass="tooltip_multiline"
            [matTooltip]="tooltip(row)">
          </mat-checkbox>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let row">
        {{ row.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="project" sticky>
      <th mat-header-cell mat-sort-header="project" *matHeaderCellDef>
        Commessa
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.project?.identifier }} - {{ row.project.description }}
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell mat-sort-header="client" *matHeaderCellDef>
        Cliente
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.project?.client?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="refundType">
      <th mat-header-cell *matHeaderCellDef>Rimborso</th>
      <td mat-cell *matCellDef="let row">
        {{ row.project.expensesRefundType }}
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell mat-sort-header="professional" *matHeaderCellDef>
        Professionista
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.professional?.user?.name }}
        {{ row.professional?.user?.lastname }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell mat-sort-header="date" *matHeaderCellDef>Data</th>
      <td mat-cell *matCellDef="let row">
        {{ row.date | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Tipologia</th>
      <td mat-cell *matCellDef="let row">
        {{ row.type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Importo</th>
      <td mat-cell *matCellDef="let row" class="text-right">
        <span *ngIf="row.amount" class="no-wrap">{{ row.amount | customCurrency }}&nbsp;€</span>
        <!-- <span *ngIf="row.km">{{ row.km | customCurrency }}&nbsp;€</span> -->
      </td>
    </ng-container>

    <ng-container matColumnDef="payer">
      <th mat-header-cell *matHeaderCellDef>Pagatore</th>
      <td mat-cell *matCellDef="let row">
        {{ row.payer }}
      </td>
    </ng-container>

    <ng-container matColumnDef="attachment">
      <th mat-header-cell *matHeaderCellDef>Giustificativo</th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.attachment">
          <a [href]="row.attachmentUrl" target="_blank">
            <mat-icon color="primary">attachment</mat-icon>
          </a>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="billable">
      <th mat-header-cell *matHeaderCellDef>Fatturabile al cliente</th>
      <td mat-cell *matCellDef="let row">
        <span [ngSwitch]="row.clientStatus">
          <span *ngSwitchCase="'Pagata'" class="badge badge--success"
            [matTooltip]="'Fattura n°'+row.billingLine.invoice?.invoiceIdentifier">
            Pagata
          </span>
          <span *ngSwitchCase="'Fatturata'" class="badge badge--info"
            [matTooltip]="'Fattura n°'+row.billingLine.invoice?.invoiceIdentifier">
            Fatturata
          </span>
          <span *ngSwitchCase="'Fatturabile'" class="badge badge--warning">
            Fatturabile
          </span>
          <span *ngSwitchCase="'Non prevista'" class="badge badge--outline_error"
            matTooltip="Non prevista la fatturazione verso il cliente per questa attività">
            Non prevista
          </span>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="validated">
      <th mat-header-cell *matHeaderCellDef>Nulla Osta</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.validated" [matTooltip]="row.validationUser?.fullName">
          done_outline
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="payable">
      <th mat-header-cell *matHeaderCellDef>Pagabile al professionista</th>
      <td mat-cell *matCellDef="let row">
        <span [ngSwitch]="row.professionalStatus">
          <span *ngSwitchCase="'Pagata'" class="badge badge--success"
            [matTooltip]="'Pagata al professionista - Fattura n°'+row.costLine?.invoiceNumber">
            Pagata
          </span>
          <span *ngSwitchCase="'Fatturata'"
            [matTooltip]="'Fatturata dal professionista - Fattura n°'+row.costLine?.invoiceNumber"
            class="badge badge--info">
            Fatturata
          </span>
          <span *ngSwitchCase="'Fatturabile'" class="badge badge--warning" matTooltip="Fatturabile dal professionista">
            Fatturabile
          </span>
          <span *ngSwitchCase="'Contabilizzata'" class="badge">
            Contabilizzata
          </span>
          <span *ngSwitchCase="'Non prevista'" class="badge badge--outline_error"
            matTooltip="Non prevista la fatturazione verso il professionista per questa attività">
            Non prevista
          </span>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef>Note</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.notes" [matTooltip]="row.notes">description</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation(); addExpense()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button *ngIf="currentUser.admin ||
                          (!row.hasGeneatedLInes && (
                            currentUser.id == row.professional?.user?.id ||
                              row.project.headProject?.user.id == currentUser.id
                          )
                        )
                          " mat-menu-item (click)="editExpense(row)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
            <button *ngIf="canDuplicate" mat-menu-item (click)="duplicateExpense(row)">
              <mat-icon>content_copy</mat-icon>
              Duplica
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="onAccount.emit(row)" *ngIf="currentUser.admin && row.accountable">
              <mat-icon>receipt_long</mat-icon>
              Contabilizza
            </button>
            <button mat-menu-item (click)="onRemoveAccount.emit(row)" *ngIf="currentUser.admin && !row.accountable"
              [disabled]="!row.accountableCancellable" [matTooltip]="row.notAccountableCancellableTooltip"
              matTooltipClass="tooltip_multiline">
              <mat-icon>cancel_presentation</mat-icon>
              Annulla contabilizzazione
            </button>
            <!-- <button *ngIf="(currentUser.admin ||
            row.project.headProject?.user.id == currentUser.id)" mat-menu-item (click)="changeBillable(row)">
              <mat-icon>receipt_long</mat-icon>
              <span *ngIf="row.billable">Non &nbsp;</span>Fatturabile
            </button>
            <button *showFor="['admin']" mat-menu-item (click)="changePayable(row)">
              <mat-icon>paid</mat-icon>
              <span *ngIf="row.payable">Non &nbsp;</span>Pagabile
            </button> -->
            <button *ngIf="
                !row.validated &&
                (currentUser.admin ||
                  row.project.headProject?.user.id == currentUser.id)
              " mat-menu-item (click)="validateExpense(row)">
              <mat-icon>check_circle</mat-icon>
              Nulla Osta
            </button>
            <button *ngIf="
                row.validated &&
                !row.payable &&
                !row.billable &&
                (currentUser.admin ||
                  row.project.headProject?.user.id == currentUser.id)
              " mat-menu-item (click)="unvalidateExpense(row)">
              <mat-icon>cached</mat-icon>
              Annulla Nulla Osta
            </button>
          </mat-menu>
        </ng-container>

        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectExpense(row)">
          Seleziona
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail">
          <div class="element-info">
            <h4>Verso il cliente</h4>
            <app-detail label="Fattura n°" [value]="element.billingLine?.invoice?.invoiceIdentifier"></app-detail>
            <app-detail label="Data fattura"
              [value]="element.billingLine?.invoice?.date | date : 'dd/MM/yyyy'"></app-detail>
            <app-detail *ngIf="element.billingLine?.invoice?.amount" label="Importo"
              [value]="(element.billingLine?.invoice?.amount | customCurrency) + ' €'"></app-detail>
            <app-detail label="Data pagamento"
              [value]="element.billingLine?.invoice?.paymentDate | date : 'dd/MM/yyyy'"></app-detail>

            <h4>Verso il Professionista</h4>
            <app-detail label="Fattura n°" [value]="element.costLine?.invoiceNumber"></app-detail>
            <app-detail label="Data fattura" [value]="element.costLine?.invoiceDate | date : 'dd/MM/yyyy'"></app-detail>
            <app-detail *ngIf="element.costLine?.amount" label="Importo"
              [value]="(element.costLine?.amount | customCurrency) + ' €'"></app-detail>
            <app-detail label="Data pagamento"
              [value]="element.costLine?.invoicePaymentDate | date : 'dd/MM/yyyy'"></app-detail>

          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"
      (click)="expandedElement = expandedElement === element ? null : element"></tr>
    <tr mat-row *matRowDef="let element; columns: ['expandedDetail']"
      [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>

<mat-expansion-panel [expanded]="isSelectedExpenses">
  <div class="button-container" *ngIf="!!selectionMode">
    <span [ngSwitch]="selectionMode">
      <button mat-raised-button *ngSwitchCase="'account'" color="primary"
        (click)="onMultipleAccount.emit(false)">Contabilizza</button>
      <button mat-raised-button *ngSwitchCase="'removeAccount'" color="primary"
        (click)="onMultipleAccount.emit(true)">Annulla Contabilizzazione</button>
      <button mat-raised-button *ngSwitchCase="'validate'" color="primary" (click)="onMultipleValidate.emit(true)">Nulla
        osta</button>
      <button mat-raised-button *ngSwitchCase="'validated'" color="primary"
        (click)="onMultipleValidate.emit(false)">Annulla Nulla
        osta</button>
    </span>
  </div>
</mat-expansion-panel>
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NgControl, Validators} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {MatMenuTrigger} from '@angular/material/menu';
import {Subject} from 'rxjs';
import {environment} from 'src/environments/environment';


export const ATTACHMENT_MAT_FORM_FIELD_CONTROL: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => AttachmentUploadComponent),
};

@Component({
  selector: "app-attachment-upload",
  templateUrl: "./attachment-upload.component.html",
  styleUrls: ["./attachment-upload.component.scss"],
  providers: [ATTACHMENT_MAT_FORM_FIELD_CONTROL],
  host: {
    "[class.floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class AttachmentUploadComponent
  implements ControlValueAccessor, MatFormFieldControl<any> {

  private unsubscribe$ = new Subject<void>();
  private _value: any;
  private _disabled = false;

  private _required = false;

  baseUrl = environment.baseUrl;
  onChange: Function;

  @Input()
  accept: string = "image/png,image/jpeg,application/pdf";

  @Input()
  attachment: string;

  @Input()
  attachmentName: string;

  @Input()
  attachmentPath: string;

  get errorState(): boolean {
    return !this.ngControl.valid;
  }

  focused: boolean; //TODO
  controlType?: string = "attachment-upload";
  autofilled?: boolean;
  clientAriaDescribedBy?: string;

  @ViewChild('attachmentInput')
  input: ElementRef<HTMLInputElement>;

  @ViewChild(MatMenuTrigger)
  trigger: MatMenuTrigger;

  constructor(
    private host: ElementRef<HTMLInputElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  stateChanges = new Subject<void>();
  id: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return this._required || this.ngControl?.control?.hasValidator(Validators.required) || false;
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    //TODO
  }
  onContainerClick(event: MouseEvent): void {
    if (!this.disabled) {
      this.trigger.openMenu();
      // this.input.nativeElement.click();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stateChanges.complete();
  }


  clear() {
    this.value = null;
  }

  set value(value: any) {
    this._value = value;
    this.notifyValueChange();
    this.stateChanges.next();
  }

  get value(): any {
    return this._value;
  }

  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(value) {
    // clear file input
    this.host.nativeElement.value = "";
    this.value = value;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.registerOnTouched;
    }
  }

  insertUrl() {
    const url = window.prompt('Url esterno', this.attachment?.startsWith('http') ? this.attachment : '');
    this.value = url;
    this.attachment = this.value
    this.attachmentName = null;
    this.onChange(this.value);
  }

  get href() {
    if (this.attachment?.startsWith('http')) {
      return this.attachment;
    }
    return `${this.baseUrl}/${this.attachment}`;
  }

  @HostListener("change", ["$event.target.files"]) emitFiles(event: FileList) {
    const file = event && event.item(0);
    if (!!file) {
      this.value = file;
      this.onChange(this.value);
    }
  }
}

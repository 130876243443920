import { createAction, props } from '@ngrx/store';

import { Expense, ExpenseDTO, ExpenseFilters, ExpenseReportDTO } from '../../commons/models/expense.model';

export const loadExpenses = createAction(
  '[Expenses] Load expenses',
  props<{
    page: number;
    perPage: number;
    order?: string;
    direction?: string;
    filters?: ExpenseFilters;
    includes?: string[];
  }>()
);
export const loadExpensesCompleted = createAction(
  '[Expenses] Load expenses Completed',
  props<{
    expenses: ExpenseDTO[];
    currentPage: number;
    total: number;
    perPage: number;
    order?: string;
    direction?: string;
    filters?: ExpenseFilters;
    includes?: string[];
  }>()
);
export const loadExpensesFailed = createAction(
  '[Expenses] Load expenses Failed',
  props<{ error: any }>()
);

export const changePage = createAction(
  '[Expenses] Change page',
  props<{ page: number; size: number }>()
);
export const changeSort = createAction(
  '[Expenses] Change sort',
  props<{ order: string; direction: string }>()
);
export const changeFilters = createAction(
  '[Expenses] Change filters',
  props<{ filters: ExpenseFilters }>()
);

export const loadExpensesReport = createAction(
  '[Expenses] Load activitie report',
  props<{
    page: number;
    perPage: number;
    order?: string;
    direction?: string;
    filters?: ExpenseFilters;
    includes?: string[];
  }>()
);
export const loadExpensesReportCompleted = createAction(
  '[Expenses] Load expenses report Completed',
  props<{
    expenses: ExpenseReportDTO[];
    currentPage: number;
    total: number;
    perPage: number;
    order?: string;
    direction?: string;
    filters?: ExpenseFilters;
    includes?: string[];
  }>()
);
export const loadExpensesReportFailed = createAction(
  '[Expenses] Load expenses report Failed',
  props<{ error: any }>()
);

export const changePageReport = createAction(
  '[Expenses] Change page report',
  props<{ page: number; size: number }>()
);
export const changeSortReport = createAction(
  '[Expenses] Change sort report',
  props<{ order: string; direction: string }>()
);
export const changeFiltersReport = createAction(
  '[Expenses] Change filters report',
  props<{ filters: ExpenseFilters }>()
);

export const editExpense = createAction(
  '[Expenses] Edit expense',
  props<{ expense: Expense, clone?: boolean }>()
);
export const expenseDialogOpened = createAction(
  '[Expenses] Detail dialog opened',
  props<{ dialogId: string }>()
);
export const closeExpenseDialog = createAction(
  '[Expenses] Close detail dialog'
);
export const duplicateExpense = createAction(
  '[Expenses] Duplicate expense',
  props<{ expense: Expense }>()
);

export const changePayable = createAction(
  '[Expenses] Change payable',
  props<{ expense: Expense, payable: boolean }>()
);
export const changeBillable = createAction(
  '[Expenses] Change billable',
  props<{ expense: Expense, billable: boolean }>()
);

export const saveExpense = createAction(
  '[Expenses] Save expense',
  props<{ expense: Expense }>()
);
export const saveExpenseCompleted = createAction(
  '[Expenses] Save expense completed',
  props<{ expense: ExpenseDTO }>()
);
export const saveExpenseFailed = createAction(
  '[Expenses] Save expense failed',
  props<{ error: any }>()
);

export const deleteExpense = createAction(
  '[Expenses] Delete expense',
  props<{ expense: ExpenseDTO }>()
);
export const deleteExpenseCompleted = createAction(
  '[Expenses] Delete expense completed',
  props<{ expense: ExpenseDTO }>()
);
export const deleteExpenseCancelled = createAction(
  '[Expenses] Delete expense cancelled'
);
export const deleteExpenseFailed = createAction(
  '[Expenses] Delete expense failed',
  props<{ error: any }>()
);

export const selectExpense = createAction(
  '[Expenses] Select expense',
  props<{ filters?: ExpenseFilters }>()
);
export const selectionDialogOpened = createAction(
  '[Expenses] Selection dialog opened',
  props<{ selectionDialogId: string }>()
);
export const closeSelectionDialog = createAction(
  '[Expenses] Close selection dialog'
);
export const expenseSelected = createAction(
  '[Expenses] Expense selected',
  props<{ expense: ExpenseDTO }>()
);

export const expenseReportExport = createAction('[ExpenseReport] Export');
export const expenseReportExportCompleted = createAction(
  '[ExpenseReport] Export Completed',
  props<{ data: string; type?: string }>()
);
export const expenseReportExportFailed = createAction(
  '[ExpenseReport] Export Failed',
  props<{ error: any }>()
);

export const addSelectedExpenses = createAction(
  '[Expenses] Add selected expenses',
  props<{ expense: Expense }>()
);
export const removeSelectedExpenses = createAction(
  '[Expenses] Remove selected expenses',
  props<{ expense: Expense }>()
);
export const resetSelectedExpenses = createAction(
  '[Expenses] Reset selected expenses'
);

export const multipleChangePayable = createAction(
  '[Expenses] Multiple change payable of selected expenses',
  props<{ payable: boolean }>()
);
export const multipleChangeBillable = createAction(
  '[Expenses] Multiple change billable of selected expenses',
  props<{ billable: boolean }>()
);
export const multipleChangeCancel = createAction(
  '[Expenses] Multiple change of selected expenses: canceled'
);

export const multipleSaveExpenseCompleted = createAction(
  '[Expenses] Multiple save expenses: completed',
  props<{ expenses: ExpenseDTO[] }>()
);
export const multipleSaveExpenseFailed = createAction(
  '[Expenses] Multiple save expenses: failed',
  props<{ error: any }>()
);

export const editValidation = createAction(
  '[Expenses] Edit validation',
  props<{ expense: Expense }>()
);
export const validateExpense = createAction(
  '[Expenses] Validate expense',
  props<{ date: string; expense: Expense }>()
);
export const validateExpenseCompleted = createAction(
  '[Expenses] Validate expense completed',
  props<{ expense: ExpenseDTO }>()
);
export const validateExpenseFailed = createAction(
  '[Expenses] Validate expense failed',
  props<{ error: any }>()
);

export const unvalidateExpense = createAction(
  '[Expenses] Unvalidate expense',
  props<{ expense: Expense }>()
);

export const editValidations = createAction(
  '[expenses] Edit validations',
  props<{ expenses: Expense[] }>()
);
export const multipleValidate = createAction(
  '[expenses] Multiple validate',
  props<{ date: string; expenses: Expense[] }>()
);
export const multipleValidateCompleted = createAction(
  '[expenses] Multiple validate completed',
  props<{ expenses: ExpenseDTO[] }>()
);
export const multipleValidateFailed = createAction(
  '[expenses] Multiple validate failed',
  props<{ error: any }>()
);

export const loadDailyExpenses = createAction(
  '[Expenses] Load daily expenses',
  props<{
    page: number;
    perPage: number;
    order?: string;
    direction?: string;
    filters?: ExpenseFilters;
    includes?: string[];
  }>()
);
export const changeFilterByDailyExpenses = createAction(
  '[Expenses] Change filter by daily expenses',
  props<{ filters: ExpenseFilters }>()
);

export const accountExpense = createAction(
  '[Expenses] Account expense',
  props<{ expense: ExpenseDTO, cancel?: boolean }>()
);
export const accountExpenses = createAction(
  '[Expenses] Account expenses',
  props<{ expenses: ExpenseDTO[], cancel?: boolean }>()
);
export const accountExpensesCompleted = createAction(
  '[Expenses] Account expenses completed',
  props<{ expenses: ExpenseDTO[] }>()
);
export const accountExpensesFailed = createAction(
  '[Expenses] Account expenses failed',
  props<{ error: any }>()
);

<ng-container [formGroup]="form">

  <ng-container *ngFor="let control of form.controls; let index = index" [formGroupName]="index">
    <div class="u-full-width daysDistribution">
        <button mat-icon-button mat-raised-button color="accent"
      (click)="removeDaysDistribution(index)" matTooltip="Rimuovi distribuzione giornata">
        <mat-icon>delete</mat-icon>
        </button>
      <mat-form-field class="u-full-width">
        <input matInput [matDatepicker]="fromDatePicker1" formControlName="date" placeholder="Mese" />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker1"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker1 startView="multi-year" (monthSelected)="setMonthAndYear($event,fromDatePicker1, index)"></mat-datepicker>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Persona</mat-label>
        <app-user-field formControlName="user"></app-user-field>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <!-- {{form.value | json}} -->

      <mat-form-field class="u-full-width">
        <mat-label>Giornate</mat-label>
        <input matInput formControlName="days" type="number" min="0" step="1" forceNumber>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>

  </ng-container>
</ng-container>
<div class="u-center-text">
  <button mat-raised-button type="button" color="accent" [disabled]="form.invalid" (click)="addDaysDistribution()">
    <span>Aggiungi distribuzione giornata</span>
  </button>
</div>





import { Action, createReducer, on } from '@ngrx/store';

import { Currency, CurrencyDTO } from '../../commons/models/currency.model';
import * as CurrencyActions from '../actions/currency.actions';
import { CurrencyFilters } from './../../commons/models/currency.model';

export interface CurrencyState {
  list: CurrencyDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: CurrencyFilters,
  dialogId: string,
  selectionDialogId: string,
  currency: Currency
};

const initialState: CurrencyState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  currency: null
};

const currencyReducer = createReducer(
  initialState,
  on(CurrencyActions.loadCurrenciesCompleted, (state, { currencies, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: currencies, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(CurrencyActions.currencyDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(CurrencyActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(CurrencyActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: CurrencyState | undefined, action: Action) {
  return currencyReducer(state, action);
}


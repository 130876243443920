import { createSelector } from '@ngrx/store';

import { selectOfferState } from '../reducers';
import { OfferState } from '../reducers/offer.reducer';

// Offer Selectors

export const getOffersTableState = createSelector(
  selectOfferState,
  (state: OfferState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getOffers = createSelector(
  selectOfferState,
  (state: OfferState) => state.list
);
export const getSelectionOffers = createSelector(
  selectOfferState,
  (state: OfferState) => state.selectionList
);

export const getTotalOffers = createSelector(
  getOffersTableState,
  (tableState) => tableState.total
);

export const getOffersCurrentPage = createSelector(
  getOffersTableState,
  (tableState) => tableState.currentPage
);

export const getOffersPerPage = createSelector(
  getOffersTableState,
  (tableState) => tableState.perPage
);

export const getOffersOrder = createSelector(
  getOffersTableState,
  (tableState) => tableState.order
);

export const getOffersDirection = createSelector(
  getOffersTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getOffersTableState,
  (tableState) => tableState.filters
);

export const getOfferDialogId = createSelector(
  selectOfferState,
  (state: OfferState) => state.dialogId
);

export const getSelectedOffer = createSelector(
  selectOfferState,
  (state: OfferState) => state.offer
);
export const getSelectionDialogId = createSelector(
  selectOfferState,
  (state: OfferState) => state.selectionDialogId
);
export const getOffer = createSelector(
  selectOfferState,
  (state: OfferState) => state.offer
);
export const getcurrentOffer = createSelector(
  selectOfferState,
  (state: OfferState) => state.currentOffer
);
export const getOfferId = createSelector(
  selectOfferState,
  (state: OfferState) => state.offerId
);

export const getSelectedBusinessOpportunity = createSelector(
  selectOfferState,
  (state: OfferState) => state.businessOpportunity
);
export const getOfferVersion = createSelector(
  selectOfferState,
  (state: OfferState) => state.offerVersion
);
export const getCurrentFormControlName = createSelector(
  selectOfferState,
  (state: OfferState) => state.currentFormControlName
);
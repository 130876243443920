<expense-report-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</expense-report-filters>
<mat-card class="u-center-text" *ngIf="!(expensesReport | async).length">
  <h4>Nessuna spesa trovata</h4>
</mat-card>
<div class="table-container" [hidden]="!(expensesReport | async).length">
  <table mat-table matSort matSortDisableClear [dataSource]="expensesReport">

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
      <td mat-cell *matCellDef="let row">
        {{ row.project?.client?.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Commessa </th>
      <td mat-cell *matCellDef="let row">
        {{ row.project?.identifier }} - {{row.project?.description}}
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Professionista </th>
      <td mat-cell *matCellDef="let row">
        {{ row.professional?.user?.name }} {{row.professional?.user?.lastname}}
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Mese </th>
      <td mat-cell *matCellDef="let row">
        {{row.date}}
      </td>
    </ng-container>

    <ng-container matColumnDef="payer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Pagatore </th>
      <td mat-cell *matCellDef="let row">
        {{row.payer}}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Importo </th>
      <td mat-cell *matCellDef="let row" class="text-right no-wrap">
        {{row.amount | customCurrency}}&nbsp;€
      </td>
    </ng-container>
    <!-- <ng-container matColumnDef="km">
      <th mat-header-cell *matHeaderCellDef> Chilometri </th>
      <td mat-cell *matCellDef="let row">
        {{row.km}}
      </td>
    </ng-container> -->

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation();exportExpenseReport()">
          <mat-icon>file_download</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
<div class="attachment-upload u-flex-full">
  <a *ngIf="attachment && attachment == value" style="overflow: scroll;" [href]="href" target="_blank"
    matTooltip="Apri file" (click)="$event.stopImmediatePropagation();">
    {{attachmentName || attachment}}
  </a>
  <ng-container *ngIf="value">
    <span *ngIf="value">
      {{value.name}}
    </span>
    <span>
      <button mat-icon-button type="button" [matMenuTriggerFor]="attachmentType"
        (click)="$event.stopImmediatePropagation()" matTooltip="Modifica">
        <mat-icon class="sidenav-icon">edit</mat-icon>
      </button>
      <button mat-icon-button type="button" (click)="$event.stopImmediatePropagation();clear()" matTooltip="Svuota">
        <mat-icon class="sidenav-icon">clear</mat-icon>
      </button>
    </span>
  </ng-container>
</div>
<span style="height: 1px; width: 1px; position: fixed" [matMenuTriggerFor]="attachmentType">
</span>
<mat-menu #attachmentType="matMenu">
  <button mat-menu-item (click)="attachmentInput.click()">
    File
  </button>
  <button mat-menu-item (click)="insertUrl()">
    URL esterno
  </button>
</mat-menu>
<input #attachmentInput type="file" [accept]="accept" style="display: none" (change)="onFileChange($event)">
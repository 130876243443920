import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Client } from 'src/app/commons/models/client.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as ClientActions from 'src/app/store/actions/client.actions';
import { AppState } from 'src/app/store/reducers';
import * as ClientSelectors from 'src/app/store/selectors/client.selectors';

import { ClientsColumn } from '../client-list/client-list.component';
import { ClientFilters } from './../../../commons/models/client.model';

@Component({
  selector: 'app-client-selection',
  templateUrl: './client-selection.component.html',
  styleUrls: ['./client-selection.component.scss'],
})
export class ClientSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  clients: Observable<Client[]>;
  filters: Observable<ClientFilters>;

  @Input()
  defaultFilters: ClientFilters = {
    search: '',
  };

  displayedColumns: ClientsColumn[] = [
    'name',
    'companyName',
    'PEC',
    'clientLeader',
    'note',
    'actions',
  ];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  private _includes: string[] = [
    'currency',
    'payment',
    'contacts',
    'client_leaders',
    'client_bonuses',
    'client_bonuses.user',
    'iva_typology',
    'bank_account',
  ];
  total: Observable<number>;

  canAdd: boolean;

  constructor(
    private store$: Store<AppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.clients = this.store$.pipe(
      select(ClientSelectors.getClients),
      takeUntil(this.unsubscribe$),
      map((dtos) => (dtos ? dtos.map((dto) => new Client(dto)) : null))
    );
    this.total = this.store$.pipe(
      select(ClientSelectors.getTotalClients),
      takeUntil(this.unsubscribe$)
    );
    this.filters = this.store$.pipe(
      select(ClientSelectors.getFilters),
      takeUntil(this.unsubscribe$)
    );
    if (data) {
      this.defaultFilters = data.defaultFilters;
      this.canAdd = data.canAdd;
    }
  }

  load() {
    this.store$.dispatch(
      ClientActions.loadClients({
        page: 1,
        perPage: this.pageSizeOptions[0],
        order: 'name',
        direction: 'asc',
        filters: this.defaultFilters,
        includes: this._includes,
      })
    );
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(
      ClientActions.changeSort({
        order: sort.active,
        direction: sort.direction,
      })
    );
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(
      ClientActions.changePage({
        page: pageEvent.pageIndex + 1,
        size: pageEvent.pageSize,
      })
    );
  }

  filtersChange(filters: ClientFilters) {
    this.store$.dispatch(ClientActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectClient(client: Client) {
    if (client) {
      this.store$.dispatch(
        ClientActions.clientSelected({ client: client.toDTO() })
      );
    }
  }

  addClient() {
    this.editClient();
  }

  editClient(client?: Client) {
    this.store$.dispatch(ClientActions.editClient({ client }));
  }

  close() {
    this.store$.dispatch(ClientActions.closeSelectionDialog());
  }
}

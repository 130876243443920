<ng-container [formGroup]="form">
  <ng-container
    *ngFor="let control of form.controls; let index = index"
    [formGroupName]="index"
  >
    <div class="u-full-width budget">
      <button
        mat-icon-button
        mat-raised-button
        color="accent"
        (click)="removeBudget(index)"
        matTooltip="Rimuovi budget"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <mat-form-field>
        <input matInput placeholder="Nome" formControlName="name" type="text" />
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Budget"
          formControlName="budget"
          type="number"
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>Budget non valido</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Budget ore"
          formControlName="hours"
          type="number"
        />
        <mat-error>Campo obbligatorio</mat-error>
        <mat-hint *ngIf="control?.value.hours && control?.value.budget">
          Ricavo giornaliero:
          {{
            (control?.value.budget / control?.value.hours) * 8 | customCurrency
          }}&nbsp;€
        </mat-hint>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="fromDatePicker1"
          formControlName="start"
          placeholder="Inizio"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="fromDatePicker1"
        ></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker1></mat-datepicker>
        <mat-error>Data non valida</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="fromDatePicker2"
          formControlName="end"
          placeholder="Fine"
          [min]="control.controls['start']?.value"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="fromDatePicker2"
        ></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker2></mat-datepicker>
        <mat-error>Data non valida</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Note"
          formControlName="notes"
          type="text"
        />
      </mat-form-field>
    </div>
  </ng-container>
</ng-container>
<div class="u-center-text">
  <button mat-raised-button type="button" color="accent" [disabled]="form.invalid" (click)="addBudget()">
    <span>Aggiungi fase di progetto</span>
  </button>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: Date): unknown {
    if(value) {
      return value.toLocaleDateString('it-IT', { timeZone: 'Europe/Rome' });
    }
   
  }

}
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { RiskOwner } from '../../../commons/models/risk-owner.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { RiskOwnerFilters } from './../../../commons/models/risk-owner.model';

export type RiskOwnersColumn = "id" | "name" |  "actions";

@Component({
  selector: 'risk-owner-list',
  templateUrl: './risk-owner-list.component.html',
  styleUrls: ['./risk-owner-list.component.scss'],
})
export class RiskOwnerListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  riskOwners: Observable<RiskOwner[]>;

  @Input()
  defaultFilters: RiskOwnerFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: RiskOwner | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: RiskOwnersColumn[] = ["id", "name", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<RiskOwnerFilters> = new EventEmitter<RiskOwnerFilters>();

  @Output()
  onSelectRiskOwner: EventEmitter<RiskOwner> = new EventEmitter<RiskOwner>();
  @Output()
  onAddRiskOwner: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditRiskOwner: EventEmitter<RiskOwner> = new EventEmitter<RiskOwner>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addRiskOwner() {
    this.onAddRiskOwner.emit();
  }

  editRiskOwner(riskOwner: RiskOwner) {
    this.onEditRiskOwner.emit(riskOwner);
  }

  selectRiskOwner(riskOwner: RiskOwner) {
    this.onSelectRiskOwner.emit(riskOwner);
  }

  onFilter(filters: RiskOwnerFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

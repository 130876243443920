import { createSelector } from '@ngrx/store';

import { selectTargetState } from '../reducers';
import { TargetState } from '../reducers/target.reducer';

// Target Selectors

export const getTargetsTableState = createSelector(
  selectTargetState,
  (state: TargetState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getTargets = createSelector(
  selectTargetState,
  (state: TargetState) => state.list
);

export const getTotalTargets = createSelector(
  getTargetsTableState,
  (tableState) => tableState.total
);

export const getTargetsCurrentPage = createSelector(
  getTargetsTableState,
  (tableState) => tableState.currentPage
);

export const getTargetsPerPage = createSelector(
  getTargetsTableState,
  (tableState) => tableState.perPage
);

export const getTargetsOrder = createSelector(
  getTargetsTableState,
  (tableState) => tableState.order
);

export const getTargetsDirection = createSelector(
  getTargetsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getTargetsTableState,
  (tableState) => tableState.filters
);

export const getTargetDialogId = createSelector(
  selectTargetState,
  (state: TargetState) => state.dialogId
);

export const getSelectedTarget = createSelector(
  selectTargetState,
  (state: TargetState) => state.target
);
export const getSelectionDialogId = createSelector(
  selectTargetState,
  (state: TargetState) => state.selectionDialogId
);

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EXPENSES, ExpenseType, REFUNDS } from 'src/app/commons/models/project.model';

import { EXPENSE_PAYERS, ExpenseFilters, ExpensePayer } from '../../../commons/models/expense.model';

@Component({
  selector: 'app-expense-filters',
  templateUrl: './expense-filters.component.html',
  styleUrls: ['./expense-filters.component.scss']
})
export class ExpenseFiltersComponent {
  @Output()
  onFilter: EventEmitter<ExpenseFilters> = new EventEmitter();

  private _defaultFilters: ExpenseFilters;
  filtersForm: FormGroup;

  expenseTypes: ExpenseType[] = EXPENSES;
  refundTypes: ExpenseType[] = REFUNDS;
  payers: ExpensePayer[] = EXPENSE_PAYERS;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
      start: "",
      end: "",
      client: "",
      payable: "",
      billable: "",
      project: "",
      head_project: "",
      expenses_debit_type: "",
      expenses_refund_type: "",
      payer: "",
      validated: "",
      professional: "",
      promo: "",
      client_invoice_id: "",
      professional_invoice_id: "",
      accounted: ""
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getExpenseFilters());
  }

  private _getExpenseFilters(): ExpenseFilters {
    let filters: ExpenseFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
      filters.start = values.start;
      filters.end = values.end;
      filters.client_id = values.client?.id;
      filters.payable = values.payable;
      filters.billable = values.billable;
      filters.project_id = values.project?.id;
      filters.head_project_id = values.head_project?.id
      filters.expenses_debit_type = values.expenses_debit_type;
      filters.expenses_refund_type = values.expenses_refund_type;
      filters.payer = values.payer;
      filters.validated = values.validated;
      filters.promo = values.promo;
      filters.professional = values.professional;
      filters.client_invoice_id = values.client_invoice_id
      filters.professional_invoice_id = values.professional_invoice_id
      filters.accounted = values.accounted;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
      start: "",
      end: "",
      client: "",
      payable: "",
      billable: "",
      project: "",
      head_project: "",
      expenses_debit_type: "",
      expenses_refund_type: "",
      payer: "",
      validated: "",
      professional: "",
      promo: "",
      client_invoice_id: "",
      professional_invoice_id: "",
      accounted: ""
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): ExpenseFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: ExpenseFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue(this.defaultFilters)
      }
    }
  }
}

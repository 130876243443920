import { createAction, props } from '@ngrx/store';
import { Bonus, BonusDTO, BonusFilters } from 'src/app/commons/models/bonus.model';
import { BonusLineDTO } from 'src/app/commons/models/bonus_line.model';
import { CostLineDTO } from 'src/app/commons/models/cost-line.model';

export const loadBonuses = createAction('[Bonuses] Load bonuses', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: BonusFilters, includes?: string[] }>());
export const loadBonusesCompleted = createAction('[Bonuses] Load bonuses Completed', props<{ bonuses: BonusDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: BonusFilters, includes?: string[] }>());
export const loadBonusesFailed = createAction('[Bonuses] Load bonuses Failed', props<{ error: any }>());

export const changePage = createAction('[Bonuses] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Bonuses] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Bonus] Change filters', props<{ filters: BonusFilters }>());

export const generateBonus = createAction('[Bonuses] generate bonus', props<{ bonus: number, year: number }>());
export const generateBonusCompleted = createAction('[Bonuses] generate  bonus completed', props<{ bonusLines: BonusLineDTO[] }>());
export const generateBonusFailed = createAction('[Bonuses] generate bonus failed', props<{ error: any }>());

export const bonusDialog = createAction('[Bonuses] Bonus dialog');
export const bonusDialogOpened = createAction('[Bonuses] Bonus dialog opened', props<{ dialogId: string }>());
export const closeBonusDialog = createAction('[Bonuses] Close bonus dialog');

// export const cancelBonusDialog = createAction('[Bonuses] Cancel bonus dialog', props<{ year: number }>());
// export const CancelBonusDialogOpened = createAction('[Bonuses]  Cancel bonus dialog opened', props<{ cancelDialogId: string }>());
// export const closeCancelBonusDialog = createAction('[Bonuses] Close cancel bonus dialog');

export const cancelBonus = createAction('[Bonuses] cancel bonus', props<{ year: number }>());
export const cancelBonusCompleted = createAction('[Bonuses] cancel  bonus completed', props<{ costLines: CostLineDTO[] }>());
export const cancelBonusFailed = createAction('[Bonuses] cancel bonus failed', props<{ error: any }>());

export const openBonusLinesDialog = createAction('[Bonuses] Bonus lines dialog', props<{ bonus: Bonus }>());
export const bonusLinesDialogOpened = createAction('[Bonuses] Bonus lines dialog opened', props<{ bonusLineDialogId: string }>());
export const closeBonusLinesDialog = createAction('[Bonuses] Close bonus lines dialog');


import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Professional } from 'src/app/commons/models/professional.model';
import { PaymentType } from 'src/app/commons/models/project.model';
import { coalesceNumber } from 'src/app/helpers/number.utils';

@Component({
  selector: 'app-professionals-field',
  templateUrl: './professionals-field.component.html',
  styleUrls: ['./professionals-field.component.scss'],
})
export class ProfessionalsFieldComponent
  implements OnInit, OnChanges, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input()
  form: FormArray;

  @Input()
  professionals: Professional[];

  @Input()
  reset$: Observable<void>;

  @Input()
  defaultKmCost: number;

  @Input()
  defaultKmRevenue: number;

  @Input()
  advisoryPayment: PaymentType;

  @Input()
  advisoryDebitType: PaymentType;
  @Input()
  dailyBudget: number;

  constructor() { }

  ngOnInit(): void {
    this.reset$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.ngOnChanges(null));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['form'] || !!changes['professionals']) {
      if (this.form) {
        this.resetForm();
        if (this.professionals) {
          this.professionals.forEach((professional) => {
            this.addProfessional(professional);
          });
        }
        this.form.addValidators(this.uniqueProfessionalValidator);
      }
    }
    if (!!changes['advisoryPayment'] || !!changes['advisoryDebitType']) {
      this.form.controls.forEach((control) => {
        this.computeValidators(<FormGroup>control);
      });
    }
    // this.form.controls.forEach((form: FormGroup) =>
    //   this.patchDailyCost(form)
    // );
  }

  setHeadProject(index) {
    this.form.controls.forEach((control, i) => {
      control.patchValue({ headProject: index === i });
    });
    this.form.markAsDirty();
  }

  removeProfessional(index) {
    this.form.removeAt(index);
    this.form.markAsDirty();
  }

  resetForm() {
    this.form.clear();
  }

  addProfessional(professional?: Professional) {
    const professionalGroup = new FormGroup({
      id: new FormControl(professional?.id),
      user: new FormControl(professional?.user, [Validators.required]),
      dailyCost: new FormControl(professional?.dailyCost),
      dailyRevenue: new FormControl(professional?.dailyRevenue),
      kmCost: new FormControl(coalesceNumber(professional?.kmCost, this.defaultKmCost), [
        Validators.required,
      ]),
      kmRevenue: new FormControl(
        coalesceNumber(professional?.kmRevenue, this.defaultKmRevenue),
        [Validators.required]
      ),
      rateCost: new FormControl(professional?.rateCost),
      headProject: new FormControl(professional?.headProject),
    });

    this.computeValidators(professionalGroup, false);
    this.form.push(professionalGroup);
    professionalGroup
      .get('user')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.patchDailyCost(professionalGroup);
      });

    professionalGroup
      .get('dailyRevenue')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.patchDailyCost(professionalGroup);
      });

    if (!professional) {
      professionalGroup.markAllAsTouched();
    }
  }

  uniqueProfessionalValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const user = control.value;

      if (!user) {
        return null;
      }

      const existing = this.form.controls
        .map((control) => control.get('user').value?.id)
        .filter((id) => id === user.id).length;

      return existing > 1 ? { userAlreadyAdded: true } : null;
    };
  }

  private computeValidators(form: FormGroup, updateValue: boolean = true) {
    const dailyCost = form.get('dailyCost');
    dailyCost.clearValidators();
    if (
      this.advisoryPayment === 'A ore' ||
      this.advisoryPayment === 'A giornata'
    ) {
      dailyCost.addValidators([Validators.required]);
    }
    if (updateValue) {
      dailyCost.updateValueAndValidity();
    }

    const rateCost = form.get('rateCost');
    rateCost.clearValidators();
    if (
      this.advisoryPayment === 'Fisso totale' ||
      this.advisoryPayment === 'Mensile'
    ) {
      rateCost.addValidators([Validators.required]);
    }
    if (updateValue) {
      rateCost.updateValueAndValidity();
    }

    const dailyRevenue = form.get('dailyRevenue');
    dailyRevenue.clearValidators();
    if (this.advisoryDebitType !== 'Fisso totale') {
      dailyRevenue.addValidators([Validators.required]);
    }
    if (updateValue) {
      dailyRevenue.updateValueAndValidity({ emitEvent: false });
    }
    form.get('user').addValidators(this.uniqueProfessionalValidator());
  }

  patchDailyCost(form: FormGroup) {
    const user = form.get('user').value;
    if (user) {
      if (user.pricePercentage) {
        if (Number(form.get('dailyRevenue').value) > 0) {
          form
            .get('dailyCost')
            .patchValue(
              (form.get('dailyRevenue').value * Number(user.price)) / 100
            );
        }
      } else {
        form.get('dailyCost').patchValue(user.price);
      }
    }
  }
}

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {RiskCategory } from 'src/app/commons/models/risk-category.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as RiskCategoryActions from 'src/app/store/actions/risk-category.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'app-risk-category-edit',
  templateUrl: './risk-category-edit.component.html',
  styleUrls: ['./risk-category-edit.component.scss']
})
export class RiskCategoryEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _riskCategory: RiskCategory;

  currentRiskCategory: RiskCategory;

  riskCategoryForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data) {
      this.riskCategory = this.data.riskCategory;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      name: ["", Validators.required],
    }
    this.riskCategoryForm = this.fb.group(group);
  }

  private ngOnChanges() {
    if (this.riskCategoryForm) {
      this.riskCategoryForm.reset();
      if (this.riskCategory) {
        this._initFormValues(this.riskCategory);
      }
    }

  }

  private _initFormValues(riskCategory: RiskCategory) {
    if (riskCategory) {
      this.riskCategoryForm.patchValue({
        name: riskCategory.name,
      });
    }
  }

  get riskCategory(): RiskCategory {
    return this._riskCategory;
  }

  set riskCategory(riskCategory: RiskCategory) {
    this._riskCategory = riskCategory;
    this.ngOnChanges();
  }

  private _prepareSaveRiskCategory(): RiskCategory {
    let savingRiskCategory: RiskCategory = RiskCategory.fromFormGroup(this.riskCategoryForm, this.riskCategory);
    return savingRiskCategory;
  }

  save() {
    let unsavedEntity = this._prepareSaveRiskCategory();
    this.store$.dispatch(RiskCategoryActions.saveRiskCategory({ riskCategory: unsavedEntity }));
  }

  close() {
    if (this.riskCategoryForm.pristine) {
      this.store$.dispatch(RiskCategoryActions.closeRiskCategoryDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(RiskCategoryActions.closeRiskCategoryDialog())
          }
        });
    }
  }

  deleteRiskCategory() {
    if (this.riskCategory) {
      this.store$.dispatch(RiskCategoryActions.deleteRiskCategory({ riskCategory: this.riskCategory.toDTO() }))
    }
  }

  revert() {
    this.ngOnChanges();
  }
}

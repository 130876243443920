import * as fromRouter from '@ngrx/router-store';
import {ActionReducer, ActionReducerMap, createFeatureSelector, MetaReducer} from '@ngrx/store';
import {localStorageSync} from 'ngrx-store-localstorage';

import * as fromActionAttachment from './action-attachment.reducer';
import * as fromAction from './action.reducer';
import * as fromActivity from './activity.reducer';
import * as fromAuth from './auth.reducers';
import * as fromBankAccount from './bank-account.reducer';
import * as fromBillingLine from './billing-line.reducer';
import * as fromBonusLine from './bonus-line.reducer';
import * as fromBonus from './bonus.reducer';
import * as fromBudget from './budget.reducer';
import * as fromBusinessOpportunity from './business-opportunity.reducer';
import * as fromClient from './client.reducer';
import * as fromContact from './contact.reducer';
import * as fromCostLine from './cost-line.reducer';
import * as fromCurrency from './currency.reducer';
import * as fromDaysDistribution from './days-distribution.reducer';
import * as fromDefaultValues from './default-values.reducer';
import * as fromExpense from './expense.reducer';
import * as fromInvoice from './invoice.reducer';
import * as fromIvaTypology from './iva-typology.reducer';
import * as fromOfferAttachment from './offer-attachment.reducer';
import * as fromOffer from './offer.reducer';
import * as fromPayment from './payment.reducer';
import * as fromProfessional from './professional.reducer';
import * as fromProjectAttachment from './project-attachment.reducer';
import * as fromProjectComment from './project-comment.reducer';
import * as fromProject from './project.reducer';
import * as fromRiskArea from './risk-area.reducer';
import * as fromRiskCategory from './risk-category.reducer';
import * as fromRiskIdentification from './risk-identification.reducer';
import * as fromRiskOwner from './risk-owner.reducer';
import * as fromRisk from './risk.reducer';
import * as fromSidebar from './sidebar.reducer';
import * as fromTarget from './target.reducer';
import * as fromUser from './user.reducer';


export interface AppState {
  sidebar: fromSidebar.SidebarState;
  router: fromRouter.RouterReducerState<any>;
  auth: fromAuth.AuthState;
  user: fromUser.UserState;
  currency: fromCurrency.CurrencyState;
  contact: fromContact.ContactState;
  client: fromClient.ClientState;
  bankAccount: fromBankAccount.BankAccountState;
  payment: fromPayment.PaymentState;
  defaultValues: fromDefaultValues.DefaultValuesState;
  target: fromTarget.TargetState;
  riskArea: fromRiskArea.RiskAreaState;
  riskCategory: fromRiskCategory.RiskCategoryState;
  riskOwner: fromRiskOwner.RiskOwnerState;
  risk: fromRisk.RiskState;
  budget: fromBudget.BudgetState;
  ivaTypology: fromIvaTypology.IvaTypologyState;
  professional: fromProfessional.ProfessionalState;
  riskIdentification: fromRiskIdentification.RiskIdentificationState;
  project: fromProject.ProjectState;
  activity: fromActivity.ActivityState;
  expense: fromExpense.ExpenseState;
  projectAttachment: fromProjectAttachment.ProjectAttachmentState;
  businessOpportunity: fromBusinessOpportunity.BusinessOpportunityState;
  offer: fromOffer.OfferState;
  projectComment: fromProjectComment.ProjectCommentState;
  daysDistribution: fromDaysDistribution.DaysDistributionState;
  offerAttachment: fromOfferAttachment.OfferAttachmentState;
  action: fromAction.ActionState;
  actionAttachment: fromActionAttachment.ActionAttachmentState;
  billingLine: fromBillingLine.BillingLineState;
  invoice: fromInvoice.InvoiceState;
  costLine: fromCostLine.CostLineState;
  bonus: fromBonus.BonusState;
  bonusLine: fromBonusLine.BonusLineState;
}

export const reducers: ActionReducerMap<AppState> = {
  sidebar: fromSidebar.reducer,
  router: fromRouter.routerReducer,
  auth: fromAuth.reducer,
  user: fromUser.reducer,
  currency: fromCurrency.reducer,
  contact: fromContact.reducer,
  client: fromClient.reducer,
  bankAccount: fromBankAccount.reducer,
  payment: fromPayment.reducer,
  defaultValues: fromDefaultValues.reducer,
  target: fromTarget.reducer,
  riskArea: fromRiskArea.reducer,
  riskCategory: fromRiskCategory.reducer,
  riskOwner: fromRiskOwner.reducer,
  risk: fromRisk.reducer,
  budget: fromBudget.reducer,
  ivaTypology: fromIvaTypology.reducer,
  professional: fromProfessional.reducer,
  riskIdentification: fromRiskIdentification.reducer,
  project: fromProject.reducer,
  activity: fromActivity.reducer,
  expense: fromExpense.reducer,
  projectAttachment: fromProjectAttachment.reducer,
  businessOpportunity: fromBusinessOpportunity.reducer,
  offer: fromOffer.reducer,
  projectComment: fromProjectComment.reducer,
  daysDistribution: fromDaysDistribution.reducer,
  offerAttachment: fromOfferAttachment.reducer,
  action: fromAction.reducer,
  actionAttachment: fromActionAttachment.reducer,
  billingLine: fromBillingLine.reducer,
  invoice: fromInvoice.reducer,
  costLine: fromCostLine.reducer,
  bonus: fromBonus.reducer,
  bonusLine: fromBonusLine.reducer
};

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      {auth: ["token"]},
    ],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, fromAuth.clearState];

export const selectRouter = createFeatureSelector<
  AppState,
  fromRouter.RouterReducerState<any>
>("router");

export const selectSidebarState = createFeatureSelector<fromSidebar.SidebarState>(
  "sidebar"
);
export const selectAuthState = createFeatureSelector<fromAuth.AuthState>(
  "auth"
);
export const selectUserState = createFeatureSelector<fromUser.UserState>(
  "user"
);
export const selectCurrencyState = createFeatureSelector<fromCurrency.CurrencyState>(
  "currency"
);
export const selectContactState = createFeatureSelector<fromContact.ContactState>(
  "contact"
);
export const selectClientState = createFeatureSelector<fromClient.ClientState>(
  "client"
);
export const selectBankAccountState = createFeatureSelector<fromBankAccount.BankAccountState>(
  "bankAccount"
);
export const selectPaymentState = createFeatureSelector<fromPayment.PaymentState>(
  "payment"
);
export const selectDefaultValuesState = createFeatureSelector<fromDefaultValues.DefaultValuesState>(
  "defaultValues"
);
export const selectTargetState = createFeatureSelector<fromTarget.TargetState>(
  "target"
);
export const selectRiskAreaState = createFeatureSelector<fromRiskArea.RiskAreaState>(
  "riskArea"
);
export const selectRiskCategoryState = createFeatureSelector<fromRiskCategory.RiskCategoryState>(
  "riskCategory"
);
export const selectRiskOwnerState = createFeatureSelector<fromRiskOwner.RiskOwnerState>(
  "riskOwner"
);
export const selectRiskState = createFeatureSelector<fromRisk.RiskState>(
  "risk"
);
export const selectBudgetState = createFeatureSelector<fromBudget.BudgetState>(
  "budget"
);
export const selectIvaTypologyState = createFeatureSelector<fromIvaTypology.IvaTypologyState>(
  "ivaTypology"
);
export const selectProfessionalState = createFeatureSelector<fromProfessional.ProfessionalState>(
  "professional"
);
export const selectRiskIdentificationState = createFeatureSelector<fromRiskIdentification.RiskIdentificationState>(
  "riskIdentification"
);
export const selectProjectState = createFeatureSelector<fromProject.ProjectState>(
  "project"
);
export const selectActivityState = createFeatureSelector<fromActivity.ActivityState>(
  "activity"
);
export const selectExpenseState = createFeatureSelector<fromExpense.ExpenseState>(
  "expense"
);
export const selectProjectAttachmentState = createFeatureSelector<fromProjectAttachment.ProjectAttachmentState>(
  "projectAttachment"
);
export const selectBusinessOpportunityState = createFeatureSelector<fromBusinessOpportunity.BusinessOpportunityState>(
  "businessOpportunity"
);
export const selectOfferState = createFeatureSelector<fromOffer.OfferState>(
  "offer"
);
export const selectProjectCommentState = createFeatureSelector<fromProjectComment.ProjectCommentState>(
  "projectComment"
);
export const selectDaysDistributionState = createFeatureSelector<fromDaysDistribution.DaysDistributionState>(
  "daysDistribution"
);
export const selectOfferAttachmentState = createFeatureSelector<fromOfferAttachment.OfferAttachmentState>(
  "offerAttachment"
);
export const selectActionState = createFeatureSelector<fromAction.ActionState>(
  "action"
);
export const selectActionAttachmentState = createFeatureSelector<fromActionAttachment.ActionAttachmentState>(
  "actionAttachment"
);
export const selectBillingLineState = createFeatureSelector<fromBillingLine.BillingLineState>(
  "billingLine"
);
export const selectInvoiceState = createFeatureSelector<fromInvoice.InvoiceState>(
  "invoice"
);
export const selectCostLineState = createFeatureSelector<fromCostLine.CostLineState>(
  "costLine"
);
export const selectBonusState = createFeatureSelector<fromBonus.BonusState>(
  "bonus"
);
export const selectBonusLineState = createFeatureSelector<fromBonusLine.BonusLineState>(
  "bonusLine"
);

const {
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl,
} = fromRouter.getSelectors(selectRouter);

export const selectRouteId = selectRouteParam("id");
export const selectStatus = selectQueryParam("status");

import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { Project } from 'src/app/commons/models/project.model';
import { AppState } from 'src/app/store/reducers';
import * as ProjectActions from 'src/app/store/actions/project.actions';
import { User } from 'src/app/commons/models/user.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { BonusLine, BonusLineFilters } from 'src/app/commons/models/bonus_line.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as BonusLineSelectors from '../../../../store/selectors/bonus-line.selectors';
import * as BonusLineActions from '../../../../store/actions/bonus-line.actions';

@Component({
  selector: "confirm-payment-dialog",
  templateUrl: "./confirm-payment-dialog.component.html",
  styleUrls: ["./confirm-payment-dialog.component.scss"]
})
export class ConfirmPaymentDialogComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private unsubscribe$ = new Subject<void>();
  project: Project;
  bonusLines: Observable<BonusLine[]>;
  pageSizeOptions = PAGE_SIZE_OPTIONS;
  filters: Observable<BonusLineFilters>;
  defaultFilters: BonusLineFilters;
  total: Observable<number>;
  private _includes: string[] = [
    "user",
    "project",
    "cost_line",
    "bonus"
  ];
  showProjectColumn: boolean = false;
  
  showCostLineColumn: boolean = false;


  constructor(private store$: Store<AppState>,  @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.bonusLines = this.store$.pipe(select(BonusLineSelectors.getBonusLines), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new BonusLine(dto)) : null));
    this.total = this.store$.pipe(select(BonusLineSelectors.getTotalBonusLines), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(BonusLineSelectors.getFilters), takeUntil(this.unsubscribe$));
   }

  ngOnInit() {
    if (this.data) {
      this.project = this.data.project;
    }
    this.defaultFilters = {
      project: this.project
    }
    this.load();
  }
  load() {
    this.store$.dispatch(BonusLineActions.loadBonusLines(
     { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters, includes:this._includes }));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  sortChange(sort: Sort) {
    this.store$.dispatch(BonusLineActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(BonusLineActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: BonusLineFilters) {
    this.store$.dispatch(BonusLineActions.changeFilters({ filters }));
  }

  exportBonusLines() {
    this.store$.dispatch(BonusLineActions.exportRecords({filters: this.defaultFilters}))
  }

  confirm() {
    if (this.project) {
      this.store$.dispatch(ProjectActions.bonusPayment({ projectId: this.project.id }))
    }
  }

  close() {
    this.store$.dispatch(ProjectActions.closeBonusPaymentDialog())
  }


}

<form [formGroup]="clientBonusForm" novalidate>
  <div class="u-flex-full">
    <mat-form-field class="u-full-width">
      <mat-label>Utente</mat-label>
      <app-user-field formControlName="user">
      </app-user-field>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Percentuale" formControlName="percentage" type="number" min="0" max="100" forceNumber >
      <mat-icon matSuffix>percent</mat-icon>
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
  </div>

</form>
<span class="button-bar u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
    [disabled]="clientBonusForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
    [disabled]="clientBonusForm.pristine || !clientBonusForm.valid">Conferma</button>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</span>

import { createAction, props } from '@ngrx/store';

import { Client, ClientDTO, ClientFilters } from '../../commons/models/client.model';

export const loadClients = createAction('[Clients] Load clients', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: ClientFilters, includes?: string[] }>());
export const loadClientsCompleted = createAction('[Clients] Load clients Completed', props<{ clients: ClientDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ClientFilters, includes?: string[] }>());
export const loadClientsFailed = createAction('[Clients] Load clients Failed', props<{ error: any }>());

export const changePage = createAction('[Clients] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Clients] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Clients] Change filters', props<{ filters: ClientFilters }>());

export const editClient = createAction('[Clients] Edit client', props<{ client: Client }>());
export const clientDialogOpened = createAction('[Clients] Detail dialog opened', props<{ dialogId: string }>());
export const closeClientDialog = createAction('[Clients] Close detail dialog');

export const saveClient = createAction('[Clients] Save client', props<{ client: Client }>());
export const saveClientCompleted = createAction('[Clients] Save client completed', props<{ client: ClientDTO }>());
export const saveClientFailed = createAction('[Clients] Save client failed', props<{ error: any }>());

export const deleteClient = createAction('[Clients] Delete client', props<{ client: ClientDTO }>());
export const deleteClientCompleted = createAction('[Clients] Delete client completed', props<{ client: ClientDTO }>());
export const deleteClientCancelled = createAction('[Clients] Delete client cancelled');
export const deleteClientFailed = createAction('[Clients] Delete client failed', props<{ error: any }>());


export const selectClient = createAction('[Clients] Select client', props<{ filters?: ClientFilters, canAdd?: boolean }>());
export const selectionDialogOpened = createAction('[Clients] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Clients] Close selection dialog');
export const clientSelected = createAction('[Clients] Client selected', props<{ client: ClientDTO }>());

import { AbstractControl } from '@angular/forms';

export function PivaValidator(control: AbstractControl): { [key: string]: boolean } | null {

    const pi = control.value;
    if (!pi) {
        return null;
    }

    if (!/^[0-9]{11}$/.test(pi)) {
        return { 'piva': true };
    }

    var s = 0;
    for (let i = 0; i <= 9; i += 2) {
        s += pi.charCodeAt(i) - '0'.charCodeAt(0);
    }
    for (let i = 1; i <= 9; i += 2) {
        var c = 2 * (pi.charCodeAt(i) - '0'.charCodeAt(0));
        if (c > 9) c = c - 9;
        s += c;
    }
    var controllo = (10 - s % 10) % 10;
    if (controllo != pi.charCodeAt(10) - '0'.charCodeAt(0)) {
        return { 'piva': true };
    }

    return null;
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { Client } from '../../../commons/models/client.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { ClientFilters } from './../../../commons/models/client.model';

export type ClientsColumn = "name" | "companyName" | "PEC" | "clientLeader" | "note" | "actions";

@Component({
  selector: 'client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      // transition(
      //   "expanded <=> collapsed",
      //   animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      // ),
    ]),
  ],
})
export class ClientListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  clients: Observable<Client[]>;

  @Input()
  defaultFilters: ClientFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Client | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ClientsColumn[] = ["name", "companyName", "PEC", "clientLeader", "note", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ClientFilters> = new EventEmitter<ClientFilters>();

  @Output()
  onSelectClient: EventEmitter<Client> = new EventEmitter<Client>();
  @Output()
  onAddClient: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditClient: EventEmitter<Client> = new EventEmitter<Client>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addClient() {
    this.onAddClient.emit();
  }

  editClient(client: Client) {
    this.onEditClient.emit(client);
  }

  selectClient(client: Client) {
    this.onSelectClient.emit(client);
  }

  onFilter(filters: ClientFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { formatDateForBackend, previousMonth } from 'src/app/helpers/time.utils';

import { CostLineFilters } from '../../../commons/models/cost-line.model';

@Component({
  selector: 'app-cost-line-filters',
  templateUrl: './cost-line-filters.component.html',
  styleUrls: ['./cost-line-filters.component.scss']
})
export class CostLineFiltersComponent {
  @Output()
  onFilter: EventEmitter<CostLineFilters> = new EventEmitter();

  private _defaultFilters: CostLineFilters;
  filtersForm: FormGroup;
  previous = previousMonth();

  constructor(private fb: FormBuilder) {
    const today = new Date();

    this._createForm();
    this._initFormValues();
  }

  private _createForm() {
    let group = {
      start: "",
      end: "",
      client: "",
      project: "",
      user: "",
      invoiceNumber: "",
      invoiceDateStart: "",
      invoiceDateEnd: "",
      invoicePaymentDateStart: "",
      invoicePaymentDateEnd: "",
      promo: "",
      invoiced: "",
      paid: "",
      accountable: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  private _initFormValues() {
    this.filtersForm.patchValue({
      start: this.previous?.start,
      end: this.previous?.end,
    });
  }

  applyFilters() {
    this.onFilter.emit(this._getCostLine());
  }

  private _getCostLine(): CostLineFilters {
    let filters: CostLineFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.start = formatDateForBackend(values.start);
      filters.end = formatDateForBackend(values.end);
      filters.client_id = values.client?.id;
      filters.project_id = values.project?.id;
      filters.user_id = values.user?.id;
      filters.invoice_number = values.invoiceNumber;
      filters.invoice_date_start = formatDateForBackend(values.invoiceDateStart);
      filters.invoice_date_end = formatDateForBackend(values.invoiceDateEnd);
      filters.invoice_payment_date_start = formatDateForBackend(values.invoicePaymentDateStart);
      filters.invoice_payment_date_end = formatDateForBackend(values.invoicePaymentDateEnd);
      filters.promo = values.promo;
      filters.invoiced = values.invoiced;
      filters.paid = values.paid;
      filters.accountable = values.accountable;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      start: "",
      end: "",
      client: "",
      project: "",
      user: "",
      invoiceNumber: "",
      invoiceDateStart: "",
      invoiceDateEnd: "",
      invoicePaymentDateStart: "",
      invoicePaymentDateEnd: "",
      promo: "",
      invoiced: "",
      paid: "",
      accountable: "",
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): CostLineFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: CostLineFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          start: this.defaultFilters.start,
          end: this.defaultFilters.end,
          project: this.defaultFilters.project_id,
          client: this.defaultFilters.client_id,
          user: this.defaultFilters.user_id,
          invoiceNumber: this.defaultFilters.invoice_number,
          invoiceDateStart: this.defaultFilters.invoice_date_start,
          invoiceDateEnd: this.defaultFilters.invoice_date_end,
          invoicePaymentDateStart: this.defaultFilters.invoice_payment_date_start,
          invoicePaymentDateEnd: this.defaultFilters.invoice_payment_date_end,
          onlyPromo: this.defaultFilters.promo,
          invoiced: this.defaultFilters.invoiced,
          paid: this.defaultFilters.paid,
          accountable: this.defaultFilters.accountable,
        })
      }
    }
  }
}

import { createSelector } from '@ngrx/store';

import { selectProfessionalState } from '../reducers';
import { ProfessionalState } from '../reducers/professional.reducer';

// Professional Selectors

export const getProfessionalsTableState = createSelector(
  selectProfessionalState,
  (state: ProfessionalState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getProfessionals = createSelector(
  selectProfessionalState,
  (state: ProfessionalState) => state.list
);

export const getTotalProfessionals = createSelector(
  getProfessionalsTableState,
  (tableState) => tableState.total
);

export const getProfessionalsCurrentPage = createSelector(
  getProfessionalsTableState,
  (tableState) => tableState.currentPage
);

export const getProfessionalsPerPage = createSelector(
  getProfessionalsTableState,
  (tableState) => tableState.perPage
);

export const getProfessionalsOrder = createSelector(
  getProfessionalsTableState,
  (tableState) => tableState.order
);

export const getProfessionalsDirection = createSelector(
  getProfessionalsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getProfessionalsTableState,
  (tableState) => tableState.filters
);

export const getProfessionalDialogId = createSelector(
  selectProfessionalState,
  (state: ProfessionalState) => state.dialogId
);

export const getSelectedProfessional = createSelector(
  selectProfessionalState,
  (state: ProfessionalState) => state.professional
);
export const getSelectionDialogId = createSelector(
  selectProfessionalState,
  (state: ProfessionalState) => state.selectionDialogId
);

import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { Client } from './client.model';


export interface ContactFilters {
  search?: string;
  client?: Client;
}

export interface ContactDTO extends BaseDTO {
  name: string;
  lastname: string;
  email: string;
  phone: string;
  mobile: string;
  fax: string;
  note: string;
 }
export class Contact extends Base {
  name: string;
  lastname: string;
  email: string;
  phone: string;
  mobile: string;
  fax: string;
  note: string;
  constructor(source: ContactDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.lastname = source.lastname;
      this.email = source.email;
      this.phone = source.phone;
      this.mobile = source.mobile;
      this.fax = source.fax;
      this.note = source.note;
    }
  }

  toDTO(): ContactDTO {
    let result: ContactDTO = <ContactDTO>super.toDTO();
    result.name = this.name;
    result.lastname = this.lastname;
    result.email = this.email;
    result.phone = this.phone;
    result.mobile = this.mobile;
    result.fax = this.fax;
    result.note = this.note;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Contact) {
    const formModel = formGroup.value;
    let contact: Contact = new Contact(null);
    contact.name = formModel.name;
    contact.lastname = formModel.lastname;
    contact.email = formModel.email;
    contact.phone = formModel.phone;
    contact.mobile = formModel.mobile;
    contact.fax = formModel.fax;
    contact.note= formModel.note;

    if (original) {
      contact.id = original.id;
    }
    return contact;
  }

}

import { createSelector } from '@ngrx/store';

import { selectBusinessOpportunityState } from '../reducers';
import { BusinessOpportunityState } from '../reducers/business-opportunity.reducer';

// BusinessOpportunity Selectors

export const getBusinessOpportunitiesTableState = createSelector(
  selectBusinessOpportunityState,
  (state: BusinessOpportunityState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getBusinessOpportunities = createSelector(
  selectBusinessOpportunityState,
  (state: BusinessOpportunityState) => state.list
);

export const getTotalBusinessOpportunities = createSelector(
  getBusinessOpportunitiesTableState,
  (tableState) => tableState.total
);

export const getBusinessOpportunitiesCurrentPage = createSelector(
  getBusinessOpportunitiesTableState,
  (tableState) => tableState.currentPage
);

export const getBusinessOpportunitiesPerPage = createSelector(
  getBusinessOpportunitiesTableState,
  (tableState) => tableState.perPage
);

export const getBusinessOpportunitiesOrder = createSelector(
  getBusinessOpportunitiesTableState,
  (tableState) => tableState.order
);

export const getBusinessOpportunitiesDirection = createSelector(
  getBusinessOpportunitiesTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getBusinessOpportunitiesTableState,
  (tableState) => tableState.filters
);

export const getBusinessOpportunityDialogId = createSelector(
  selectBusinessOpportunityState,
  (state: BusinessOpportunityState) => state.dialogId
);

export const getSelectedBusinessOpportunity = createSelector(
  selectBusinessOpportunityState,
  (state: BusinessOpportunityState) => state.businessOpportunity
);
export const getSelectionDialogId = createSelector(
  selectBusinessOpportunityState,
  (state: BusinessOpportunityState) => state.selectionDialogId
);
export const getBusinessOpportunity = createSelector(
  selectBusinessOpportunityState,
  (state: BusinessOpportunityState) => state.businessOpportunity
);

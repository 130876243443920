import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { Risk } from '../../../commons/models/risk.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { RiskFilters } from './../../../commons/models/risk.model';

export type RisksColumn =
  | 'area'
  | 'target'
  | 'identifier'
  | 'threat'
  | 'warning'
  | 'category'
  | 'countermeasure'
  | 'owner'
  | 'riskActionee'
  | 'actions';

@Component({
  selector: 'risk-list',
  templateUrl: './risk-list.component.html',
  styleUrls: ['./risk-list.component.scss'],
})
export class RiskListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  risks: Observable<Risk[]>;

  @Input()
  defaultFilters: RiskFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Risk | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: RisksColumn[] = [
    'area',
    'target',
    'identifier',
    'threat',
    'warning',
    'category',
    'countermeasure',
    'owner',
    'riskActionee',
    'actions',
  ];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<RiskFilters> = new EventEmitter<RiskFilters>();

  @Output()
  onSelectRisk: EventEmitter<Risk> = new EventEmitter<Risk>();
  @Output()
  onAddRisk: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditRisk: EventEmitter<Risk> = new EventEmitter<Risk>();

  constructor() {}

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addRisk() {
    this.onAddRisk.emit();
  }

  editRisk(risk: Risk) {
    this.onEditRisk.emit(risk);
  }

  selectRisk(risk: Risk) {
    this.onSelectRisk.emit(risk);
  }

  onFilter(filters: RiskFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

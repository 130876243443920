<header mat-dialog-title class="u-flex-full">

  <span *ngIf="bonusLines?.length > 0">Singoli bonus relativi all'anno <strong>{{bonus?.year}}</strong> -
    <strong>{{bonus.amount | customCurrency}} €</strong></span>
  <span>
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item *ngIf="bonusLines?.length > 0" (click)="exportBonusLines()">
        <mat-icon>file_download</mat-icon>
        Export CSV
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="cancelBonusDialog()">
        <mat-icon>delete</mat-icon>
        Undo R&D Generation
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>

  <div style="margin: 0 15px;">
    <bonus-line-list [bonusLines]="bonusLines" [total]="total" [defaultFilters]="defaultFilters"
      (onFilterChange)="filtersChange($event)" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
      (onExportBonusLines)="exportBonusLines()" [showProjectColumn]="showProjectColumn"
      [showCostLineColumn]="showCostLineColumn"></bonus-line-list>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Contact } from 'src/app/commons/models/contact.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as ContactActions from 'src/app/store/actions/contact.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class ContactEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _contact: Contact;

  currentContact: Contact;

  contactForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data) {
      this.contact = this.data.contact;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      name: ["", Validators.required],
      lastname: ["", Validators.required],
      email: ["", Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")],
      phone: [],
      mobile: [],
      fax: [],
      note: [],
    }
    this.contactForm = this.fb.group(group);
  }

  private ngOnChanges() {
    if (this.contactForm) {
      this.contactForm.reset();
      if (this.contact) {
        this._initFormValues(this.contact);
      }else {
        this.contactForm.markAllAsTouched();
      }
    }

  }

  private _initFormValues(contact: Contact) {
    if (contact) {
      this.contactForm.patchValue({
        name: contact.name,
        lastname: contact.lastname,
        email: contact.email,
        phone: contact.phone,
        mobile: contact.mobile,
        fax: contact.fax,
        note: contact.note,
      });
    }
  }

  get contact(): Contact {
    return this._contact;
  }

  set contact(contact: Contact) {
    this._contact = contact;
    this.ngOnChanges();
  }

  private _prepareSaveContact(): Contact {
    let savingContact: Contact = Contact.fromFormGroup(this.contactForm, this.contact);
    return savingContact;
  }

  save() {
    let unsavedEntity = this._prepareSaveContact();
    this.store$.dispatch(ContactActions.saveContact({ contact: unsavedEntity }));
  }

  close() {
    if (this.contactForm.pristine) {
      this.store$.dispatch(ContactActions.closeContactDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(ContactActions.closeContactDialog())
          }
        });
    }
  }

  deleteContact() {
    if (this.contact) {
      this.store$.dispatch(ContactActions.deleteContact({ contact: this.contact.toDTO() }))
    }
  }

  revert() {
    this.ngOnChanges();
  }
}

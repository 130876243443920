import { state, style, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { BillingLine, BillingLineFilters } from '../../../commons/models/billing-line.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type BillingLinesColumn = "date" | "description" | "amount" | "project" | "client" | "type" | "invoiced" | "selected" | "actions";

@Component({
  selector: 'billing-line-list',
  templateUrl: './billing-line-list.component.html',
  styleUrls: ['./billing-line-list.component.scss'],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      // transition(
      //   "expanded <=> collapsed",
      //   animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      // ),
    ]),
  ],
})
export class BillingLineListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  billingLines: BillingLine[];
  @Input()
  selectedBillingLines: BillingLine[];

  @Input()
  defaultFilters: BillingLineFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: BillingLine | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: BillingLinesColumn[] = ["selected", "date", "amount", "project", "client", "description", "type", "invoiced", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<BillingLineFilters> = new EventEmitter<BillingLineFilters>();

  @Output()
  onSelectBillingLine: EventEmitter<BillingLine> = new EventEmitter<BillingLine>();
  @Output()
  onAddBillingLine: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditBillingLine: EventEmitter<BillingLine> = new EventEmitter<BillingLine>();
  @Output()
  onShowBillingLine: EventEmitter<BillingLine> = new EventEmitter<BillingLine>();
  @Output()
  onAddSelectedBillingLine: EventEmitter<BillingLine> = new EventEmitter<BillingLine>();
  @Output()
  onRemoveSelectedBillingLine: EventEmitter<BillingLine> = new EventEmitter<BillingLine>();
  @Output()
  onGenerateInvoice: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onCanceledInvoices: EventEmitter<BillingLine> = new EventEmitter<BillingLine>();

  get selectedItems(): boolean {
    return this.selectedBillingLines.length > 0;
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  isSelected(billingLine: BillingLine): boolean {
    return this.selectedBillingLines.map(obj => obj.id).includes(billingLine.id)
  }

  addBillingLine() {
    this.onAddBillingLine.emit();
  }

  addSelectedBillingLine(billingLine: BillingLine) {
    if(this.isSelectable(billingLine)) {
      this.onAddSelectedBillingLine.emit(billingLine);
    }
  }

  isSelectable(billingLine: BillingLine): boolean {
    return !billingLine.invoiceId
  }

  removeSelectedBillingLine(billingLine: BillingLine) {
    this.onRemoveSelectedBillingLine.emit(billingLine)
  }

  toggleSelectedBillingLine(billingLine: BillingLine) {
    if (this.isSelected(billingLine)) {
      this.removeSelectedBillingLine(billingLine);
    } else {
      this.addSelectedBillingLine(billingLine);
    }
  }

  editBillingLine(billingLine: BillingLine) {
    this.onEditBillingLine.emit(billingLine);
  }

  selectBillingLine(billingLine: BillingLine) {
    this.onSelectBillingLine.emit(billingLine);
  }

  onFilter(filters: BillingLineFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  showBillingLine(activityList: BillingLine) {
    this.onShowBillingLine.emit(activityList);
  }

  canceledInvoices(billingLine: BillingLine) {
    this.onCanceledInvoices.emit(billingLine);
  }

  generateInvoice() {
    this.onGenerateInvoice.emit();
  }

  isAllSelected() {
    const numSelected = this.selectedBillingLines.length;
    const numRows = this.billingLines.filter(bl => this.isSelectable(bl)).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selectedBillingLines.forEach(bl => this.removeSelectedBillingLine(bl)) :
        this.billingLines.forEach(bl => this.addSelectedBillingLine(bl));
  }
}

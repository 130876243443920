import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BankAccount } from 'src/app/commons/models/bank-account.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as BankAccountActions from 'src/app/store/actions/bank-account.actions';
import { AppState } from 'src/app/store/reducers';
import * as BankAccountSelectors from 'src/app/store/selectors/bank-account.selectors';

import { BankAccountsColumn } from '../bank-account-list/bank-account-list.component';
import { BankAccountFilters } from './../../../commons/models/bank-account.model';


@Component({
  selector: 'app-bank-account-selection',
  templateUrl: './bank-account-selection.component.html',
  styleUrls: ['./bank-account-selection.component.scss']
})
export class BankAccountSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  bankAccounts: Observable<BankAccount[]>;
  filters: Observable<BankAccountFilters>;

  @Input()
  defaultFilters: BankAccountFilters = {
    search: "",
  }

  displayedColumns: BankAccountsColumn[] = ["name", "iban", "actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.bankAccounts = this.store$.pipe(select(BankAccountSelectors.getBankAccounts), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new BankAccount(dto)) : null));
    this.total = this.store$.pipe(select(BankAccountSelectors.getTotalBankAccounts), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(BankAccountSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(BankAccountActions.loadBankAccounts(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(BankAccountActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(BankAccountActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: BankAccountFilters) {
    this.store$.dispatch(BankAccountActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectBankAccount(bankAccount: BankAccount) {
    if (bankAccount) {
      this.store$.dispatch(BankAccountActions.bankAccountSelected({ bankAccount: bankAccount.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(BankAccountActions.closeSelectionDialog())
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Role } from 'src/app/commons/models/user.model';

@Pipe({
  name: 'offerProbability'
})
export class OfferProbabilityPipe implements PipeTransform {
  transform(value: number): unknown {
    switch (value) {
      case 10:
        return "Bassa 10%";
      case 40:
        return "Media 40%";
      case 70:
        return "Alta 70%";
      case 90:
        return "Molto alta 90%";
      case 100:
        return "Sicura 100%";
    }
  }

}

<!-- <header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="defaultValues">Modifica valori predefiniti</span>
    <span *ngIf="!defaultValues">Nuovi valori predifiniti</span>
  </span>
  <span *ngIf="defaultValues">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteDefaultValues()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header> -->
<mat-dialog-content>
  <form [formGroup]="defaultValuesForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Bonus Cliente"
          formControlName="bonusClient"
          type="number"
          min="0"
          max="100"
          forceNumber
        />
        <mat-icon matSuffix>percent</mat-icon>
        <mat-error
          *ngIf="defaultValuesForm.controls['bonusClient'].errors?.incorrect"
          >La somma dei bonus è uguale a {{ totalBonuses }}% deve essere pari a
          100%</mat-error
        >
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Bonus Vendite"
          formControlName="bonusSales"
          type="number"
          min="0"
          max="100"
          forceNumber
        />
        <mat-icon matSuffix>percent</mat-icon>
        <mat-error
          *ngIf="defaultValuesForm.controls['bonusSales'].errors?.incorrect"
          >La somma dei bonus è uguale a {{ totalBonuses }}% deve essere pari a
          100%</mat-error
        >
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Bonus Progetto"
          formControlName="bonusProject"
          type="number"
          min="0"
          max="100"
          forceNumber
        />
        <mat-icon matSuffix>percent</mat-icon>
        <mat-error
          *ngIf="defaultValuesForm.controls['bonusProject'].errors?.incorrect"
          >La somma dei bonus è uguale a {{ totalBonuses }}% deve essere pari a
          100%</mat-error
        >
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Advisory fee"
          formControlName="advisoryFee"
          type="number"
          min="0"
          max="100"
          forceNumber
        />
        <mat-icon matSuffix>percent</mat-icon>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Costo € / km"
          formControlName="kmCost"
          type="number"
          forceNumber
        />
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <app-currency-field
          placeholder="Valuta"
          [formControl]="defaultValuesForm.get('currency')"
          label="Valuta"
          [required]="true"
        >
        </app-currency-field>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <app-iva-typology-field
          placeholder="Regime IVA"
          [formControl]="defaultValuesForm.get('ivaTypology')"
          label="Regime IVA"
          [required]="true"
        >
        </app-iva-typology-field>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>
  </form>
  <!-- {{defaultValuesForm.value | json}} -->
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button
      mat-raised-button
      color="basic"
      type="reset"
      (click)="revert()"
      [disabled]="defaultValuesForm.pristine"
    >
      Reset
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="defaultValuesForm.pristine || !defaultValuesForm.valid"
    >
      Salva
    </button>
    <span class="fill-remaining"></span>
  </span>
</mat-dialog-actions>

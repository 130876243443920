import { createAction, props } from '@ngrx/store';

import { RiskOwner, RiskOwnerDTO, RiskOwnerFilters } from '../../commons/models/risk-owner.model';

export const loadRiskOwners = createAction('[RiskOwners] Load RiskOwners', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: RiskOwnerFilters, includes?: string[] }>());
export const loadRiskOwnersCompleted = createAction('[RiskOwners] Load RiskOwners Completed', props<{ riskOwners: RiskOwnerDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: RiskOwnerFilters, includes?: string[] }>());
export const loadRiskOwnersFailed = createAction('[RiskOwners] Load RiskOwners Failed', props<{ error: any }>());

export const changePage = createAction('[RiskOwners] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[RiskOwners] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[RiskOwners] Change filters', props<{ filters: RiskOwnerFilters }>());

export const editRiskOwner = createAction('[RiskOwners] Edit riskOwner', props<{ riskOwner: RiskOwner }>());
export const riskOwnerDialogOpened = createAction('[RiskOwners] Detail dialog opened', props<{ dialogId: string }>());
export const closeRiskOwnerDialog = createAction('[RiskOwners] Close detail dialog');

export const saveRiskOwner = createAction('[RiskOwners] Save riskOwner', props<{ riskOwner: RiskOwner }>());
export const saveRiskOwnerCompleted = createAction('[RiskOwners] Save riskOwner completed', props<{ riskOwner: RiskOwnerDTO }>());
export const saveRiskOwnerFailed = createAction('[RiskOwners] Save riskOwner failed', props<{ error: any }>());

export const deleteRiskOwner = createAction('[RiskOwners] Delete riskOwner', props<{ riskOwner: RiskOwnerDTO }>());
export const deleteRiskOwnerCompleted = createAction('[RiskOwners] Delete riskOwner completed', props<{ riskOwner: RiskOwnerDTO }>());
export const deleteRiskOwnerCancelled = createAction('[RiskOwners] Delete riskOwner cancelled');
export const deleteRiskOwnerFailed = createAction('[RiskOwners] Delete riskOwner failed', props<{ error: any }>());

export const selectRiskOwner = createAction('[RiskOwners] Select riskOwner', props<{ filters?: RiskOwnerFilters }>());
export const selectionDialogOpened = createAction('[RiskOwners] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[RiskOwners] Close selection dialog');
export const riskOwnerSelected = createAction('[RiskOwners] RiskOwner selected', props<{ riskOwner: RiskOwnerDTO }>());

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Bonus, BonusFilters } from 'src/app/commons/models/bonus.model';
import * as BonusSelectors from '../../../store/selectors/bonus.selectors';
import { map, takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';


@Component({
  selector: 'app-bonus-filters',
  templateUrl: './bonus-filters.component.html',
  styleUrls: ['./bonus-filters.component.scss']
})
export class BonusFiltersComponent {


  @Output()
  onFilter: EventEmitter<BonusFilters> = new EventEmitter();

  private _defaultFilters: BonusFilters;
  filtersForm: FormGroup;
  private unsubscribe$ = new Subject<void>();
  bonuses: Bonus[];
  years: number[];

  constructor(private store$: Store<AppState>, private fb: FormBuilder) {
    this.store$.pipe(select(BonusSelectors.getBonuses), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new Bonus(dto)) : null)).subscribe(bonuses =>{
      this.bonuses = bonuses
      this.years = bonuses.map(bonus => bonus.year)
    });
    this._createForm();
  }

  private _createForm() {
    let group = {
      year: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getBonusFilters());
  }

  private _getBonusFilters(): BonusFilters {
    let filters: BonusFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.year = values.year;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      year: "",
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): BonusFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: BonusFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          year: this.defaultFilters.year,
        })
      }
    }
  }
}

import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Payment } from 'src/app/commons/models/payment.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as PaymentActions from 'src/app/store/actions/payment.actions';
import { AppState } from 'src/app/store/reducers';
import * as PaymentSelectors from 'src/app/store/selectors/payment.selectors';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

import { PaymentFilters } from './../../../commons/models/payment.model';
import { PaymentsColumn } from '../payment-list/payment-list.component';



@Component({
  selector: 'app-payment-selection',
  templateUrl: './payment-selection.component.html',
  styleUrls: ['./payment-selection.component.scss']
})
export class PaymentSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  payments: Observable<Payment[]>;
  filters: Observable<PaymentFilters>;

  @Input()
  defaultFilters: PaymentFilters = {
    search: "",
  }

  displayedColumns: PaymentsColumn[] = ["code", "description", "start", "lead", "actions"];;

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.payments = this.store$.pipe(select(PaymentSelectors.getPayments), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new Payment(dto)) : null));
    this.total = this.store$.pipe(select(PaymentSelectors.getTotalPayments), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(PaymentSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(PaymentActions.loadPayments(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(PaymentActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(PaymentActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: PaymentFilters) {
    this.store$.dispatch(PaymentActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectPayment(payment: Payment) {
    if (payment) {
      this.store$.dispatch(PaymentActions.paymentSelected({ payment: payment.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(PaymentActions.closeSelectionDialog())
  }
}

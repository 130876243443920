import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {Observable} from 'rxjs';

import {OfferAttachment, OfferAttachmentFilters} from '../../../commons/models/offer-attachment.model';
import {PAGE_SIZE_OPTIONS} from '../../../helpers/table.helper';

export type OfferAttachmentsColumn = "id" | "name" | "file" | "actions";

@Component({
  selector: 'offer-attachment-list',
  templateUrl: './offer-attachment-list.component.html',
  styleUrls: ['./offer-attachment-list.component.scss'],
})
export class OfferAttachmentListComponent {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input()
  offerAttachments: OfferAttachment[];

  @Input()
  defaultFilters: OfferAttachmentFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: OfferAttachment | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: OfferAttachmentsColumn[] = ["name", "file", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<OfferAttachmentFilters> = new EventEmitter<OfferAttachmentFilters>();

  @Output()
  onSelectOfferAttachment: EventEmitter<OfferAttachment> = new EventEmitter<OfferAttachment>();
  @Output()
  onAddOfferAttachment: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditOfferAttachment: EventEmitter<OfferAttachment> = new EventEmitter<OfferAttachment>();
  @Output()
  onDuplicateOfferAttachment: EventEmitter<OfferAttachment> = new EventEmitter<OfferAttachment>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addOfferAttachment() {
    this.onAddOfferAttachment.emit();
  }

  editOfferAttachment(offerAttachment: OfferAttachment) {
    this.onEditOfferAttachment.emit(offerAttachment);
  }

  selectOfferAttachment(offerAttachment: OfferAttachment) {
    this.onSelectOfferAttachment.emit(offerAttachment);
  }

  onFilter(filters: OfferAttachmentFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

import { AbstractControl } from '@angular/forms';
import { formatDateForBackend } from 'src/app/helpers/time.utils';

import { Activity } from './activity.model';
import { Base, BaseDTO } from './base.model';
import { Project, ProjectDTO } from './project.model';


export interface BudgetFilters {
  search?: string;
  project_id?: number;
}

export interface BudgetDTO extends BaseDTO {
  name: string;
  budget: number;
  hours: number;
  start?: string;
  end?: string;
  notes?: string;
  project_id: number;

  project: ProjectDTO;
}
export class Budget extends Base {
  name: string;
  budget: number;
  hours: number;
  start?: Date;
  end?: Date;
  notes?: string;
  projectId: number;

  project: Project;

  constructor(source: BudgetDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.budget = source.budget;
      this.start = source.start ? new Date(source.start) : null;
      this.end = source.end ? new Date(source.end) : null;
      this.notes = source.notes;
      this.hours = source.hours;

      this.project = new Project(source.project);
    }
  }

  toDTO(): BudgetDTO {
    let result: BudgetDTO = <BudgetDTO>super.toDTO();
    result.name = this.name;
    result.budget = this.budget;
    result.start = this.start ? formatDateForBackend(this.start) : null;
    result.end = this.end ? formatDateForBackend(this.end) : null;
    result.notes = this.notes;
    result.project_id = this.projectId;
    result.hours = this.hours;

    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Budget) {
    const formModel = formGroup.value;
    let budget: Budget = new Budget(null);
    budget.id = formModel.id;
    budget.name = formModel.name;
    budget.budget = formModel.budget;
    budget.start = formModel.start;
    budget.end = formModel.end;
    budget.notes = formModel.notes;
    budget.hours = formModel.hours;
    budget.projectId = formModel.project?.id

    if (original) {
      budget.id = original.id;
    }
    return budget;
  }

  get activityHours(): number {
    let hours = 0;
    this.project?.activities.filter(a => a.budgetId == this.id).forEach((activity: Activity) => {
      hours += activity.hours
    })
    return hours
  }
  get billedActivityHours(): number {
    let hours = 0;
    let billedActivities = this.project?.activities.filter(a => a.budgetId == this.id).filter(a => !!a.billingLineId)
    billedActivities.forEach((activity: Activity) => {
      hours += activity.hours
    })
    return hours
  }


}

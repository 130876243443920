import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ActionFilters } from '../../../commons/models/action.model';

@Component({
  selector: 'app-action-filters',
  templateUrl: './action-filters.component.html',
  styleUrls: ['./action-filters.component.scss']
})
export class ActionFiltersComponent {
  @Output()
  onFilter: EventEmitter<ActionFilters> = new EventEmitter();

  private _defaultFilters: ActionFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      description: "",
      start: "",
      end: "",
      offer: "",
      businessOpportunity: ""
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getAction());
  }

  private _getAction(): ActionFilters {
    let filters: ActionFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.description = values.description;
      filters.start = values.start;
      filters.end = values.end;
      filters.offer_id = values.offer?.id;
      filters.business_opportunity_id = values.businessOpportunity?.id;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      description: "",
      start: "",
      end: "",
      offer: "",
      businessOpportunity: ""
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): ActionFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: ActionFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          description: this.defaultFilters.description,
          start: this.defaultFilters.start,
          end: this.defaultFilters.end,
          offer: this.defaultFilters.offer_id,
          businessOpportunity: this.defaultFilters.business_opportunity_id
        })
      }
    }
  }
}

import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RiskIdentification } from 'src/app/commons/models/risk-identification.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as RiskIdentificationActions from 'src/app/store/actions/risk-identification.actions';
import { AppState } from 'src/app/store/reducers';
import * as RiskIdentificationSelectors from 'src/app/store/selectors/risk-identification.selectors';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

import { RiskIdentificationFilters } from './../../../commons/models/risk-identification.model';
import { RiskIdentificationsColumn } from '../risk-identification-list/risk-identification-list.component';



@Component({
  selector: 'app-risk-identification-selection',
  templateUrl: './risk-identification-selection.component.html',
  styleUrls: ['./risk-identification-selection.component.scss']
})
export class RiskIdentificationSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  riskIdentifications: Observable<RiskIdentification[]>;
  filters: Observable<RiskIdentificationFilters>;

  @Input()
  defaultFilters: RiskIdentificationFilters = {
    search: "",
  }

  displayedColumns: RiskIdentificationsColumn[] = ["id", "riskId", "probability", "impact", "riskOwnerId", "riskActioneeId", "actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.riskIdentifications = this.store$.pipe(select(RiskIdentificationSelectors.getRiskIdentifications), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new RiskIdentification(dto)) : null));
    this.total = this.store$.pipe(select(RiskIdentificationSelectors.getTotalRiskIdentifications), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(RiskIdentificationSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(RiskIdentificationActions.loadRiskIdentifications(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(RiskIdentificationActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(RiskIdentificationActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: RiskIdentificationFilters) {
    this.store$.dispatch(RiskIdentificationActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectRiskIdentification(riskIdentification: RiskIdentification) {
    if (riskIdentification) {
      this.store$.dispatch(RiskIdentificationActions.riskIdentificationSelected({ riskIdentification: riskIdentification.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(RiskIdentificationActions.closeSelectionDialog())
  }
}

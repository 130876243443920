import {Action, createReducer, on} from '@ngrx/store';

import {Risk, RiskDTO} from '../../commons/models/risk.model';
import * as RiskActions from '../actions/risk.actions';
import {RiskFilters} from './../../commons/models/risk.model';

export interface RiskState {
  list: RiskDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: RiskFilters,
  dialogId: string,
  selectionDialogId: string,
  currentFormControlName: string,
  risk: Risk
};

const initialState: RiskState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  risk: null,
  currentFormControlName: null
};

const riskReducer = createReducer(
  initialState,
  on(RiskActions.loadRisksCompleted, (state, {risks, currentPage, total, perPage, order, direction, filters, includes}) => {
    return {...state, list: risks, currentPage, total, perPage, order, direction, filters, includes};
  }),
  on(RiskActions.riskDialogOpened, (state, {dialogId}) => {
    return {...state, dialogId};
  }),
  on(RiskActions.changeFilters, (state, {filters}) => {
    return {...state, currentPage: 1, filters};
  }),
  on(RiskActions.selectionDialogOpened, (state, {selectionDialogId}) => {
    return {...state, selectionDialogId};
  }),
  on(RiskActions.selectRisk, (state, {filters, currentFormControlName}) => {
    return {...state, currentPage: 1, filters, currentFormControlName};
  }),
);

export function reducer(state: RiskState | undefined, action: Action) {
  return riskReducer(state, action);
}


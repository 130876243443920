import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { Target } from '../../../commons/models/target.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { TargetFilters } from './../../../commons/models/target.model';

export type TargetsColumn = 'id' | 'name' | 'actions';

@Component({
  selector: 'target-list',
  templateUrl: './target-list.component.html',
  styleUrls: ['./target-list.component.scss'],
})
export class TargetListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  targets: Observable<Target[]>;

  @Input()
  defaultFilters: TargetFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Target | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: TargetsColumn[] = ['name', 'actions'];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<TargetFilters> =
    new EventEmitter<TargetFilters>();

  @Output()
  onSelectTarget: EventEmitter<Target> = new EventEmitter<Target>();
  @Output()
  onAddTarget: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditTarget: EventEmitter<Target> = new EventEmitter<Target>();

  constructor() {}

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addTarget() {
    this.onAddTarget.emit();
  }

  editTarget(target: Target) {
    this.onEditTarget.emit(target);
  }

  selectTarget(target: Target) {
    this.onSelectTarget.emit(target);
  }

  onFilter(filters: TargetFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

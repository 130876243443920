import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BillingLine } from 'src/app/commons/models/billing-line.model';

@Component({
  selector: 'app-billing-lines-field',
  templateUrl: './billing-lines-field.component.html',
  styleUrls: ['./billing-lines-field.component.scss']
})
export class BillingLinesFieldComponent implements OnInit, OnChanges, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  @Input()
  form: FormArray;

  @Input()
  billingLines: BillingLine[];

  @Input()
  reset$: Observable<void>;

  @Input()
  disabled: boolean;

  constructor() { }

  ngOnInit(): void {
    this.reset$?.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.ngOnChanges(null));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.form) {
      this.resetForm();
      if (this.billingLines) {
        this.billingLines.forEach(billingLine => this.addBillingLine(billingLine));
      }

      if (this.disabled) this.form.disable();
    }
  }

  removeBillingLine(index) {
    this.form.removeAt(index);
    this.form.markAsDirty();
  }

  resetForm() {
    this.form.clear();
  }

  addBillingLine(billingLine?: BillingLine) {
    const group = new FormGroup({
      id: new FormControl(billingLine?.id),
      date: new FormControl(billingLine?.date),
      amount: new FormControl(billingLine?.amount),
      description: new FormControl(billingLine?.description),
    })

    this.form.push(group);
  }
}

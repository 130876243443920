import { createSelector } from '@ngrx/store';

import { selectActionState } from '../reducers';
import { ActionState } from '../reducers/action.reducer';

// Action Selectors

export const getActionsTableState = createSelector(
  selectActionState,
  (state: ActionState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getActions = createSelector(
  selectActionState,
  (state: ActionState) => state.list
);

export const getTotalActions = createSelector(
  getActionsTableState,
  (tableState) => tableState.total
);

export const getActionsCurrentPage = createSelector(
  getActionsTableState,
  (tableState) => tableState.currentPage
);

export const getActionsPerPage = createSelector(
  getActionsTableState,
  (tableState) => tableState.perPage
);

export const getActionsOrder = createSelector(
  getActionsTableState,
  (tableState) => tableState.order
);

export const getActionsDirection = createSelector(
  getActionsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getActionsTableState,
  (tableState) => tableState.filters
);

export const getActionDialogId = createSelector(
  selectActionState,
  (state: ActionState) => state.dialogId
);

export const getSelectedAction = createSelector(
  selectActionState,
  (state: ActionState) => state.action
);
export const getSelectionDialogId = createSelector(
  selectActionState,
  (state: ActionState) => state.selectionDialogId
);

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { Professional, ProfessionalFilters } from '../../../commons/models/professional.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type ProfessionalsColumn = "user" | "dailyCost" | "rateCost" | "kmCost" | "dailyRevenue" | "kmRevenue" | "headProject" | "actions";

@Component({
  selector: 'professional-list',
  templateUrl: './professional-list.component.html',
  styleUrls: ['./professional-list.component.scss'],
})
export class ProfessionalListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  professionals: Observable<Professional[]>;

  @Input()
  defaultFilters: ProfessionalFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Professional | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ProfessionalsColumn[] = ["user" , "dailyCost" , "rateCost" , "kmCost" , "dailyRevenue" , "kmRevenue" , "headProject" , "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Input()
  showProject: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ProfessionalFilters> = new EventEmitter<ProfessionalFilters>();

  @Output()
  onSelectProfessional: EventEmitter<Professional> = new EventEmitter<Professional>();
  @Output()
  onAddProfessional: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditProfessional: EventEmitter<Professional> = new EventEmitter<Professional>();

  constructor() { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addProfessional() {
    this.onAddProfessional.emit();
  }

  editProfessional(professional: Professional) {
    this.onEditProfessional.emit(professional);
  }

  selectProfessional(professional: Professional) {
    this.onSelectProfessional.emit(professional);
  }

  onFilter(filters: ProfessionalFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

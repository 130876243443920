import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { Contact } from '../../../commons/models/contact.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { ContactFilters } from './../../../commons/models/contact.model';

export type ContactsColumn = "lastname" | "name" |  "email" | "phone" | "mobile" | "fax" |"note" | "actions";

@Component({
  selector: 'contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  contacts: Observable<Contact[]>;

  @Input()
  defaultFilters: ContactFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Contact | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ContactsColumn[] = ["lastname", "name", "email", "phone", "mobile","note", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ContactFilters> = new EventEmitter<ContactFilters>();

  @Output()
  onSelectContact: EventEmitter<Contact> = new EventEmitter<Contact>();
  @Output()
  onAddContact: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditContact: EventEmitter<Contact> = new EventEmitter<Contact>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addContact() {
    this.onAddContact.emit();
  }

  editContact(contact: Contact) {
    this.onEditContact.emit(contact);
  }

  selectContact(contact: Contact) {
    this.onSelectContact.emit(contact);
  }

  onFilter(filters: ContactFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

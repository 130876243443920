import { Action, createReducer, on } from '@ngrx/store';

import { Contact, ContactDTO } from '../../commons/models/contact.model';
import * as ContactActions from '../actions/contact.actions';
import { ContactFilters } from './../../commons/models/contact.model';

export interface ContactState {
  list: ContactDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ContactFilters,
  dialogId: string,
  selectionDialogId: string,
  contact: Contact,
  currentFormControlName: string
};

const initialState: ContactState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  contact: null,
  currentFormControlName: null
};

const contactReducer = createReducer(
  initialState,
  on(ContactActions.loadContactsCompleted, (state, { contacts, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: contacts, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(ContactActions.contactDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ContactActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(ContactActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(ContactActions.selectContact, (state, { filters, currentFormControlName }) => {
    return { ...state, currentPage: 1, filters, currentFormControlName };
  }),

);

export function reducer(state: ContactState | undefined, action: Action) {
  return contactReducer(state, action);
}


<mat-expansion-panel style="margin-bottom: 16px;">
  <mat-expansion-panel-header>
    Filtra
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Ricerca libera</mat-label>
        <input type="text" matInput formControlName="search">
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-select formControlName="roles" multiple>
          <mat-select-trigger>
            <span *ngIf="filtersForm.value.roles?.length == roles.length">Tutti</span>
            <span *ngIf="filtersForm.value.roles?.length < roles.length">
              {{(filtersForm.value.roles ? filtersForm.value.roles[0] : '') | userRole}}
              <span *ngIf="filtersForm.value.roles?.length > 1" class="additional-selection">
                (+{{filtersForm.value.roles.length - 1}}
                {{filtersForm.value.roles?.length === 2 ? 'altro' : 'altri'}})
              </span>
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let role of roles" [value]="role" [ngClass]="role">
            {{role | userRole}}</mat-option>
        </mat-select>
        <mat-hint>Ruolo</mat-hint>
      </mat-form-field>
      <mat-form-field class="u-full-width" *ngIf="showActiveFilter">
        <mat-label>Attivo</mat-label>
        <mat-select formControlName="active">
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
      <span class="u-flex-full">
        <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
          (click)="applyFilters()">Filtra</button>
        <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-expansion-panel>

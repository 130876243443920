import { createSelector } from '@ngrx/store';
import { DefaultValuesDTO } from 'src/app/commons/models/default-values.model';

import { selectDefaultValuesState } from '../reducers';
import { DefaultValuesState } from '../reducers/default-values.reducer';

// DefaultValues Selectors
export const getDefaultValues = createSelector(
  selectDefaultValuesState,
  (state: DefaultValuesState) => state.defaultValues
);

export const getArchiveDate = createSelector(
  getDefaultValues,
  (values: DefaultValuesDTO) => values.archive_date
);

export const getDefaultValuesDialogId = createSelector(
  selectDefaultValuesState,
  (state: DefaultValuesState) => state.dialogId
);

export const getNations = createSelector(
  selectDefaultValuesState,
  (state: DefaultValuesState) => state.nations
);

export const getRegions = createSelector(
  selectDefaultValuesState,
  (state: DefaultValuesState) => state.regions
);

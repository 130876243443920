import {Action, createReducer, on} from '@ngrx/store';
import {removeObjectFromArray} from 'src/app/helpers/array.utils';

import {Activity, ActivityDTO, ActivityFilters, ActivityReportDTO} from '../../commons/models/activity.model';
import * as ActivityActions from '../actions/activity.actions';

export interface ActivityState {
  list: ActivityDTO[];
  total: number;
  includes: string[];
  currentPage: number;
  perPage: number;
  order: string;
  direction: string;
  filters: ActivityFilters;

  listReport: ActivityReportDTO[];
  totalReport: number;
  perPageReport: number;
  currentPageReport: number;
  orderReport: string;
  directionReport: string;
  filtersReport: ActivityFilters;

  dialogId: string;
  selectionDialogId: string;
  activity: Activity;
  selectedActivities: Activity[];
}

const initialState: ActivityState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: 'date',
  direction: 'desc',
  filters: null,

  listReport: [],
  totalReport: 0,
  perPageReport: 25,
  currentPageReport: 1,
  orderReport: null,
  directionReport: null,
  filtersReport: null,

  dialogId: null,
  selectionDialogId: null,
  activity: null,
  selectedActivities: [],
};

const activityReducer = createReducer(
  initialState,
  on(
    ActivityActions.loadActivitiesCompleted,
    (
      state,
      {
        activities,
        currentPage,
        total,
        perPage,
        order,
        direction,
        filters,
        includes,
      }
    ) => {
      return {
        ...state,
        list: activities,
        currentPage,
        total,
        perPage,
        order,
        direction,
        filters,
        includes,
      };
    }
  ),
  on(
    ActivityActions.loadActivitiesReportCompleted,
    (
      state,
      {activities, currentPage, total, perPage, order, direction, filters}
    ) => {
      return {
        ...state,
        listReport: activities,
        currentPageReport: currentPage,
        totalReport: total,
        perPageReport: perPage,
        orderReport: order,
        directionReport: direction,
        filtersReport: filters,
      };
    }
  ),
  on(ActivityActions.activityDialogOpened, (state, {dialogId}) => {
    return {...state, dialogId};
  }),
  on(ActivityActions.changeFilters, (state, {filters}) => {
    return {...state, currentPage: 1, filters, selectedActivities: []};
  }),
  on(ActivityActions.changeFiltersReport, (state, {filters}) => {
    return {...state, currentPage: 1, filtersReport: filters};
  }),
  on(ActivityActions.selectionDialogOpened, (state, {selectionDialogId}) => {
    return {...state, selectionDialogId};
  }),
  on(ActivityActions.addSelectedActivities, (state, {activity}) => {
    if (state.selectedActivities.find(a => activity.id === a.id)) {
      return state;
    }
    return {
      ...state,
      selectedActivities: [...state.selectedActivities, activity],
    };
  }),
  on(ActivityActions.removeSelectedActivities, (state, {activity}) => {
    const selectedActivities = removeObjectFromArray<Activity>(
      state.selectedActivities,
      activity
    );
    return {...state, selectedActivities};
  }),
  on(ActivityActions.resetSelectedActivities, (state) => {
    return {...state, selectedActivities: []};
  })
);

export function reducer(state: ActivityState | undefined, action: Action) {
  return activityReducer(state, action);
}

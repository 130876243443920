import { createSelector } from '@ngrx/store';

import { selectClientState } from '../reducers';
import { ClientState } from '../reducers/client.reducer';

// Client Selectors

export const getClientsTableState = createSelector(
  selectClientState,
  (state: ClientState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getClients = createSelector(
  selectClientState,
  (state: ClientState) => state.list
);

export const getTotalClients = createSelector(
  getClientsTableState,
  (tableState) => tableState.total
);

export const getClientsCurrentPage = createSelector(
  getClientsTableState,
  (tableState) => tableState.currentPage
);

export const getClientsPerPage = createSelector(
  getClientsTableState,
  (tableState) => tableState.perPage
);

export const getClientsOrder = createSelector(
  getClientsTableState,
  (tableState) => tableState.order
);

export const getClientsDirection = createSelector(
  getClientsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getClientsTableState,
  (tableState) => tableState.filters
);

export const getClientDialogId = createSelector(
  selectClientState,
  (state: ClientState) => state.dialogId
);

export const getSelectedClient = createSelector(
  selectClientState,
  (state: ClientState) => state.client
);
export const getSelectionDialogId = createSelector(
  selectClientState,
  (state: ClientState) => state.selectionDialogId
);
export const getClient = createSelector(
  selectClientState,
  (state: ClientState) => state.client
);

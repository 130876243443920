<app-risk-category-filters
  (onFilter)="onFilter($event)"
  [defaultFilters]="defaultFilters"
>
</app-risk-category-filters>
<mat-card class="u-center-text" *ngIf="!(riskCategories | async).length">
  <h4>Nessuna contromisura trovata</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addRiskCategory()">
    Aggiungi
  </button>
</mat-card>
<div class="table-container" [hidden]="!(riskCategories | async).length">
  <table mat-table matSort [dataSource]="riskCategories">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let row">
        {{ row.id }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
      <td mat-cell *matCellDef="let row">
        {{ row.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button
          *ngIf="canAdd"
          mat-icon-button
          mat-raised-button
          color="accent"
          (click)="$event.stopPropagation(); addRiskCategory()"
        >
          <mat-icon matTooltip="Aggiungi">playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button
            mat-icon-button
            (click)="$event.stopPropagation(); editRiskCategory(row)"
          >
            <mat-icon matTooltip="Modifica">create</mat-icon>
          </button>
        </ng-container>
        <button
          *ngIf="canSelect"
          mat-raised-button
          color="primary"
          (click)="selectRiskCategory(row)"
        >
          Seleziona
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSizeOptions[0]"
    [length]="total | async"
    showFirstLastButtons
  >
  </mat-paginator>
</div>

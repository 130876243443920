import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

export function removeFromArray<T>(source: Array<T>, element: T): Array<T> {
  if (source.includes(element)) {
    source = [...source];
    source.splice(source.indexOf(element), 1);
  }
  return source;
}

export function indexOfObject<T>(source: Array<T>, element: T): number {
  return source.map(obj => JSON.stringify(obj)).indexOf(JSON.stringify(element));
}

export function removeObjectFromArray<T>(source: Array<T>, element: T): Array<T> {
  const index = indexOfObject<T>(source, element);
  if (index >= 0) {
    source = [...source];
    source.splice(index, 1);
  }
  return source;
}

export function autocompleteSelectionValidator(control: FormControl): ValidationErrors | null {
  let selection = control.value;
  if (typeof selection === "string") {
    return { invalid: true };
  }
  return null;
}


export function hasRequiredField(abstractControl: AbstractControl): boolean {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator['required']) {
      return true;
    }
  }
  if (abstractControl['controls']) {
    for (const controlName in abstractControl['controls']) {
      if (abstractControl['controls'][controlName]) {
        if (hasRequiredField(abstractControl['controls'][controlName])) {
          return true;
        }
      }
    }
  }
  return false;
};

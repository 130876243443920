import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { TargetFilters } from '../../../commons/models/target.model';

@Component({
  selector: 'app-target-filters',
  templateUrl: './target-filters.component.html',
  styleUrls: ['./target-filters.component.scss']
})
export class TargetFiltersComponent {


  @Output()
  onFilter: EventEmitter<TargetFilters> = new EventEmitter();

  private _defaultFilters: TargetFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getTargetFilters());
  }

  private _getTargetFilters(): TargetFilters {
    let filters: TargetFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): TargetFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: TargetFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
        })
      }
    }
  }
}

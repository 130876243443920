import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { formatDateForBackend, previousMonth } from 'src/app/helpers/time.utils';

import { InvoiceFilters } from '../../../commons/models/invoice.model';

@Component({
  selector: 'app-invoice-filters',
  templateUrl: './invoice-filters.component.html',
  styleUrls: ['./invoice-filters.component.scss']
})
export class InvoiceFiltersComponent {
  @Output()
  onFilter: EventEmitter<InvoiceFilters> = new EventEmitter();

  private _defaultFilters: InvoiceFilters;
  filtersForm: FormGroup;

  previous = previousMonth();

  constructor(private fb: FormBuilder) {
    this._createForm();
    this._setDefaultValues();
  }

  private _createForm() {
    let group = {
      identifier: "",
      start: "",
      end: "",
      client: "",
      project: "",
      paid: ""
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getInvoiceFilters());
  }

  private _getInvoiceFilters(): InvoiceFilters {
    let filters: InvoiceFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.identifier = values.identifier;
      filters.start = formatDateForBackend(values.start);
      filters.end = formatDateForBackend(values.end);
      filters.client_id = values.client?.id;
      filters.project_id = values.project?.id;
      filters.paid = values.paid;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      start: this.previous?.start,
      end: this.previous?.end
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): InvoiceFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: InvoiceFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.filtersForm) {
      if (this.defaultFilters) {
        this.filtersForm.patchValue({
          start: this.defaultFilters.start,
          end: this.defaultFilters.end,
          paid: this.defaultFilters.paid,
          client: this.defaultFilters.client_id,
          project: this.defaultFilters.project_id,
          identifier: this.defaultFilters.identifier
        })
      } else {
        this.filtersForm.patchValue({
          start: this.previous?.start,
          end: this.previous?.end
        })
      }
    }
  }
}

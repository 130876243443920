<app-risk-filters
  (onFilter)="onFilter($event)"
  [defaultFilters]="defaultFilters"
>
</app-risk-filters>
<mat-card class="u-center-text" *ngIf="!(risks | async).length">
  <h4>Nessun rischio trovato</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addRisk()">Aggiungi</button>
</mat-card>
<div class="table-container" [hidden]="!(risks | async).length">
  <table
    mat-table
    matSort
    [dataSource]="risks"
    mat-table
    matSortDisableClear
    matSort
    matSortDirection="asc"
  >
    <ng-container matColumnDef="identifier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Ident.</th>
      <td mat-cell *matCellDef="let row">
        {{ row.identifier }}
      </td>
    </ng-container>

    <ng-container matColumnDef="area">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Area</th>
      <td mat-cell *matCellDef="let row">
        {{ row.riskArea?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="target">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Obiettivo</th>
      <td mat-cell *matCellDef="let row">
        {{ row.target?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Categoria contromisura
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.riskCategory?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="owner">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="risk_owner_description"
      >
        Risk owner
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.riskOwnerDescription }}
      </td>
    </ng-container>
    <ng-container matColumnDef="warning">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Early warning indicator
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.warning }}
      </td>
    </ng-container>
    <ng-container matColumnDef="threat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Minaccia/Opportunità
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.threat }}
      </td>
    </ng-container>
    <ng-container matColumnDef="countermeasure">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Contromisura</th>
      <td mat-cell *matCellDef="let row">
        {{ row.countermeasure }}
      </td>
    </ng-container>
    <ng-container matColumnDef="riskActionee">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="risk_actionnee">
        Risk actionee
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.riskActionee }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button
          *ngIf="canAdd"
          mat-icon-button
          mat-raised-button
          color="accent"
          (click)="$event.stopPropagation(); addRisk()"
        >
          <mat-icon matTooltip="Aggiungi">playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button
            mat-icon-button
            (click)="$event.stopPropagation(); editRisk(row)"
          >
            <mat-icon matTooltip="Modifica">create</mat-icon>
          </button>
        </ng-container>
        <button
          *ngIf="canSelect"
          mat-raised-button
          color="primary"
          (click)="selectRisk(row)"
        >
          Seleziona
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSizeOptions[0]"
    [length]="total | async"
    showFirstLastButtons
  >
  </mat-paginator>
</div>

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { ExpenseFilters, ExpenseReport } from '../../../commons/models/expense.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type ExpensesReportColumn = "client" | "project" | "user" | "date" | "amount"| "payer" | "actions";

@Component({
  selector: 'expense-report-list',
  templateUrl: './expense-report-list.component.html',
  styleUrls: ['./expense-report-list.component.scss'],
})
export class ExpenseReportListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  expensesReport: Observable<ExpenseReport[]>;

  @Input()
  defaultFilters: ExpenseFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: ExpenseReport | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ExpensesReportColumn[] = ["client","project", "user", "date", "amount", "payer", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ExpenseFilters> = new EventEmitter<ExpenseFilters>();
  @Output()
  onExportExpenseReport: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  onFilter(filters: ExpenseFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  exportExpenseReport() {
    this.onExportExpenseReport.emit();
  }
}

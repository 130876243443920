import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { RiskArea } from '../../../commons/models/risk-area.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { RiskAreaFilters } from './../../../commons/models/risk-area.model';

export type RiskAreasColumn = 'id' | 'name' | 'actions';

@Component({
  selector: 'risk-area-list',
  templateUrl: './risk-area-list.component.html',
  styleUrls: ['./risk-area-list.component.scss'],
})
export class RiskAreaListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  riskAreas: Observable<RiskArea[]>;

  @Input()
  defaultFilters: RiskAreaFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: RiskArea | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: RiskAreasColumn[] = ['name', 'actions'];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<RiskAreaFilters> =
    new EventEmitter<RiskAreaFilters>();

  @Output()
  onSelectRiskArea: EventEmitter<RiskArea> = new EventEmitter<RiskArea>();
  @Output()
  onAddRiskArea: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditRiskArea: EventEmitter<RiskArea> = new EventEmitter<RiskArea>();

  constructor() {}

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addRiskArea() {
    this.onAddRiskArea.emit();
  }

  editRiskArea(riskArea: RiskArea) {
    this.onEditRiskArea.emit(riskArea);
  }

  selectRiskArea(riskArea: RiskArea) {
    this.onSelectRiskArea.emit(riskArea);
  }

  onFilter(filters: RiskAreaFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

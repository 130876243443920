import { createAction, props } from '@ngrx/store';

import { Contact, ContactDTO, ContactFilters } from '../../commons/models/contact.model';

export const loadContacts = createAction(
  '[Contacts] Load contacts',
  props<{
    page: number;
    perPage: number;
    order?: string;
    direction?: string;
    filters?: ContactFilters;
    includes?: string[];
  }>()
);
export const loadContactsCompleted = createAction(
  '[Contacts] Load contacts Completed',
  props<{
    contacts: ContactDTO[];
    currentPage: number;
    total: number;
    perPage: number;
    order?: string;
    direction?: string;
    filters?: ContactFilters;
    includes?: string[];
  }>()
);
export const loadContactsFailed = createAction(
  '[Contacts] Load contacts Failed',
  props<{ error: any }>()
);

export const changePage = createAction(
  '[Contacts] Change page',
  props<{ page: number; size: number }>()
);
export const changeSort = createAction(
  '[Contacts] Change sort',
  props<{ order: string; direction: string }>()
);
export const changeFilters = createAction(
  '[Contacts] Change filters',
  props<{ filters: ContactFilters }>()
);

export const editContact = createAction(
  '[Contacts] Edit contact',
  props<{ contact: Contact }>()
);
export const contactDialogOpened = createAction(
  '[Contacts] Detail dialog opened',
  props<{ dialogId: string }>()
);
export const closeContactDialog = createAction(
  '[Contacts] Close detail dialog'
);

export const saveContact = createAction(
  '[Contacts] Save contact',
  props<{ contact: Contact }>()
);
export const saveContactCompleted = createAction(
  '[Contacts] Save contact completed',
  props<{ contact: ContactDTO }>()
);
export const saveContactFailed = createAction(
  '[Contacts] Save contact failed',
  props<{ error: any }>()
);

export const deleteContact = createAction(
  '[Contacts] Delete contact',
  props<{ contact: ContactDTO }>()
);
export const deleteContactCompleted = createAction(
  '[Contacts] Delete contact completed',
  props<{ contact: ContactDTO }>()
);
export const deleteContactCancelled = createAction(
  '[Contacts] Delete contact cancelled'
);
export const deleteContactFailed = createAction(
  '[Contacts] Delete contact failed',
  props<{ error: any }>()
);

export const selectContact = createAction(
  '[Contacts] Select contact',
  props<{
    filters?: ContactFilters;
    currentFormControlName: string;
    canAdd?: boolean;
  }>()
);
export const selectionDialogOpened = createAction(
  '[Contacts] Selection dialog opened',
  props<{ selectionDialogId: string }>()
);
export const closeSelectionDialog = createAction(
  '[Contacts] Close selection dialog'
);
export const contactSelected = createAction(
  '[Contacts] Contact selected',
  props<{ contact: ContactDTO; formControlName: string }>()
);

<!-- <app-project-attachment-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-project-attachment-filters> -->
<div class="u-center-text" *ngIf="!projectAttachments?.length">
  <h4>Nessun allegato trovato</h4>
  <button *ngIf="canAdd" mat-raised-button color="accent" (click)="addProjectAttachment()">Aggiungi</button>
</div>
<div class="table-container" [hidden]="!projectAttachments?.length">
  <table mat-table matSort [dataSource]="projectAttachments">
    <ng-container matColumnDef="projectAttachmentCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Codice valuta </th>
      <td mat-cell *matCellDef="let row">
        {{ row.projectAttachmentCode }}
      </td>
    </ng-container>

    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef> Commessa </th>
      <td mat-cell *matCellDef="let row">
        {{row.project?.identifier}}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nome </th>
      <td mat-cell *matCellDef="let row">
        {{row.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="file">
      <th mat-header-cell *matHeaderCellDef> File </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.file">
          <a [href]="row.fileUrl" target="_blank">
            <mat-icon color="primary">attachment</mat-icon>
          </a>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef> Note </th>
      <td mat-cell *matCellDef="let row">
        {{row.notes}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation();addProjectAttachment()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editProjectAttachment(row)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
          </mat-menu>
        </ng-container>

        <button *ngIf="canSelect" mat-raised-button color="primary"
          (click)="selectProjectAttachment(row)">Seleziona</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { RiskArea, RiskAreaDTO } from './risk-area.model';
import { RiskCategory, RiskCategoryDTO } from './risk-category.model';
import { RiskOwner, RiskOwnerDTO } from './risk-owner.model';
import { Target, TargetDTO } from './target.model';

export interface RiskFilters {
  search?: string;
  target?: Target;
  riskArea?: RiskArea;
  riskCategory?: RiskCategory;
  riskOwner?: RiskOwner;
}

export interface RiskDTO extends BaseDTO {
  identifier: string;
  target_id: number;
  target: TargetDTO;
  risk_area_id: number;
  risk_area: RiskAreaDTO;
  risk_owner_id: number;
  risk_owner: RiskOwnerDTO;
  risk_owner_description: string;
  risk_category_id: number;
  risk_category: RiskCategoryDTO;
  threat: string;
  warning: string;
  countermeasure: string;
  risk_actionnee: string;
}
export class Risk extends Base {
  identifier: string;
  target: Target;
  riskArea: RiskArea;
  riskOwner: RiskOwner;
  riskCategory: RiskCategory;
  threat: string;
  warning: string;
  countermeasure: string;
  riskActionee: string;
  riskOwnerDescription: string;

  constructor(source: RiskDTO) {
    super(source);
    if (source) {
      this.identifier = source.identifier;

      if (source.target) {
        this.target = new Target(source.target);
        this.addLoadedRelation('target');
      }
      if (source.risk_area) {
        this.riskArea = new RiskArea(source.risk_area);
        this.addLoadedRelation('risk_area');
      }
      if (source.risk_category) {
        this.riskCategory = new RiskCategory(source.risk_category);
        this.addLoadedRelation('risk_category');
      }
      if (source.risk_owner) {
        this.riskOwner = new Target(source.risk_owner);
        this.addLoadedRelation('risk_owner');
      }

      this.threat = source.threat;
      this.countermeasure = source.countermeasure;
      this.warning = source.warning;
      this.riskActionee = source.risk_actionnee;
      this.riskOwnerDescription = source.risk_owner_description;
    }
  }

  toDTO(): RiskDTO {
    let result: RiskDTO = <RiskDTO>super.toDTO();
    result.identifier = this.identifier;
    result.target = this.target ? this.target.toDTO() : null;
    result.target_id = this.target ? this.target.id : null;
    result.risk_area = this.riskArea ? this.riskArea.toDTO() : null;
    result.risk_area_id = this.riskArea ? this.riskArea.id : null;
    result.risk_category = this.riskCategory ? this.riskCategory.toDTO() : null;
    result.risk_category_id = this.riskCategory ? this.riskCategory.id : null;
    result.risk_owner = this.riskOwner ? this.riskOwner.toDTO() : null;
    result.risk_owner_id = this.riskOwner ? this.riskOwner.id : null;
    result.threat = this.threat;
    result.warning = this.warning;
    result.countermeasure = this.countermeasure;
    result.risk_actionnee = this.riskActionee;
    result.risk_owner_description = this.riskOwnerDescription;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Risk) {
    const formModel = formGroup.value;
    let risk: Risk = new Risk(null);
    risk.identifier = formModel.identifier;
    risk.target = formModel.target;
    risk.riskArea = formModel.riskArea;
    risk.riskCategory = formModel.riskCategory;
    risk.riskOwner = formModel.riskOwner;
    risk.threat = formModel.threat;
    risk.warning = formModel.warning;
    risk.countermeasure = formModel.countermeasure;
    risk.riskActionee = formModel.riskActionee;
    risk.riskOwnerDescription = formModel.riskOwnerDescription;
    if (original) {
      risk.id = original.id;
    }
    return risk;
  }
}

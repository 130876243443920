<mat-card class="u-center-text" *ngIf="!(invoices | async)?.length">
  <h4>Nessuna fattura trovata</h4>
</mat-card>
<div class="table-container" [hidden]="!(invoices | async)?.length">
  <table mat-table matSort [dataSource]="invoices" multiTemplateDataRows>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Identificativo </th>
      <td mat-cell *matCellDef="let row">
        {{ row.invoiceIdentifier }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> Data </th>
      <td mat-cell *matCellDef="let row">
        {{row.date | dateFormat}}
      </td>
    </ng-container>

    <ng-container matColumnDef="expiration">
      <th mat-header-cell *matHeaderCellDef> Scadenza </th>
      <td mat-cell *matCellDef="let row">
        {{row.expiration | dateFormat}}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> Importo </th>
      <td mat-cell *matCellDef="let row">
        {{row.amount}}
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef> Cliente </th>
      <td mat-cell *matCellDef="let row">
        {{row.client?.name }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
<!-- {{ invoices | async | json }} -->

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProjectSelectionComponent } from 'src/app/modules/shared/project-selection/project-selection.component';

import { AlertService } from '../../commons/services/alert.service';
import { LaravelProjectService } from '../../commons/services/backend/laravel-project.service';
import * as ProjectActions from '../actions/project.actions';
import * as RouterActions from '../actions/router.actions';
import { AppState } from '../reducers';
import { getProjectDialogId, getProjectsTableState, getSelectionDialogId } from '../selectors/project.selectors';
import {
  ConfirmPaymentDialogComponent,
} from './../../modules/home/projects/confirm-payment-dialog/confirm-payment-dialog.component';
import { getBonusPaymentDialogId, getCurrentProject, getProjectId } from './../selectors/project.selectors';

@Injectable()
export class ProjectEffects {
  error$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectActions.saveProjectFailed),
        tap(({ error }) => {
          if (error) {
            this.alertService.showErrorMessage('Error:', error);
          }
        })
      ),
    { dispatch: false }
  );

  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadProjects),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.projectService
          .list(page, perPage, order, direction, filters, includes)
          .pipe(
            map((result) =>
              ProjectActions.loadProjectsCompleted({
                projects: result.data,
                currentPage: page,
                total: result.total,
                perPage,
                order,
                direction,
                filters,
                includes,
              })
            ),
            catchError((error) => {
              return of(ProjectActions.loadProjectsFailed({ error }));
            })
          );
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.changePage),
      withLatestFrom(this.store$.select(getProjectsTableState)),
      map(
        ([
          { page, size },
          { total, currentPage, perPage, direction, order, filters, includes },
        ]) =>
          ProjectActions.loadProjects({
            page: page,
            perPage: size,
            order,
            direction,
            filters,
            includes,
          })
      )
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.changeSort),
      withLatestFrom(this.store$.select(getProjectsTableState)),
      map(
        ([
          action,
          { total, currentPage, perPage, direction, order, filters, includes },
        ]) =>
          ProjectActions.loadProjects({
            page: currentPage,
            perPage: perPage,
            order: action.order,
            direction: action.direction,
            filters,
            includes,
          })
      )
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.changeFilters),
      withLatestFrom(this.store$.select(getProjectsTableState)),
      map(
        ([
          { filters },
          { total, currentPage, perPage, direction, order, includes },
        ]) =>
          ProjectActions.loadProjects({
            page: currentPage,
            perPage: perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    )
  );

  showProject = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.showProject),
      map(({ currentProject }) =>
        RouterActions.routerGo({ path: ['/project/', currentProject.id] })
      )
    )
  );

  addProject = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.addProject),
      map(() => RouterActions.routerGo({ path: ['/project/new'] }))
    )
  );

  loadCurrentProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadCurrentProject),
      withLatestFrom(this.store$.select(getCurrentProject)),
      switchMap(([_, currentProject]) => {
        return this.projectService.getProjectById(currentProject.id).pipe(
          map((result) =>
            ProjectActions.loadCurrentProjectCompleted({
              currentProject: result,
            })
          ),
          catchError((error) => {
            return of(ProjectActions.loadCurrentProjectFailed({ error }));
          })
        );
      })
    )
  );

  loadProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.loadProject),
      switchMap(({ id }) => {
        return this.projectService.getProjectById(id).pipe(
          map((result) =>
            ProjectActions.loadProjectCompleted({ project: result })
          ),
          catchError((error) => {
            return of(ProjectActions.loadProjectFailed({ error }));
          })
        );
      })
    )
  );

  saveProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.saveProject),
      mergeMap(({ project }) =>
        this.projectService.upsert(project.toDTO()).pipe(
          map((result) =>
            ProjectActions.saveProjectCompleted({ project: result })
          ),
          catchError((error) => of(ProjectActions.saveProjectFailed({ error })))
        )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.saveProjectCompleted),
      map((action) => action.project),
      tap((project) =>
        this.alertService.showConfirmMessage(
          `Commessa ${project.identifier} salvata con successo`
        )
      ),
      mergeMap((project) => [
        ProjectActions.loadProject({ id: project.id }),
        RouterActions.routerGo({ path: ['/project/' + project.id] }),
      ])
    )
  );

  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProject),
      switchMap(({ project }) =>
        this.alertService
          .showConfirmDialog(
            'Conferma eliminazione',
            `Sei sicuro di voler eliminare la commessa ${project.identifier}?`
          )
          .pipe(
            mergeMap((confirm) => {
              return confirm
                ? this.projectService.delete(project.id).pipe(
                    map((response) =>
                      ProjectActions.deleteProjectCompleted({ project })
                    ),
                    catchError((error) =>
                      of(ProjectActions.deleteProjectFailed({ project, error }))
                    )
                  )
                : of(ProjectActions.deleteProjectCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProjectCompleted),
      tap(({ project }) =>
        this.alertService.showConfirmMessage(
          `Commessa ${project.identifier} eliminata con successo`
        )
      ),
      map(({ project }) => RouterActions.routerGo({ path: ['/projects'] }))
    )
  );

  deleteProjectFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProjectFailed),
      tap(({ error, project }) =>
        this.alertService.showErrorMessage('Error:', error)
      ),
      map(({ error, project }) =>
        RouterActions.routerGo({ path: ['/project/', project.id] })
      )
    )
  );

  closeDialog = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectActions.closeProjectDialog),
        withLatestFrom(this.store$.select(getProjectDialogId)),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId)?.close();
          }
        })
      ),
    { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.saveProjectCompleted),
      withLatestFrom(this.store$.select(getProjectsTableState)),
      map(
        ([_, { currentPage, perPage, direction, order, filters, includes }]) =>
          ProjectActions.loadProjects({
            page: currentPage,
            perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.deleteProjectCompleted),
      withLatestFrom(this.store$.select(getProjectsTableState)),
      map(
        ([_, { currentPage, perPage, direction, order, filters, includes }]) =>
          ProjectActions.loadProjects({
            page: currentPage,
            perPage,
            order,
            direction,
            filters,
            includes,
          })
      )
    )
  );

  selectProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.selectProject),
      map(({ filters, showClientFilter, showPromoFilter }) => {
        let dialogRef = this.dialog.open(ProjectSelectionComponent, {
          data: {
            defaultFilters: filters,
            showClientFilter,
            showPromoFilter,
          },
        });
        return ProjectActions.selectionDialogOpened({
          selectionDialogId: dialogRef.id,
        });
      })
    )
  );

  closeSelectionDialog = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectActions.closeSelectionDialog),
        withLatestFrom(this.store$.select(getSelectionDialogId)),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId).close();
          }
        })
      ),
    { dispatch: false }
  );

  confirmBonusPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.confirmBonusPayment),
      map(({ project }) => {
        let dialogRef = this.dialog.open(ConfirmPaymentDialogComponent, {
          data: {
            project,
          },
          width: '80%',
        });
        return ProjectActions.bonusPaymentDialogOpened({
          bonusPaymentDialogId: dialogRef.id,
        });
      })
    )
  );

  closeBonusPaymentDialog = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProjectActions.closeBonusPaymentDialog),
        withLatestFrom(this.store$.select(getBonusPaymentDialogId)),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId).close();
          }
        })
      ),
    { dispatch: false }
  );

  projectsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.projectSelected),
      map(() => ProjectActions.closeSelectionDialog())
    )
  );

  bonusPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.bonusPayment),
      mergeMap(({ projectId }) =>
        this.projectService.bonusPayment(projectId).pipe(
          map((result) =>
            ProjectActions.bonusPaymentCompleted({ costLines: result })
          ),
          catchError((error) =>
            of(ProjectActions.bonusPaymentFailed({ error }))
          )
        )
      )
    )
  );
  onBonusPaymentCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.bonusPaymentCompleted),
      tap(() =>
        this.alertService.showConfirmMessage(
          `Sblocco pagamento bonus effettuato con successo`
        )
      ),
      map(() => ProjectActions.closeBonusPaymentDialog())
    )
  );

  reloadProjectAfterPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.bonusPaymentCompleted),
      withLatestFrom(this.store$.select(getProjectId)),
      map(([_, projectId]) => ProjectActions.loadProject({ id: projectId }))
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private projectService: LaravelProjectService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) {}
}


import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { PaymentEditComponent } from 'src/app/modules/home/payments/payment-edit/payment-edit.component';
import { AlertService } from '../../commons/services/alert.service';
import { LaravelPaymentService } from '../../commons/services/backend/laravel-payment.service';
import * as PaymentActions from '../actions/payment.actions';
import { AppState } from '../reducers';
import { getPaymentDialogId, getPaymentsTableState, getSelectionDialogId } from '../selectors/payment.selectors';
import { PaymentSelectionComponent } from 'src/app/modules/shared/payment-selection/payment-selection.component';


@Injectable()
export class PaymentEffects {
  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.savePaymentFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

  loadPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.loadPayments),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.paymentService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              PaymentActions.loadPaymentsCompleted({ payments: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(PaymentActions.loadPaymentsFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.changePage),
      withLatestFrom(this.store$.select(getPaymentsTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => PaymentActions.loadPayments({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.changeSort),
      withLatestFrom(this.store$.select(getPaymentsTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => PaymentActions.loadPayments({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.changeFilters),
      withLatestFrom(this.store$.select(getPaymentsTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => PaymentActions.loadPayments({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  editPayment$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentActions.editPayment),
    map(({ payment }) => {
      let dialogRef = this.dialog.open(PaymentEditComponent, {
        data: {
          payment
        },
        width: '600px',
      });
      return PaymentActions.paymentDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  savePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.savePayment),
      mergeMap(({ payment }) =>
        this.paymentService.upsert(payment.toDTO())
          .pipe(
            map(result =>
              PaymentActions.savePaymentCompleted({ payment: result })
            ),
            catchError(error => of(PaymentActions.savePaymentFailed({ error })))
          )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.savePaymentCompleted),
      map(action => action.payment),
      tap(payment => this.alertService.showConfirmMessage(`${payment.code} salvata con successo`)),
      map(() => PaymentActions.closePaymentDialog())
    )
  );


  deletePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.deletePayment),
      switchMap(({ payment }) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare il pagamento ${payment.code}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.paymentService.delete(payment.id)
                  .pipe(
                    map(() => PaymentActions.deletePaymentCompleted({ payment })),
                    catchError(error => of(PaymentActions.deletePaymentFailed({ error })))
                  )

                : of(PaymentActions.deletePaymentCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.deletePaymentCompleted),
      tap(({ payment }) => this.alertService.showConfirmMessage(`Pagamento ${payment.code} eliminato con successo`)),
      map(() => PaymentActions.closePaymentDialog())
    )
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.closePaymentDialog),
      withLatestFrom(this.store$.select(getPaymentDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.savePaymentCompleted),
      withLatestFrom(this.store$.select(getPaymentsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => PaymentActions.loadPayments({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.deletePaymentCompleted),
      withLatestFrom(this.store$.select(getPaymentsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => PaymentActions.loadPayments({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );


  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  selectPayment$ = createEffect(() => this.actions$.pipe(
    ofType(PaymentActions.selectPayment),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(PaymentSelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return PaymentActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );

  paymentsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentActions.paymentSelected),
      map(() => PaymentActions.closeSelectionDialog())
    ))





  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private paymentService: LaravelPaymentService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}



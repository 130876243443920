import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BudgetFilters } from '../../../commons/models/budget.model';

@Component({
  selector: 'app-budget-filters',
  templateUrl: './budget-filters.component.html',
  styleUrls: ['./budget-filters.component.scss']
})
export class BudgetFiltersComponent {
  @Output()
  onFilter: EventEmitter<BudgetFilters> = new EventEmitter();

  private _defaultFilters: BudgetFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getBankAccount());
  }

  private _getBankAccount(): BudgetFilters {
    let filters: BudgetFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): BudgetFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: BudgetFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
        })
      }
    }
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ActivityFilters } from '../../../commons/models/activity.model';

@Component({
  selector: 'activity-report-filters',
  templateUrl: './activity-report-filters.component.html',
  styleUrls: ['./activity-report-filters.component.scss']
})
export class ActivityReportFiltersComponent {
  @Output()
  onFilter: EventEmitter<ActivityFilters> = new EventEmitter();

  private _defaultFilters: ActivityFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
    this.resetFilters();
  }

  private _createForm() {
    let group = {
      start: "",
      end: "",
      payable: "",
      client: "",
      project: "",
      professional: ""
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getActivityFilters());
  }

  private _getActivityFilters(): ActivityFilters {
    let filters: ActivityFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.start = values.start;
      filters.end = values.end;
      filters.professional = values.professional;
      filters.project_id = values.project?.id;
      filters.client_id = values.client?.id;
      filters.payable = values.payable;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): ActivityFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: ActivityFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          start: this.defaultFilters.start,
          end: this.defaultFilters.end
        })
      }
    }
  }
}

import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Actions, EffectsModule, ofType } from '@ngrx/effects';
import { Action, Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, POSITION, SPINNER } from 'ngx-ui-loader';
import { forkJoin, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActionAttachmentEffects } from 'src/app/store/effects/action-attachment.effects';
import { BankAccountEffects } from 'src/app/store/effects/bank-account.effects';
import { BonusLineEffects } from 'src/app/store/effects/bonus-line.effects';
import { BonusEffects } from 'src/app/store/effects/bonus.effects';
import { BudgetEffects } from 'src/app/store/effects/budget.effects';
import { ContactEffects } from 'src/app/store/effects/contact.effects';
import { PaymentEffects } from 'src/app/store/effects/payment.effects';
import { RiskAreaEffects } from 'src/app/store/effects/risk-area.effects';
import { RiskCategoryEffects } from 'src/app/store/effects/risk-category.effects';
import { RiskIdentificationEffects } from 'src/app/store/effects/risk-identification.effects';
import { RiskOwnerEffects } from 'src/app/store/effects/risk-owner.effects';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptorService } from './helpers/token-interceptor.service';
import { MaterialModule } from './modules/material/material.module';
import { SharedModule } from './modules/shared/shared.module';
import * as AuthActions from './store/actions/auth.actions';
import * as DefaultValuesActions from './store/actions/default-values.actions';
import { AuthEffects } from './store/effects/auth.effects';
import { ClientEffects } from './store/effects/client.effects';
import { CurrencyEffects } from './store/effects/currency.effects';
import { DefaultValuesEffects } from './store/effects/default-values.effects';
import { IvaTypologyEffects } from './store/effects/iva-typology.effects';
import { OfferEffects } from './store/effects/offer.effects';
import { ProfessionalEffects } from './store/effects/professional.effects';
import { ProjectEffects } from './store/effects/project.effects';
import { RouterEffects } from './store/effects/router.effects';
import { TargetEffects } from './store/effects/target.effects';
import { UserEffects } from './store/effects/user.effects';
import { AppState, metaReducers, reducers } from './store/reducers';

registerLocaleData(localeIt, 'it-IT');

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: '#EE0025',
  fgsType: SPINNER.squareJellyBox,
  hasProgressBar: false,
  overlayColor: 'rgba(130,130,130,0.7)',
  fgsPosition: POSITION.bottomCenter,
};

export function initApplication(
  store: Store<AppState>,
  actions$: Actions
): Function {
  return () =>
    new Promise((resolve) => {
      store.dispatch(AuthActions.loadCurrentUser());
      store.dispatch(DefaultValuesActions.loadDefaultValues());
      store.dispatch(DefaultValuesActions.loadNations());
      const initTasks$:Observable<Action>[] = [];
      initTasks$.push(
        actions$.pipe(ofType(
          AuthActions.loadCurrentUserCompleted,
          AuthActions.loadCurrentUserFailed
        ), take(1))
      );
      initTasks$.push(
        actions$.pipe(ofType(
          DefaultValuesActions.loadDefaultValuesCompleted,
          DefaultValuesActions.loadDefaultValuesFailed
        ), take(1))
      );
      forkJoin(initTasks$).subscribe(() => resolve(true));
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    MatProgressBarModule,
    BrowserModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    FlexLayoutModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      AuthEffects,
      RouterEffects,
      UserEffects,
      CurrencyEffects,
      ContactEffects,
      PaymentEffects,
      TargetEffects,
      RiskAreaEffects,
      RiskCategoryEffects,
      RiskOwnerEffects,
      ProjectEffects,
      ClientEffects,
      BankAccountEffects,
      IvaTypologyEffects,
      ProfessionalEffects,
      RiskIdentificationEffects,
      BudgetEffects,
      OfferEffects,
      BonusEffects,
      DefaultValuesEffects,
      ActionAttachmentEffects,
      BonusLineEffects,
    ]),
    MaterialModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    AuthEffects,
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      deps: [Store, Actions],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'it-IT',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import {Action, createReducer, on} from '@ngrx/store';
import {removeObjectFromArray} from 'src/app/helpers/array.utils';

import {Expense, ExpenseDTO, ExpenseFilters, ExpenseReportDTO} from '../../commons/models/expense.model';
import * as ExpenseActions from '../actions/expense.actions';

export interface ExpenseState {
  list: ExpenseDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ExpenseFilters,

  listReport: ExpenseReportDTO[],
  totalReport: number,
  perPageReport: number,
  currentPageReport: number,
  orderReport: string,
  directionReport: string,
  filtersReport: ExpenseFilters,

  dialogId: string,
  selectionDialogId: string,
  expense: Expense,
  selectedExpenses: Expense[]
};

const initialState: ExpenseState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,

  listReport: [],
  totalReport: 0,
  perPageReport: 25,
  currentPageReport: 1,
  orderReport: null,
  directionReport: null,
  filtersReport: null,

  dialogId: null,
  selectionDialogId: null,
  expense: null,
  selectedExpenses: []
};

const expenseReducer = createReducer(
  initialState,
  on(ExpenseActions.loadExpensesCompleted, (state, {expenses, currentPage, total, perPage, order, direction, includes, filters}) => {
    return {...state, list: expenses, currentPage, total, perPage, order, direction, includes, filters};
  }),
  on(ExpenseActions.loadExpensesReportCompleted, (state, {expenses, currentPage, total, perPage, order, direction, filters}) => {
    return {...state, listReport: expenses, currentPageReport: currentPage, totalReport: total, perPageReport: perPage, orderReport: order, directionReport: direction, filtersReport: filters};
  }),
  on(ExpenseActions.expenseDialogOpened, (state, {dialogId}) => {
    return {...state, dialogId};
  }),
  on(ExpenseActions.changeFilters, (state, {filters}) => {
    return {...state, currentPage: 1, filters, selectedExpenses: []};
  }),
  on(ExpenseActions.changeFiltersReport, (state, {filters}) => {
    return {...state, currentPage: 1, filtersReport: filters};
  }),
  on(ExpenseActions.changeFilterByDailyExpenses, (state, {filters}) => {
    return {...state, currentPage: 1, filters};
  }),
  on(ExpenseActions.selectionDialogOpened, (state, {selectionDialogId}) => {
    return {...state, selectionDialogId};
  }),
  on(ExpenseActions.addSelectedExpenses, (state, {expense}) => {
    if (state.selectedExpenses.find(e => expense.id === e.id)) {
      return state;
    }
    return {...state, selectedExpenses: [...state.selectedExpenses, expense]}
  }),
  on(ExpenseActions.removeSelectedExpenses, (state, {expense}) => {
    const selectedExpenses = removeObjectFromArray<Expense>(state.selectedExpenses, expense);
    return {...state, selectedExpenses}
  }),
  on(ExpenseActions.resetSelectedExpenses, (state) => {
    return {...state, selectedExpenses: []}
  }),
);

export function reducer(state: ExpenseState | undefined, action: Action) {
  return expenseReducer(state, action);
}


import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AlertService } from 'src/app/commons/services/alert.service';
import { AppState } from 'src/app/store/reducers';

import * as BonusActions from '../../../../store/actions/bonus.actions';

@Component({
  selector: 'app-generate-bonus',
  templateUrl: './generate-bonus.component.html',
  styleUrls: ['./generate-bonus.component.scss']
})
export class GenerateBonusComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  years: number[];

  bonusForm: FormGroup;
  constructor(
    private store$: Store<AppState>,
    private alertService: AlertService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    const now = new Date().getUTCFullYear();
    this.years = Array(5).fill('').map((v, idx) => now - idx);
    this._createForm();
    this.ngOnChanges();
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      year: ["", Validators.required ],
      bonus: ["", Validators.required],
    };
    this.bonusForm = this.fb.group(group);

  }
  private ngOnChanges() {
    if (this.bonusForm) {
      this.bonusForm.reset();
    }
  }

  generateBonus() {
   let  year = this.bonusForm.value.year;
   let bonus = this.bonusForm.value.bonus;
    this.store$.dispatch(
      BonusActions.generateBonus({ year, bonus })
    );
  }
  close() {
    if (this.bonusForm.pristine) {
      this.store$.dispatch(BonusActions.closeBonusDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(BonusActions.closeBonusDialog())
          }
        });
    }
  }



  revert() {
    this.ngOnChanges();
  }

}

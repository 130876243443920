import { Action, createReducer, on } from '@ngrx/store';

import {
  ProjectAttachment,
  ProjectAttachmentDTO,
  ProjectAttachmentFilters,
} from '../../commons/models/project-attachment.model';
import * as ProjectAttachmentActions from '../actions/project-attachment.actions';


export interface ProjectAttachmentState {
  list: ProjectAttachmentDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ProjectAttachmentFilters,
  dialogId: string,
  selectionDialogId: string,
  projectAttachment: ProjectAttachment
};

const initialState: ProjectAttachmentState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  projectAttachment: null
};

const projectAttachmentReducer = createReducer(
  initialState,
  on(ProjectAttachmentActions.loadProjectAttachmentsCompleted, (state, { projectAttachments, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: projectAttachments, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(ProjectAttachmentActions.projectAttachmentDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ProjectAttachmentActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(ProjectAttachmentActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(ProjectAttachmentActions.loadProjectAttachments, (state, { filters }) => {
    return { ...state, filters };
  })
);

export function reducer(state: ProjectAttachmentState | undefined, action: Action) {
  return projectAttachmentReducer(state, action);
}


import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { BankAccount, BankAccountFilters } from '../../../commons/models/bank-account.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type BankAccountsColumn = 'id' | 'name' | 'iban' | 'actions';

@Component({
  selector: 'bank-account-list',
  templateUrl: './bank-account-list.component.html',
  styleUrls: ['./bank-account-list.component.scss'],
})
export class BankAccountListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  bankAccounts: Observable<BankAccount[]>;

  @Input()
  defaultFilters: BankAccountFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: BankAccount | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: BankAccountsColumn[] = ['name', 'iban', 'actions'];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<BankAccountFilters> =
    new EventEmitter<BankAccountFilters>();

  @Output()
  onSelectBankAccount: EventEmitter<BankAccount> =
    new EventEmitter<BankAccount>();
  @Output()
  onAddBankAccount: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditBankAccount: EventEmitter<BankAccount> =
    new EventEmitter<BankAccount>();

  constructor() {}

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addBankAccount() {
    this.onAddBankAccount.emit();
  }

  editBankAccount(account: BankAccount) {
    this.onEditBankAccount.emit(account);
  }

  selectBankAccount(account: BankAccount) {
    this.onSelectBankAccount.emit(account);
  }

  onFilter(filters: BankAccountFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

import { AbstractControl } from '@angular/forms';
import { Base, BaseDTO } from './base.model';
import { Currency, CurrencyDTO } from './currency.model';
import { Client, ClientDTO } from './client.model';
import { User, UserDTO } from './user.model';

export interface ProjectBonusFilters {
    user_id?: number;
    project_id?: number;
}

export interface ClientBonusDTO extends BaseDTO {
    user_id: number;
    client_id: number;
    user: UserDTO;
    client: ClientDTO;
    percentage: number;
}

export class ClientBonus extends Base {

    user: User;
    client: Client;
    percentage: number;

    constructor(source: ClientBonusDTO) {
        super(source);
        if (source) {
            if (source.user) {
                this.user = new User(source.user);
                this.addLoadedRelation('user');
            }
            if (source.client) {
                this.client = new Client(source.client);
                this.addLoadedRelation('client');
            }
            this.percentage = source.percentage;
        }
    }

    toDTO(): ClientBonusDTO {
        let result: ClientBonusDTO = <ClientBonusDTO>super.toDTO();
        result.user = this.user ? this.user.toDTO() : null;
        result.user_id = this.user ? this.user.id : null;
        result.percentage = this.percentage;
        result.client = this.client ? this.client.toDTO() : null;
        result.client_id = this.client ? this.client.id : null;

        return result;
    }

    static fromFormGroup(formGroup: AbstractControl, original?: ClientBonus) {
        const formModel = formGroup.value;
        let clientBonus: ClientBonus = new ClientBonus(null);
        clientBonus.user = formModel.user;
        clientBonus.percentage = formModel.percentage;
        clientBonus.client = formModel.client;

        if (original) {
            clientBonus.id = original.id;
        }
        return clientBonus;
    }

}

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, forwardRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Contact, ContactFilters } from 'src/app/commons/models/contact.model';
import * as ContactActions from 'src/app/store/actions/contact.actions';
import { AppState } from 'src/app/store/reducers';
import * as ContactSelectors from 'src/app/store/selectors/contact.selectors';

export const CONTACTS_FIELD_MAT_FORM_FIELD_CONTROL: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => ContactsFieldComponent),
};

@Component({
  selector: 'app-contacts-field',
  templateUrl: './contacts-field.component.html',
  styleUrls: ['./contacts-field.component.scss'],
  providers: [CONTACTS_FIELD_MAT_FORM_FIELD_CONTROL],
  host: {
    '[class.floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class ContactsFieldComponent
  implements OnDestroy, ControlValueAccessor, MatFormFieldControl<Contact[]>
{
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  private unsubscribe$ = new Subject<void>();

  private _value: Contact[] = [];
  private _disabled = false;

  private _required = false;

  get errorState(): boolean {
    return !this.ngControl.valid;
  }
  focused: boolean; //TODO
  controlType?: string = 'contact-selection-field';
  autofilled?: boolean;
  contactAriaDescribedBy?: string;
  currentFormControlName: string;

  @Input()
  contactFilters: ContactFilters;

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    @Optional() @Self() public ngControl: NgControl,
    private fb: FormBuilder
  ) {
    this.actions$
      .pipe(
        ofType(ContactActions.contactSelected),
        takeUntil(this.unsubscribe$),
        withLatestFrom(
          this.store$.select(ContactSelectors.getCurrentFormControlName)
        ),
        filter(
          ([_, currentFormControlName]) =>
            currentFormControlName == this.currentFormControlName
        ),
        map(([{ contact }, _]) => (contact ? new Contact(contact) : null))
      )
      .subscribe((contact) => {
        // this.value = [] ;
        this.value.push(contact);
        this.notifyValueChange();
      });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  removeContact(index: number) {
    this.value.splice(index, 1);
    this.notifyValueChange();
  }

  stateChanges = new Subject<void>();
  id: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return (
      this._required ||
      this.ngControl?.control?.hasValidator(Validators.required) ||
      false
    );
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {}

  onContainerClick(event: MouseEvent): void {}
  ngAfterContentInit(): void {
    const control = this.ngControl && this.ngControl.control;
    if (control) {
      this.currentFormControlName = this.getControlName(control);
    }
  }
  private getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stateChanges.complete();
  }

  selectContact() {
    this.store$.dispatch(
      ContactActions.selectContact({
        filters: this.contactFilters,
        currentFormControlName: `${this.currentFormControlName}`,
        canAdd: true,
      })
    );
  }

  clearContact() {
    this.value = null;
  }
  @Input()
  set value(value: Contact[]) {
    this._value = value;
    this.notifyValueChange();
    this.stateChanges.next();
  }

  get value(): Contact[] {
    return this._value;
  }

  onChange: (value) => {};
  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: Contact[]): void {
    this.value = [];
    if (obj) {
      this.value.push(...obj);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

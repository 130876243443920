import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BusinessOpportunity } from 'src/app/commons/models/business-opportunity.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as BusinessOpportunityActions from 'src/app/store/actions/business-opportunity.actions';
import { AppState } from 'src/app/store/reducers';
import * as BusinessOpportunitySelectors from 'src/app/store/selectors/business-opportunity.selectors';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

import { BusinessOpportunityFilters } from '../../../commons/models/business-opportunity.model';
import { BusinessOpportunitiesColumn } from '../business-opportunity-list/business-opportunity-list.component';



@Component({
  selector: 'app-business-opportunity-selection',
  templateUrl: './business-opportunity-selection.component.html',
  styleUrls: ['./business-opportunity-selection.component.scss']
})
export class BusinessOpportunitySelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  businessOpportunities: Observable<BusinessOpportunity[]>;
  filters: Observable<BusinessOpportunityFilters>;

  @Input()
  defaultFilters: BusinessOpportunityFilters = {
    search: "",
  }

  displayedColumns: BusinessOpportunitiesColumn[] = ["title", "promo", "actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.businessOpportunities = this.store$.pipe(select(BusinessOpportunitySelectors.getBusinessOpportunities), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new BusinessOpportunity(dto)) : null));
    this.total = this.store$.pipe(select(BusinessOpportunitySelectors.getTotalBusinessOpportunities), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(BusinessOpportunitySelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(BusinessOpportunityActions.loadBusinessOpportunities(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(BusinessOpportunityActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(BusinessOpportunityActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: BusinessOpportunityFilters) {
    this.store$.dispatch(BusinessOpportunityActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectBusinessOpportunity(businessOpportunity: BusinessOpportunity) {
    if (businessOpportunity) {
      this.store$.dispatch(BusinessOpportunityActions.businessOpportunitySelected({ businessOpportunity: businessOpportunity.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(BusinessOpportunityActions.closeSelectionDialog())
  }
}

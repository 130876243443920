import { Directive, HostListener } from '@angular/core';


@Directive({
  selector: '[forceNumber]'
})
export class ForceNumberDirective{
  constructor() { }

  @HostListener('keypress', ['$event'])
  onKeyPress(event) {
    event = event || window.event;
    var charCode = (typeof event.which == "undefined") ? event.keyCode : event.which;
    var charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9\.\,]+$/))
      event.preventDefault();
  }
}

import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Activity } from 'src/app/commons/models/activity.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as ActivityActions from 'src/app/store/actions/activity.actions';
import { AppState } from 'src/app/store/reducers';
import * as ActivitySelectors from 'src/app/store/selectors/activity.selectors';

import { ActivityFilters } from '../../../commons/models/activity.model';
import { ActivitiesColumn } from '../activity-list/activity-list.component';

@Component({
  selector: 'app-activity-selection',
  templateUrl: './activity-selection.component.html',
  styleUrls: ['./activity-selection.component.scss'],
})
export class ActivitySelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  activities: Observable<Activity[]>;
  filters: Observable<ActivityFilters>;

  @Input()
  defaultFilters: ActivityFilters = {
    search: '',
  };

  displayedColumns: ActivitiesColumn[] = ['project', 'professional', 'actions'];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  private _includes: string[] = [
    ' professional',
    'project',
    'project.activities',
    'billing_line',
    'cost_line',
    'validation_user',
  ];
  constructor(
    private store$: Store<AppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.activities = this.store$.pipe(
      select(ActivitySelectors.getActivities),
      takeUntil(this.unsubscribe$),
      map((dtos) => (dtos ? dtos.map((dto) => new Activity(dto)) : null))
    );
    this.total = this.store$.pipe(
      select(ActivitySelectors.getTotalActivities),
      takeUntil(this.unsubscribe$)
    );
    this.filters = this.store$.pipe(
      select(ActivitySelectors.getFilters),
      takeUntil(this.unsubscribe$)
    );
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(
      ActivityActions.loadActivities({
        page: 1,
        perPage: this.pageSizeOptions[0],
        filters: this.defaultFilters,
        includes: this._includes,
      })
    );
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(
      ActivityActions.changeSort({
        order: sort.active,
        direction: sort.direction,
      })
    );
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(
      ActivityActions.changePage({
        page: pageEvent.pageIndex + 1,
        size: pageEvent.pageSize,
      })
    );
  }

  filtersChange(filters: ActivityFilters) {
    this.store$.dispatch(ActivityActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectActivity(activity: Activity) {
    if (activity) {
      this.store$.dispatch(
        ActivityActions.activitySelected({ activity: activity.toDTO() })
      );
    }
  }

  close() {
    this.store$.dispatch(ActivityActions.closeSelectionDialog());
  }
}

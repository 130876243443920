import { createSelector } from '@ngrx/store';

import { selectRiskIdentificationState } from '../reducers';
import { RiskIdentificationState } from '../reducers/risk-identification.reducer';

// RiskIdentification Selectors

export const getRiskIdentificationsTableState = createSelector(
  selectRiskIdentificationState,
  (state: RiskIdentificationState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getRiskIdentifications = createSelector(
  selectRiskIdentificationState,
  (state: RiskIdentificationState) => state.list
);

export const getTotalRiskIdentifications = createSelector(
  getRiskIdentificationsTableState,
  (tableState) => tableState.total
);

export const getRiskIdentificationsCurrentPage = createSelector(
  getRiskIdentificationsTableState,
  (tableState) => tableState.currentPage
);

export const getRiskIdentificationsPerPage = createSelector(
  getRiskIdentificationsTableState,
  (tableState) => tableState.perPage
);

export const getRiskIdentificationsOrder = createSelector(
  getRiskIdentificationsTableState,
  (tableState) => tableState.order
);

export const getRiskIdentificationsDirection = createSelector(
  getRiskIdentificationsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getRiskIdentificationsTableState,
  (tableState) => tableState.filters
);

export const getRiskIdentificationDialogId = createSelector(
  selectRiskIdentificationState,
  (state: RiskIdentificationState) => state.dialogId
);

export const getSelectedRiskIdentification = createSelector(
  selectRiskIdentificationState,
  (state: RiskIdentificationState) => state.riskIdentification
);
export const getSelectionDialogId = createSelector(
  selectRiskIdentificationState,
  (state: RiskIdentificationState) => state.selectionDialogId
);

import { createSelector } from '@ngrx/store';

import { selectPaymentState } from '../reducers';
import { PaymentState } from '../reducers/payment.reducer';

// Payment Selectors
export const getPaymentsTableState = createSelector(
  selectPaymentState,
  (state: PaymentState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getPayments = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.list
);

export const getTotalPayments = createSelector(
  getPaymentsTableState,
  (tableState) => tableState.total
);

export const getPaymentsCurrentPage = createSelector(
  getPaymentsTableState,
  (tableState) => tableState.currentPage
);

export const getPaymentsPerPage = createSelector(
  getPaymentsTableState,
  (tableState) => tableState.perPage
);

export const getPaymentsOrder = createSelector(
  getPaymentsTableState,
  (tableState) => tableState.order
);

export const getPaymentsDirection = createSelector(
  getPaymentsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getPaymentsTableState,
  (tableState) => tableState.filters
);

export const getPaymentDialogId = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.dialogId
);

export const getSelectedPayment = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.payment
);
export const getSelectionDialogId = createSelector(
  selectPaymentState,
  (state: PaymentState) => state.selectionDialogId
);

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { IvaTypologyFilters } from '../../../commons/models/iva-typology.model';

@Component({
  selector: 'app-iva-typology-filters',
  templateUrl: './iva-typology-filters.component.html',
  styleUrls: ['./iva-typology-filters.component.scss']
})
export class IvaTypologyFiltersComponent {
  @Output()
  onFilter: EventEmitter<IvaTypologyFilters> = new EventEmitter();

  private _defaultFilters: IvaTypologyFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getBankAccount());
  }

  private _getBankAccount(): IvaTypologyFilters {
    let filters: IvaTypologyFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): IvaTypologyFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: IvaTypologyFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
        })
      }
    }
  }
}

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RiskIdentification } from 'src/app/commons/models/risk-identification.model';

@Component({
  selector: 'app-risk-identifications-field',
  templateUrl: './risk-identifications-field.component.html',
  styleUrls: ['./risk-identifications-field.component.scss'],
})
export class RiskIdentificationsFieldComponent
  implements OnInit, OnChanges, OnDestroy
{
  private unsubscribe$ = new Subject<void>();

  @Input()
  form: FormArray;

  @Input()
  riskIdentifications: RiskIdentification[];

  @Input()
  reset$: Observable<void>;

  constructor() {}

  ngOnInit(): void {
    this.reset$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.ngOnChanges(null));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.form) {
      this.resetForm();
      if (this.riskIdentifications) {
        this.riskIdentifications.forEach((riskIdentification) =>
          this.addRiskIdentification(riskIdentification)
        );
      }
    }
  }

  setHeadProject(index) {
    this.form.controls.forEach((control, i) => {
      control.patchValue({ headProject: index === i });
    });
    this.form.markAsDirty();
  }

  removeRiskIdentification(index) {
    this.form.removeAt(index);
    this.form.markAsDirty();
  }

  resetForm() {
    this.form.clear();
  }

  addRiskIdentification(riskIdentification?: RiskIdentification) {
    const group = new FormGroup({
      id: new FormControl(riskIdentification?.id),
      risk: new FormControl(riskIdentification?.risk, [Validators.required]),
      probability: new FormControl(riskIdentification?.probability, [
        Validators.required,
      ]),
      impact: new FormControl(riskIdentification?.impact, [
        Validators.required,
      ]),
      riskOwner: new FormControl(riskIdentification?.riskOwner),
      // riskActionee:  new FormControl(riskIdentification?.riskActionee),
      actionee: new FormControl(riskIdentification?.actionee),
    });
    this.form.push(group);
    if(!riskIdentification) {
      group.markAllAsTouched();
    }
  }
}

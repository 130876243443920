import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {Target } from 'src/app/commons/models/target.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as TargetActions from 'src/app/store/actions/target.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'app-target-edit',
  templateUrl: './target-edit.component.html',
  styleUrls: ['./target-edit.component.scss']
})
export class TargetEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _target: Target;

  currentTarget: Target;

  targetForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data) {
      this.target = this.data.target;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      name: ["", Validators.required],
    }
    this.targetForm = this.fb.group(group);
  }

  private ngOnChanges() {
    if (this.targetForm) {
      this.targetForm.reset();
      if (this.target) {
        this._initFormValues(this.target);
      }
    }

  }

  private _initFormValues(target: Target) {
    if (target) {
      this.targetForm.patchValue({
        name: target.name,
      });
    }
  }

  get target(): Target {
    return this._target;
  }

  set target(target: Target) {
    this._target = target;
    this.ngOnChanges();
  }

  private _prepareSaveTarget(): Target {
    let savingTarget: Target = Target.fromFormGroup(this.targetForm, this.target);
    return savingTarget;
  }

  save() {
    let unsavedEntity = this._prepareSaveTarget();
    this.store$.dispatch(TargetActions.saveTarget({ target: unsavedEntity }));
  }

  close() {
    if (this.targetForm.pristine) {
      this.store$.dispatch(TargetActions.closeTargetDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(TargetActions.closeTargetDialog())
          }
        });
    }
  }

  deleteTarget() {
    if (this.target) {
      this.store$.dispatch(TargetActions.deleteTarget({ target: this.target.toDTO() }))
    }
  }

  revert() {
    this.ngOnChanges();
  }
}

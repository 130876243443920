import { createSelector } from '@ngrx/store';

import { selectActionAttachmentState } from '../reducers';
import { ActionAttachmentState } from '../reducers/action-attachment.reducer';

// ActionAttachment Selectors

export const getActionAttachmentsTableState = createSelector(
  selectActionAttachmentState,
  (state: ActionAttachmentState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getActionAttachments = createSelector(
  selectActionAttachmentState,
  (state: ActionAttachmentState) => state.list
);

export const getTotalActionAttachments = createSelector(
  getActionAttachmentsTableState,
  (tableState) => tableState.total
);

export const getActionAttachmentsCurrentPage = createSelector(
  getActionAttachmentsTableState,
  (tableState) => tableState.currentPage
);

export const getActionAttachmentsPerPage = createSelector(
  getActionAttachmentsTableState,
  (tableState) => tableState.perPage
);

export const getActionAttachmentsOrder = createSelector(
  getActionAttachmentsTableState,
  (tableState) => tableState.order
);

export const getActionAttachmentsDirection = createSelector(
  getActionAttachmentsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getActionAttachmentsTableState,
  (tableState) => tableState.filters
);

export const getActionAttachmentDialogId = createSelector(
  selectActionAttachmentState,
  (state: ActionAttachmentState) => state.dialogId
);

export const getSelectedActionAttachment = createSelector(
  selectActionAttachmentState,
  (state: ActionAttachmentState) => state.actionAttachment
);
export const getSelectionDialogId = createSelector(
  selectActionAttachmentState,
  (state: ActionAttachmentState) => state.selectionDialogId
);
export const getAttachmentsDialogId = createSelector(
  selectActionAttachmentState,
  (state: ActionAttachmentState) => state.attachmentsDialogId
);

import {AbstractControl} from '@angular/forms';
import {environment} from 'src/environments/environment';

import {Base, BaseDTO} from './base.model';
import {Project, ProjectDTO} from './project.model';


export interface ProjectAttachmentFilters {
  search?: string;
  project_id?: number;
}

export interface ProjectAttachmentDTO extends BaseDTO {
  project_id: number;
  name: string;
  file: string | File;
  file_path?: string;

  project: ProjectDTO;
}

export class ProjectAttachment extends Base {
  projectId: number;
  name: string;
  file: string | File;
  filePath?: string;

  project: Project;

  constructor(source: ProjectAttachmentDTO) {
    super(source);
    if (source) {
      this.projectId = source.project_id;
      this.name = source.name;
      this.file = source.file;

      this.project = new Project(source.project);
    }
  }

  toDTO(): ProjectAttachmentDTO {
    let result: ProjectAttachmentDTO = <ProjectAttachmentDTO>super.toDTO();
    result.project_id = this.projectId;
    result.name = this.name;
    result.file = this.file;
    result.file_path = this.filePath;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: ProjectAttachment) {
    const formModel = formGroup.value;
    let projectAttachment: ProjectAttachment = new ProjectAttachment(null);
    projectAttachment.name = formModel.name;
    if (formModel.file instanceof File) {
      projectAttachment.file = formModel.file;
    } else {
      projectAttachment.filePath = formModel.file;
    }
    projectAttachment.projectId = formModel.project.id;

    if (original) {
      projectAttachment.id = original.id;
    }

    return projectAttachment;
  }

  get fileUrl(): string {
    let path: string;
    if (this.file instanceof File) {
      path = this.filePath;
    } else {
      path = this.file;
    }
    if (path?.startsWith('http')) {
      return path;
    }
    return `${environment.baseUrl}/${path}`;
  }

}

import {createSelector} from '@ngrx/store';

import {selectRiskState} from '../reducers';
import {RiskState} from '../reducers/risk.reducer';

// Risk Selectors

export const getRisksTableState = createSelector(
  selectRiskState,
  (state: RiskState) => {return {total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes}}
);

export const getRisks = createSelector(
  selectRiskState,
  (state: RiskState) => state.list
);

export const getTotalRisks = createSelector(
  getRisksTableState,
  (tableState) => tableState.total
);

export const getRisksCurrentPage = createSelector(
  getRisksTableState,
  (tableState) => tableState.currentPage
);

export const getRisksPerPage = createSelector(
  getRisksTableState,
  (tableState) => tableState.perPage
);

export const getRisksOrder = createSelector(
  getRisksTableState,
  (tableState) => tableState.order
);

export const getRisksDirection = createSelector(
  getRisksTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getRisksTableState,
  (tableState) => tableState.filters
);

export const getRiskDialogId = createSelector(
  selectRiskState,
  (state: RiskState) => state.dialogId
);

export const getSelectedRisk = createSelector(
  selectRiskState,
  (state: RiskState) => state.risk
);
export const getSelectionDialogId = createSelector(
  selectRiskState,
  (state: RiskState) => state.selectionDialogId
);

export const getCurrentFormControlName = createSelector(
  selectRiskState,
  (state: RiskState) => state.currentFormControlName
);

<form [formGroup]="projectBonusForm" novalidate>
  <div class="u-flex-full">
    <mat-form-field class="u-full-width">
      <mat-label>Utente</mat-label>
      <app-user-field formControlName="projectBonusUser">
      </app-user-field>
    </mat-form-field>
    <mat-form-field *ngIf="!isBonusInPercentage" class="u-full-width">
      <input matInput placeholder="Percentuale" formControlName="percentage" type="number" min="0" max="100" forceNumber>
      <mat-icon matSuffix>percent</mat-icon>
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
  </div>

</form>
<span class="u-flex-full button-bar">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="projectBonusForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="projectBonusForm.pristine || !projectBonusForm.valid">Conferma</button>
    </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</span>

<header mat-dialog-title class="u-flex-full">
  <span>
    Seleziona regime IVA
  </span>
</header>
<mat-dialog-content>
  <iva-typology-list [ivaTypologies]="ivaTypologies" [defaultFilters]="defaultFilters" [displayedColumns]="displayedColumns"
    [total]="total" [canSelect]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)" (onSelectIvaTypology)="selectIvaTypology($event)"></iva-typology-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Budget } from 'src/app/commons/models/budget.model';

@Component({
  selector: 'app-budgets-field',
  templateUrl: './budgets-field.component.html',
  styleUrls: ['./budgets-field.component.scss'],
})
export class BudgetsFieldComponent implements OnInit, OnChanges, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  @Input()
  form: FormArray;

  @Input()
  budgets: Budget[];

  @Input()
  reset$: Observable<void>;

  constructor() {}

  ngOnInit(): void {
    this.reset$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.ngOnChanges(null));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.form) {
      this.resetForm();
      if (this.budgets) {
        this.budgets.forEach((budget) => {
          this.addBudget(budget);
        });
      }
    }
  }

  setHeadProject(index) {
    this.form.controls.forEach((control, i) => {
      control.patchValue({ headProject: index === i });
    });
    this.form.markAsDirty();
  }

  removeBudget(index) {
    this.form.removeAt(index);
    this.form.markAsDirty();
  }

  resetForm() {
    this.form.clear();
  }

  addBudget(budget?: Budget) {
    const group = new FormGroup({
      id: new FormControl(budget?.id),
      name: new FormControl(budget?.name, [Validators.required]),
      budget: new FormControl(budget?.budget, [Validators.required]),
      hours: new FormControl(budget?.hours, [Validators.required]),
      start: new FormControl(budget?.start),
      end: new FormControl(budget?.end),
      notes: new FormControl(budget?.notes),
    });
    this.form.push(group);
    if(!budget) {
      group.markAllAsTouched();
    }
  }
}

<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="riskOwner">Modifica risk owner: {{riskOwner.name}}</span>
    <span *ngIf="!riskOwner">Nuovo risk owner</span>
  </span>
  <span *ngIf="riskOwner">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteRiskOwner()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="riskOwnerForm" novalidate>
    <div class="u-flex-full">
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Nome" formControlName="name" type="text" >
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
     </div>
  </form>
    <!-- {{riskOwnerForm.value | json}} -->

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="riskOwnerForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="riskOwnerForm.pristine || !riskOwnerForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ClientFilters } from '../../../commons/models/client.model';

@Component({
  selector: 'app-client-filters',
  templateUrl: './client-filters.component.html',
  styleUrls: ['./client-filters.component.scss']
})
export class ClientFiltersComponent {

  @Output()
  onFilter: EventEmitter<ClientFilters> = new EventEmitter();

  private _defaultFilters: ClientFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
      clientLeader: ""
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getClientFilters());
  }

  private _getClientFilters(): ClientFilters {
    let filters: ClientFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
      filters.clientLeader = values.clientLeader;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
      clientLeader: ""
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): ClientFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: ClientFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
          clientLeader: this.defaultFilters.clientLeader
        })
      }
    }
  }
}

import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';


export interface IvaTypologyFilters {
    search?: string;
}

export interface IvaTypologyDTO extends BaseDTO {
    code: string;
    percentage: number;
    nature: string;
}

export class IvaTypology extends Base {
    code: string;
    percentage: number;
    nature: string;

    constructor(source: IvaTypologyDTO) {
        super(source);
        if (source) {
            this.code = source.code;
            this.percentage = source.percentage;
            this.nature = source.nature;
        }
    }

    toDTO(): IvaTypologyDTO {
        let result: IvaTypologyDTO = <IvaTypologyDTO>super.toDTO();
        result.code = this.code;
        result.percentage = this.percentage;
        result.nature = this.nature;

        return result;
    }

    static fromFormGroup(formGroup: AbstractControl, original?: IvaTypology) {
        const formModel = formGroup.value;
        let typology: IvaTypology = new IvaTypology(null);
        typology.code = formModel.code;
        typology.percentage = formModel.percentage;
        typology.nature = formModel.nature;

        if (original) {
          typology.id = original.id;
        }
        return typology;
    }

}

import { AbstractControl } from '@angular/forms';
import { resourceLimits } from 'worker_threads';

import { Base, BaseDTO } from './base.model';


export interface RiskCategoryFilters {
  search?: string;
}

export interface RiskCategoryDTO extends BaseDTO {
  name: string;
 }
export class RiskCategory extends Base {
  name: string;

  constructor(source: RiskCategoryDTO) {
    super(source);
    if (source) {
      this.name = source.name;
    }
  }

  toDTO(): RiskCategoryDTO {
    let result: RiskCategoryDTO = <RiskCategoryDTO>super.toDTO();
    result.name = this.name;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: RiskCategory) {
    const formModel = formGroup.value;
    let riskCategory: RiskCategory = new RiskCategory(null);
    riskCategory.name = formModel.name;   
    if (original) {
      riskCategory.id = original.id;
    }
    return riskCategory;
  }

}

import { createAction, props } from '@ngrx/store';

import { RiskIdentification, RiskIdentificationDTO, RiskIdentificationFilters } from '../../commons/models/risk-identification.model';

export const loadRiskIdentifications = createAction('[RiskIdentifications] Load RiskIdentifications', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: RiskIdentificationFilters, includes?: string[] }>());
export const loadRiskIdentificationsCompleted = createAction('[RiskIdentifications] Load RiskIdentifications Completed', props<{ riskIdentifications: RiskIdentificationDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: RiskIdentificationFilters, includes?: string[] }>());
export const loadRiskIdentificationsFailed = createAction('[RiskIdentifications] Load RiskIdentifications Failed', props<{ error: any }>());

export const changePage = createAction('[RiskIdentifications] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[RiskIdentifications] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[RiskIdentifications] Change filters', props<{ filters: RiskIdentificationFilters }>());

export const editRiskIdentification = createAction('[RiskIdentifications] Edit riskIdentification', props<{ riskIdentification?: RiskIdentification }>());
export const riskIdentificationDialogOpened = createAction('[RiskIdentifications] Detail dialog opened', props<{ dialogId: string }>());
export const closeRiskIdentificationDialog = createAction('[RiskIdentifications] Close detail dialog');

export const saveRiskIdentification = createAction('[RiskIdentifications] Save riskIdentification', props<{ riskIdentification: RiskIdentification }>());
export const saveRiskIdentificationCompleted = createAction('[RiskIdentifications] Save riskIdentification completed', props<{ riskIdentification: RiskIdentificationDTO }>());
export const saveRiskIdentificationFailed = createAction('[RiskIdentifications] Save riskIdentification failed', props<{ error: any }>());

export const deleteRiskIdentification = createAction('[RiskIdentifications] Delete riskIdentification', props<{ riskIdentification: RiskIdentificationDTO }>());
export const deleteRiskIdentificationCompleted = createAction('[RiskIdentifications] Delete riskIdentification completed', props<{ riskIdentification: RiskIdentificationDTO }>());
export const deleteRiskIdentificationCancelled = createAction('[RiskIdentifications] Delete riskIdentification cancelled');
export const deleteRiskIdentificationFailed = createAction('[RiskIdentifications] Delete riskIdentification failed', props<{ error: any }>());

export const selectRiskIdentification = createAction('[RiskIdentifications] Select riskIdentification', props<{ filters?: RiskIdentificationFilters }>());
export const selectionDialogOpened = createAction('[RiskIdentifications] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[RiskIdentifications] Close selection dialog');
export const riskIdentificationSelected = createAction('[RiskIdentifications] RiskIdentification selected', props<{ riskIdentification: RiskIdentificationDTO }>());

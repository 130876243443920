import { createAction, props } from '@ngrx/store';

import { CostLine, CostLineDTO, CostLineFilters } from '../../commons/models/cost-line.model';

export const loadCostLines = createAction('[CostLines] Load CostLines', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: CostLineFilters, includes?: string[] }>());
export const loadCostLinesCompleted = createAction('[CostLines] Load CostLines Completed', props<{ costLines: CostLineDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: CostLineFilters, includes?: string[] }>());
export const loadCostLinesFailed = createAction('[CostLines] Load CostLines Failed', props<{ error: any }>());

export const editInvoicePaymentDate = createAction('[CostLines] Edit invoicePaymentDate');
export const invoicePaymentDate = createAction('[CostLines] Set invoicePaymentDate', props<{ invoicePaymentDate: string }>());
export const invoicePaymentCompleted = createAction('[CostLines] Set invoicePaymentDate Completed', props<{ costLines: CostLineDTO[] }>());
export const invoicePaymentFailed = createAction('[CostLines] Set invoicePaymentDate Failed', props<{ error: any }>());
export const invoicePaymentCanceled = createAction('[CostLines] Set invoicePaymentDate cancelled');

export const changePage = createAction('[CostLines] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[CostLines] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[CostLines] Change filters', props<{ filters: CostLineFilters }>());

export const editCostLine = createAction('[CostLines] Edit costLine', props<{ costLine: CostLine }>());
export const costLineDialogOpened = createAction('[CostLines] Detail dialog opened', props<{ dialogId: string }>());
export const closeCostLineDialog = createAction('[CostLines] Close detail dialog');

export const showCostLine = createAction('[CostLines] Show costLine', props<{ costLine: CostLineDTO }>());
export const addCostLine = createAction('[CostLine] Add costLine');
export const loadCostLine = createAction('[CostLines] Load costLine', props<{ id: number }>());
export const loadCostLineCompleted = createAction('[CostLines] Load costLine Completed', props<{ costLine: CostLineDTO }>());
export const loadCostLineFailed = createAction('[CostLines] Load costLine Failed', props<{ error: any }>());

export const saveCostLine = createAction('[CostLines] Save costLine', props<{ costLine: CostLine }>());
export const saveCostLineCompleted = createAction('[CostLines] Save costLine completed', props<{ costLine: CostLineDTO }>());
export const saveCostLineFailed = createAction('[CostLines] Save costLine failed', props<{ error: any }>());

export const deleteCostLine = createAction('[CostLines] Delete costLine', props<{ costLine: CostLineDTO }>());
export const deleteCostLineCompleted = createAction('[CostLines] Delete costLine completed', props<{ costLine: CostLineDTO }>());
export const deleteCostLineCancelled = createAction('[CostLines] Delete costLine cancelled');
export const deleteCostLineFailed = createAction('[CostLines] Delete costLine failed', props<{ error: any }>());

export const selectCostLine = createAction('[CostLines] Select costLine', props<{ filters?: CostLineFilters }>());
export const selectionDialogOpened = createAction('[CostLines] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[CostLines] Close selection dialog');
export const costLineSelected = createAction('[CostLines] CostLine selected', props<{ costLine: CostLineDTO }>());

export const addSelectCostLines = createAction('[CostLines] Add selected costLines', props<{ costLine: CostLine }>());
export const removeSelectedCostLines = createAction('[CostLines] Remove selected costLines', props<{ costLine: CostLine }>());
export const resetSelectedCostLines = createAction('[CostLines] Reset selected costLines');

export const costLinesExport = createAction('[CostLines] Export');
export const costLinesExportCompleted = createAction('[CostLines] Export Completed', props<{ data: string, type?: string }>());
export const costLinesExportFailed = createAction('[CostLines] Export Failed', props<{ error: any }>());

export const editInvoiceDate = createAction('[CostLines] Edit invoice date');
export const invoiceDate = createAction('[CostLines] Set invoice date', props<{ invoiceDate: string, invoiceNumber: number }>());
export const invoiceDateCompleted = createAction('[CostLines] Set invoice date Completed', props<{ costLines: CostLineDTO[] }>());
export const invoiceDateFailed = createAction('[CostLines] Set invoice date Failed', props<{ error: any }>());
export const invoiceDateCanceled = createAction('[CostLines] Set invoice date cancelled');

export const accountCostLine = createAction('[CostLines] Account cost line', props<{ costLine: CostLineDTO, cancel?: boolean }>());
export const accountCostLines = createAction('[CostLines] Account cost lines', props<{ costLines: CostLineDTO[], cancel?: boolean }>());
export const accountCostLinesCompleted = createAction('[CostLines] Account cost lines completed', props<{ costLines: CostLineDTO[] }>());
export const accountCostLinesFailed = createAction('[CostLines] Account cost lines failed', props<{ error: any }>());

import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

import { DetailDialogComponent } from '../detail-dialog/detail-dialog.component';

@Component({
  selector: 'snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    public dialog: MatDialog,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  showDetail() {
    let dialogRef: MatDialogRef<DetailDialogComponent> = this.dialog.open(
      DetailDialogComponent,
      {
        disableClose: true,
      }
    );
    dialogRef.componentInstance.title = 'Dettaglio errore';
    if (typeof this.data.error.Error == 'string') {
      dialogRef.componentInstance.confirmMessage = this.data.error.Error;
    } else {
      if (this.data?.error?.Error) {
        Object.values(this.data.error.Error).forEach((val) => {
          dialogRef.componentInstance.confirmMessage = val[0];
        });
      }
    }

    return dialogRef.afterClosed();
  }
}

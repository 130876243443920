<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="bankAccount">Modifica conto corrente: {{bankAccount.name}}</span>
    <span *ngIf="!bankAccount">Nuovo conto corrente</span>
  </span>
  <span *ngIf="bankAccount">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteBankAccount()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="bankAccountForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Nome" formControlName="name" type="text">
        <mat-error>Campo obbligatorio</mat-error>
        <mat-hint>Il nome deve corrispondere al campo "Nome" su Fattura24</mat-hint>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="IBAN" formControlName="iban" type="text">
        <mat-error>IBAN non valido</mat-error>
      </mat-form-field>
    </div>
  </form>
  <!-- {{bankAccountForm.value | json}} -->

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="bankAccountForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="bankAccountForm.pristine || !bankAccountForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { RiskCategory } from '../../../commons/models/risk-category.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { RiskCategoryFilters } from './../../../commons/models/risk-category.model';

export type RiskCategorysColumn = 'id' | 'name' | 'actions';

@Component({
  selector: 'risk-category-list',
  templateUrl: './risk-category-list.component.html',
  styleUrls: ['./risk-category-list.component.scss'],
})
export class RiskCategoryListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  riskCategories: Observable<RiskCategory[]>;

  @Input()
  defaultFilters: RiskCategoryFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: RiskCategory | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: RiskCategorysColumn[] = ['name', 'actions'];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<RiskCategoryFilters> =
    new EventEmitter<RiskCategoryFilters>();

  @Output()
  onSelectRiskCategory: EventEmitter<RiskCategory> =
    new EventEmitter<RiskCategory>();
  @Output()
  onAddRiskCategory: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditRiskCategory: EventEmitter<RiskCategory> =
    new EventEmitter<RiskCategory>();

  constructor() {}

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addRiskCategory() {
    this.onAddRiskCategory.emit();
  }

  editRiskCategory(riskCategory: RiskCategory) {
    this.onEditRiskCategory.emit(riskCategory);
  }

  selectRiskCategory(riskCategory: RiskCategory) {
    this.onSelectRiskCategory.emit(riskCategory);
  }

  onFilter(filters: RiskCategoryFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

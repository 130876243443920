<header mat-dialog-title class="u-flex-full">
  <span> Seleziona commessa </span>
</header>
<mat-dialog-content>
  <project-list
    [projects]="projects"
    [defaultFilters]="defaultFilters"
    [displayedColumns]="displayedColumns"
    [total]="total"
    [canSelect]="true"
    (onSortChange)="sortChange($event)"
    (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)"
    (onSelectProject)="selectProject($event)"
    [showClientFilter]="showClientFilter"
    [showPromoFilter]="showPromoFilter"
  ></project-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span> </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Chiudi
  </button>
</mat-dialog-actions>

import { Action, createReducer, on } from '@ngrx/store';

import { BusinessOpportunityDTO, BusinessOpportunityFilters } from '../../commons/models/business-opportunity.model';
import * as BusinessOpportunityActions from '../actions/business-opportunity.actions';

export interface BusinessOpportunityState {
  list: BusinessOpportunityDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: BusinessOpportunityFilters,
  dialogId: string,
  selectionDialogId: string,
  businessOpportunity: BusinessOpportunityDTO
};

const initialState: BusinessOpportunityState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  businessOpportunity: null
};

const businessOpportunityReducer = createReducer(
  initialState,
  on(BusinessOpportunityActions.loadBusinessOpportunitiesCompleted, (state, { businessOpportunities, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: businessOpportunities, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(BusinessOpportunityActions.loadBusinessOpportunityCompleted, (state, { businessOpportunity }) => {
    return { ...state, businessOpportunity };
  }),
  on(BusinessOpportunityActions.businessOpportunityDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(BusinessOpportunityActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(BusinessOpportunityActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(BusinessOpportunityActions.showBusinessOpportunity, (state, { businessOpportunity }) => {
    return { ...state, businessOpportunity };
  }),
  on(BusinessOpportunityActions.addBusinessOpportunity, (state) => {
    return { ...state, businessOpportunity: null };
  }),
);

export function reducer(state: BusinessOpportunityState | undefined, action: Action) {
  return businessOpportunityReducer(state, action);
}


import { state, style, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { BillingLine } from 'src/app/commons/models/billing-line.model';

import { Invoice, InvoiceFilters } from '../../../commons/models/invoice.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type InvoicesColumn =
  | 'id'
  | 'date'
  | 'expiration'
  | 'amount'
  | 'activities'
  | 'expenses'
  | 'client'
  | 'generated'
  | 'paid'
  | 'actions';

@Component({
  selector: 'invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', display: 'none' })
      ),
      state('expanded', style({ height: '*' })),
      // transition(
      //   "expanded <=> collapsed",
      //   animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      // ),
    ]),
  ],
})
export class InvoiceListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  invoices: Observable<Invoice[]>;

  @Input()
  defaultFilters: InvoiceFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Invoice | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: InvoicesColumn[] = [
    'id',
    'date',
    'expiration',
    // 'activities',
    // 'expenses',
    'amount',
    'client',
    'generated',
    'paid',
    'actions',
  ];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<InvoiceFilters> =
    new EventEmitter<InvoiceFilters>();

  @Output()
  onSelectInvoice: EventEmitter<Invoice> = new EventEmitter<Invoice>();
  @Output()
  onAddInvoice: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditInvoice: EventEmitter<Invoice> = new EventEmitter<Invoice>();
  @Output()
  onPaymentInvoice: EventEmitter<Invoice> = new EventEmitter<Invoice>();
  @Output()
  onCancelPaymentInvoice: EventEmitter<Invoice> = new EventEmitter<Invoice>();
  @Output()
  onCancelInvoice: EventEmitter<Invoice> = new EventEmitter<Invoice>();
  @Output()
  onExportInvoices: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onDetach: EventEmitter<BillingLine> = new EventEmitter<BillingLine>();
  @Output()
  onGenerateInvoice: EventEmitter<Invoice> = new EventEmitter<Invoice>();
  @Output()
  onGeneratePdfExpenses: EventEmitter<Invoice> = new EventEmitter<Invoice>();
  @Output()
  onGeneratePdfActivities: EventEmitter<Invoice> = new EventEmitter<Invoice>();

  constructor() {}

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addInvoice() {
    this.onAddInvoice.emit();
  }

  editInvoice(invoice: Invoice) {
    this.onEditInvoice.emit(invoice);
  }

  paymentInvoice(invoice: Invoice) {
    this.onPaymentInvoice.emit(invoice);
  }

  cancelPaymentInvoice(invoice: Invoice) {
    this.onCancelPaymentInvoice.emit(invoice);
  }

  cancelInvoice(invoice: Invoice) {
    this.onCancelInvoice.emit(invoice);
  }

  generateInvoice(invoice: Invoice) {
    this.onGenerateInvoice.emit(invoice);
  }

  selectInvoice(invoice: Invoice) {
    this.onSelectInvoice.emit(invoice);
  }

  generatePdfExpenses(invoice: Invoice) {
    this.onGeneratePdfExpenses.emit(invoice);
  }

  generatePdfActivities(invoice: Invoice) {
    this.onGeneratePdfActivities.emit(invoice);
  }

  onFilter(filters: InvoiceFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  exportInvoices() {
    this.onExportInvoices.emit();
  }

  detachBillingLine(billingLine: BillingLine) {
    this.onDetach.emit(billingLine);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { RiskFilters } from '../../../commons/models/risk.model';

@Component({
  selector: 'app-risk-filters',
  templateUrl: './risk-filters.component.html',
  styleUrls: ['./risk-filters.component.scss']
})
export class RiskFiltersComponent {


  @Output()
  onFilter: EventEmitter<RiskFilters> = new EventEmitter();

  private _defaultFilters: RiskFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
      target: "",
      riskArea: "",
      riskCategory: "",
      riskOwner: ""
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getRiskFilters());
  }

  private _getRiskFilters(): RiskFilters {
    let filters: RiskFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
      filters.riskArea = values.riskArea;
      filters.riskCategory = values.riskCategory;
      filters.riskOwner = values.riskOwner;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
      target: "",
      riskArea: "",
      riskCategory: "",
      riskOwner: ""
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): RiskFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: RiskFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
          target: this.defaultFilters.target,
          riskArea: this.defaultFilters.riskArea,
          riskCategory: this.defaultFilters.riskCategory,
          riskOwner: this.defaultFilters.riskOwner,
        })
      }
    }
  }
}

import { AbstractControl } from '@angular/forms';
import { Base, BaseDTO } from './base.model';


export interface BankAccountFilters {
  search?: string;
}

export interface BankAccountDTO extends BaseDTO {
  name: string;
  iban: string;
}

export class BankAccount extends Base {
  name: string;
  iban: string;

  constructor(source: BankAccountDTO) {
    super(source);
    if (source) {
      this.name = source.name;
      this.iban = source.iban;
    }
  }

  toDTO(): BankAccountDTO {
    let result: BankAccountDTO = <BankAccountDTO>super.toDTO();
    result.name = this.name;
    result.iban = this.iban;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: BankAccount) {
    const formModel = formGroup.value;
    let bankAccount: BankAccount = new BankAccount(null);
    bankAccount.name = formModel.name;
    bankAccount.iban = formModel.iban;

    if (original) {
      bankAccount.id = original.id;
    }
    return bankAccount;
  }

}

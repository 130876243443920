<header mat-dialog-title class="u-flex-full">
  <span>
    <span>Duplica offerta: {{offer?.version}}</span>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="offerForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput [matDatepicker]="fromDatePicker1" formControlName="date" placeholder="Data"  />
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker1"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker1></mat-datepicker>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>
  </form>
  <!-- {{offerForm.value | json}} -->
</mat-dialog-content>

<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="offerForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="offerForm.pristine || !offerForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>

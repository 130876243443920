import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Risk } from 'src/app/commons/models/risk.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as RiskActions from 'src/app/store/actions/risk.actions';
import { AppState } from 'src/app/store/reducers';
import * as RiskSelectors from 'src/app/store/selectors/risk.selectors';

import { RiskFilters } from '../../../commons/models/risk.model';
import { RisksColumn } from '../risk-list/risk-list.component';

@Component({
  selector: 'app-risk-selection',
  templateUrl: './risk-selection.component.html',
  styleUrls: ['./risk-selection.component.scss'],
})
export class RiskSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  risks: Observable<Risk[]>;
  filters: Observable<RiskFilters>;

  @Input()
  defaultFilters: RiskFilters = {
    search: '',
  };

  displayedColumns: RisksColumn[] = [
    'area',
    'target',
    'identifier',
    'threat',
    'warning',
    'category',
    'countermeasure',
    'owner',
    'riskActionee',
    'actions',
  ];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  private _includes: string[] = [
    'target',
    'risk_area',
    'risk_category',
    'risk_owner',
  ];

  total: Observable<number>;
  constructor(
    private store$: Store<AppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.risks = this.store$.pipe(
      select(RiskSelectors.getRisks),
      takeUntil(this.unsubscribe$),
      map((dtos) => (dtos ? dtos.map((dto) => new Risk(dto)) : null))
    );
    this.total = this.store$.pipe(
      select(RiskSelectors.getTotalRisks),
      takeUntil(this.unsubscribe$)
    );
    this.filters = this.store$.pipe(
      select(RiskSelectors.getFilters),
      takeUntil(this.unsubscribe$)
    );
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(
      RiskActions.loadRisks({
        page: 1,
        perPage: this.pageSizeOptions[0],
        filters: this.defaultFilters,
        includes: this._includes,
      })
    );
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(
      RiskActions.changeSort({ order: sort.active, direction: sort.direction })
    );
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(
      RiskActions.changePage({
        page: pageEvent.pageIndex + 1,
        size: pageEvent.pageSize,
      })
    );
  }

  filtersChange(filters: RiskFilters) {
    this.store$.dispatch(RiskActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectRisk(risk: Risk) {
    if (risk) {
      this.store$.dispatch(RiskActions.riskSelected({ risk: risk.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(RiskActions.closeSelectionDialog());
  }
}

<mat-expansion-panel style="margin-bottom: 16px;" [expanded]="true">
  <mat-expansion-panel-header>
    Filtra
  </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Data</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Inizio" formControlName="start">
          <input matEndDate placeholder="Fine" formControlName="end">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Cliente</mat-label>
        <app-client-field formControlName="client"></app-client-field>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Commessa</mat-label>
        <app-project-field formControlName="project"></app-project-field>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Professionista</mat-label>
        <app-user-field formControlName="professional">
        </app-user-field>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-select matInput formControlName="payer" placeholder="Pagatore" >
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option *ngFor="let payer of expensePayers" [value]="payer">{{payer}}</mat-option>
        </mat-select>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>

      <mat-checkbox type="checkbox" formControlName="payable" floatLabel="always" color="primary">
        <mat-label>Pagabile</mat-label>
      </mat-checkbox>
    </div>

<span class="u-flex-full">
      <button
        class="filter"
        mat-raised-button
        [disabled]="filtersForm.pristine"
        color="primary"
        (click)="applyFilters()"
      >
        Filtra
      </button>
      <button
        mat-icon-button
        *ngIf="!filtersForm.pristine"
        (click)="resetFilters()"
        matTooltip="Reset"
      >
        <mat-icon>replay</mat-icon>
      </button>
    </span>
  </form>
</mat-expansion-panel>

<ng-container [formGroup]="form">
  <ng-container
    *ngFor="let professionalCtrl of form.controls; let index = index"
    [formGroupName]="index"
  >
    <div class="u-full-width professional">
      <button
        mat-icon-button
        mat-raised-button
        color="accent"
        (click)="removeProfessional(index)"
        matTooltip="Rimuovi professionista"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <mat-form-field>
        <mat-label>Professionista</mat-label>
        <app-user-field formControlName="user"></app-user-field>
        <mat-error *ngIf="professionalCtrl.get('user').errors?.required"
          >Campo obbligatorio</mat-error
        >
        <mat-error *ngIf="professionalCtrl.get('user').errors?.userAlreadyAdded"
          >Professionista già presente</mat-error
        >
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Fatturato € / km"
          formControlName="kmRevenue"
          type="number"
          min="0"
          forceNumber
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Rimborso € / km"
          formControlName="kmCost"
          type="number"
          min="0"
          forceNumber
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="advisoryDebitType !== 'Fisso totale'">
        <!--
          [readonly]="
            professionalCtrl?.value?.user?.seniority == 'Consultant' ||
            professionalCtrl?.value?.user?.seniority == 'Partner'
          "
        -->
        <input
          matInput
          placeholder="Ricavo giornaliero"
          formControlName="dailyRevenue"
          type="number"
          min="0"
          forceNumber
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field
        *ngIf="advisoryPayment === 'A ore' || advisoryPayment === 'A giornata'"
      >
        <input
          matInput
          placeholder="Costo giornaliero"
          formControlName="dailyCost"
          type="number"
          min="0"
          forceNumber
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field
        *ngIf="
          advisoryPayment === 'Fisso totale' || advisoryPayment === 'Mensile'
        "
      >
        <input
          matInput
          placeholder="Costo forfettario"
          formControlName="rateCost"
          type="number"
          min="0"
          forceNumber
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        *ngIf="!professionalCtrl?.value.headProject"
        (click)="setHeadProject(index)"
      >
        Imposta come Capo Commessa
      </button>
      <mat-icon
        *ngIf="!!professionalCtrl?.value.headProject"
        [matTooltip]="
          professionalCtrl?.value?.user?.fullName + ' è Capo commessa'
        "
        >groups</mat-icon
      >
    </div>
    <!-- <button mat-raised-button (click)="removeProfessional(index)">REMOVE</button> -->
  </ng-container>
</ng-container>
<div class="u-center-text">
  <button
    mat-raised-button
    type="button"
    color="accent"
    (click)="addProfessional()"
    [disabled]="form.invalid"
  >
    <span>Aggiungi professionista</span>
  </button>
</div>

import { Action, createReducer, on } from '@ngrx/store';

import { Target, TargetDTO } from '../../commons/models/target.model';
import * as TargetActions from '../actions/target.actions';
import { TargetFilters } from './../../commons/models/target.model';

export interface TargetState {
  list: TargetDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: TargetFilters,
  dialogId: string,
  selectionDialogId: string,
  target: Target
};

const initialState: TargetState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  target: null
};

const targetReducer = createReducer(
  initialState,
  on(TargetActions.loadTargetsCompleted, (state, { targets, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: targets, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(TargetActions.targetDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(TargetActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(TargetActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: TargetState | undefined, action: Action) {
  return targetReducer(state, action);
}


import { AbstractControl } from '@angular/forms';
import { formatDateForBackend } from 'src/app/helpers/time.utils';

import { Base, BaseDTO } from './base.model';
import { Currency, CurrencyDTO } from './currency.model';
import { IvaTypology, IvaTypologyDTO } from './iva-typology.model';


export interface DefaultValuesDTO extends BaseDTO {
  bonus_client: number;
  bonus_sales: number;
  bonus_project: number;
  advisory_fee: number;
  km_cost: number;
  currency_id: number;
  currency: CurrencyDTO
  iva_typology_id: number;
  iva_typology: IvaTypologyDTO;
  archive_date?: string;
}

export class DefaultValues extends Base {
  bonusClient: number;
  bonusSales: number;
  bonusProject: number;
  advisoryFee: number;
  kmCost: number;
  currencyId: number;
  currency: Currency;
  ivaTypologyId: number;
  ivaTypology: IvaTypologyDTO;
  archiveDate: Date;

  constructor(source: DefaultValuesDTO) {
    super(source);
    if (source) {
      this.bonusClient = source.bonus_client;
      this.bonusSales = source.bonus_sales;
      this.bonusProject = source.bonus_project;
      this.advisoryFee = source.advisory_fee;
      this.kmCost = source.km_cost;
      this.currencyId = source.currency_id;
      this.currency = new Currency(source.currency);
      this.ivaTypologyId = source.iva_typology_id;
      if (source.iva_typology) {
        this.ivaTypologyId = source.iva_typology.id;
        this.ivaTypology = new IvaTypology(source.iva_typology);
      }
      if (source.archive_date) {
        this.archiveDate = new Date(source.archive_date);
      }
    }
  }

  toDTO(): DefaultValuesDTO {
    let result: DefaultValuesDTO = <DefaultValuesDTO>super.toDTO();
    result.bonus_client = this.bonusClient;
    result.bonus_sales = this.bonusSales;
    result.bonus_project = this.bonusProject;
    result.advisory_fee = this.advisoryFee;
    result.km_cost = this.kmCost;
    result.currency_id = this.currencyId;
    result.iva_typology_id = this.ivaTypologyId;
    result.archive_date = formatDateForBackend(this.archiveDate)
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: DefaultValues) {
    const formModel = formGroup.value;
    let defaultValues: DefaultValues = new DefaultValues(null);
    defaultValues.bonusClient = formModel.bonusClient;
    defaultValues.bonusSales = formModel.bonusSales;
    defaultValues.bonusProject = formModel.bonusProject;
    defaultValues.advisoryFee = formModel.advisoryFee;
    defaultValues.kmCost = formModel.kmCost;
    defaultValues.currencyId = formModel.currency.id;
    defaultValues.ivaTypologyId = formModel.ivaTypology.id;

    if (original) {
      defaultValues.id = original.id;
    }
    return defaultValues;
  }

}

import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RiskArea } from 'src/app/commons/models/risk-area.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as RiskAreaActions from 'src/app/store/actions/risk-area.actions';
import { AppState } from 'src/app/store/reducers';
import * as RiskAreaSelectors from 'src/app/store/selectors/risk-area.selectors';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

import { RiskAreaFilters } from './../../../commons/models/risk-area.model';
import { RiskAreasColumn } from '../risk-area-list/risk-area-list.component';



@Component({
  selector: 'app-risk-area-selection',
  templateUrl: './risk-area-selection.component.html',
  styleUrls: ['./risk-area-selection.component.scss']
})
export class RiskAreaSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  riskAreas: Observable<RiskArea[]>;
  filters: Observable<RiskAreaFilters>;

  @Input()
  defaultFilters: RiskAreaFilters = {
    search: "",
  }

  displayedColumns: RiskAreasColumn[] = ["id", "name", "actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.riskAreas = this.store$.pipe(select(RiskAreaSelectors.getRiskAreas), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new RiskArea(dto)) : null));
    this.total = this.store$.pipe(select(RiskAreaSelectors.getTotalRiskAreas), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(RiskAreaSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(RiskAreaActions.loadRiskAreas(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(RiskAreaActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(RiskAreaActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: RiskAreaFilters) {
    this.store$.dispatch(RiskAreaActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectRiskArea(riskArea: RiskArea) {
    if (riskArea) {
      this.store$.dispatch(RiskAreaActions.riskAreaSelected({ riskArea: riskArea.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(RiskAreaActions.closeSelectionDialog())
  }
}

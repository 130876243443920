import { createAction, props } from '@ngrx/store';

import { BankAccount, BankAccountDTO, BankAccountFilters } from '../../commons/models/bank-account.model';

export const loadBankAccounts = createAction('[BankAccounts] Load BankAccounts', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: BankAccountFilters, includes?: string[] }>());
export const loadBankAccountsCompleted = createAction('[BankAccounts] Load BankAccounts Completed', props<{ bankAccounts: BankAccountDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: BankAccountFilters, includes?: string[] }>());
export const loadBankAccountsFailed = createAction('[BankAccounts] Load BankAccounts Failed', props<{ error: any }>());

export const changePage = createAction('[BankAccounts] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[BankAccounts] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[BankAccounts] Change filters', props<{ filters: BankAccountFilters }>());

export const editBankAccount = createAction('[BankAccounts] Edit bankAccount', props<{ bankAccount: BankAccount }>());
export const bankAccountDialogOpened = createAction('[BankAccounts] Detail dialog opened', props<{ dialogId: string }>());
export const closeBankAccountDialog = createAction('[BankAccounts] Close detail dialog');

export const saveBankAccount = createAction('[BankAccounts] Save bankAccount', props<{ bankAccount: BankAccount }>());
export const saveBankAccountCompleted = createAction('[BankAccounts] Save bankAccount completed', props<{ bankAccount: BankAccountDTO }>());
export const saveBankAccountFailed = createAction('[BankAccounts] Save bankAccount failed', props<{ error: any }>());

export const deleteBankAccount = createAction('[BankAccounts] Delete bankAccount', props<{ bankAccount: BankAccountDTO }>());
export const deleteBankAccountCompleted = createAction('[BankAccounts] Delete bankAccount completed', props<{ bankAccount: BankAccountDTO }>());
export const deleteBankAccountCancelled = createAction('[BankAccounts] Delete bankAccount cancelled');
export const deleteBankAccountFailed = createAction('[BankAccounts] Delete bankAccount failed', props<{ error: any }>());

export const selectBankAccount = createAction('[BankAccounts] Select bankAccount', props<{ filters?: BankAccountFilters }>());
export const selectionDialogOpened = createAction('[BankAccounts] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[BankAccounts] Close selection dialog');
export const bankAccountSelected = createAction('[BankAccounts] BankAccount selected', props<{ bankAccount: BankAccountDTO }>());

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  DefaultValuesEditComponent,
} from 'src/app/modules/home/default-values/default-values-edit/default-values-edit.component';

import { AlertService } from '../../commons/services/alert.service';
import { LaravelDefaultValuesService } from '../../commons/services/backend/laravel-default-values.service';
import * as DefaultValuesActions from '../actions/default-values.actions';
import { AppState } from '../reducers';
import { getDefaultValuesDialogId } from '../selectors/default-values.selectors';

@Injectable()
export class DefaultValuesEffects {
  error$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DefaultValuesActions.saveDefaultValuesFailed),
        tap(({ error }) => {
          if (error) {
            this.alertService.showErrorMessage('Errore', error);
          }
        })
      ),
    { dispatch: false }
  );

  loadDefaultValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultValuesActions.loadDefaultValues),
      switchMap(() => {
        return this.defaultValuesService.list().pipe(
          map((result) =>
            DefaultValuesActions.loadDefaultValuesCompleted({
              defaultValues: result,
            })
          ),
          catchError((error) => {
            return of(DefaultValuesActions.loadDefaultValuesFailed({ error }));
          })
        );
      })
    )
  );

  loadNations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultValuesActions.loadNations),
      switchMap(() => {
        return this.defaultValuesService.nations().pipe(
          map((result) =>
            DefaultValuesActions.loadNationsCompleted({ nations: result })
          ),
          catchError((error) => {
            return of(DefaultValuesActions.loadNationsFailed({ error }));
          })
        );
      })
    )
  );

  editDefaultValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultValuesActions.editDefaultValues),
      map(({ defaultValues }) => {
        let dialogRef = this.dialog.open(DefaultValuesEditComponent, {
          data: {
            defaultValues,
          },
        });
        return DefaultValuesActions.defaultValuesDialogOpened({
          dialogId: dialogRef.id,
        });
      })
    )
  );

  saveArchiveDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultValuesActions.saveArchiveDate),
      switchMap(({ date }) =>
        this.defaultValuesService.setArchiveDate(date).pipe(
          map((result) =>
            DefaultValuesActions.saveArchiveDateCompleted({
              defaultValues: result,
            })
          ),
          catchError((error) =>
            of(DefaultValuesActions.saveArchiveDateFailed({ error }))
          )
        )
      )
    )
  );

  saveArchiveDateCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultValuesActions.saveArchiveDateCompleted),
      tap(() =>
        this.alertService.showConfirmMessage(
          `Chiusura mensile impostata con successo`
        )
      ),
    ), { dispatch: false }
  );

  saveDefaultValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultValuesActions.saveDefaultValues),
      mergeMap(({ defaultValues }) =>
        this.defaultValuesService.upsert(defaultValues.toDTO()).pipe(
          map((result) =>
            DefaultValuesActions.saveDefaultValuesCompleted({
              defaultValues: result,
            })
          ),
          catchError((error) =>
            of(DefaultValuesActions.saveDefaultValuesFailed({ error }))
          )
        )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultValuesActions.saveDefaultValuesCompleted),
      map((action) => action.defaultValues),
      tap((defaultValues) =>
        this.alertService.showConfirmMessage(
          `Valori predefiniti salvati con successo`
        )
      ),
      map(() => DefaultValuesActions.closeDefaultValuesDialog())
    )
  );

  deleteDefaultValues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultValuesActions.deleteDefaultValues),
      switchMap(() =>
        this.alertService
          .showConfirmDialog(
            'Conferma eliminazione',
            `Sei sicuro di voler eliminare i valori predifiniti ?`
          )
          .pipe(
            mergeMap((confirm) => {
              return confirm
                ? this.defaultValuesService.delete().pipe(
                  map(({ defaultValues }) =>
                    DefaultValuesActions.deleteDefaultValuesCompleted({
                      defaultValues,
                    })
                  ),
                  catchError((error) =>
                    of(
                      DefaultValuesActions.deleteDefaultValuesFailed({
                        error,
                      })
                    )
                  )
                )
                : of(DefaultValuesActions.deleteDefaultValuesCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultValuesActions.deleteDefaultValuesCompleted),
      tap(({ defaultValues }) =>
        this.alertService.showConfirmMessage(
          `Valori predifiniti eliminati con successo`
        )
      ),
      map(() => DefaultValuesActions.closeDefaultValuesDialog())
    )
  );

  closeDialog = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DefaultValuesActions.closeDefaultValuesDialog),
        withLatestFrom(this.store$.select(getDefaultValuesDialogId)),
        tap(([_, dialogId]) => {
          if (dialogId) {
            this.dialog.getDialogById(dialogId).close();
          }
        })
      ),
    { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultValuesActions.saveDefaultValuesCompleted),
      map(() => DefaultValuesActions.loadDefaultValues())
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(DefaultValuesActions.deleteDefaultValuesCompleted),
      map(() => DefaultValuesActions.loadDefaultValues())
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private defaultValuesService: LaravelDefaultValuesService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { Budget, BudgetFilters } from '../../../commons/models/budget.model';

export type BudgetsColumn = "id" | "name" | "budget" | "start" | "end" | "notes" | "hours"| "actions";

@Component({
  selector: 'budget-list',
  templateUrl: './budget-list.component.html',
  styleUrls: ['./budget-list.component.scss'],
})
export class BudgetListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  budgets: Observable<Budget[]>;

  @Input()
  defaultFilters: BudgetFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Budget | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: BudgetsColumn[] = ["id", "name", "budget", "start", "end", "notes", "hours", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<BudgetFilters> = new EventEmitter<BudgetFilters>();

  @Output()
  onSelectBudget: EventEmitter<Budget> = new EventEmitter<Budget>();
  @Output()
  onAddBudget: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditBudget: EventEmitter<Budget> = new EventEmitter<Budget>();

  constructor() { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addBudget() {
    this.onAddBudget.emit();
  }

  editBudget(budget: Budget) {
    this.onEditBudget.emit(budget);
  }

  selectBudget(budget: Budget) {
    this.onSelectBudget.emit(budget);
  }

  onFilter(filters: BudgetFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { OfferAttachment } from 'src/app/commons/models/offer-attachment.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as OfferAttachmentActions from 'src/app/store/actions/offer-attachment.actions';
import { AppState } from 'src/app/store/reducers';
import * as OfferAttachmentSelectors from 'src/app/store/selectors/offer-attachment.selectors';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

import { OfferAttachmentFilters } from '../../../commons/models/offer-attachment.model';
import { OfferAttachmentsColumn } from '../offer-attachment-list/offer-attachment-list.component';


@Component({
  selector: 'app-offer-attachment-selection',
  templateUrl: './offer-attachment-selection.component.html',
  styleUrls: ['./offer-attachment-selection.component.scss']
})
export class OfferAttachmentSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  offerAttachments: Observable<OfferAttachment[]>;
  filters: Observable<OfferAttachmentFilters>;

  @Input()
  defaultFilters: OfferAttachmentFilters = {
    search: "",
  }

  displayedColumns: OfferAttachmentsColumn[] = ["id", "name", "file", "actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.offerAttachments = this.store$.pipe(select(OfferAttachmentSelectors.getOfferAttachments), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new OfferAttachment(dto)) : null));
    this.total = this.store$.pipe(select(OfferAttachmentSelectors.getTotalOfferAttachments), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(OfferAttachmentSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    // this.store$.dispatch(OfferAttachmentActions.loadOfferAttachments(
    //   { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(OfferAttachmentActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(OfferAttachmentActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: OfferAttachmentFilters) {
    this.store$.dispatch(OfferAttachmentActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectOfferAttachment(offerAttachment: OfferAttachment) {
    if (offerAttachment) {
      this.store$.dispatch(OfferAttachmentActions.offerAttachmentSelected({ offerAttachment: offerAttachment.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(OfferAttachmentActions.closeSelectionDialog())
  }
}

import { createAction, props } from '@ngrx/store';
import { BusinessOpportunity } from 'src/app/commons/models/business-opportunity.model';

import { Offer, OfferDTO, OfferFilters } from '../../commons/models/offer.model';

export const loadOffers = createAction('[Offers] Load Offers', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: OfferFilters, includes?: string[] }>());
export const loadOffersCompleted = createAction('[Offers] Load Offers Completed', props<{ offers: OfferDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: OfferFilters, includes?: string[] }>());
export const loadOffersFailed = createAction('[Offers] Load Offers Failed', props<{ error: any }>());


export const loadSelectionOffers = createAction('[Offers] Load selection Offers', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: OfferFilters, includes?: string[] }>());
export const loadSelectionOffersCompleted = createAction('[Offers] Load selection Offers Completed', props<{ offers: OfferDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: OfferFilters, includes?: string[] }>());
export const loadSelectionOffersFailed = createAction('[Offers] Load selection Offers Failed', props<{ error: any }>());

export const loadSelectedOffers = createAction('[Offers] Load selected Offers', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: OfferFilters, includes?: string[] }>());
export const loadSelectedOffersCompleted = createAction('[Offers] Load selected Offers Completed', props<{ offers: OfferDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: OfferFilters, includes?: string[] }>());
export const loadSelectedOffersFailed = createAction('[Offers] Load seleced Offers Failed', props<{ error: any }>());

export const changePage = createAction('[Offers] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Offers] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Offers] Change filters', props<{ filters: OfferFilters }>());
export const changeSelectionFilters = createAction('[Offers] Change selection filters', props<{ filters: OfferFilters }>());


export const showOffer = createAction('[Offers] Show offer', props<{ offer: OfferDTO }>());
export const addOffer = createAction('[Offer] Add offer', props<{ businessOpportunity?: BusinessOpportunity }>());
export const loadOffer = createAction('[Offers] Load offer', props<{ id: number }>());
export const loadOfferCompleted = createAction('[Offers] Load offer Completed', props<{ offer: OfferDTO }>());
export const loadOfferFailed = createAction('[Offers] Load offer Failed', props<{ error: any }>());

export const editOffer = createAction('[Offers] Edit offer', props<{ offer?: Offer }>());
export const offerDialogOpened = createAction('[Offers] Detail dialog opened', props<{ dialogId: string }>());
export const closeOfferDialog = createAction('[Offers] Close detail dialog');

export const saveOffer = createAction('[Offers] Save offer', props<{ offer: Offer }>());
export const saveOfferCompleted = createAction('[Offers] Save offer completed', props<{ offer: OfferDTO }>());
export const saveOfferFailed = createAction('[Offers] Save offer failed', props<{ error: any }>());
export const duplicateOffer = createAction('[Offers] Duplicate offer', props<{ offer: Offer }>());

export const deleteOffer = createAction('[Offers] Delete offer', props<{ offer: OfferDTO }>());
export const deleteOfferCompleted = createAction('[Offers] Delete offer completed', props<{ offer: OfferDTO }>());
export const deleteOfferCancelled = createAction('[Offers] Delete offer cancelled');
export const deleteOfferFailed = createAction('[Offers] Delete offer failed', props<{ error: any }>());

export const selectOffer = createAction('[Offers] Select offer', props<{ filters?: OfferFilters, currentFormControlName: string }>());
export const selectionDialogOpened = createAction('[Offers] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Offers] Close selection dialog');
export const offerSelected = createAction('[Offers] Offer selected', props<{ offer: OfferDTO }>());

export const offerVersion = createAction('[Offers] Offer version', props<{ id: number }>());
export const offerVersionCompleted = createAction('[Offers] Offer version completed', props<{ version: number }>());
export const offerVersionFailed = createAction('[Offers] Offer version failed');

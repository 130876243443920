import { Action, createReducer, on } from '@ngrx/store';

import {
  RiskIdentification,
  RiskIdentificationDTO,
  RiskIdentificationFilters,
} from '../../commons/models/risk-identification.model';
import * as RiskIdentificationActions from '../actions/risk-identification.actions';

export interface RiskIdentificationState {
  list: RiskIdentificationDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: RiskIdentificationFilters,
  dialogId: string,
  selectionDialogId: string,
  riskIdentification: RiskIdentification
};

const initialState: RiskIdentificationState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  riskIdentification: null
};

const riskIdentificationReducer = createReducer(
  initialState,
  on(RiskIdentificationActions.loadRiskIdentificationsCompleted, (state, { riskIdentifications, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: riskIdentifications, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(RiskIdentificationActions.riskIdentificationDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(RiskIdentificationActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(RiskIdentificationActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: RiskIdentificationState | undefined, action: Action) {
  return riskIdentificationReducer(state, action);
}


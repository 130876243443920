import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Budget } from 'src/app/commons/models/budget.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as BudgetActions from 'src/app/store/actions/budget.actions';
import { AppState } from 'src/app/store/reducers';
import * as BudgetSelectors from 'src/app/store/selectors/budget.selectors';

import { BudgetFilters } from '../../../commons/models/budget.model';
import { BudgetsColumn } from '../budget-list/budget-list.component';


@Component({
  selector: 'app-budget-selection',
  templateUrl: './budget-selection.component.html',
  styleUrls: ['./budget-selection.component.scss']
})
export class BudgetSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  budgets: Observable<Budget[]>;
  filters: Observable<BudgetFilters>;

  @Input()
  defaultFilters: BudgetFilters = {
    search: "",
  }

  displayedColumns: BudgetsColumn[] = ["name", "budget", "hours", "actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  private _includes: string[] = [
    "project",
    "project.activities",
    "project.currency"
  ];
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.budgets = this.store$.pipe(select(BudgetSelectors.getBudgets), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new Budget(dto)) : null));
    this.total = this.store$.pipe(select(BudgetSelectors.getTotalBudgets), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(BudgetSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(BudgetActions.loadBudgets(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters, includes: this._includes }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(BudgetActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(BudgetActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: BudgetFilters) {
    const mergedFilters: BudgetFilters = {
      search: filters.search,
      project_id: this.defaultFilters.project_id,
    }
    this.store$.dispatch(BudgetActions.changeFilters({ filters: mergedFilters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectBudget(budget: Budget) {
    if (budget) {
      this.store$.dispatch(BudgetActions.budgetSelected({ budget: budget.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(BudgetActions.closeSelectionDialog())
  }
}

import { createSelector } from '@ngrx/store';

import { selectIvaTypologyState } from '../reducers';
import { IvaTypologyState } from '../reducers/iva-typology.reducer';

// IvaTypology Selectors

export const getIvaTypologiesTableState = createSelector(
  selectIvaTypologyState,
  (state: IvaTypologyState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getIvaTypologies = createSelector(
  selectIvaTypologyState,
  (state: IvaTypologyState) => state.list
);

export const getTotalIvaTypologies = createSelector(
  getIvaTypologiesTableState,
  (tableState) => tableState.total
);

export const getIvaTypologiesCurrentPage = createSelector(
  getIvaTypologiesTableState,
  (tableState) => tableState.currentPage
);

export const getIvaTypologiesPerPage = createSelector(
  getIvaTypologiesTableState,
  (tableState) => tableState.perPage
);

export const getIvaTypologiesOrder = createSelector(
  getIvaTypologiesTableState,
  (tableState) => tableState.order
);

export const getIvaTypologiesDirection = createSelector(
  getIvaTypologiesTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getIvaTypologiesTableState,
  (tableState) => tableState.filters
);

export const getIvaTypologyDialogId = createSelector(
  selectIvaTypologyState,
  (state: IvaTypologyState) => state.dialogId
);

export const getSelectedIvaTypology = createSelector(
  selectIvaTypologyState,
  (state: IvaTypologyState) => state.ivaTypology
);
export const getSelectionDialogId = createSelector(
  selectIvaTypologyState,
  (state: IvaTypologyState) => state.selectionDialogId
);

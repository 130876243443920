<form [formGroup]="budgetForm" novalidate>
  <div class="u-flex-full">
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Nome" formControlName="name" type="text" />
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        placeholder="Budget"
        formControlName="budget"
        type="number"
        min="0"
        forceNumber
      />
      <mat-icon matSuffix>euro</mat-icon>
      <mat-error>Budget non valido</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input
        matInput
        placeholder="Budget ore"
        formControlName="hours"
        type="number"
        required
      />
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="fromDatePicker1"
        formControlName="start"
        placeholder="Inizio"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="fromDatePicker1"
      ></mat-datepicker-toggle>
      <mat-datepicker #fromDatePicker1></mat-datepicker>
      <mat-error>Data non valida</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="fromDatePicker2"
        formControlName="end"
        placeholder="Fine"
        [min]="budgetForm.controls['start'].value"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="fromDatePicker2"
      ></mat-datepicker-toggle>
      <mat-datepicker #fromDatePicker2></mat-datepicker>
      <mat-error>Data non valida</mat-error>
    </mat-form-field>
  </div>
  <div class="u-flex-full">
    <mat-form-field class="u-full-width">
      <textarea
        matInput
        placeholder="Note"
        formControlName="notes"
        rows="2"
      ></textarea>
    </mat-form-field>
  </div>
</form>
<!-- {{budgetForm.value | json}} -->

<div class="u-flex-full actions">
  <span>
    <button
      mat-raised-button
      color="basic"
      type="reset"
      (click)="revert()"
      [disabled]="budgetForm.pristine"
    >
      Reset
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="budgetForm.pristine || !budgetForm.valid"
    >
      Conferma
    </button>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Chiudi
  </button>
</div>

import { createAction, props } from '@ngrx/store';

import { ProjectComment, ProjectCommentDTO, ProjectCommentFilters } from '../../commons/models/project-comment.model';

export const loadProjectComments = createAction('[ProjectComments] Load projectComments', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: ProjectCommentFilters, includes?: string[] }>());
export const loadProjectCommentsCompleted = createAction('[ProjectComments] Load projectComments Completed', props<{ projectComments: ProjectCommentDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ProjectCommentFilters, includes?: string[] }>());
export const loadProjectCommentsFailed = createAction('[ProjectComments] Load projectComments Failed', props<{ error: any }>());

export const changePage = createAction('[ProjectComments] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[ProjectComments] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[ProjectComments] Change filters', props<{ filters: ProjectCommentFilters }>());

export const editProjectComment = createAction('[ProjectComments] Edit projectComment', props<{ projectComment: ProjectComment }>());
export const projectCommentDialogOpened = createAction('[ProjectComments] Detail dialog opened', props<{ dialogId: string }>());
export const closeProjectCommentDialog = createAction('[ProjectComments] Close detail dialog');

export const saveProjectComment = createAction('[ProjectComments] Save projectComment', props<{ projectComment: ProjectComment }>());
export const saveProjectCommentCompleted = createAction('[ProjectComments] Save projectComment completed', props<{ projectComment: ProjectCommentDTO }>());
export const saveProjectCommentFailed = createAction('[ProjectComments] Save projectComment failed', props<{ error: any }>());

export const deleteProjectComment = createAction('[ProjectComments] Delete projectComment', props<{ projectComment: ProjectCommentDTO }>());
export const deleteProjectCommentCompleted = createAction('[ProjectComments] Delete projectComment completed', props<{ projectComment: ProjectCommentDTO }>());
export const deleteProjectCommentCancelled = createAction('[ProjectComments] Delete projectComment cancelled');
export const deleteProjectCommentFailed = createAction('[ProjectComments] Delete projectComment failed', props<{ error: any }>());

export const selectProjectComment = createAction('[ProjectComments] Select projectComment', props<{ filters?: ProjectCommentFilters }>());
export const selectionDialogOpened = createAction('[ProjectComments] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[ProjectComments] Close selection dialog');
export const projectCommentSelected = createAction('[ProjectComments] ProjectComment selected', props<{ projectComment: ProjectCommentDTO }>());

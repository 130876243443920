<header mat-dialog-title class="u-flex-full">
  <span>
    Seleziona riga di fatturazione
  </span>
</header>
<mat-dialog-content>
  <billing-line-list [billingLines]="billingLines" [defaultFilters]="defaultFilters"
    [displayedColumns]="displayedColumns" [total]="total" [canSelect]="true" (onSortChange)="sortChange($event)"
    (onPageChange)="pageChange($event)" (onFilterChange)="filtersChange($event)"
    (onSelectBillingLine)="selectBillingLine($event)"></billing-line-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { DaysDistributionFilters } from '../../../commons/models/days-distribution.model';

@Component({
  selector: 'app-days-distribution-filters',
  templateUrl: './days-distribution-filters.component.html',
  styleUrls: ['./days-distribution-filters.component.scss']
})
export class DaysDistributionFiltersComponent {
  @Output()
  onFilter: EventEmitter<DaysDistributionFilters> = new EventEmitter();

  private _defaultFilters: DaysDistributionFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getDaysDistribution());
  }

  private _getDaysDistribution(): DaysDistributionFilters {
    let filters: DaysDistributionFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): DaysDistributionFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: DaysDistributionFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
        })
      }
    }
  }
}

import { Action, createReducer, on } from '@ngrx/store';

import { RiskOwner, RiskOwnerDTO } from '../../commons/models/risk-owner.model';
import * as RiskOwnerActions from '../actions/risk-owner.actions';
import { RiskOwnerFilters } from './../../commons/models/risk-owner.model';

export interface RiskOwnerState {
  list: RiskOwnerDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: RiskOwnerFilters,
  dialogId: string,
  selectionDialogId: string,
  riskOwner: RiskOwner
};

const initialState: RiskOwnerState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  riskOwner: null
};

const riskOwnerReducer = createReducer(
  initialState,
  on(RiskOwnerActions.loadRiskOwnersCompleted, (state, { riskOwners, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: riskOwners, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(RiskOwnerActions.riskOwnerDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(RiskOwnerActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(RiskOwnerActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: RiskOwnerState | undefined, action: Action) {
  return riskOwnerReducer(state, action);
}


<header mat-dialog-title class="u-flex-full">
  <h1 mat-dialog-title>Conferma sblocco pagamento bonus</h1>
  <button *ngIf="(bonusLines | async).length" mat-icon-button mat-raised-button color="primary"
    (click)="exportBonusLines()">
    <mat-icon matTooltip="Scarica CSV">file_download</mat-icon>
  </button>
</header>
<div mat-dialog-content>
  <p> Sei sicuro di voler sbloccare il pagamento bonus per le seguenti righe di bonus?</p>
  <div style="margin: 0 15px;">
    <bonus-line-list [bonusLines]="bonusLines | async" [total]="total" [defaultFilters]="defaultFilters"
      (onFilterChange)="filtersChange($event)" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
      (onExportBonusLines)="exportBonusLines()" [showProjectColumn]="showProjectColumn"
      [showCostLineColumn]="showCostLineColumn"></bonus-line-list>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="confirm()">Conferma</button>
  <button mat-raised-button (click)="close()">Annulla</button>
</div>
import { createSelector } from '@ngrx/store';

import { selectBankAccountState } from '../reducers';
import { BankAccountState } from '../reducers/bank-account.reducer';

// BankAccount Selectors

export const getBankAccountsTableState = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getBankAccounts = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.list
);

export const getTotalBankAccounts = createSelector(
  getBankAccountsTableState,
  (tableState) => tableState.total
);

export const getBankAccountsCurrentPage = createSelector(
  getBankAccountsTableState,
  (tableState) => tableState.currentPage
);

export const getBankAccountsPerPage = createSelector(
  getBankAccountsTableState,
  (tableState) => tableState.perPage
);

export const getBankAccountsOrder = createSelector(
  getBankAccountsTableState,
  (tableState) => tableState.order
);

export const getBankAccountsDirection = createSelector(
  getBankAccountsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getBankAccountsTableState,
  (tableState) => tableState.filters
);

export const getBankAccountDialogId = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.dialogId
);

export const getSelectedBankAccount = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.bankAccount
);
export const getSelectionDialogId = createSelector(
  selectBankAccountState,
  (state: BankAccountState) => state.selectionDialogId
);

import {createAction, props} from '@ngrx/store';

import {Risk, RiskDTO, RiskFilters} from '../../commons/models/risk.model';

export const loadRisks = createAction('[Risks] Load Risks', props<{page: number, perPage: number, order?: string, direction?: string, filters?: RiskFilters, includes?: string[]}>());
export const loadRisksCompleted = createAction('[Risks] Load Risks Completed', props<{risks: RiskDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: RiskFilters, includes?: string[]}>());
export const loadRisksFailed = createAction('[Risks] Load Risks Failed', props<{error: any}>());

export const changePage = createAction('[Risks] Change page', props<{page: number, size: number}>());
export const changeSort = createAction('[Risks] Change sort', props<{order: string, direction: string}>());
export const changeFilters = createAction('[Risks] Change filters', props<{filters: RiskFilters}>());

export const editRisk = createAction('[Risks] Edit risk', props<{risk: Risk}>());
export const riskDialogOpened = createAction('[Risks] Detail dialog opened', props<{dialogId: string}>());
export const closeRiskDialog = createAction('[Risks] Close detail dialog');

export const saveRisk = createAction('[Risks] Save risk', props<{risk: Risk}>());
export const saveRiskCompleted = createAction('[Risks] Save risk completed', props<{risk: RiskDTO}>());
export const saveRiskFailed = createAction('[Risks] Save risk failed', props<{error: any}>());

export const deleteRisk = createAction('[Risks] Delete risk', props<{risk: RiskDTO}>());
export const deleteRiskCompleted = createAction('[Risks] Delete risk completed', props<{risk: RiskDTO}>());
export const deleteRiskCancelled = createAction('[Risks] Delete risk cancelled');
export const deleteRiskFailed = createAction('[Risks] Delete risk failed', props<{error: any}>());

export const selectRisk = createAction('[Risks] Select risk', props<{filters?: RiskFilters, currentFormControlName: string}>());
export const selectionDialogOpened = createAction('[Risks] Selection dialog opened', props<{selectionDialogId: string}>());
export const closeSelectionDialog = createAction('[Risks] Close selection dialog');
export const riskSelected = createAction('[Risks] Risk selected', props<{risk: RiskDTO}>());

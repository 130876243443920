import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {Observable} from 'rxjs';

import {ActionAttachment, ActionAttachmentFilters} from '../../../commons/models/action-attachment.model';
import {PAGE_SIZE_OPTIONS} from '../../../helpers/table.helper';

export type ActionAttachmentsColumn = "id" | "name" | "file" | "actions";

@Component({
  selector: 'action-attachment-list',
  templateUrl: './action-attachment-list.component.html',
  styleUrls: ['./action-attachment-list.component.scss'],
})
export class ActionAttachmentListComponent {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input()
  actionAttachments: ActionAttachment[];

  @Input()
  defaultFilters: ActionAttachmentFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: ActionAttachment | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ActionAttachmentsColumn[] = ["name", "file", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ActionAttachmentFilters> = new EventEmitter<ActionAttachmentFilters>();

  @Output()
  onSelectActionAttachment: EventEmitter<ActionAttachment> = new EventEmitter<ActionAttachment>();
  @Output()
  onAddActionAttachment: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditActionAttachment: EventEmitter<ActionAttachment> = new EventEmitter<ActionAttachment>();
  @Output()
  onDuplicateActionAttachment: EventEmitter<ActionAttachment> = new EventEmitter<ActionAttachment>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addActionAttachment() {
    this.onAddActionAttachment.emit();
  }

  editActionAttachment(actionAttachment: ActionAttachment) {
    this.onEditActionAttachment.emit(actionAttachment);
  }

  selectActionAttachment(actionAttachment: ActionAttachment) {
    this.onSelectActionAttachment.emit(actionAttachment);
  }

  onFilter(filters: ActionAttachmentFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

import { createAction, props } from '@ngrx/store';

import { Target, TargetDTO, TargetFilters } from '../../commons/models/target.model';

export const loadTargets = createAction('[Targets] Load Targets', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: TargetFilters, includes?: string[] }>());
export const loadTargetsCompleted = createAction('[Targets] Load Targets Completed', props<{ targets: TargetDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: TargetFilters, includes?: string[] }>());
export const loadTargetsFailed = createAction('[Targets] Load Targets Failed', props<{ error: any }>());

export const changePage = createAction('[Targets] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Targets] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Targets] Change filters', props<{ filters: TargetFilters }>());

export const editTarget = createAction('[Targets] Edit target', props<{ target: Target }>());
export const targetDialogOpened = createAction('[Targets] Detail dialog opened', props<{ dialogId: string }>());
export const closeTargetDialog = createAction('[Targets] Close detail dialog');

export const saveTarget = createAction('[Targets] Save target', props<{ target: Target }>());
export const saveTargetCompleted = createAction('[Targets] Save target completed', props<{ target: TargetDTO }>());
export const saveTargetFailed = createAction('[Targets] Save target failed', props<{ error: any }>());

export const deleteTarget = createAction('[Targets] Delete target', props<{ target: TargetDTO }>());
export const deleteTargetCompleted = createAction('[Targets] Delete target completed', props<{ target: TargetDTO }>());
export const deleteTargetCancelled = createAction('[Targets] Delete target cancelled');
export const deleteTargetFailed = createAction('[Targets] Delete target failed', props<{ error: any }>());

export const selectTarget = createAction('[Targets] Select target', props<{ filters?: TargetFilters }>());
export const selectionDialogOpened = createAction('[Targets] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Targets] Close selection dialog');
export const targetSelected = createAction('[Targets] Target selected', props<{ target: TargetDTO }>());

import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { Project, ProjectDTO } from './project.model';
import { Risk, RiskDTO } from './risk.model';
import { User, UserDTO } from './user.model';

export interface RiskIdentificationFilters {
  search?: string;
}

export interface RiskIdentificationDTO extends BaseDTO {
  risk_id: number;
  project_id: number;
  probability: number;
  impact: number;
  risk_owner_id?: number;
  risk_actionee_id?: number;
  risk?: RiskDTO;
  risk_owner?: UserDTO;
  risk_actionee?: UserDTO;
  actionee: string;
  project: ProjectDTO;
}

export class RiskIdentification extends Base {
  riskId: number;
  probability: number;
  projectId: number;
  impact: number;
  riskOwnerId?: number;
  riskActioneeId?: number;
  risk?: Risk;
  riskOwner?: User;
  riskActionee?: User;
  actionee: string;
  project: Project;

  constructor(source: RiskIdentificationDTO) {
    super(source);
    if (source) {
      this.riskId = source.risk_id;
      this.probability = source.probability;
      this.impact = source.impact;
      this.riskOwnerId = source.risk_owner_id;
      this.riskActioneeId = source.risk_actionee_id;
      this.project = new Project(source.project);
      this.actionee = source.actionee;
      if (source.risk) {
        this.risk = new Risk(source.risk);
        this.addLoadedRelation('risk');
      }
      if (source.risk_owner) {
        this.riskOwner = new User(source.risk_owner);
        this.addLoadedRelation('riskOwner');
      }
      if (source.risk_actionee) {
        this.riskActionee = new User(source.risk_actionee);
        this.addLoadedRelation('riskActionee');
      }
    }
  }

  toDTO(): RiskIdentificationDTO {
    let result: RiskIdentificationDTO = <RiskIdentificationDTO>super.toDTO();
    result.risk = this.risk ? this.risk.toDTO() : null;
    result.risk_id = this.risk ? this.risk.id : null;
    result.probability = this.probability;
    result.impact = this.impact;
    result.risk_owner = this.riskOwner ? this.riskOwner.toDTO() : null;
    result.risk_owner_id = this.riskOwner ? this.riskOwner.id : null;
    result.risk_actionee = this.riskActionee ? this.riskActionee.toDTO() : null;
    result.risk_actionee_id = this.riskActionee ? this.riskActionee.id : null;
    result.project_id = this.projectId;
    result.actionee = this.actionee;
    return result;
  }

  static fromFormGroup(
    formGroup: AbstractControl,
    original?: RiskIdentification
  ) {
    const formModel = formGroup.value;
    let riskIdentification: RiskIdentification = new RiskIdentification(null);
    riskIdentification.id = formModel.id;
    riskIdentification.risk = formModel.risk;
    riskIdentification.probability = formModel.probability;
    riskIdentification.impact = formModel.impact;
    riskIdentification.projectId = formModel.project?.id;
    riskIdentification.riskOwner = formModel.riskOwner;
    riskIdentification.riskActionee = formModel.riskActionee;
    riskIdentification.actionee = formModel.actionee;
    if (original) {
      riskIdentification.id = original.id;
    }

    return riskIdentification;
  }
}

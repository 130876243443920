import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {AlertService} from 'src/app/commons/services/alert.service';

import {LaravelUserService} from '../../commons/services/backend/laravel-user.service';
import * as AuthActions from '../actions/auth.actions';
import * as DefaultValuesActions from '../actions/default-values.actions';

@Injectable()
export class AuthEffects {

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      mergeMap(({username, password}) =>
        this.laravelUserService.login(username, password)
          .pipe(
            map(result => AuthActions.loginCompleted({currentUser: result.user, token: result.success_token})),
            catchError(error => of(AuthActions.loginFailed({error}))))
      ))
  );

  afterLogin$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginCompleted),
    tap(() => {
      this.router.navigate(['/']);
      this.alertService.showConfirmMessage(`Login effettuato con successo`)
      // map(() => AuthActions.loadCurrentUser())
    })), {dispatch: false}
  );

  loadDefaultValuesAfterLogin$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginCompleted),
    map(() => DefaultValuesActions.loadDefaultValues()))
  );

  loginError$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginFailed),
    tap((action) => this.alertService.showErrorMessage(`Errore durante il login`, action.error))
  ), {dispatch: false}
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      mergeMap(({showConfirm}) => {
        if (showConfirm) {
          return this.alertService.showConfirmDialog('Conferma', `Sei sicuro di voler effettuare il logout?`)
            //mrosetti - Nothing to do so far, just relaunch logoutCompleted action
            .pipe(
              map((confirm) => {
                return confirm ? AuthActions.logoutCompleted() : AuthActions.logoutCancelled();
              })
            )
        } else {
          return of(AuthActions.logoutCompleted());
        }
      }))
  );

  afterLogout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logoutCompleted),
    tap(() => this.router.navigate(['login'])),
    tap(() => this.alertService.showConfirmMessage(`Logout effettuato con successo`))

  ), {dispatch: false}
  );

  currentUser$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loadCurrentUser),
    mergeMap(() =>
      this.laravelUserService.getCurrentUser()
        .pipe(
          map((currentUser) => AuthActions.loadCurrentUserCompleted({currentUser})),
          catchError(error => of(AuthActions.loadCurrentUserFailed({error}))))
    )
  ));


  constructor(
    private actions$: Actions,
    private laravelUserService: LaravelUserService,
    private alertService: AlertService,
    private router: Router
  ) { }
}

import {AbstractControl, FormArray} from '@angular/forms';
import {formatDateForBackend} from 'src/app/helpers/time.utils';

import {Action, ActionDTO} from './action.model';
import {Base, BaseDTO} from './base.model';
import {Client, ClientDTO} from './client.model';
import {Contact, ContactDTO} from './contact.model';
import {Offer, OfferDTO} from './offer.model';
import {Project, ProjectDTO} from './project.model';
import {User, UserDTO} from './user.model';


export interface BusinessOpportunityFilters {
  search?: string;
  state?: BusinessOpportunityType[];
  from?: Date;
  to?: Date;
  client?: Client;
  salesLeader?: UserDTO
}

export type BusinessOpportunityType = 'Prospect' | 'Promo' | 'Offer' | 'Vinto' | 'Perso' | 'On going' | 'Eseguito';

export interface BusinessOpportunityDTO extends BaseDTO {
  client_id: number;
  contact_id: number;
  sales_leader_id: number;
  date: string;
  status: BusinessOpportunityType;
  title: string;
  promo_id?: number;

  client: ClientDTO;
  contact: ContactDTO;
  sales_leader: UserDTO;
  promo?: ProjectDTO;
  actions: ActionDTO[];
  offers: OfferDTO[]

}

export class BusinessOpportunity extends Base {
  clientId: number;
  contactId: number;
  salesLeaderId: number;
  date: Date;
  status: BusinessOpportunityType;
  title: string;
  promoId?: number;

  client: Client;
  contact: Contact;
  salesLeader: User;
  promo: Project;
  actions: Action[];
  offers: Offer[]

  constructor(source: BusinessOpportunityDTO) {
    super(source);
    if (source) {
      this.clientId = source.client_id;
      this.contactId = source.contact_id;
      this.salesLeaderId = source.sales_leader_id;
      this.date = new Date(source.date);
      this.status = source.status;
      this.title = source.title;
      this.promoId = source.promo_id;

      this.client = new Client(source.client);
      this.addLoadedRelation('client');
      this.contact = new Contact(source.contact);
      this.salesLeader = new User(source.sales_leader);
      this.promo = new Project(source.promo);
      if (source.actions) {
        this.actions = source.actions.map((dto) => new Action(dto));
        this.addLoadedRelation('actions');
      }
      if (source.offers) {
        this.offers = source.offers.map((dto) => new Offer(dto));
        this.addLoadedRelation('offers');
      }
    }
  }

  toDTO(): BusinessOpportunityDTO {
    let result: BusinessOpportunityDTO = <BusinessOpportunityDTO>super.toDTO();
    result.client_id = this.clientId;
    result.client = this.client ? this.client.toDTO() : null;
    result.contact_id = this.contactId;
    result.sales_leader_id = this.salesLeaderId;
    result.date = formatDateForBackend(this.date);
    result.status = this.status;
    result.title = this.title;
    result.promo_id = this.promoId;
    result.actions = this.actions
      ? this.actions.map((action) => action.toDTO())
      : null;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: BusinessOpportunity) {
    const formModel = formGroup.value;
    let businessOpportunity: BusinessOpportunity = new BusinessOpportunity(null);
    businessOpportunity.clientId = formModel.client?.id;
    businessOpportunity.contactId = formModel.contact?.id;
    businessOpportunity.salesLeaderId = formModel.salesLeader?.id;
    businessOpportunity.date = formModel.date;
    businessOpportunity.status = formModel.status;
    businessOpportunity.title = formModel.title;
    businessOpportunity.promoId = formModel.promo?.id;

    const actionCtrls: FormArray = formGroup.get('actions') as FormArray;
    businessOpportunity.actions = actionCtrls?.controls.map(model => Action.fromFormGroup(model));

    if (original) {
      businessOpportunity.id = original.id;
    }
    return businessOpportunity;
  }

}

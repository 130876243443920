import { createAction, props } from '@ngrx/store';
import { Offer } from 'src/app/commons/models/offer.model';

import { Action, ActionDTO, ActionFilters } from '../../commons/models/action.model';

export const loadActions = createAction('[Actions] Load Actions', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: ActionFilters, includes?: string[] }>());
export const loadActionsCompleted = createAction('[Actions] Load Actions Completed', props<{ actions: ActionDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ActionFilters, includes?: string[] }>());
export const loadActionsFailed = createAction('[Actions] Load Actions Failed', props<{ error: any }>());

export const changePage = createAction('[Actions] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Actions] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Actions] Change filters', props<{ filters: ActionFilters }>());

export const editAction = createAction('[Actions] Edit action', props<{ action: Action }>());
export const actionDialogOpened = createAction('[Actions] Detail dialog opened', props<{ dialogId: string }>());
export const closeActionDialog = createAction('[Actions] Close detail dialog');

export const showAction = createAction('[Actions] Show action', props<{ action: ActionDTO }>());
export const addAction = createAction('[Action] Add action');
export const loadAction = createAction('[Actions] Load action', props<{ id: number }>());
export const loadActionCompleted = createAction('[Actions] Load action Completed', props<{ action: ActionDTO }>());
export const loadActionFailed = createAction('[Actions] Load action Failed', props<{ error: any }>());

export const saveAction = createAction('[Actions] Save action', props<{ action: Action }>());
export const saveActionCompleted = createAction('[Actions] Save action completed', props<{ action: ActionDTO }>());
export const saveActionFailed = createAction('[Actions] Save action failed', props<{ error: any }>());

export const deleteAction = createAction('[Actions] Delete action', props<{ action: ActionDTO }>());
export const deleteActionCompleted = createAction('[Actions] Delete action completed', props<{ action: ActionDTO }>());
export const deleteActionCancelled = createAction('[Actions] Delete action cancelled');
export const deleteActionFailed = createAction('[Actions] Delete action failed', props<{ error: any }>());

export const selectAction = createAction('[Actions] Select action', props<{ filters?: ActionFilters }>());
export const selectionDialogOpened = createAction('[Actions] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Actions] Close selection dialog');
export const actionSelected = createAction('[Actions] Action selected', props<{ action: ActionDTO }>());

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { environment } from 'src/environments/environment';

import { OfferDTO, OfferFilters } from '../../models/offer.model';

@Injectable({
  providedIn: "root"
})
export class LaravelOfferService {
  constructor(private httpClient: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/offers",
      show: environment.baseUrl + "/api/offer",
      store: environment.baseUrl + "/api/offer",
      destroy: environment.baseUrl + "/api/offer",
      duplicate: environment.baseUrl + "/api/offer/duplicate",
      version: environment.baseUrl + "/api/offer/version"
    };
  }

  public list(page: number,
    per_page: number,
    order: string,
    direction: string,
    filters: OfferFilters,
    includes?: string[]
  ): Observable<ListResultDTO<OfferDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (includes) params["includes[]"] = includes;
    if (filters) {
      if (filters.title) params["title"] = "" + filters.title;
      if (filters.version) params["version"] = "" + filters.version;
      if (filters.start) params["start"] = formatDateForBackend(filters.start);
      if (filters.end) params["end"] = formatDateForBackend(filters.end);
      if (filters.business_opportunity_id) params["business_opportunity_id"] = filters.business_opportunity_id;
      if (filters.client_id) params["client_id"] = filters.client_id;
    }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpClient.get<ListResultDTO<OfferDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpClient.get<OfferDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }


  public show(id: number): Observable<OfferDTO> {
    let params = { id: "" + id };
    return this.httpClient.get<OfferDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });

  }
  public version(id: number): Observable<number> {
    let params = { id: "" + id };
    return this.httpClient.get<number>(this.ROUTES.version, {
      params: new HttpParams({
        fromObject: params
      })
    });

  }

  public upsert(offer: OfferDTO): Observable<OfferDTO> {
    if (offer.id) {
      return this.httpClient.put<OfferDTO>(`${this.ROUTES.store}`, { offer });
    } else {
      return this.httpClient.post<OfferDTO>(`${this.ROUTES.store}`, { offer });
    }
  }

  public duplicate(id: number): Observable<OfferDTO> {
    let params = { id: "" + id };
    return this.httpClient.post<OfferDTO>(this.ROUTES.duplicate, {}, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public delete(id: number): Observable<any> {
    let params = { id: "" + id };
    return this.httpClient.delete(this.ROUTES.destroy, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }
}

<mat-chip-list #chipList>
  <mat-chip color="primary" *ngFor="let clientBonus of value; let i = index;" [removable]="true" [selectable]="false"
    (removed)="removeClientBonus(i)" (click)="editClientBonus(i)">
    {{clientBonus.user?.name}} {{clientBonus.user?.lastname}}<span *ngIf="clientBonus?.percentage">&nbsp;- {{clientBonus.percentage}}%</span>
    <mat-icon matChipRemove>cancel</mat-icon>

  </mat-chip>
  <button type="button" style="margin: 5px" matTooltip="Aggiungi utente bonus client" (click)="addClientBonus()"
    mat-icon-button mat-raised-button>
    <mat-icon>add</mat-icon>
  </button>

</mat-chip-list>
<mat-expansion-panel class="mat-elevation-z0" [expanded]="openEdit">
  <app-client-bonus-edit [clientBonus]="currentClientBonus" (onClose)="close()" (onSave)="save($event)">
  </app-client-bonus-edit>
</mat-expansion-panel>

<!-- {{ currentProjectBonus | json }} -->

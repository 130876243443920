import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { RiskIdentification } from 'src/app/commons/models/risk-identification.model';
import { AlertService } from 'src/app/commons/services/alert.service';


@Component({
  selector: 'app-risk-identification-edit',
  templateUrl: './risk-identification-edit.component.html',
  styleUrls: ['./risk-identification-edit.component.scss']
})
export class RiskIdentificationEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _riskIdentification: RiskIdentification;

  @Output()
  onSave: EventEmitter<RiskIdentification> = new EventEmitter<RiskIdentification>();
  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();

  riskIdentificationForm: FormGroup;
  constructor(private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    if (this.data) {
      this.riskIdentification = this.data.riskIdentification;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private ngOnChanges() {
    if (this.riskIdentificationForm) {
      this.riskIdentificationForm.reset();
      this._initFormValues(this.riskIdentification);
    }
  }

  get riskIdentification(): RiskIdentification {
    return this._riskIdentification;
  }

  @Input() set riskIdentification(riskIdentification: RiskIdentification) {
    this._riskIdentification = riskIdentification;
    this.ngOnChanges();
  }

  private _createForm() {
    let group = {
      risk: ["", Validators.required],
      probability: ["", Validators.required],
      impact: ["", Validators.required],
      riskOwner: [""],
      riskActionee: [""],
    }
    this.riskIdentificationForm = this.fb.group(group);
  }

  private _initFormValues(riskIdentification?: RiskIdentification) {

    if (riskIdentification) {
      this.riskIdentificationForm.patchValue({
        risk: riskIdentification.risk,
        probability: riskIdentification.probability,
        impact: riskIdentification.impact,
        riskOwner: riskIdentification.riskOwner,
        riskActionee: riskIdentification.riskActionee,
      });
    }
  }


  private _prepareSaveRiskIdentification(): RiskIdentification {
    let savingRiskIdentification: RiskIdentification = RiskIdentification.fromFormGroup(this.riskIdentificationForm, this.riskIdentification);
    return savingRiskIdentification;
  }

  closeEdit() {
    this.onClose.emit();
    this.revert();
  }

  save() {
    let unsavedEntity = this._prepareSaveRiskIdentification();
    this.onSave.emit(unsavedEntity);
    this.closeEdit();
  }

  close() {
    if (this.riskIdentificationForm.pristine) {
      this.closeEdit();
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.closeEdit();
          }
        });
    }
  }

  revert() {
    this.ngOnChanges();
  }
}

import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClientBonus } from 'src/app/commons/models/client-bonus.model';
import { Client } from 'src/app/commons/models/client.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import { AppState } from 'src/app/store/reducers';
import * as ClientSelectors from 'src/app/store/selectors/client.selectors';


@Component({
  selector: 'app-client-bonus-edit',
  templateUrl: './client-bonus-edit.component.html',
  styleUrls: ['./client-bonus-edit.component.scss']
})
export class ClientBonusEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _projectBonus: ClientBonus;
  client: Client;


  @Output()
  onSave: EventEmitter<ClientBonus> = new EventEmitter<ClientBonus>();
  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();


  clientBonusForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.store$.pipe(select(ClientSelectors.getClient), takeUntil(this.unsubscribe$)).subscribe(client => {
      this.client = client;
    });
  }

  ngOnInit() {
    if (this.data) {
      this.clientBonus = this.data.clientBonus;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private ngOnChanges() {
    if (this.clientBonusForm ) {
      this.clientBonusForm.reset();
      this._initFormValues(this.clientBonus);
    }
  }

  get clientBonus(): ClientBonus {
    return this._projectBonus;
  }

  @Input() set clientBonus(clientBonus: ClientBonus) {
    this._projectBonus = clientBonus;
    this.ngOnChanges();
  }

  private _createForm() {
    let group = {
      user: ["", Validators.required],
      percentage: ["", Validators.required],

    }
    this.clientBonusForm = this.fb.group(group);

      }


  private _initFormValues(clientBonus?: ClientBonus) {

    if (clientBonus) {
      this.clientBonusForm.patchValue({
        user: clientBonus.user,
        percentage: clientBonus.percentage
      });
    }
  }


  private _prepareSaveProjectBonus(): ClientBonus {
    let savingProjectBonus: ClientBonus = ClientBonus.fromFormGroup(this.clientBonusForm, this.clientBonus);
    return savingProjectBonus;
  }

  save() {
    let unsavedEntity = this._prepareSaveProjectBonus();
    this.onSave.emit(unsavedEntity);
    this.closeEdit();
  }

  closeEdit() {
    this.onClose.emit();
    this.revert();
  }

  close() {
    if (this.clientBonusForm.dirty) {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.closeEdit();
          }
        });
    } else {
      this.closeEdit();
    }
  }

  revert() {
  this.ngOnChanges();
  }
}

<header mat-dialog-title class="u-flex-full">
  <span>
    Seleziona Business Opportunity
  </span>
</header>
<mat-dialog-content>
  <business-opportunity-list [businessOpportunities]="businessOpportunities" [defaultFilters]="defaultFilters"
    [displayedColumns]="displayedColumns" [total]="total" [canSelect]="true" (onSortChange)="sortChange($event)"
    (onPageChange)="pageChange($event)" (onFilterChange)="filtersChange($event)"
    (onSelectBusinessOpportunity)="selectBusinessOpportunity($event)"></business-opportunity-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {Observable} from 'rxjs';

import {ProjectAttachment, ProjectAttachmentFilters} from '../../../commons/models/project-attachment.model';
import {PAGE_SIZE_OPTIONS} from '../../../helpers/table.helper';

export type ProjectAttachmentsColumn = "name" | "file" | "actions";

@Component({
  selector: 'project-attachment-list',
  templateUrl: './project-attachment-list.component.html',
  styleUrls: ['./project-attachment-list.component.scss'],
})
export class ProjectAttachmentListComponent {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input()
  projectAttachments: ProjectAttachment[];

  @Input()
  defaultFilters: ProjectAttachmentFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: ProjectAttachment | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ProjectAttachmentsColumn[] = ["name", "file", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ProjectAttachmentFilters> = new EventEmitter<ProjectAttachmentFilters>();

  @Output()
  onSelectProjectAttachment: EventEmitter<ProjectAttachment> = new EventEmitter<ProjectAttachment>();
  @Output()
  onAddProjectAttachment: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditProjectAttachment: EventEmitter<ProjectAttachment> = new EventEmitter<ProjectAttachment>();
  @Output()
  onDuplicateProjectAttachment: EventEmitter<ProjectAttachment> = new EventEmitter<ProjectAttachment>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addProjectAttachment() {
    this.onAddProjectAttachment.emit();
  }

  editProjectAttachment(projectAttachment: ProjectAttachment) {
    this.onEditProjectAttachment.emit(projectAttachment);
  }

  selectProjectAttachment(projectAttachment: ProjectAttachment) {
    this.onSelectProjectAttachment.emit(projectAttachment);
  }

  onFilter(filters: ProjectAttachmentFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

import {AbstractControl} from '@angular/forms';
import {environment} from 'src/environments/environment';

import {Base, BaseDTO} from './base.model';
import {Offer, OfferDTO} from './offer.model';


export interface OfferAttachmentFilters {
  search?: string;
  offer_id?: number;
}

export interface OfferAttachmentDTO extends BaseDTO {
  offer_id: number;
  name: string;
  file: string | File;
  file_path?: string;

  offer: OfferDTO;
}

export class OfferAttachment extends Base {
  offerId: number;
  name: string;
  file: string | File;
  filePath?: string;

  offer: Offer;

  constructor(source: OfferAttachmentDTO) {
    super(source);
    if (source) {
      this.offerId = source.offer_id;
      this.name = source.name;
      this.file = source.file;

      this.offer = new Offer(source.offer);
    }
  }

  toDTO(): OfferAttachmentDTO {
    let result: OfferAttachmentDTO = <OfferAttachmentDTO>super.toDTO();
    result.offer_id = this.offerId;
    result.name = this.name;
    result.file = this.file;
    result.file_path = this.filePath;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: OfferAttachment) {
    const formModel = formGroup.value;
    let offerAttachment: OfferAttachment = new OfferAttachment(null);
    offerAttachment.name = formModel.name;
    if (formModel.file instanceof File) {
      offerAttachment.file = formModel.file;
    } else {
      offerAttachment.filePath = formModel.file;
    }
    offerAttachment.offerId = formModel.offer.id;

    if (original) {
      offerAttachment.id = original.id;
    }

    return offerAttachment;
  }

  get fileUrl(): string {
    let path: string;
    if (this.file instanceof File) {
      path = this.filePath;
    } else {
      path = this.file;
    }
    if (path?.startsWith('http')) {
      return path;
    }
    return `${environment.baseUrl}/${path}`;
  }
}

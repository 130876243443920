import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {BillingLineFilters} from '../../../commons/models/billing-line.model';

@Component({
  selector: 'app-billing-line-filters',
  templateUrl: './billing-line-filters.component.html',
  styleUrls: ['./billing-line-filters.component.scss'],
})
export class BillingLineFiltersComponent {
  @Output()
  onFilter: EventEmitter<BillingLineFilters> = new EventEmitter();

  private _defaultFilters: BillingLineFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
    this._initFormValues();
  }

  private _createForm() {
    let group = {
      start: '',
      end: '',
      client: '',
      project: '',
      search: '',
      billed: '',
      paid: '',
      promo: '',
      type: ''
    };

    this.filtersForm = this.fb.group(group);
  }

  private _initFormValues() {
    this.filtersForm.patchValue({
      start: this.defaultFilters?.start,
      end: this.defaultFilters?.end,
    });
  }

  applyFilters() {
    this.onFilter.emit(this._getBillingLine());
  }

  private _getBillingLine(): BillingLineFilters {
    let filters: BillingLineFilters = {};
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.start = values.start;
      filters.end = values.end;
      filters.client_id = values.client?.id;
      filters.project_id = values.project?.id;
      filters.billed = values.billed;
      filters.paid = values.paid;
      filters.search = values.search;
      filters.type = values.type;
      filters.promo = values.promo;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      start: '',
      end: '',
      client: '',
      project: '',
      billed: null,
      paid: null,
      search: '',
      promo: null,
      type: null
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): BillingLineFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: BillingLineFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          start: this.defaultFilters.start,
          end: this.defaultFilters.end,
          project: this.defaultFilters.project_id,
          client: this.defaultFilters.client_id,
          billed: this.defaultFilters.billed,
          paid: this.defaultFilters.paid,
          search: this.defaultFilters.search,
          promo: this.defaultFilters.promo,
          type: this.defaultFilters.type,
        });
      }
    }
  }
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { DefaultValues } from '../../../commons/models/default-values.model';

export type DefaultValuesColumn = "bonusClient" | "bonusSales" | "bonusProject" | "advisoryFee" | "kmCost" | "currency" | "actions";

@Component({
  selector: 'default-values-list',
  templateUrl: './default-values-list.component.html',
  styleUrls: ['./default-values-list.component.scss'],
})
export class DefaultValuesListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  defaultValues: Observable<DefaultValues[]>;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: DefaultValues | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: DefaultValuesColumn[] = ["bonusClient", "bonusSales", "bonusProject", "advisoryFee", "kmCost", "currency", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onAddDefaultValues: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditDefaultValues: EventEmitter<DefaultValues> = new EventEmitter<DefaultValues>();

  constructor() { }

  addDefaultValues() {
    this.onAddDefaultValues.emit();
  }

  editDefaultValues(account: DefaultValues) {
    this.onEditDefaultValues.emit(account);
  }
}

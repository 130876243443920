import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { RiskOwner } from 'src/app/commons/models/risk-owner.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as RiskOwnerActions from 'src/app/store/actions/risk-owner.actions';
import { AppState } from 'src/app/store/reducers';
import * as RiskOwnerSelectors from 'src/app/store/selectors/risk-owner.selectors';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

import { RiskOwnerFilters } from './../../../commons/models/risk-owner.model';
import { RiskOwnersColumn } from '../risk-owner-list/risk-owner-list.component';



@Component({
  selector: 'app-risk-owner-selection',
  templateUrl: './risk-owner-selection.component.html',
  styleUrls: ['./risk-owner-selection.component.scss']
})
export class RiskOwnerSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  riskOwners: Observable<RiskOwner[]>;
  filters: Observable<RiskOwnerFilters>;

  @Input()
  defaultFilters: RiskOwnerFilters = {
    search: "",
  }

  displayedColumns: RiskOwnersColumn[] = ["id", "name", "actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.riskOwners = this.store$.pipe(select(RiskOwnerSelectors.getRiskOwners), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new RiskOwner(dto)) : null));
    this.total = this.store$.pipe(select(RiskOwnerSelectors.getTotalRiskOwners), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(RiskOwnerSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(RiskOwnerActions.loadRiskOwners(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(RiskOwnerActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(RiskOwnerActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: RiskOwnerFilters) {
    this.store$.dispatch(RiskOwnerActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectRiskOwner(riskOwner: RiskOwner) {
    if (riskOwner) {
      this.store$.dispatch(RiskOwnerActions.riskOwnerSelected({ riskOwner: riskOwner.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(RiskOwnerActions.closeSelectionDialog())
  }
}

import { createAction, props } from '@ngrx/store';

import { Activity, ActivityDTO, ActivityFilters, ActivityReportDTO } from '../../commons/models/activity.model';

export const loadActivities = createAction(
  '[Activities] Load activities',
  props<{
    page: number;
    perPage: number;
    order?: string;
    direction?: string;
    filters?: ActivityFilters;
    includes?: string[];
  }>()
);
export const loadActivitiesCompleted = createAction(
  '[Activities] Load activities Completed',
  props<{
    activities: ActivityDTO[];
    currentPage: number;
    total: number;
    perPage: number;
    order?: string;
    direction?: string;
    filters?: ActivityFilters;
    includes?: string[];
  }>()
);
export const loadActivitiesFailed = createAction(
  '[Activities] Load activities Failed',
  props<{ error: any }>()
);

export const changePage = createAction(
  '[Activities] Change page',
  props<{ page: number; size: number }>()
);
export const changeSort = createAction(
  '[Activities] Change sort',
  props<{ order: string; direction: string }>()
);
export const changeFilters = createAction(
  '[Activities] Change filters',
  props<{ filters: ActivityFilters }>()
);

export const loadActivitiesReport = createAction(
  '[Activities] Load activitie report',
  props<{
    page: number;
    perPage: number;
    order?: string;
    direction?: string;
    filters?: ActivityFilters;
    includes?: string[];
  }>()
);
export const loadActivitiesReportCompleted = createAction(
  '[Activities] Load activities report Completed',
  props<{
    activities: ActivityReportDTO[];
    currentPage: number;
    total: number;
    perPage: number;
    order?: string;
    direction?: string;
    filters?: ActivityFilters;
    includes?: string[];
  }>()
);
export const loadActivitiesReportFailed = createAction(
  '[Activities] Load activities report Failed',
  props<{ error: any }>()
);

export const changePageReport = createAction(
  '[Activities] Change page report',
  props<{ page: number; size: number }>()
);
export const changeSortReport = createAction(
  '[Activities] Change sort report',
  props<{ order: string; direction: string }>()
);
export const changeFiltersReport = createAction(
  '[Activities] Change filters report',
  props<{ filters: ActivityFilters }>()
);

export const editActivity = createAction(
  '[Activities] Edit activity',
  props<{ activity: Activity, clone?: boolean }>()
);
export const activityDialogOpened = createAction(
  '[Activities] Detail dialog opened',
  props<{ dialogId: string }>()
);
export const closeActivityDialog = createAction(
  '[Activities] Close detail dialog'
);
export const duplicateActivity = createAction(
  '[Activities] Duplicate activity',
  props<{ activity: Activity }>()
);

export const changeBillable = createAction(
  '[Activities] Change billable',
  props<{ activity: Activity, billable: boolean }>()
);
export const changePayable = createAction(
  '[Activities] Change payable',
  props<{ activity: Activity, payable: boolean }>()
);

export const saveActivity = createAction(
  '[Activities] Save activity',
  props<{ activity: Activity }>()
);
export const saveActivityCompleted = createAction(
  '[Activities] Save activity completed',
  props<{ activity: ActivityDTO }>()
);
export const saveActivityFailed = createAction(
  '[Activities] Save activity failed',
  props<{ error: any }>()
);

export const deleteActivity = createAction(
  '[Activities] Delete activity',
  props<{ activity: ActivityDTO }>()
);
export const deleteActivityCompleted = createAction(
  '[Activities] Delete activity completed',
  props<{ activity: ActivityDTO }>()
);
export const deleteActivityCancelled = createAction(
  '[Activities] Delete activity cancelled'
);
export const deleteActivityFailed = createAction(
  '[Activities] Delete activity failed',
  props<{ error: any }>()
);

export const selectActivity = createAction(
  '[Activities] Select activity',
  props<{ filters?: ActivityFilters }>()
);
export const selectionDialogOpened = createAction(
  '[Activities] Selection dialog opened',
  props<{ selectionDialogId: string }>()
);
export const closeSelectionDialog = createAction(
  '[Activities] Close selection dialog'
);
export const activitySelected = createAction(
  '[Activities] Activity selected',
  props<{ activity: ActivityDTO }>()
);

export const activityReportExport = createAction('[ActivityReport] Export');
export const activityReportExportCompleted = createAction(
  '[ActivityReport] Export Completed',
  props<{ data: string; type?: string }>()
);
export const activityReportExportFailed = createAction(
  '[ActivityReport] Export Failed',
  props<{ error: any }>()
);

export const addSelectedActivities = createAction(
  '[Activities] Add selected activities',
  props<{ activity: Activity }>()
);
export const removeSelectedActivities = createAction(
  '[Activities] Remove selected activities',
  props<{ activity: Activity }>()
);
export const resetSelectedActivities = createAction(
  '[Activities] Reset selected activities'
);

export const changeMultiplePayable = createAction(
  '[Activities] Change multiple payable',
  props<{ activities: Activity[] }>()
);
export const changeMultiplePayableCompleted = createAction(
  '[Activities] Change multiple payable completed',
  props<{ activities: ActivityDTO[] }>()
);
export const changeMultiplePayableFailed = createAction(
  '[Activities] Change multiple payable failed',
  props<{ error: any }>()
);

export const changeMultipleBillable = createAction(
  '[Activities] Change multiple billable',
  props<{ activities: Activity[] }>()
);
export const changeMultipleBillableCompleted = createAction(
  '[Activities] Change multiple billable completed',
  props<{ activities: ActivityDTO[] }>()
);
export const changeMultipleBillableFailed = createAction(
  '[Activities] Change multiple billable failed',
  props<{ error: any }>()
);

export const editValidations = createAction(
  '[Activities] Edit validations',
  props<{ activities: Activity[] }>()
);
export const multipleValidate = createAction(
  '[Activities] Multiple validate',
  props<{ date: string; activities: Activity[] }>()
);
export const multipleValidateCompleted = createAction(
  '[Activities] Multiple validate completed',
  props<{ activities: ActivityDTO[] }>()
);
export const multipleValidateFailed = createAction(
  '[Activities] Multiple validate failed',
  props<{ error: any }>()
);

export const editValidation = createAction(
  '[Activities] Edit validation',
  props<{ activity: Activity }>()
);
export const validateActivity = createAction(
  '[Activities] Validate activity',
  props<{ date: string; activity: Activity }>()
);
export const validateActivityCompleted = createAction(
  '[Activities] Validate activity completed',
  props<{ activity: ActivityDTO }>()
);
export const validateActivityFailed = createAction(
  '[Activities] Validate activity failed',
  props<{ error: any }>()
);

export const unvalidateActivity = createAction(
  '[Activities] Unvalidate activity',
  props<{ activity: Activity }>()
);

export const accountActivity = createAction(
  '[Activities] Account activity',
  props<{ activity: ActivityDTO, cancel?: boolean }>()
);
export const accountActivities = createAction(
  '[Activities] Account activities',
  props<{ activities: ActivityDTO[], cancel?: boolean }>()
);
export const accountActivitiesCompleted = createAction(
  '[Activities] Account activities completed',
  props<{ activities: ActivityDTO[] }>()
);
export const accountActivitiesFailed = createAction(
  '[Activities] Account activities failed',
  props<{ error: any }>()
);

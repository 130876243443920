<!-- <app-offer-attachment-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-offer-attachment-filters> -->
<mat-card class="u-center-text" *ngIf="!offerAttachments?.length">
  <h4>Nessun allegato trovato</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addOfferAttachment()">Aggiungi</button>
</mat-card>
<div class="table-container" [hidden]="!offerAttachments?.length">
  <table mat-table matSort [dataSource]="offerAttachments">
    <ng-container matColumnDef="offerAttachmentCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Codice valuta </th>
      <td mat-cell *matCellDef="let row">
        {{ row.offerAttachmentCode }}
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let row">
        {{row.id}}
      </td>
    </ng-container>

    <ng-container matColumnDef="offer">
      <th mat-header-cell *matHeaderCellDef> Offerta </th>
      <td mat-cell *matCellDef="let row">
        {{row.offer?.title}}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nome </th>
      <td mat-cell *matCellDef="let row">
        {{row.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="file">
      <th mat-header-cell *matHeaderCellDef> File </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.file">
          <a [href]="row.fileUrl" target="_blank">
            <mat-icon color="primary">attachment</mat-icon>
          </a>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef> Note </th>
      <td mat-cell *matCellDef="let row">
        {{row.notes}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation();addOfferAttachment()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button (click)="$event.stopPropagation();editOfferAttachment(row)">
            <mat-icon matTooltip="Modifica">create</mat-icon>
          </button>
        </ng-container>

        <button *ngIf="canSelect" mat-raised-button color="primary"
          (click)="selectOfferAttachment(row)">Seleziona</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { Offer, OfferFilters } from '../../../commons/models/offer.model';

export type OffersColumn = "id" | "businessOpportunity" | "version" | "date" | "title" | "totalAmount" | "probability" | "notes" | "days" | "valuePerDay" | "expenseType" | "kmCost" | "estimatedValue" | "project" | "actions";

@Component({
  selector: 'offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss'],
})
export class OfferListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  offers: Observable<Offer[]>;

  @Input()
  defaultFilters: OfferFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Offer | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: OffersColumn[] = ["version", "title", "date", "totalAmount", "probability", "expenseType", "project", "notes", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<OfferFilters> = new EventEmitter<OfferFilters>();

  @Output()
  onSelectOffer: EventEmitter<Offer> = new EventEmitter<Offer>();
  @Output()
  onAddOffer: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditOffer: EventEmitter<Offer> = new EventEmitter<Offer>();
  @Output()
  onDuplicateOffer: EventEmitter<Offer> = new EventEmitter<Offer>();
  @Output()
  onShowOffer: EventEmitter<Offer> = new EventEmitter<Offer>();

  constructor() { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addOffer() {
    this.onAddOffer.emit();
  }

  selectOffer(offer: Offer) {
    this.onSelectOffer.emit(offer);
  }

  editOffer(offer: Offer) {
    this.onEditOffer.emit(offer);
  }

  duplicateOffer(offer: Offer) {
    this.onDuplicateOffer.emit(offer);
  }

  onFilter(filters: OfferFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

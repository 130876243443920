import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { IvaTypology, IvaTypologyFilters } from '../../../commons/models/iva-typology.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type IvaTypologiesColumn = 'id' | 'code' | 'percentage' | 'nature' | 'actions';

@Component({
  selector: 'iva-typology-list',
  templateUrl: './iva-typology-list.component.html',
  styleUrls: ['./iva-typology-list.component.scss'],
})
export class IvaTypologyListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  ivaTypologies: Observable<IvaTypology[]>;

  @Input()
  defaultFilters: IvaTypologyFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: IvaTypology | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: IvaTypologiesColumn[] = ['code', 'percentage', 'nature', 'actions'];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<IvaTypologyFilters> =
    new EventEmitter<IvaTypologyFilters>();

  @Output()
  onSelectIvaTypology: EventEmitter<IvaTypology> =
    new EventEmitter<IvaTypology>();
  @Output()
  onAddIvaTypology: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditIvaTypology: EventEmitter<IvaTypology> =
    new EventEmitter<IvaTypology>();

  constructor() {}

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addIvaTypology() {
    this.onAddIvaTypology.emit();
  }

  editIvaTypology(ivaTypology: IvaTypology) {
    this.onEditIvaTypology.emit(ivaTypology);
  }

  selectIvaTypology(ivaTypology: IvaTypology) {
    this.onSelectIvaTypology.emit(ivaTypology);
  }

  onFilter(filters: IvaTypologyFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

import {AbstractControl} from '@angular/forms';
import {environment} from 'src/environments/environment';

import {Action, ActionDTO} from './action.model';
import {Base, BaseDTO} from './base.model';


export interface ActionAttachmentFilters {
  search?: string;
  action_id?: number;
}

export interface ActionAttachmentDTO extends BaseDTO {
  action_id: number;
  name: string;
  file: string | File;
  file_path?: string;

  action: ActionDTO;
}

export class ActionAttachment extends Base {
  actionId: number;
  name: string;
  file: string | File;
  filePath?: string;

  action: Action;

  constructor(source: ActionAttachmentDTO) {
    super(source);
    if (source) {
      this.actionId = source.action_id;
      this.name = source.name;
      this.file = source.file;

      this.action = new Action(source.action);
      this.addLoadedRelation('action');
    }
  }

  toDTO(): ActionAttachmentDTO {
    let result: ActionAttachmentDTO = <ActionAttachmentDTO>super.toDTO();
    result.action_id = this.actionId;
    result.name = this.name;
    result.file = this.file;
    result.file_path = this.filePath;
    result.action = this.action ? this.action.toDTO() : null;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: ActionAttachment) {
    const formModel = formGroup.value;
    let actionAttachment: ActionAttachment = new ActionAttachment(null);
    actionAttachment.name = formModel.name;
    if (formModel.file instanceof File) {
      actionAttachment.file = formModel.file;
    } else {
      actionAttachment.filePath = formModel.file;
    }

    actionAttachment.actionId = formModel.action.id;

    if (original) {
      actionAttachment.id = original.id;
    }

    return actionAttachment;
  }

  get fileUrl(): string {
    let path: string;
    if (this.file instanceof File) {
      path = this.filePath;
    } else {
      path = this.file;
    }
    if (path?.startsWith('http')) {
      return path;
    }
    return `${environment.baseUrl}/${path}`;
  }
}

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Action, ActionFilters } from 'src/app/commons/models/action.model';
import { OfferFilters } from 'src/app/commons/models/offer.model';
import * as ActionAttachmentActions from 'src/app/store/actions/action-attachment.actions';
import { AppState } from 'src/app/store/reducers';


@Component({
  selector: "app-action-field",
  templateUrl: "./action-field.component.html",
  styleUrls: ["./action-field.component.scss"],

})
export class ActionFieldComponent
  implements OnInit, OnChanges, OnDestroy {
    private unsubscribe$ = new Subject<void>();

    @Input()
    form: FormArray;

    @Input()
    actionFilters: ActionFilters;
    @Input()
    offerFilters: OfferFilters;

    @Input()
    actions: Action[];

    @Input()
    reset$: Observable<void>;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.reset$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.ngOnChanges(null));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(_: SimpleChanges): void {
    if(this.form) {
      this.resetForm();
      if(this.actions) {
        this.actions.forEach(action => this.addAction(action));
      }
    }
  }
  removeAction(index) {
    this.form.removeAt(index);
    this.form.markAsDirty();
  }
  openAttachments(control) {
    this.store$.dispatch(ActionAttachmentActions.openActionAttachment({ actionId: control?.value?.id }));
  }
  resetForm() {
    this.form.clear();
  }

  addAction(action?: Action) {
    const group = new FormGroup({
      id: new FormControl(action?.id),
      date: new FormControl(action?.date, [Validators.required]),
      description: new FormControl(action?.description, [Validators.required]),
      // businessOpportunity: new FormControl(action?.businessOpportunity, [Validators.required]),
      offer:  new FormControl(action?.offer),
    })
    this.form.push(group);
    if(!action) {
      group.markAllAsTouched();
    }
  }


}

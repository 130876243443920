<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="payment">Modifica pagamento: {{payment.code}}</span>
    <span *ngIf="!payment">Nuovo pagamento</span>
  </span>
  <span *ngIf="payment">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deletePayment()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="paymentForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Codice" formControlName="code" type="text" >
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <textarea matInput placeholder="Descrizione" formControlName="description" type="text" rows="2"></textarea>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Inizio</mat-label>

        <mat-select formControlName="start" >
          <mat-option *ngFor="let label of starts" [value]="label">
            {{label}}
          </mat-option>
        </mat-select>

        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <input matInput placeholder="Lead" formControlName="lead" type="number" min="0" forceNumber>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>
  </form>
  <!-- {{paymentForm.value | json}} -->

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="paymentForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="paymentForm.pristine || !paymentForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>

import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { Project, ProjectDTO } from './project.model';
import { User, UserDTO } from './user.model';

export interface ProfessionalFilters {
    user_id?: number;
    project_id?: number;
}

export interface ProfessionalDTO extends BaseDTO {
    user_id: number;
    daily_cost: number;
    rate_cost: number;
    km_cost: number;
    daily_revenue: number;
    km_revenue: number;
    head_project: boolean;
    project_id: number;

    user: UserDTO;
    project: ProjectDTO;
}

export class Professional extends Base {
    user_id: number;
    project_id: number;
    dailyCost: number;
    rateCost: number;
    kmCost: number;
    dailyRevenue: number;
    kmRevenue: number;
    headProject: boolean;

    user: User;
    project: Project;

    constructor(source: ProfessionalDTO) {
        super(source);
        if (source) {
            if (source.user) {
                this.user = new User(source.user);
                this.addLoadedRelation('user');
            }
            if (source.project) {
                this.project = new Project(source.project);
            }
            this.dailyCost = source.daily_cost;
            this.rateCost = source.rate_cost;
            this.kmCost = source.km_cost;
            this.dailyRevenue = source.daily_revenue;
            this.kmRevenue = source.km_revenue;
            this.headProject = source.head_project;


        }
    }

    toDTO(): ProfessionalDTO {
        let result: ProfessionalDTO = <ProfessionalDTO>super.toDTO();
        result.user = this.user ? this.user.toDTO() : null;
        result.user_id = this.user ? this.user.id : null;
        result.daily_cost = this.dailyCost;
        result.rate_cost = this.rateCost;
        result.km_cost = this.kmCost;
        result.daily_revenue = this.dailyRevenue;
        result.km_revenue = this.kmRevenue;
        result.head_project = this.headProject;
        result.project = this.project ? this.project.toDTO() : null;
        result.project_id = this.project ? this.project.id : null;

        return result;
    }

    static fromFormGroup(formGroup: AbstractControl, original?: Professional) {
        const formModel = formGroup.value;
        let professional: Professional = new Professional(null);
        professional.user = formModel.user;
        professional.dailyCost = formModel.dailyCost;
        professional.rateCost = formModel.rateCost;
        professional.kmCost = formModel.kmCost;
        professional.dailyRevenue = formModel.dailyRevenue;
        professional.kmRevenue = formModel.kmRevenue;
        professional.headProject = formModel.headProject;
        professional.project = formModel.project;
        professional.id = formModel.id;

        if (original) {
            professional.id = original.id;
        }
        return professional;
    }

}

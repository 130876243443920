<app-bank-account-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-bank-account-filters>
<mat-card class="u-center-text" *ngIf="!(budgets | async).length">
  <h4>Nessun budget trovato</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addBudget()">
    Aggiungi
  </button>
</mat-card>
<div class="table-container" [hidden]="!(budgets | async).length">
  <table mat-table matSort [dataSource]="budgets">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let row">
        {{ row.id }}
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
      <td mat-cell *matCellDef="let row">
        {{ row.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Commessa</th>
      <td mat-cell *matCellDef="let row">
        {{ row.project?.identifier }}
      </td>
    </ng-container>
    <ng-container matColumnDef="budget">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Budget</th>
      <td mat-cell *matCellDef="let row">
        <!-- {{row.budget}} -->
        <!-- {{row.hours}} -->
        <span class=" no-wrap">{{ row.budget | customCurrency }}
          <span *ngIf="row.project?.currency">{{ row.project?.currency.currencyCode }}
          </span>
          / {{ row.hours | customCurrency : "1.0-0" }} ore
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="hours">
      <th mat-header-cell *matHeaderCellDef>Contatore ore</th>
      <td mat-cell *matCellDef="let row">
        <span> </span>
        <div>Consumate - {{ row.activityHours }}</div>
        <div>Fatturate - {{ row.billedActivityHours }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="start">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Inizio</th>
      <td mat-cell *matCellDef="let row">
        {{ row.start | dateFormat }}
      </td>
    </ng-container>
    <ng-container matColumnDef="end">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fine</th>
      <td mat-cell *matCellDef="let row">
        {{ row.end | dateFormat }}
      </td>
    </ng-container>
    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef>Note</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.notes" [matTooltip]="row.notes">description</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation(); addBudget()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editBudget(row)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
          </mat-menu>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectBudget(row)">
          Seleziona
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { BudgetSelectionComponent } from 'src/app/modules/shared/budget-selection/budget-selection.component';
import { AlertService } from '../../commons/services/alert.service';
import { LaravelBudgetService } from '../../commons/services/backend/laravel-budget.service';
import * as BudgetActions from '../actions/budget.actions';

import { AppState } from '../reducers';
import { getBudgetDialogId, getBudgetsTableState, getSelectionDialogId } from '../selectors/budget.selectors';
import * as ProjectActions from '../actions/project.actions';


@Injectable()
export class BudgetEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BudgetActions.saveBudgetFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

  loadBudgets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BudgetActions.loadBudgets),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.budgetService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              BudgetActions.loadBudgetsCompleted({ budgets: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(BudgetActions.loadBudgetsFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(BudgetActions.changePage),
      withLatestFrom(this.store$.select(getBudgetsTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => BudgetActions.loadBudgets({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(BudgetActions.changeSort),
      withLatestFrom(this.store$.select(getBudgetsTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => BudgetActions.loadBudgets({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(BudgetActions.changeFilters),
      withLatestFrom(this.store$.select(getBudgetsTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => BudgetActions.loadBudgets({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  // editBudget$ = createEffect(() => this.actions$.pipe(
  //   ofType(BudgetActions.editBudget),
  //   map(({ budget }) => {
  //     let dialogRef = this.dialog.open(BudgetEditComponent, {
  //       data: {
  //         budget,
  //       },
  //     });
  //     return BudgetActions.budgetDialogOpened({ dialogId: dialogRef.id });
  //   }))
  // );

  selectBudget$ = createEffect(() => this.actions$.pipe(
    ofType(BudgetActions.selectBudget),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(BudgetSelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return BudgetActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );

  // saveBudget$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(BudgetActions.saveBudget),
  //     mergeMap(({ budget }) =>
  //       this.budgetService.upsert(budget.toDTO())
  //         .pipe(
  //           map(result =>
  //             BudgetActions.saveBudgetCompleted({ budget: result })
  //           ),
  //           catchError(error => of(BudgetActions.saveBudgetFailed({ error })))
  //         )
  //     )
  //   )
  // );

  // onSaveCompleted$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(BudgetActions.saveBudgetCompleted),
  //     map(action => action.budget),
  //     tap(budget => this.alertService.showConfirmMessage(`${budget.name} salvata con successo`)),
  //     map(() => BudgetActions.closeBudgetDialog()),

  //   )
  // );
  // loadCurrentProjectAfterSave$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(BudgetActions.saveBudgetCompleted),
  //     map(() => ProjectActions.loadCurrentProject())
  //   )
  // );



  // deleteBudget$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(BudgetActions.deleteBudget),
  //     switchMap(({ budget }) =>
  //       this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare il budget ${budget.name}?`)
  //         .pipe(
  //           mergeMap((confirm) => {
  //             return confirm ?
  //               this.budgetService.delete(budget.id)
  //                 .pipe(
  //                   map(() => BudgetActions.deleteBudgetCompleted({ budget })),
  //                   catchError(error => of(BudgetActions.deleteBudgetFailed({ error })))
  //                 )

  //               : of(BudgetActions.deleteBudgetCancelled());
  //           })
  //         )
  //     )
  //   )
  // );

  // onDeleteCompleted$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(BudgetActions.deleteBudgetCompleted),
  //     tap(({ budget }) => this.alertService.showConfirmMessage(`Budget ${budget.name} eliminato con successo`)),
  //     map(() => BudgetActions.closeBudgetDialog())
  //   )
  // );


  // closeDialog = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(BudgetActions.closeBudgetDialog),
  //     withLatestFrom(this.store$.select(getBudgetDialogId)),
  //     tap(([_, dialogId]) => {
  //       if (dialogId) {
  //         this.dialog.getDialogById(dialogId).close();
  //       }
  //     })
  //   ), { dispatch: false }
  // );

  // reloadAfterSave = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(BudgetActions.saveBudgetCompleted),
  //     withLatestFrom(this.store$.select(getBudgetsTableState)),
  //     map(([_, { currentPage, perPage, direction, order, filters, includes }]) => BudgetActions.loadBudgets({ page: currentPage, perPage, order, direction, filters, includes }))
  //   )
  // );


  // reloadAfterDelete = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(BudgetActions.deleteBudgetCompleted),
  //     withLatestFrom(this.store$.select(getBudgetsTableState)),
  //     map(([_, { currentPage, perPage, direction, order, filters, includes }]) => BudgetActions.loadBudgets({ page: currentPage, perPage, order, direction, filters, includes }))
  //   )
  // );


  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(BudgetActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  budgetsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BudgetActions.budgetSelected),
      map(() => BudgetActions.closeSelectionDialog())
    ))


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private budgetService: LaravelBudgetService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

import {BusinessOpportunityFilters} from '../../../commons/models/business-opportunity.model';
import {BusinessOpportunityType} from './../../../commons/models/business-opportunity.model';

@Component({
  selector: 'app-business-opportunity-filters',
  templateUrl: './business-opportunity-filters.component.html',
  styleUrls: ['./business-opportunity-filters.component.scss']
})
export class BusinessOpportunityFiltersComponent {
  statuses: BusinessOpportunityType[] = ['Prospect', 'Promo', 'Offer', 'Vinto', 'Perso', 'On going', 'Eseguito']

  @Output()
  onFilter: EventEmitter<BusinessOpportunityFilters> = new EventEmitter();

  private _defaultFilters: BusinessOpportunityFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
      state: [['Prospect', 'Promo', 'Offer', 'Vinto', 'Perso', 'On going', 'Eseguito']],
      salesLeader: '',
      client: "",
      from: "",
      to: ""
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getBusinessOpportunityFilters());
  }

  private _getBusinessOpportunityFilters(): BusinessOpportunityFilters {
    let filters: BusinessOpportunityFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
      filters.state = values.state;
      filters.from = values.from;
      filters.to = values.to;
      filters.client = values.client;
      filters.salesLeader = values.salesLeader;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
      client: "",
      from: "",
      to: "",
      salesLeader: '',
      state: []
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): BusinessOpportunityFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: BusinessOpportunityFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
          client: this.defaultFilters.client,
          from: this.defaultFilters.from,
          to: this.defaultFilters.to,
          state: this._defaultFilters.state,
          salesLeader: this._defaultFilters.salesLeader
        })
      }
    }
  }
}

<h2 class="u-flex-full">
  <span>
    <mat-icon>receipt</mat-icon>Spese per data e Commessa
  </span>
</h2>
<div class="mat-elevation-z8" style="margin: 0 15px;">
  <daily-expenses-list
    [dailyExpenses]="dailyExpenses | async"
    [currentUser]="currentUser"
    [defaultFilters]="defaultFilters"
    [total]="total"
    (onFilterChange)="filtersChange($event)">
  </daily-expenses-list>
  <!-- (onSortChange)="sortChange($event)"
    (onPageChange)="pageChange($event)" -->
</div>

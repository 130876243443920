import { openBonusLinesDialog } from './../actions/bonus.actions';
import { Action, createReducer, on } from '@ngrx/store';

import { Bonus, BonusDTO } from '../../commons/models/bonus.model';
import * as BonusLineActions from '../actions/bonus-line.actions';
import { BonusFilters } from '../../commons/models/bonus.model';
import { BonusLineDTO, BonusLineFilters } from 'src/app/commons/models/bonus_line.model';

export interface BonusLineState {
  list: BonusLineDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: BonusLineFilters,

};

const initialState: BonusLineState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,

};

const bonusReducer = createReducer(
  initialState,
  on(BonusLineActions.loadBonusLinesCompleted, (state, { bonusLines, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: bonusLines, currentPage, total, perPage, order, direction, filters, includes };
  }),

);

export function reducer(state: BonusLineState | undefined, action: Action) {
  return bonusReducer(state, action);
}


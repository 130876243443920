<!-- <app-bonus-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-bonus-filters> -->
<mat-card class="u-center-text" *ngIf="!(bonuses | async).length">
  <h4>No R&D found</h4>
  <button mat-raised-button (click)="addBonus()">
    R&D Generation
  </button>
</mat-card>
<div class="table-container" [hidden]="!(bonuses | async).length">
  <table mat-table matSort [dataSource]="bonuses">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let row">
        {{row.id}}
      </td>
    </ng-container>

    <ng-container matColumnDef="year">
      <th mat-header-cell *matHeaderCellDef> Year </th>
      <td mat-cell *matCellDef="let row">
        {{row.year }}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> Available for R&D </th>
      <td mat-cell *matCellDef="let row" class="text-right no-wrap">
        {{row.amount | customCurrency}}&nbsp;€
      </td>
    </ng-container>

    <ng-container matColumnDef="ratio">
      <th mat-header-cell *matHeaderCellDef> Ratio </th>
      <td mat-cell *matCellDef="let row" class="text-right no-wrap">
        {{row.amount / row.margin | percent:'1.0-2'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="bonus_client">
      <th mat-header-cell *matHeaderCellDef> Client R&D </th>
      <td mat-cell *matCellDef="let row" class="text-right no-wrap">
        {{row.bonus_client| customCurrency}}&nbsp;€
      </td>
    </ng-container>

    <ng-container matColumnDef="project_bonus">
      <th mat-header-cell *matHeaderCellDef> Project R&D </th>
      <td mat-cell *matCellDef="let row" class="text-right no-wrap">
        {{row.project_bonus| customCurrency}}&nbsp;€
      </td>
    </ng-container>

    <ng-container matColumnDef="bonus_sales">
      <th mat-header-cell *matHeaderCellDef> Sales R&D </th>
      <td mat-cell *matCellDef="let row" class="text-right no-wrap">
        {{row.bonus_sales| customCurrency}}&nbsp;€
      </td>
    </ng-container>

    <ng-container matColumnDef="total_distributed">
      <th mat-header-cell *matHeaderCellDef> R&D Distributed </th>
      <td mat-cell *matCellDef="let row" class="text-right no-wrap">
        {{(row.bonus_sales + row.bonus_client + row.project_bonus)| customCurrency}}&nbsp;€
      </td>
    </ng-container>

    <ng-container matColumnDef="margin">
      <th mat-header-cell *matHeaderCellDef> Projects Gross Margin </th>
      <td mat-cell *matCellDef="let row" class="text-right no-wrap">
        {{row.margin| customCurrency}}&nbsp;€
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-icon-button mat-raised-button color="accent" (click)="$event.stopPropagation(); addBonus()">
          <mat-icon matTooltip="R&D Generation">playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openBonusLines(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
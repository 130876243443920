import { Action, createReducer, on } from '@ngrx/store';

import { ActionDTO, ActionFilters } from '../../commons/models/action.model';
import * as ActionActions from '../actions/action.actions';

export interface ActionState {
  list: ActionDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ActionFilters,
  dialogId: string,
  selectionDialogId: string,
  action: ActionDTO
};

const initialState: ActionState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  action: null
};

const actionReducer = createReducer(
  initialState,
  on(ActionActions.loadActionsCompleted, (state, { actions, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: actions, currentPage, total, perPage, order, direction, filters, includes, action: null };
  }),
  on(ActionActions.actionDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ActionActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(ActionActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(ActionActions.showAction, (state, { action }) => {
    return { ...state, action };
  }),
  on(ActionActions.loadActionCompleted, (state, { action }) => {
    return { ...state, action };
  }),
);

export function reducer(state: ActionState | undefined, action: Action) {
  return actionReducer(state, action);
}


import { Action, createReducer, on } from '@ngrx/store';

import { ProjectComment, ProjectCommentDTO, ProjectCommentFilters } from '../../commons/models/project-comment.model';
import * as ProjectCommentActions from '../actions/project-comment.actions';


export interface ProjectCommentState {
  list: ProjectCommentDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ProjectCommentFilters,
  dialogId: string,
  selectionDialogId: string,
  projectComment: ProjectComment
};

const initialState: ProjectCommentState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  projectComment: null
};

const projectCommentReducer = createReducer(
  initialState,
  on(ProjectCommentActions.loadProjectCommentsCompleted, (state, { projectComments, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: projectComments, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(ProjectCommentActions.projectCommentDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ProjectCommentActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(ProjectCommentActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(ProjectCommentActions.loadProjectComments, (state, { filters }) => {
    return { ...state, filters };
  })
);

export function reducer(state: ProjectCommentState | undefined, action: Action) {
  return projectCommentReducer(state, action);
}


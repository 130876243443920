import { Action, createReducer, on } from '@ngrx/store';

import { Client, ClientDTO } from '../../commons/models/client.model';
import * as ClientActions from '../actions/client.actions';
import { ClientFilters } from './../../commons/models/client.model';

export interface ClientState {
  list: ClientDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ClientFilters,
  dialogId: string,
  selectionDialogId: string,
  client: Client
};

const initialState: ClientState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  client: null
};

const clientReducer = createReducer(
  initialState,
  on(ClientActions.loadClientsCompleted, (state, { clients, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: clients, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(ClientActions.clientDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ClientActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(ClientActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(ClientActions.editClient, (state, { client }) => {
    return { ...state, client };
  })
);

export function reducer(state: ClientState | undefined, action: Action) {
  return clientReducer(state, action);
}


<mat-expansion-panel style="margin-bottom: 16px">
  <mat-expansion-panel-header> Filtra </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Ricerca libera</mat-label>
        <input type="text" matInput formControlName="search" />
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Area</mat-label>
        <app-risk-area-field formControlName="riskArea"> </app-risk-area-field>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Categoria contromisura</mat-label>
        <app-risk-category-field formControlName="riskCategory">
        </app-risk-category-field>
      </mat-form-field>
      <!-- <mat-form-field class="u-full-width">
        <mat-label>Risk owner</mat-label>
        <app-risk-owner-field formControlName="riskOwner">
        </app-risk-owner-field>
      </mat-form-field> -->
      <span class="u-flex-full">
        <button
          class="filter"
          mat-raised-button
          [disabled]="filtersForm.pristine"
          color="primary"
          (click)="applyFilters()"
        >
          Filtra
        </button>
        <button
          mat-icon-button
          *ngIf="!filtersForm.pristine"
          (click)="resetFilters()"
          matTooltip="Reset"
        >
          <mat-icon>replay</mat-icon>
        </button>
      </span>
    </div>
  </form>
</mat-expansion-panel>

import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { Project, ProjectDTO } from './project.model';
import { Budget, BudgetDTO } from './budget.model';
import { User, UserDTO } from './user.model';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { Offer, OfferDTO } from './offer.model';


export interface DaysDistributionFilters {
    search?: string;
    offer_id?: number;
}

export interface DaysDistributionDTO extends BaseDTO {
    date: string;
    offer_id: number;
    user_id: number;
    days: number;

    offer: OfferDTO;
    user: UserDTO;
}

export class DaysDistribution extends Base {
    date: Date;
    offerId: number;
    userId: number;
    days: number;

    offer: Offer;
    user: User;

    constructor(source: DaysDistributionDTO) {
        super(source);
        if (source) {
            this.date = new Date(source.date);
            this.offerId = source.offer_id;
            this.userId = source.user_id;
            this.days = source.days;
            this.offer = new Offer(source.offer);
            this.user = new User(source.user);
        }
    }

    toDTO(): DaysDistributionDTO {
        let result: DaysDistributionDTO = <DaysDistributionDTO>super.toDTO();
        result.date = formatDateForBackend(this.date);
        result.offer_id = this.offerId;
        result.user_id = this.userId;
        result.days = this.days;
        return result;
    }

    static fromFormGroup(formGroup: AbstractControl, original?: DaysDistribution) {
        const formModel = formGroup.value;
        let daysDistribution: DaysDistribution = new DaysDistribution(null);
        daysDistribution.date = formModel.date;
        daysDistribution.offerId = formModel.offer?.id;
        daysDistribution.userId = formModel.user?.id;
        daysDistribution.days = formModel.days;
        daysDistribution.id = formModel.id;

        if (original) {
            daysDistribution.id = original.id;
        }
        return daysDistribution;
    }

}

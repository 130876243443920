import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ProfessionalFilters } from '../../../commons/models/professional.model';

@Component({
  selector: 'app-professional-filters',
  templateUrl: './professional-filters.component.html',
  styleUrls: ['./professional-filters.component.scss']
})
export class ProfessionalFiltersComponent {
  @Output()
  onFilter: EventEmitter<ProfessionalFilters> = new EventEmitter();

  @Input()
  showProject: boolean = true;

  private _defaultFilters: ProfessionalFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      user: "",
      project: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getProfessional());
  }

  private _getProfessional(): ProfessionalFilters {
    let filters: ProfessionalFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.user_id = values.user?.id;
      filters.project_id = values.project?.id
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      user: "",
      project: ""
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): ProfessionalFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: ProfessionalFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          user: this.defaultFilters.user_id,
          project: this.defaultFilters.project_id
        })
      }
    }
  }
}

<div class="u-flex-full">
  <label>{{ value?.name }} {{ value?.lastname }}</label>
  <button
    class="close"
    *ngIf="value"
    mat-button
    type="button"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="clearContact()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="client">Modifica cliente: {{ client.name }}</span>
    <span *ngIf="!client">Nuovo cliente</span>
  </span>
  <span *ngIf="client">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteClient()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="clientForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field>
        <input matInput placeholder="Nome breve" formControlName="name" type="text" />
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Ragione sociale completa"
          formControlName="companyName"
          type="text"
        />
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="P.IVA" formControlName="VAT" type="text" />
        <mat-error>P.IVA non valida</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Codice fiscale"
          formControlName="identifier"
          type="text"
        />
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="E-mail" formControlName="email" type="email" />
        <mat-error>Formato non valido</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="E-mail amministrazione" formControlName="adminEmail" type="email" />
        <mat-error>Formato non valido</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="PEC" formControlName="PEC" type="email" />
        <mat-error>Formato non valido</mat-error>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Indirizzo"
          formControlName="address"
          type="text"
        />
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Città"
          formControlName="city"
          type="text"
        />
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Provincia"
          formControlName="province"
          type="text"
        />
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <!-- <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Regione"
          formControlName="region"
          type="text"
        />
      </mat-form-field> -->
      <mat-form-field class="u-full-width">
        <input type="text" placeholder="Regione" aria-label="Regione" matInput formControlName="region" [matAutocomplete]="regionAutocomplete">
        <mat-autocomplete #regionAutocomplete="matAutocomplete" autoActiveFirstOption>
          <mat-option *ngFor="let region of filteredRegions$ | async" [value]="region">
            {{region}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="CAP" formControlName="CAP" type="text" />
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          type="text"
          placeholder="Nazione"
          aria-label="Nation"
          matInput
          formControlName="country"
          [matAutocomplete]="nation"
        />
        <mat-autocomplete autoActiveFirstOption #nation="matAutocomplete">
          <mat-option
            *ngFor="let nation of filteredNations | async"
            [value]="nation.name"
          >
            {{ nation.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Codice nazione"
          formControlName="countryCode"
          type="text"
        />
        <mat-error *ngIf="clientForm.controls['countryCode'].errors">
          Formato non valido
        </mat-error>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Bonus cliente"
          formControlName="bonusClient"
          type="number"
          min="0"
          max="100"
          forceNumber
        />
        <mat-icon matSuffix>percent</mat-icon>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Tipologia di pagamento</mat-label>
        <app-payment-field formControlName="payment"> </app-payment-field>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Domiciliazione pagamento</mat-label>
        <app-bank-account-field formControlName="bankAccount">
        </app-bank-account-field>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width" floatLabel="auto">
        <mat-label>Client leaders </mat-label>
        <app-client-bonuses-field
          formControlName="clientBonuses"
        ></app-client-bonuses-field>
        <mat-icon
          matSuffix
          matTooltip="Questa percentuale è la quota di Bonus Cliente assegnata all'Utente"
          >help</mat-icon
        >
        <mat-error
          *ngIf="clientForm.controls['clientBonuses'].errors?.incorrect"
          >La somma delle percentuali è uguale a {{ totalBonusClient }}% deve
          essere pari a 100%</mat-error
        >
      </mat-form-field>
    </div>
    <!-- <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Client leader</mat-label>
        <app-users-field formControlName="clientLeaders">
        </app-users-field>
      </mat-form-field>
    </div> -->
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Contatti</mat-label>
        <app-contacts-field formControlName="contacts"> </app-contacts-field>
      </mat-form-field>
    </div>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Valuta</mat-label>
        <app-currency-field formControlName="currency"> </app-currency-field>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input
          matInput
          placeholder="Codice destinatario"
          formControlName="recipientCode"
          type="text"
        />
        <mat-error *ngIf="clientForm.controls['recipientCode'].errors">
          Formato non valido
        </mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Regime IVA</mat-label>
        <app-iva-typology-field
          formControlName="ivaTypology"
        ></app-iva-typology-field>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>
    <mat-expansion-panel>
      <mat-expansion-panel-header> Lettera intenti </mat-expansion-panel-header>
      <div class="u-flex-full">
        <mat-form-field class="u-full-width">
          <input
            matInput
            placeholder="Riferimento"
            formControlName="reference"
            type="text"
          />
        <mat-error *ngIf="clientForm.controls['reference'].errors">
          Formato non valido - Previsto nella forma XXXXXXXXXXX-YYYYYYY
        </mat-error>
        </mat-form-field>
        <mat-form-field class="u-full-width">
          <input matInput [matDatepicker]="referenceDatePicker" formControlName="referenceDate" placeholder="Data" />
          <mat-datepicker-toggle matSuffix [for]="referenceDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #referenceDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </mat-expansion-panel>
    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Note" formControlName="note" type="text" />
      </mat-form-field>
    </div>
  </form>
  <!-- {{clientForm.value | json}}  -->
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button
      mat-raised-button
      color="basic"
      type="reset"
      (click)="revert()"
      [disabled]="clientForm.pristine"
    >
      Reset
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="clientForm.pristine || !clientForm.valid"
    >
      Salva
    </button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Chiudi
  </button>
</mat-dialog-actions>

<mat-chip-list #chipList>
  <mat-chip color="primary" *ngFor="let projectBonus of value; let i = index;" [removable]="true" [selectable]="false"
    (removed)="removeProjectBonus(i)" (click)="editProjectBonus(i)">
    {{projectBonus.user?.fullName}}<span *ngIf="projectBonus?.percentage">: {{projectBonus.percentage}}%</span>
    <mat-icon matChipRemove>cancel</mat-icon>

  </mat-chip>
  <button type="button" style="margin: 5px" matTooltip="Aggiungi utente bonus project" (click)="addProjectBonus()"
    mat-icon-button mat-raised-button>
    <mat-icon>add</mat-icon>
  </button>

</mat-chip-list>
<mat-expansion-panel class="mat-elevation-z0" [expanded]="openEdit">
  <app-project-bonus-edit [isBonusInPercentage]= "isBonusInPercentage" [projectBonus]="currentProjectBonus" (onClose)="close()" (onSave)="save($event)">
  </app-project-bonus-edit>
</mat-expansion-panel>

<!-- {{ currentProjectBonus | json }} -->

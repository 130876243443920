import { AbstractControl } from '@angular/forms';
import { BillingLine, BillingLineDTO } from 'src/app/commons/models/billing-line.model';
import { CostLine, CostLineDTO } from 'src/app/commons/models/cost-line.model';
import { User, UserDTO } from 'src/app/commons/models/user.model';
import { formatDate, formatDateForBackend, parseDate } from 'src/app/helpers/time.utils';
import { environment } from 'src/environments/environment';

import { ClientStatus, ProfessionalStatus } from './activity.model';
import { Base, BaseDTO } from './base.model';
import { Professional, ProfessionalDTO } from './professional.model';
import { Project, ProjectDTO, RefundType } from './project.model';

export interface ExpenseFilters {
  search?: string;
  start?: Date;
  end?: Date;
  client_id?: number;
  payable?: boolean;
  billable?: boolean | number;
  project_id?: number;
  head_project_id?: number;
  professional?: UserDTO;
  expenses_debit_type?: ExpenseType;
  expenses_refund_type?: RefundType;
  payer?: ExpensePayer;
  validated?: boolean;
  promo?: boolean;
  client_invoice_id?: string;
  professional_invoice_id?: string;
  accounted?: boolean;
}

export type ExpenseType =
  | 'Own Car'
  | 'Car Rent'
  | 'Fuel'
  | 'Toll'
  | 'Flight'
  | 'Train'
  | 'Hotel'
  | 'Restaurant'
  | 'Taxi'
  | 'Parking'
  | 'Phone'
  | 'Miscellaneous';
export const EXPENSE_TYPES: ExpenseType[] = [
  'Own Car',
  'Car Rent',
  'Fuel',
  'Toll',
  'Flight',
  'Train',
  'Hotel',
  'Restaurant',
  'Taxi',
  'Parking',
  'Phone',
  'Miscellaneous',
];

export type ExpensePayer = 'Consulente' | 'Azienda';
export const EXPENSE_PAYERS: ExpensePayer[] = ['Consulente', 'Azienda'];

export interface ExpenseReportDTO extends BaseDTO {
  month: number;
  year: number;
  amount: number;
  project_id: number;
  professional_id: number;
  payer: ExpensePayer;
  professional: ProfessionalDTO;
  project: ProjectDTO;
}

export class ExpenseReport extends Base {
  date: string;
  amount: number;
  projectId: number;
  professionalId: number;
  payer: ExpensePayer;
  professional: Professional;
  project: Project;

  constructor(data: ExpenseReportDTO) {
    super(data);
    if (data) {
      this.date = `${data.month}/${data.year}`;
      this.amount = data.amount;
      this.projectId = data.project_id;
      this.professionalId = data.professional_id;
      this.payer = data.payer;

      if (data.professional) {
        this.professional = new Professional(data.professional);
      }
      if (data.project) {
        this.project = new Project(data.project);
      }
    }
  }
}

export interface ExpenseDTO extends BaseDTO {
  project_id: number;
  professional_id: number;
  billing_line_id: number;
  cost_line_id: number;
  date: string;
  type: ExpenseType;
  amount: number;
  km: number;
  payer: ExpensePayer;
  attachment?: string | File;
  attachment_path?: string;
  attachment_name?: string;
  notes?: string;
  project: ProjectDTO;
  professional: ProfessionalDTO;
  billing_line: BillingLineDTO;
  cost_line: CostLineDTO;
  validation_date: string;
  validated: boolean;
  validation_user: UserDTO;
  needs_billing_line: boolean;
  needs_cost_line: boolean;
}

export class Expense extends Base {
  projectId: number;
  professionalId: number;
  billingLineId: number;
  costLineId: number;
  date: Date;
  type: ExpenseType;
  amount: number;
  km: number;
  payer: ExpensePayer;
  attachment?: string | File;
  attachmentPath?: string;
  attachmentName?: string;
  notes: string;
  project: Project;
  professional: Professional;
  billingLine: BillingLine;
  costLine: CostLine;
  validationDate: Date;
  validated: boolean;
  validationUser: User;
  needsBillingLine: boolean;
  needsCostLine: boolean;

  constructor(source: ExpenseDTO) {
    super(source);
    if (source) {
      this.projectId = source.project_id;
      this.professionalId = source.professional_id;
      this.billingLineId = source.billing_line_id;
      this.costLineId = source.cost_line_id;
      if (source.date) {
        this.date = parseDate(source.date, 'DD-MM-YYYY'); //mrosetti - This entity has custom format from backend. Does not know why exactly
      }
      if (source.validation_date) {
        this.validationDate = new Date(source.validation_date);
      }
      this.validated = source.validated;
      this.type = source.type;
      this.amount = source.amount;
      this.payer = source.payer;
      this.attachment = source.attachment;
      this.attachmentName = source.attachment_name;
      this.notes = source.notes;
      this.km = source.km;

      if (source.project) {
        this.project = new Project(source.project);
        this.addLoadedRelation('project');
      }
      if (source.professional) {
        this.professional = new Professional(source.professional);
        this.addLoadedRelation('professional');
      }
      if (source.billing_line) {
        this.billingLine = new BillingLine(source.billing_line);
        this.addLoadedRelation('billing_line');
      }
      if (source.cost_line) {
        this.costLine = new CostLine(source.cost_line);
        this.addLoadedRelation('cost_line');
      }
      if (source.validation_user) {
        this.validationUser = new User(source.validation_user);
        this.addLoadedRelation('validation_user');
      }
      this.needsBillingLine = source.needs_billing_line;
      this.needsCostLine = source.needs_cost_line;
    }
  }

  toDTO(): ExpenseDTO {
    let result: ExpenseDTO = <ExpenseDTO>super.toDTO();
    result.project_id = this.projectId;
    result.professional_id = this.professionalId;
    result.billing_line_id = this.billingLineId;
    result.cost_line_id = this.costLineId;
    result.date = formatDateForBackend(this.date);
    result.type = this.type;
    result.amount = this.amount;
    result.payer = this.payer;
    result.attachment = this.attachment;
    result.attachment_path = this.attachmentPath;
    result.attachment_name = this.attachmentName;
    result.notes = this.notes;
    result.km = this.km;
    result.validation_date = formatDateForBackend(this.validationDate);
    result.validated = this.validated;
    result.needs_billing_line = this.needsBillingLine;
    result.needs_cost_line = this.needsCostLine;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, originalId?: number, originalAttachmentName?: string) {
    const formModel = formGroup.value;
    let expense: Expense = new Expense(null);
    expense.projectId = formModel.project?.id;
    expense.professionalId = formModel.professional?.id;
    expense.billingLineId = formModel.BillingLine?.id;
    expense.costLineId = formModel.costLine?.id;
    expense.date = formModel.date;
    expense.type = formModel.type;
    expense.amount = formModel.amount;
    expense.payer = formModel.payer;
    if (formModel.attachment instanceof File) {
      expense.attachment = formModel.attachment;
    } else {
      if (originalAttachmentName) {
        expense.attachmentName = originalAttachmentName;
      }
      expense.attachmentPath = formModel.attachment;
      if (expense.attachmentPath?.startsWith('http')) { //File esterno
        expense.attachmentName = expense.attachmentPath;
      }
    }
    expense.notes = formModel.notes;
    expense.km = formModel.km;
    expense.validationDate = formModel.validationDate;

    if (originalId) {
      expense.id = originalId;
    }
    return expense;
  }

  get attachmentUrl(): string {
    let path: string;
    if (this.attachment instanceof File) {
      path = this.attachmentPath;
    } else {
      path = this.attachment;
    }
    if (path?.startsWith('http')) {
      return path;
    }
    return `${environment.baseUrl}/${path}`;
  }

  toString(): string {
    return `${formatDate(this.date)} - ${this.type}`;
  }

  get accountable(): boolean {
    return (this.needsBillingLine && !this.billingLineId) || (this.needsCostLine && !this.costLineId);
  }

  get hasGeneatedLInes(): boolean {
    return !!this.billingLineId || !!this.costLineId;
  }

  get accountableCancellable(): boolean {
    return (this.needsBillingLine && this.clientStatus === 'Fatturabile') || (this.needsCostLine && this.professionalStatus === 'Contabilizzata');
  }

  get notAccountableCancellableTooltip(): string {
    if (!this.accountableCancellable) {
      let result = 'Impossibile annullare la contabilizzazione';
      if (this.needsBillingLine && this.clientStatus !== 'Fatturabile') {
        result += '\nRiga di fatturazione già fatturata';
      }
      if (this.needsCostLine && this.professionalStatus !== 'Contabilizzata') {
        result += '\nRiga di costo già impostata come pagabile'
      }
      return result;
    }
    return '';
  }

  get clientStatus(): ClientStatus {
    if (this.billingLine?.invoice?.paymentDate) {
      return 'Pagata';
    }
    if (this.billingLine?.invoiceId) {
      return 'Fatturata';
    }
    if (this.billingLineId) {
      return 'Fatturabile';
    }
    if (!this.needsBillingLine) {
      return 'Non prevista';
    }
  }

  get professionalStatus(): ProfessionalStatus {
    if (this.costLine?.invoicePaymentDate) {
      return 'Pagata';
    }
    if (this.costLine?.invoiceDate) {
      return 'Fatturata';
    }
    if (this.costLine?.accountable) {
      return 'Fatturabile'
    }
    if (this.costLineId) {
      return 'Contabilizzata';
    }
    if (!this.needsCostLine) {
      return 'Non prevista';
    }
  }
}

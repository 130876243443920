import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { IvaTypology } from 'src/app/commons/models/iva-typology.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as IvaTypologyActions from 'src/app/store/actions/iva-typology.actions';
import { AppState } from 'src/app/store/reducers';
import * as IvaTypologySelectors from 'src/app/store/selectors/iva-typology.selectors';

import { IvaTypologyFilters } from '../../../commons/models/iva-typology.model';
import { IvaTypologiesColumn } from '../iva-typology-list/iva-typology-list.component';


@Component({
  selector: 'app-iva-typology-selection',
  templateUrl: './iva-typology-selection.component.html',
  styleUrls: ['./iva-typology-selection.component.scss']
})
export class IvaTypologySelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  ivaTypologies: Observable<IvaTypology[]>;
  filters: Observable<IvaTypologyFilters>;

  @Input()
  defaultFilters: IvaTypologyFilters = {
    search: "",
  }

  displayedColumns: IvaTypologiesColumn[] = ["code", "percentage", 'nature',"actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.ivaTypologies = this.store$.pipe(select(IvaTypologySelectors.getIvaTypologies), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new IvaTypology(dto)) : null));
    this.total = this.store$.pipe(select(IvaTypologySelectors.getTotalIvaTypologies), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(IvaTypologySelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(IvaTypologyActions.loadIvaTypologies(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(IvaTypologyActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(IvaTypologyActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: IvaTypologyFilters) {
    this.store$.dispatch(IvaTypologyActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectIvaTypology(ivaTypology: IvaTypology) {
    if (ivaTypology) {
      this.store$.dispatch(IvaTypologyActions.ivaTypologySelected({ ivaTypology: ivaTypology.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(IvaTypologyActions.closeSelectionDialog())
  }
}

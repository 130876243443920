import { Action, createReducer, on } from '@ngrx/store';

import { BankAccount, BankAccountDTO, BankAccountFilters } from '../../commons/models/bank-account.model';
import * as BankAccountActions from '../actions/bank-account.actions';

export interface BankAccountState {
  list: BankAccountDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: BankAccountFilters,
  dialogId: string,
  selectionDialogId: string,
  bankAccount: BankAccount
};

const initialState: BankAccountState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  bankAccount: null
};

const bankAccountReducer = createReducer(
  initialState,
  on(BankAccountActions.loadBankAccountsCompleted, (state, { bankAccounts, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: bankAccounts, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(BankAccountActions.bankAccountDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(BankAccountActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(BankAccountActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: BankAccountState | undefined, action: Action) {
  return bankAccountReducer(state, action);
}


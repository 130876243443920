import { createSelector } from '@ngrx/store';

import { selectOfferAttachmentState } from '../reducers';
import { OfferAttachmentState } from '../reducers/offer-attachment.reducer';

// OfferAttachment Selectors

export const getOfferAttachmentsTableState = createSelector(
  selectOfferAttachmentState,
  (state: OfferAttachmentState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getOfferAttachments = createSelector(
  selectOfferAttachmentState,
  (state: OfferAttachmentState) => state.list
);

export const getTotalOfferAttachments = createSelector(
  getOfferAttachmentsTableState,
  (tableState) => tableState.total
);

export const getOfferAttachmentsCurrentPage = createSelector(
  getOfferAttachmentsTableState,
  (tableState) => tableState.currentPage
);

export const getOfferAttachmentsPerPage = createSelector(
  getOfferAttachmentsTableState,
  (tableState) => tableState.perPage
);

export const getOfferAttachmentsOrder = createSelector(
  getOfferAttachmentsTableState,
  (tableState) => tableState.order
);

export const getOfferAttachmentsDirection = createSelector(
  getOfferAttachmentsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getOfferAttachmentsTableState,
  (tableState) => tableState.filters
);

export const getOfferAttachmentDialogId = createSelector(
  selectOfferAttachmentState,
  (state: OfferAttachmentState) => state.dialogId
);

export const getSelectedOfferAttachment = createSelector(
  selectOfferAttachmentState,
  (state: OfferAttachmentState) => state.offerAttachment
);
export const getSelectionDialogId = createSelector(
  selectOfferAttachmentState,
  (state: OfferAttachmentState) => state.selectionDialogId
);

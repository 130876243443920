<mat-expansion-panel style="margin-bottom: 16px">
  <mat-expansion-panel-header> Filtra </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Data</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Inizio" formControlName="start" />
          <input matEndDate placeholder="Fine" formControlName="end" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Cliente</mat-label>
        <app-client-field formControlName="client"></app-client-field>
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Commessa</mat-label>
        <app-project-field formControlName="project"></app-project-field>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>Promozione</mat-label>
        <mat-select formControlName="promo">
          <mat-option value="">-- Tutte --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Fattura</mat-label>
        <input matInput formControlName="search" />
      </mat-form-field>
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-select matInput placeholder="Tipologia" formControlName="type">
          <mat-option value="">-- Tutte --</mat-option>
          <mat-option value="Attività">Attività</mat-option>
          <mat-option value="Spesa">Spesa</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Fatturate al cliente</mat-label>
        <mat-select formControlName="billed">
          <mat-option value="">-- Tutte --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Pagate dal cliente</mat-label>
        <mat-select formControlName="paid">
          <mat-option value="">-- Tutte --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <span class="u-flex-full">
      <button
        class="filter"
        mat-raised-button
        [disabled]="filtersForm.pristine"
        color="primary"
        (click)="applyFilters()"
      >
        Filtra
      </button>
      <button
        mat-icon-button
        *ngIf="!filtersForm.pristine"
        (click)="resetFilters()"
        matTooltip="Reset"
      >
        <mat-icon>replay</mat-icon>
      </button>
    </span>
  </form>
</mat-expansion-panel>

<mat-chip-list #chipList>
  <mat-chip
    color="primary"
    *ngFor="let contact of value; let i = index"
    [removable]="true"
    [selectable]="false"
    (removed)="removeContact(i)"
  >
    {{ contact?.name }} {{ contact?.lastname }}
    <mat-icon matChipRemove>cancel</mat-icon>
  </mat-chip>
  <button
    type="button"
    style="margin: 5px"
    matTooltip="Seleziona utente"
    (click)="selectContact()"
    mat-icon-button
    mat-raised-button
  >
    <mat-icon>add</mat-icon>
  </button>
</mat-chip-list>

import { Action, createReducer, on } from '@ngrx/store';

import { IvaTypology, IvaTypologyDTO, IvaTypologyFilters } from '../../commons/models/iva-typology.model';
import * as IvaTypologyActions from '../actions/iva-typology.actions';

export interface IvaTypologyState {
  list: IvaTypologyDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: IvaTypologyFilters,
  dialogId: string,
  selectionDialogId: string,
  ivaTypology: IvaTypology
};

const initialState: IvaTypologyState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  ivaTypology: null
};

const ivaTypologyReducer = createReducer(
  initialState,
  on(IvaTypologyActions.loadIvaTypologiesCompleted, (state, { ivaTypologies, currentPage, total, perPage, order, direction, filters,includes }) => {
    return { ...state, list: ivaTypologies, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(IvaTypologyActions.ivaTypologyDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(IvaTypologyActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(IvaTypologyActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: IvaTypologyState | undefined, action: Action) {
  return ivaTypologyReducer(state, action);
}



import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { RiskIdentificationSelectionComponent } from 'src/app/modules/shared/risk-identification-selection/risk-identification-selection.component';
import { AlertService } from '../../commons/services/alert.service';
import { LaravelRiskIdentificationService } from '../../commons/services/backend/laravel-risk-identification.service';
import * as RiskIdentificationActions from '../actions/risk-identification.actions';
import * as ProjectActions from '../actions/project.actions';
import { AppState } from '../reducers';
import { getRiskIdentificationDialogId, getRiskIdentificationsTableState, getSelectionDialogId } from '../selectors/risk-identification.selectors';
import { getProjectId } from './../selectors/project.selectors';


@Injectable()
export class RiskIdentificationEffects {
  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskIdentificationActions.saveRiskIdentificationFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

  loadRiskIdentifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskIdentificationActions.loadRiskIdentifications),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.riskIdentificationService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              RiskIdentificationActions.loadRiskIdentificationsCompleted({ riskIdentifications: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(RiskIdentificationActions.loadRiskIdentificationsFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskIdentificationActions.changePage),
      withLatestFrom(this.store$.select(getRiskIdentificationsTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => RiskIdentificationActions.loadRiskIdentifications({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskIdentificationActions.changeSort),
      withLatestFrom(this.store$.select(getRiskIdentificationsTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => RiskIdentificationActions.loadRiskIdentifications({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskIdentificationActions.changeFilters),
      withLatestFrom(this.store$.select(getRiskIdentificationsTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => RiskIdentificationActions.loadRiskIdentifications({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  //   editRiskIdentification$ = createEffect(() => this.actions$.pipe(
  //     ofType(RiskIdentificationActions.editRiskIdentification),
  //     map(({ riskIdentification }) => {
  //       let dialogRef = this.dialog.open(RiskIdentificationEditComponent, {
  //         data: {
  //           riskIdentification,
  //         },

  //       });
  //       return RiskIdentificationActions.riskIdentificationDialogOpened({ dialogId: dialogRef.id });
  //     }))
  //   );

  //   saveRiskIdentification$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(RiskIdentificationActions.saveRiskIdentification),
  //       mergeMap(({ riskIdentification}) =>
  //         this.riskIdentificationService.upsert(riskIdentification.toDTO())
  //           .pipe(
  //             map(result =>
  //               RiskIdentificationActions.saveRiskIdentificationCompleted({ riskIdentification: result })
  //             ),
  //             catchError(error => of(RiskIdentificationActions.saveRiskIdentificationFailed({ error })))
  //           )
  //       )
  //     )
  //   );

  //   onSaveCompleted$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(RiskIdentificationActions.saveRiskIdentificationCompleted),
  //       map(action => action.riskIdentification),
  //       tap(riskIdentification => this.alertService.showConfirmMessage(`Identificatore di rischio ${riskIdentification.id} salvato con successo`)),
  //       map(() => RiskIdentificationActions.closeRiskIdentificationDialog())
  //     )
  //   );
  //   loadCurrentProjectAfterSave$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(RiskIdentificationActions.saveRiskIdentificationCompleted),
  //     map(() => ProjectActions.loadCurrentProject())
  //   )
  // );

  //   deleteRiskIdentification$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(RiskIdentificationActions.deleteRiskIdentification),
  //       switchMap(({ riskIdentification }) =>
  //         this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare identificatore di rischio ${riskIdentification.id}?`)
  //           .pipe(
  //             mergeMap((confirm) => {
  //               return confirm ?
  //                 this.riskIdentificationService.delete(riskIdentification.id)
  //                   .pipe(
  //                     map(() => RiskIdentificationActions.deleteRiskIdentificationCompleted({ riskIdentification })),
  //                     catchError(error => of(RiskIdentificationActions.deleteRiskIdentificationFailed({ error })))
  //                   )

  //                 : of(RiskIdentificationActions.deleteRiskIdentificationCancelled());
  //             })
  //           )
  //       )
  //     )
  //   );

  //   onDeleteCompleted$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(RiskIdentificationActions.deleteRiskIdentificationCompleted),
  //       tap(({ riskIdentification }) => this.alertService.showConfirmMessage(`Identificatore di rischio ${riskIdentification.id} eliminato con successo`)),
  //       map(() => RiskIdentificationActions.closeRiskIdentificationDialog())
  //     )
  //   );


  //   closeDialog = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(RiskIdentificationActions.closeRiskIdentificationDialog),
  //       withLatestFrom(this.store$.select(getRiskIdentificationDialogId)),
  //       tap(([_, dialogId]) => {
  //         if (dialogId) {
  //           this.dialog.getDialogById(dialogId).close();
  //         }
  //       })
  //     ), { dispatch: false }
  //   );

  //   reloadAfterSave = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(RiskIdentificationActions.saveRiskIdentificationCompleted),
  //       withLatestFrom(this.store$.select(getRiskIdentificationsTableState)),
  //       map(([_, { currentPage, perPage, direction, order, filters, includes }]) => RiskIdentificationActions.loadRiskIdentifications({ page: currentPage, perPage, order, direction, filters, includes }))
  //     )
  //   );

  //   reloadAfterDelete = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(RiskIdentificationActions.deleteRiskIdentificationCompleted),
  //       withLatestFrom(this.store$.select(getRiskIdentificationsTableState)),
  //       map(([_, { currentPage, perPage, direction, order, filters, includes }]) => RiskIdentificationActions.loadRiskIdentifications({ page: currentPage, perPage, order, direction, filters, includes }))
  //     )
  //   );

  selectRiskIdentification$ = createEffect(() => this.actions$.pipe(
    ofType(RiskIdentificationActions.selectRiskIdentification),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(RiskIdentificationSelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return RiskIdentificationActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );


  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskIdentificationActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  riskIdentificationsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RiskIdentificationActions.riskIdentificationSelected),
      map(() => RiskIdentificationActions.closeSelectionDialog())
    ))


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private riskIdentificationService: LaravelRiskIdentificationService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}

<form [formGroup]="riskIdentificationForm" novalidate>
  <div class="u-flex-full">
    <mat-form-field class="u-full-width">
      <mat-label>Rischio</mat-label>
      <app-risk-field formControlName="risk"></app-risk-field>
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Probabilità</mat-label>
      <input
        matInput
        placeholder="Probabilità"
        formControlName="probability"
        type="number"
        min="0"
        max="100"
        forceNumber
      />
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        placeholder="Impatto"
        formControlName="impact"
        type="number"
        forceNumber
      />
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <mat-label>Risk Owner</mat-label>
      <app-user-field formControlName="riskOwner"></app-user-field>
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <mat-label>Risk Actionee</mat-label>
      <app-user-field formControlName="riskActionee"></app-user-field>
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
  </div>
</form>

<div class="u-flex-full actions">
  <span>
    <button
      mat-raised-button
      color="basic"
      type="reset"
      (click)="revert()"
      [disabled]="riskIdentificationForm.pristine"
    >
      Reset
    </button>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="
        riskIdentificationForm.pristine || !riskIdentificationForm.valid
      "
    >
      Conferma
    </button>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">
    Chiudi
  </button>
</div>

import { createAction, props } from '@ngrx/store';

import { IvaTypology, IvaTypologyDTO, IvaTypologyFilters } from '../../commons/models/iva-typology.model';

export const loadIvaTypologies = createAction('[IvaTypologies] Load IvaTypologies', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: IvaTypologyFilters, includes?: string[] }>());
export const loadIvaTypologiesCompleted = createAction('[IvaTypologies] Load IvaTypologies Completed', props<{ ivaTypologies: IvaTypologyDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: IvaTypologyFilters, includes?: string[] }>());
export const loadIvaTypologiesFailed = createAction('[IvaTypologies] Load IvaTypologies Failed', props<{ error: any }>());

export const changePage = createAction('[IvaTypologies] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[IvaTypologies] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[IvaTypologies] Change filters', props<{ filters: IvaTypologyFilters }>());

export const editIvaTypology = createAction('[IvaTypologies] Edit ivaTypology', props<{ ivaTypology: IvaTypology }>());
export const ivaTypologyDialogOpened = createAction('[IvaTypologies] Detail dialog opened', props<{ dialogId: string }>());
export const closeIvaTypologyDialog = createAction('[IvaTypologies] Close detail dialog');

export const saveIvaTypology = createAction('[IvaTypologies] Save ivaTypology', props<{ ivaTypology: IvaTypology }>());
export const saveIvaTypologyCompleted = createAction('[IvaTypologies] Save ivaTypology completed', props<{ ivaTypology: IvaTypologyDTO }>());
export const saveIvaTypologyFailed = createAction('[IvaTypologies] Save ivaTypology failed', props<{ error: any }>());

export const deleteIvaTypology = createAction('[IvaTypologies] Delete ivaTypology', props<{ ivaTypology: IvaTypologyDTO }>());
export const deleteIvaTypologyCompleted = createAction('[IvaTypologies] Delete ivaTypology completed', props<{ ivaTypology: IvaTypologyDTO }>());
export const deleteIvaTypologyCancelled = createAction('[IvaTypologies] Delete ivaTypology cancelled');
export const deleteIvaTypologyFailed = createAction('[IvaTypologies] Delete ivaTypology failed', props<{ error: any }>());

export const selectIvaTypology = createAction('[IvaTypologies] Select ivaTypology', props<{ filters?: IvaTypologyFilters }>());
export const selectionDialogOpened = createAction('[IvaTypologies] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[IvaTypologies] Close selection dialog');
export const ivaTypologySelected = createAction('[IvaTypologies] IvaTypology selected', props<{ ivaTypology: IvaTypologyDTO }>());

import { Action, createReducer, on } from '@ngrx/store';

import { OfferAttachment, OfferAttachmentDTO, OfferAttachmentFilters } from '../../commons/models/offer-attachment.model';
import * as OfferAttachmentActions from '../actions/offer-attachment.actions';


export interface OfferAttachmentState {
  list: OfferAttachmentDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: OfferAttachmentFilters,
  dialogId: string,
  selectionDialogId: string,
  offerAttachment: OfferAttachment,
};

const initialState: OfferAttachmentState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  offerAttachment: null
};

const offerAttachmentReducer = createReducer(
  initialState,
  on(OfferAttachmentActions.loadOfferAttachmentsCompleted, (state, { offerAttachments, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: offerAttachments, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(OfferAttachmentActions.offerAttachmentDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(OfferAttachmentActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(OfferAttachmentActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(OfferAttachmentActions.editOfferAttachment, (state, { offerId }) => {
    return { ...state, offerId };
  }),
  on(OfferAttachmentActions.loadOfferAttachments, (state, { filters }) => {
    return { ...state, filters };
  }),
);

export function reducer(state: OfferAttachmentState | undefined, action: Action) {
  return offerAttachmentReducer(state, action);
}


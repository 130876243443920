import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {Observable} from 'rxjs';

import {BusinessOpportunity, BusinessOpportunityFilters} from '../../../commons/models/business-opportunity.model';
import {PAGE_SIZE_OPTIONS} from '../../../helpers/table.helper';

export type BusinessOpportunitiesColumn = "id" | "client" | "contact" | "salesLeader" | "date" | "status" | "title" | "promo" | "actions";

@Component({
  selector: 'business-opportunity-list',
  templateUrl: './business-opportunity-list.component.html',
  styleUrls: ['./business-opportunity-list.component.scss'],
})
export class BusinessOpportunityListComponent {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Input()
  businessOpportunities: Observable<BusinessOpportunity[]>;

  @Input()
  defaultFilters: BusinessOpportunityFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: BusinessOpportunity | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: BusinessOpportunitiesColumn[] = ["title", "date", "status", "client", "contact", "salesLeader", "promo", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<BusinessOpportunityFilters> = new EventEmitter<BusinessOpportunityFilters>();

  @Output()
  onSelectBusinessOpportunity: EventEmitter<BusinessOpportunity> = new EventEmitter<BusinessOpportunity>();
  @Output()
  onAddBusinessOpportunity: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditBusinessOpportunity: EventEmitter<BusinessOpportunity> = new EventEmitter<BusinessOpportunity>();
  @Output()
  onDuplicateBusinessOpportunity: EventEmitter<BusinessOpportunity> = new EventEmitter<BusinessOpportunity>();
  @Output()
  onChangeBillable: EventEmitter<BusinessOpportunity> = new EventEmitter<BusinessOpportunity>();
  @Output()
  onChangePayable: EventEmitter<BusinessOpportunity> = new EventEmitter<BusinessOpportunity>();
  @Output()
  onShowBusinessOpportunity: EventEmitter<BusinessOpportunity> = new EventEmitter<BusinessOpportunity>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addBusinessOpportunity() {
    this.onAddBusinessOpportunity.emit();
  }

  editBusinessOpportunity(businessOpportunity: BusinessOpportunity) {
    this.onEditBusinessOpportunity.emit(businessOpportunity);
  }

  duplicateBusinessOpportunity(businessOpportunity: BusinessOpportunity) {
    this.onDuplicateBusinessOpportunity.emit(businessOpportunity);
  }

  selectBusinessOpportunity(businessOpportunity: BusinessOpportunity) {
    this.onSelectBusinessOpportunity.emit(businessOpportunity);
  }

  onFilter(filters: BusinessOpportunityFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  changeBillable(businessOpportunity: BusinessOpportunity) {
    this.onChangeBillable.emit(businessOpportunity);
  }

  changePayable(businessOpportunity: BusinessOpportunity) {
    this.onChangePayable.emit(businessOpportunity);
  }

  showBusinessOpportunity(businessOpportunity: BusinessOpportunity) {
    this.onShowBusinessOpportunity.emit(businessOpportunity);
  }
}

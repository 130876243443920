import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe){}

  transform(value: number | string, format: string = '1.2-2'): string | null {
    const preFormatted = this.decimalPipe.transform(value, format);
    if(preFormatted) {
      const point = /\./gi;
      const comma = /\,/gi;
      return preFormatted.replace(point, ' ').replace(comma, '.');
    }
    return null;
  }

}

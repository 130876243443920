<mat-card class="u-center-text" *ngIf="!bonusLines?.length">
  <h4>Nessuna riga di bonus trovata</h4>
</mat-card>
<div class="table-container" [hidden]="!bonusLines?.length">
  <table mat-table matSort [dataSource]="bonusLines">

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef> Utente </th>
      <td mat-cell *matCellDef="let row">
        {{row.user?.fullName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef> <span *ngIf="showProjectColumn">Commessa</span></th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="showProjectColumn">
          {{ row.project?.identifier }} - {{ row.project?.description }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef> <span *ngIf="showProjectColumn">Cliente</span></th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="showProjectColumn">
          {{ row.project?.client?.name }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="costLine">
      <th mat-header-cell *matHeaderCellDef><span *ngIf="showCostLineColumn">Riga di costo</span></th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="showCostLineColumn">{{row.costLine?.id }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="unlocked">
      <th mat-header-cell *matHeaderCellDef><span *ngIf="showCostLineColumn">Sbloccato</span></th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="showCostLineColumn">
          <mat-icon *ngIf="!!row.costLine">
            done_outline
          </mat-icon>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Tipo bonus </th>
      <td mat-cell *matCellDef="let row">
        {{row.type }}
      </td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> Importo </th>
      <td mat-cell *matCellDef="let row" class="no-wrap">
        {{row.amount | customCurrency}}&nbsp;€ su {{row.availableAmount | customCurrency}}&nbsp;€ ({{row.percentage |
        percent:'1.0-2'}})
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
import { createSelector } from '@ngrx/store';

import { selectBudgetState } from '../reducers';
import { BudgetState } from '../reducers/budget.reducer';

// Budget Selectors

export const getBudgetsTableState = createSelector(
  selectBudgetState,
  (state: BudgetState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getBudgets = createSelector(
  selectBudgetState,
  (state: BudgetState) => state.list
);

export const getTotalBudgets = createSelector(
  getBudgetsTableState,
  (tableState) => tableState.total
);

export const getBudgetsCurrentPage = createSelector(
  getBudgetsTableState,
  (tableState) => tableState.currentPage
);

export const getBudgetsPerPage = createSelector(
  getBudgetsTableState,
  (tableState) => tableState.perPage
);

export const getBudgetsOrder = createSelector(
  getBudgetsTableState,
  (tableState) => tableState.order
);

export const getBudgetsDirection = createSelector(
  getBudgetsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getBudgetsTableState,
  (tableState) => tableState.filters
);

export const getBudgetDialogId = createSelector(
  selectBudgetState,
  (state: BudgetState) => state.dialogId
);

export const getSelectedBudget = createSelector(
  selectBudgetState,
  (state: BudgetState) => state.budget
);
export const getSelectionDialogId = createSelector(
  selectBudgetState,
  (state: BudgetState) => state.selectionDialogId
);

import { AbstractControl } from '@angular/forms';
import { resourceLimits } from 'worker_threads';

import { Base, BaseDTO } from './base.model';


export interface RiskOwnerFilters {
  search?: string;
}

export interface RiskOwnerDTO extends BaseDTO {
  name: string;
 }
export class RiskOwner extends Base {
  name: string;

  constructor(source: RiskOwnerDTO) {
    super(source);
    if (source) {
      this.name = source.name;
    }
  }

  toDTO(): RiskOwnerDTO {
    let result: RiskOwnerDTO = <RiskOwnerDTO>super.toDTO();
    result.name = this.name;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: RiskOwner) {
    const formModel = formGroup.value;
    let riskOwner: RiskOwner = new RiskOwner(null);
    riskOwner.name = formModel.name;   
    if (original) {
      riskOwner.id = original.id;
    }
    return riskOwner;
  }

}

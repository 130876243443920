import { createAction, props } from '@ngrx/store';

import { ProjectAttachment, ProjectAttachmentDTO, ProjectAttachmentFilters } from '../../commons/models/project-attachment.model';

export const loadProjectAttachments = createAction('[ProjectAttachments] Load projectAttachments', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: ProjectAttachmentFilters, includes?: string[] }>());
export const loadProjectAttachmentsCompleted = createAction('[ProjectAttachments] Load projectAttachments Completed', props<{ projectAttachments: ProjectAttachmentDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ProjectAttachmentFilters, includes?: string[] }>());
export const loadProjectAttachmentsFailed = createAction('[ProjectAttachments] Load projectAttachments Failed', props<{ error: any }>());

export const changePage = createAction('[ProjectAttachments] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[ProjectAttachments] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[ProjectAttachments] Change filters', props<{ filters: ProjectAttachmentFilters }>());

export const editProjectAttachment = createAction('[ProjectAttachments] Edit projectAttachment', props<{ projectAttachment: ProjectAttachment}>());
export const projectAttachmentDialogOpened = createAction('[ProjectAttachments] Detail dialog opened', props<{ dialogId: string }>());
export const closeProjectAttachmentDialog = createAction('[ProjectAttachments] Close detail dialog');

export const saveProjectAttachment = createAction('[ProjectAttachments] Save projectAttachment', props<{ projectAttachment: ProjectAttachment }>());
export const saveProjectAttachmentCompleted = createAction('[ProjectAttachments] Save projectAttachment completed', props<{ projectAttachment: ProjectAttachmentDTO }>());
export const saveProjectAttachmentFailed = createAction('[ProjectAttachments] Save projectAttachment failed', props<{ error: any }>());

export const deleteProjectAttachment = createAction('[ProjectAttachments] Delete projectAttachment', props<{ projectAttachment: ProjectAttachmentDTO }>());
export const deleteProjectAttachmentCompleted = createAction('[ProjectAttachments] Delete projectAttachment completed', props<{ projectAttachment: ProjectAttachmentDTO}>());
export const deleteProjectAttachmentCancelled = createAction('[ProjectAttachments] Delete projectAttachment cancelled');
export const deleteProjectAttachmentFailed = createAction('[ProjectAttachments] Delete projectAttachment failed', props<{ error: any }>());

export const selectProjectAttachment = createAction('[ProjectAttachments] Select projectAttachment', props<{ filters?: ProjectAttachmentFilters }>());
export const selectionDialogOpened = createAction('[ProjectAttachments] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[ProjectAttachments] Close selection dialog');
export const projectAttachmentSelected = createAction('[ProjectAttachments] ProjectAttachment selected', props<{ projectAttachment: ProjectAttachmentDTO }>());

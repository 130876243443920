import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { Invoice } from '../../../commons/models/invoice.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { InvoiceFilters } from '../../../commons/models/invoice.model';

export type InvoicesColumn = "id" | "date" | "expiration" | "amount" | "client";

@Component({
  selector: 'canceled-invoice-list',
  templateUrl: './canceled-invoice-list.component.html',
  styleUrls: ['./canceled-invoice-list.component.scss'],
})
export class CanceledInvoiceListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  invoices: Observable<Invoice[]>;

  @Input()
  defaultFilters: InvoiceFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Invoice | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: InvoicesColumn[] = ["id", "date", "expiration", "amount", "client"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<InvoiceFilters> = new EventEmitter<InvoiceFilters>();

  @Output()
  onSelectInvoice: EventEmitter<Invoice> = new EventEmitter<Invoice>();
  @Output()
  onAddInvoice: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditInvoice: EventEmitter<Invoice> = new EventEmitter<Invoice>();
  @Output()
  onPaymentInvoice: EventEmitter<Invoice> = new EventEmitter<Invoice>();
  @Output()
  onCancelInvoice: EventEmitter<Invoice> = new EventEmitter<Invoice>();

  constructor() { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addInvoice() {
    this.onAddInvoice.emit();
  }

  editInvoice(invoice: Invoice) {
    this.onEditInvoice.emit(invoice);
  }

  paymentInvoice(invoice: Invoice) {
    this.onPaymentInvoice.emit(invoice);
  }

  cancelInvoice(invoice: Invoice) {
    this.onCancelInvoice.emit(invoice);
  }

  selectInvoice(invoice: Invoice) {
    this.onSelectInvoice.emit(invoice);
  }

  onFilter(filters: InvoiceFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

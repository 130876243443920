import { actionAttachmentsDialogOpened } from './../actions/action-attachment.actions';
import { showBusinessOpportunity } from './../actions/business-opportunity.actions';
import { Action, createReducer, on } from '@ngrx/store';

import { ActionAttachment, ActionAttachmentDTO, ActionAttachmentFilters } from '../../commons/models/action-attachment.model';
import * as ActionAttachmentActions from '../actions/action-attachment.actions';


export interface ActionAttachmentState {
  list: ActionAttachmentDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ActionAttachmentFilters,
  dialogId: string,
  selectionDialogId: string,
  attachmentsDialogId: string,
  actionAttachment: ActionAttachment
  actionAttachments: ActionAttachment[]
};

const initialState: ActionAttachmentState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  actionAttachment: null,
  actionAttachments: null,
  attachmentsDialogId: null
};

const actionAttachmentReducer = createReducer(
  initialState,
  on(ActionAttachmentActions.loadActionAttachmentsCompleted, (state, { actionAttachments, currentPage, total, perPage, order, direction, includes }) => {
    return { ...state, list: actionAttachments, currentPage, total, perPage, order, direction, includes };
  }),
  on(ActionAttachmentActions.actionAttachmentDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ActionAttachmentActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(ActionAttachmentActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(ActionAttachmentActions.actionAttachmentsDialogOpened, (state, { attachmentsDialogId }) => {
    return { ...state, attachmentsDialogId };
  }),
  on(ActionAttachmentActions.loadActionAttachments, (state, { filters }) => {
    return { ...state, filters };
  }),

);

export function reducer(state: ActionAttachmentState | undefined, action: Action) {
  return actionAttachmentReducer(state, action);
}


import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { RiskIdentification, RiskIdentificationFilters } from '../../../commons/models/risk-identification.model';

export type RiskIdentificationsColumn = "id" | "riskId" | "probability" | "impact" | "riskOwnerId" | "riskActioneeId" | "actions";

@Component({
  selector: 'risk-identification-list',
  templateUrl: './risk-identification-list.component.html',
  styleUrls: ['./risk-identification-list.component.scss'],
})
export class RiskIdentificationListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  riskIdentifications: Observable<RiskIdentification[]>;

  @Input()
  defaultFilters: RiskIdentificationFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: RiskIdentification | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: RiskIdentificationsColumn[] = ["id", "riskId", "probability", "impact", "riskOwnerId", "riskActioneeId", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<RiskIdentificationFilters> = new EventEmitter<RiskIdentificationFilters>();

  @Output()
  onSelectRiskIdentification: EventEmitter<RiskIdentification> = new EventEmitter<RiskIdentification>();
  @Output()
  onAddRiskIdentification: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditRiskIdentification: EventEmitter<RiskIdentification> = new EventEmitter<RiskIdentification>();

  constructor() { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addRiskIdentification() {
    this.onAddRiskIdentification.emit();
  }

  editRiskIdentification(account: RiskIdentification) {
    this.onEditRiskIdentification.emit(account);
  }

  selectRiskIdentification(account: RiskIdentification) {
    this.onSelectRiskIdentification.emit(account);
  }

  onFilter(filters: RiskIdentificationFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ContactFilters } from '../../../commons/models/contact.model';

@Component({
  selector: 'app-contact-filters',
  templateUrl: './contact-filters.component.html',
  styleUrls: ['./contact-filters.component.scss']
})
export class ContactFiltersComponent {


  @Output()
  onFilter: EventEmitter<ContactFilters> = new EventEmitter();

  private _defaultFilters: ContactFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
      client: null
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getContactFilters());
  }

  private _getContactFilters(): ContactFilters {
    let filters: ContactFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
      filters.client = values.client;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
      client: null
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): ContactFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: ContactFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
          client: this.defaultFilters.client
        })
      }
    }
  }
}

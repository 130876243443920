import { Action, createReducer, on } from '@ngrx/store';
import { BusinessOpportunity } from 'src/app/commons/models/business-opportunity.model';

import { Offer, OfferDTO, OfferFilters } from '../../commons/models/offer.model';
import * as OfferActions from '../actions/offer.actions';

export interface OfferState {
  list: OfferDTO[],
  selectionList: OfferDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: OfferFilters,
  dialogId: string,
  selectionDialogId: string,
  offer: OfferDTO,
  currentOffer: Offer,
  offerId: number,
  businessOpportunity: BusinessOpportunity,
  offerVersion: number,
  currentFormControlName: string
};

const initialState: OfferState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  offer: null,
  offerId: null,
  businessOpportunity: null,
  currentOffer: null,
  offerVersion: null,
  currentFormControlName: null,
  selectionList: null
};

const offerReducer = createReducer(
  initialState,
  on(OfferActions.loadOffersCompleted, (state, { offers, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: offers, currentPage, total, perPage, order, direction, includes, filters, businessOpportunity: null };
  }),
  on(OfferActions.loadSelectionOffersCompleted, (state, { offers, currentPage, total, perPage, order, direction, includes }) => {
    return { ...state, selectionList: offers, currentPage, total, perPage, order, direction, includes, businessOpportunity: null };
  }),
  on(OfferActions.addOffer, (state, { businessOpportunity }) => {
    return { ...state, businessOpportunity };
  }),
  on(OfferActions.loadOfferCompleted, (state, { offer }) => {
    return { ...state, offer };
  }),
  on(OfferActions.editOffer, (state, { offer }) => {
    return { ...state, currentOffer: offer };
  }),
  on(OfferActions.offerDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(OfferActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(OfferActions.changeSelectionFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(OfferActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(OfferActions.offerVersionCompleted, (state, { version }) => {
    return { ...state, offerVersion: version };
  }),
  on(OfferActions.selectOffer, (state, { filters, currentFormControlName }) => {
    return { ...state, currentPage: 1, filters, currentFormControlName };
  }),
);

export function reducer(state: OfferState | undefined, action: Action) {
  return offerReducer(state, action);
}


<!-- <h2 class="u-flex-full">
  <span>
    <mat-icon>attachment</mat-icon>Allegati
  </span>
</h2> -->
<div>
  <offer-attachment-list [offerAttachments]="offerAttachments" [defaultFilters]="defaultFilters" [total]="total"
    [canAdd]="true" [canEdit]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)" (onAddOfferAttachment)="addOfferAttachment()"
    (onEditOfferAttachment)="editOfferAttachment($event)">
  </offer-attachment-list>
</div>
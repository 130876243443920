import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { environment } from 'src/environments/environment';
import { BonusLineDTO } from '../../models/bonus_line.model';
import { CostLineDTO } from '../../models/cost-line.model';

import { ProjectDTO } from '../../models/project.model';
import { ProjectFilters } from './../../models/project.model';

@Injectable({
  providedIn: "root"
})
export class LaravelProjectService {


  constructor(private httpProject: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/projects",
      show: environment.baseUrl + "/api/project",
      store: environment.baseUrl + "/api/project",
      destroy: environment.baseUrl + "/api/project",
      bonusPayment: environment.baseUrl + "/api/project/bonus_payment",
      validateProject: environment.baseUrl + "/api/validate_project",
     
    };
  }

  public list(page: number,
    per_page: number,
    order: string,
    direction: string,
    filters: ProjectFilters,
    includes?: string[]
  ): Observable<ListResultDTO<ProjectDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (includes) params["includes[]"] = includes;
    if (filters) {
      if (filters.search) params["search"] = "" + filters.search;

      if (filters.start)
        params["start"] = formatDateForBackend(filters.start);
      if (filters.end)
        params["end"] = formatDateForBackend(filters.end);
      if (filters.clientId)
        params["client_id"] = filters.clientId;
        if (filters.creator)
        params["creator_id"] = filters.creator.id;
        if (filters.modifier)
        params["modifier_id"] = filters.modifier.id;
      if (filters.professional)
        params["professional_id"] = filters.professional.id;
        if (filters.promotion != undefined)
        params["promotion"] = filters.promotion;
        if (filters.excludeWithOffer) params["exclude_with_offer"] = true;
    }
      
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpProject.get<ListResultDTO<ProjectDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpProject.get<ProjectDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }


  public getProjectById(id: number): Observable<ProjectDTO> {
    let params = { id: "" + id };
    return this.httpProject.get<ProjectDTO>(this.ROUTES.show, {
      params: new HttpParams({
        fromObject: params
      })
    });

  }

  public upsert(project: ProjectDTO): Observable<ProjectDTO> {
    if (project.id) {
      return this.httpProject.put<ProjectDTO>(`${this.ROUTES.store}`, { project });
    } else {
      return this.httpProject.post<ProjectDTO>(`${this.ROUTES.store}`, { project });
    }
  }

  public delete(id: number): Observable<any> {
    let params = { id: "" + id };
    return this.httpProject.delete(this.ROUTES.destroy, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }

  public bonusPayment(id: number): Observable<CostLineDTO[]> {
    let params = {id: "" + id};

    return this.httpProject.get<CostLineDTO[]>(this.ROUTES.bonusPayment, {
      params: new HttpParams({
        fromObject: params
      })
    });

  }


  public validateProject( projectId: number, description: string, clientId: number): Observable<boolean> {
    let params = {};
    if (projectId) params["project_id"] = "" + projectId;
    if (description) params["description"] =  description;
    if (clientId) params["client_id"] =  clientId;
    return this.httpProject.get<boolean>(this.ROUTES.validateProject, {
      params: new HttpParams({ fromObject: params })
    });
  }

}

import { createAction, props } from '@ngrx/store';
import { Bonus, BonusDTO, BonusFilters } from 'src/app/commons/models/bonus.model';
import { BonusLineDTO, BonusLineFilters } from 'src/app/commons/models/bonus_line.model';
import { CostLineDTO } from 'src/app/commons/models/cost-line.model';

export const loadBonusLines = createAction('[BonusLines] Load bonus lines', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: BonusLineFilters, includes?: string[] }>());
export const loadBonusLinesCompleted = createAction('[BonusLines] Load bonus lines Completed', props<{ bonusLines: BonusLineDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: BonusLineFilters, includes?: string[] }>());
export const loadBonusLinesFailed = createAction('[BonusLines] Load bonus lines Failed', props<{ error: any }>());

export const changePage = createAction('[BonusLines] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[BonusLines] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[BonusLines] Change filters', props<{ filters: BonusLineFilters }>());

export const exportRecords = createAction("[BonusLines] Export records", props<{ filters?: BonusLineFilters }>());
export const exportRecordsCompleted = createAction("[BonusLines] Export records completed",props<{ blob: Blob }>());
export const exportRecordsFailed = createAction("[BonusLines] Export records failed",props<{ error: any }>());



import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DaysDistribution } from 'src/app/commons/models/days-distribution.model';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: "app-days-distribution-field",
  templateUrl: "./days-distribution-field.component.html",
  styleUrls: ["./days-distribution-field.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],

})
export class DaysDistributionFieldComponent
  implements OnInit, OnChanges, OnDestroy {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  private unsubscribe$ = new Subject<void>();

  @Input()
  form: FormArray;


  @Input()
  daysDistributions: DaysDistribution[];

  @Input()
  reset$: Observable<void>;


  constructor() {}


  ngOnInit() {
    this.reset$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.ngOnChanges(null));
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(_: SimpleChanges): void {
    if(this.form) {
      this.resetForm();
      if(this.daysDistributions) {
        this.daysDistributions.forEach(action => this.addDaysDistribution(action));
      }
    }
  }
  removeDaysDistribution(index) {
    this.form.removeAt(index);
    this.form.markAsDirty();
  }

  resetForm() {
    this.form.clear();
  }

  addDaysDistribution(daysDistribution?: DaysDistribution) {
    const group = new FormGroup({
      id: new FormControl(daysDistribution?.id),
      date: new FormControl(daysDistribution?.date, [Validators.required]),
      // offer: new FormControl(daysDistribution?.offer, [Validators.required]),
      user: new FormControl(daysDistribution?.user, [Validators.required]),
      days: new FormControl(daysDistribution?.days, [Validators.required]),

    })
    this.form.push(group);
    if(!daysDistribution){
      group.markAllAsTouched();
    }
  }

  // date = new FormControl(moment());
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>, index: number) {
    this.form.controls[index].get('date').setValue(normalizedMonthAndYear.toDate());
    datepicker.close();
  }
}





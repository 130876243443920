<mat-expansion-panel style="margin-bottom: 16px">
  <mat-expansion-panel-header> Filtra </mat-expansion-panel-header>
  <form [formGroup]="filtersForm" novalidate>
    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Data</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Inizio" formControlName="start" />
          <input matEndDate placeholder="Fine" formControlName="end" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Cliente</mat-label>
        <app-client-field formControlName="client"></app-client-field>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Professionista</mat-label>
        <app-user-field formControlName="professional"></app-user-field>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Commessa</mat-label>
        <app-project-field formControlName="project"></app-project-field>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Capo Commessa</mat-label>
        <app-user-field formControlName="head_project"></app-user-field>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Fattura cliente</mat-label>
        <input matInput type="text" formControlName="client_invoice_id">
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-label>Fattura professionista</mat-label>
        <input matInput type="text" formControlName="professional_invoice_id">
      </mat-form-field>

    </div>
    <div class="u-flex-full">

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-select matInput placeholder="Pagatotre" formControlName="payer">
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option *ngFor="let payer of payers" [value]="payer">
            {{ payer }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-select matInput placeholder="Addebito al cliente" formControlName="expenses_debit_type">
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option *ngFor="let type of expenseTypes" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="u-full-width">
        <mat-select matInput placeholder="Pagamento al professionista" formControlName="expenses_refund_type">
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option *ngFor="let type of refundTypes" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="u-flex-full">
      <mat-form-field class="u-full-width">
        <mat-label>Contabilizzata</mat-label>
        <mat-select formControlName="accounted">
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <mat-label>Fatturabile al cliente</mat-label>
        <mat-select formControlName="billable">
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>Pagabile al professionista</mat-label>
        <mat-select formControlName="payable">
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>Nulla Osta</mat-label>
        <mat-select formControlName="validated">
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="u-full-width">
        <mat-label>Promozione</mat-label>
        <mat-select formControlName="promo">
          <mat-option value="">-- Tutti --</mat-option>
          <mat-option [value]="true">Sì</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <span class="u-flex-full">
      <button class="filter" mat-raised-button [disabled]="filtersForm.pristine" color="primary"
        (click)="applyFilters()">
        Filtra
      </button>
      <button mat-icon-button *ngIf="!filtersForm.pristine" (click)="resetFilters()" matTooltip="Reset">
        <mat-icon>replay</mat-icon>
      </button>
    </span>
  </form>
</mat-expansion-panel>
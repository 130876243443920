import { Action, createReducer, on } from '@ngrx/store';

import {
  DaysDistribution,
  DaysDistributionDTO,
  DaysDistributionFilters,
} from '../../commons/models/days-distribution.model';
import * as DaysDistributionActions from '../actions/days-distribution.actions';

export interface DaysDistributionState {
  list: DaysDistributionDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: DaysDistributionFilters,
  dialogId: string,
  selectionDialogId: string,
  daysDistribution: DaysDistribution
};

const initialState: DaysDistributionState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  daysDistribution: null
};

const daysDistributionReducer = createReducer(
  initialState,
  on(DaysDistributionActions.loadDaysDistributionsCompleted, (state, { daysDistributions, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: daysDistributions, currentPage, total, perPage, order, direction, filters,includes };
  }),
  on(DaysDistributionActions.daysDistributionDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(DaysDistributionActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(DaysDistributionActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: DaysDistributionState | undefined, action: Action) {
  return daysDistributionReducer(state, action);
}


import { createAction, props } from '@ngrx/store';

import { Payment, PaymentDTO, PaymentFilters } from '../../commons/models/payment.model';

export const loadPayments = createAction('[Payments] Load Payments', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: PaymentFilters, includes?: string[] }>());
export const loadPaymentsCompleted = createAction('[Payments] Load Payments Completed', props<{ payments: PaymentDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: PaymentFilters, includes?: string[] }>());
export const loadPaymentsFailed = createAction('[Payments] Load Payments Failed', props<{ error: any }>());

export const changePage = createAction('[Payments] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Payments] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Payments] Change filters', props<{ filters: PaymentFilters }>());

export const editPayment = createAction('[Payments] Edit payment', props<{ payment: Payment }>());
export const paymentDialogOpened = createAction('[Payments] Detail dialog opened', props<{ dialogId: string }>());
export const closePaymentDialog = createAction('[Payments] Close detail dialog');

export const savePayment = createAction('[Payments] Save payment', props<{ payment: Payment }>());
export const savePaymentCompleted = createAction('[Payments] Save payment completed', props<{ payment: PaymentDTO }>());
export const savePaymentFailed = createAction('[Payments] Save payment failed', props<{ error: any }>());

export const deletePayment = createAction('[Payments] Delete payment', props<{ payment: PaymentDTO }>());
export const deletePaymentCompleted = createAction('[Payments] Delete payment completed', props<{ payment: PaymentDTO }>());
export const deletePaymentCancelled = createAction('[Payments] Delete payment cancelled');
export const deletePaymentFailed = createAction('[Payments] Delete payment failed', props<{ error: any }>());

export const selectPayment = createAction('[Payments] Select payment', props<{ filters?: PaymentFilters }>());
export const selectionDialogOpened = createAction('[Payments] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Payments] Close selection dialog');
export const paymentSelected = createAction('[Payments] Payment selected', props<{ payment: PaymentDTO }>());

import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Target } from 'src/app/commons/models/target.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as TargetActions from 'src/app/store/actions/target.actions';
import { AppState } from 'src/app/store/reducers';
import * as TargetSelectors from 'src/app/store/selectors/target.selectors';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

import { TargetFilters } from './../../../commons/models/target.model';
import { TargetsColumn } from '../target-list/target-list.component';



@Component({
  selector: 'app-target-selection',
  templateUrl: './target-selection.component.html',
  styleUrls: ['./target-selection.component.scss']
})
export class TargetSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  targets: Observable<Target[]>;
  filters: Observable<TargetFilters>;

  @Input()
  defaultFilters: TargetFilters = {
    search: "",
  }

  displayedColumns: TargetsColumn[] = ["id", "name", "actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.targets = this.store$.pipe(select(TargetSelectors.getTargets), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new Target(dto)) : null));
    this.total = this.store$.pipe(select(TargetSelectors.getTotalTargets), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(TargetSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(TargetActions.loadTargets(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(TargetActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(TargetActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: TargetFilters) {
    this.store$.dispatch(TargetActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectTarget(target: Target) {
    if (target) {
      this.store$.dispatch(TargetActions.targetSelected({ target: target.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(TargetActions.closeSelectionDialog())
  }
}

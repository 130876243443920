import { createAction, props } from '@ngrx/store';

import { RiskArea, RiskAreaDTO, RiskAreaFilters } from '../../commons/models/risk-area.model';

export const loadRiskAreas = createAction('[RiskAreas] Load RiskAreas', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: RiskAreaFilters, includes?: string[] }>());
export const loadRiskAreasCompleted = createAction('[RiskAreas] Load RiskAreas Completed', props<{ riskAreas: RiskAreaDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: RiskAreaFilters, includes?: string[] }>());
export const loadRiskAreasFailed = createAction('[RiskAreas] Load RiskAreas Failed', props<{ error: any }>());

export const changePage = createAction('[RiskAreas] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[RiskAreas] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[RiskAreas] Change filters', props<{ filters: RiskAreaFilters }>());

export const editRiskArea = createAction('[RiskAreas] Edit riskArea', props<{ riskArea: RiskArea }>());
export const riskAreaDialogOpened = createAction('[RiskAreas] Detail dialog opened', props<{ dialogId: string }>());
export const closeRiskAreaDialog = createAction('[RiskAreas] Close detail dialog');

export const saveRiskArea = createAction('[RiskAreas] Save riskArea', props<{ riskArea: RiskArea }>());
export const saveRiskAreaCompleted = createAction('[RiskAreas] Save riskArea completed', props<{ riskArea: RiskAreaDTO }>());
export const saveRiskAreaFailed = createAction('[RiskAreas] Save riskArea failed', props<{ error: any }>());

export const deleteRiskArea = createAction('[RiskAreas] Delete riskArea', props<{ riskArea: RiskAreaDTO }>());
export const deleteRiskAreaCompleted = createAction('[RiskAreas] Delete riskArea completed', props<{ riskArea: RiskAreaDTO }>());
export const deleteRiskAreaCancelled = createAction('[RiskAreas] Delete riskArea cancelled');
export const deleteRiskAreaFailed = createAction('[RiskAreas] Delete riskArea failed', props<{ error: any }>());

export const selectRiskArea = createAction('[RiskAreas] Select riskArea', props<{ filters?: RiskAreaFilters }>());
export const selectionDialogOpened = createAction('[RiskAreas] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[RiskAreas] Close selection dialog');
export const riskAreaSelected = createAction('[RiskAreas] RiskArea selected', props<{ riskArea: RiskAreaDTO }>());

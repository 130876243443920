import { AbstractControl } from '@angular/forms';
import { resourceLimits } from 'worker_threads';

import { Base, BaseDTO } from './base.model';


export interface RiskAreaFilters {
  search?: string;
}

export interface RiskAreaDTO extends BaseDTO {
  name: string;
 }
export class RiskArea extends Base {
  name: string;

  constructor(source: RiskAreaDTO) {
    super(source);
    if (source) {
      this.name = source.name;
    }
  }

  toDTO(): RiskAreaDTO {
    let result: RiskAreaDTO = <RiskAreaDTO>super.toDTO();
    result.name = this.name;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: RiskArea) {
    const formModel = formGroup.value;
    let riskArea: RiskArea = new RiskArea(null);
    riskArea.name = formModel.name;   
    if (original) {
      riskArea.id = original.id;
    }
    return riskArea;
  }

}

import { createSelector } from '@ngrx/store';

import { selectRiskCategoryState } from '../reducers';
import { RiskCategoryState } from '../reducers/risk-category.reducer';

// RiskCategory Selectors

export const getRiskCategorysTableState = createSelector(
  selectRiskCategoryState,
  (state: RiskCategoryState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getRiskCategorys = createSelector(
  selectRiskCategoryState,
  (state: RiskCategoryState) => state.list
);

export const getTotalRiskCategorys = createSelector(
  getRiskCategorysTableState,
  (tableState) => tableState.total
);

export const getRiskCategorysCurrentPage = createSelector(
  getRiskCategorysTableState,
  (tableState) => tableState.currentPage
);

export const getRiskCategorysPerPage = createSelector(
  getRiskCategorysTableState,
  (tableState) => tableState.perPage
);

export const getRiskCategorysOrder = createSelector(
  getRiskCategorysTableState,
  (tableState) => tableState.order
);

export const getRiskCategorysDirection = createSelector(
  getRiskCategorysTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getRiskCategorysTableState,
  (tableState) => tableState.filters
);

export const getRiskCategoryDialogId = createSelector(
  selectRiskCategoryState,
  (state: RiskCategoryState) => state.dialogId
);

export const getSelectedRiskCategory = createSelector(
  selectRiskCategoryState,
  (state: RiskCategoryState) => state.riskCategory
);
export const getSelectionDialogId = createSelector(
  selectRiskCategoryState,
  (state: RiskCategoryState) => state.selectionDialogId
);

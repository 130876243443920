import { AbstractControl } from '@angular/forms';
import { Base, BaseDTO } from './base.model';
import { Currency, CurrencyDTO } from './currency.model';
import { Project, ProjectDTO } from './project.model';
import { User, UserDTO } from './user.model';

export interface SalesBonusFilters {
    user_id?: number;
    project_id?: number;
}

export interface SalesBonusDTO extends BaseDTO {
    user_id: number;
    project_id: number;
    user: UserDTO;
    project: ProjectDTO;
    percentage: number;
}

export class SalesBonus extends Base {

    user: User;
    project: Project;
    percentage: number;

    constructor(source: SalesBonusDTO) {
        super(source);
        if (source) {
            if (source.user) {
                this.user = new User(source.user);
                this.addLoadedRelation('user');
            }
            if (source.project) {
                this.project = new Project(source.project);
            }
            this.percentage = source.percentage;
        }
    }

    toDTO(): SalesBonusDTO {
        let result: SalesBonusDTO = <SalesBonusDTO>super.toDTO();
        result.user = this.user ? this.user.toDTO() : null;
        result.user_id = this.user ? this.user.id : null;
        result.percentage = this.percentage;
        result.project = this.project ? this.project.toDTO() : null;
        result.project_id = this.project ? this.project.id : null;

        return result;
    }

    static fromFormGroup(formGroup: AbstractControl, original?: SalesBonus) {
        const formModel = formGroup.value;
        let salesBonus: SalesBonus = new SalesBonus(null);
        salesBonus.user = formModel.salesBonusUser;
        salesBonus.percentage = formModel.percentage;
        salesBonus.project = formModel.project;

        if (original) {
            salesBonus.id = original.id;
        }
        return salesBonus;
    }

}

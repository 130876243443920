import { Action, createReducer, on } from '@ngrx/store';

import { Payment, PaymentDTO, PaymentFilters } from '../../commons/models/payment.model';
import * as PaymentActions from '../actions/payment.actions';

export interface PaymentState {
  list: PaymentDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: PaymentFilters,
  dialogId: string,
  selectionDialogId: string,
  payment: Payment
};

const initialState: PaymentState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  payment: null
};

const paymentReducer = createReducer(
  initialState,
  on(PaymentActions.loadPaymentsCompleted, (state, { payments, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: payments, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(PaymentActions.paymentDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(PaymentActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(PaymentActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: PaymentState | undefined, action: Action) {
  return paymentReducer(state, action);
}


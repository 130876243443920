<header mat-dialog-title class="u-flex-full">
  <span>
    Seleziona area
  </span>
</header>
<mat-dialog-content>
  <risk-area-list [riskAreas]="riskAreas" [defaultFilters]="defaultFilters" [displayedColumns]="displayedColumns" [total]="total"
    [canSelect]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)" (onSelectRiskArea)="selectRiskArea($event)"></risk-area-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>

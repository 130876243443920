import { createAction, props } from '@ngrx/store';

import { RiskCategory, RiskCategoryDTO, RiskCategoryFilters } from '../../commons/models/risk-category.model';

export const loadRiskCategories = createAction('[RiskCategories] Load RiskCategories', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: RiskCategoryFilters, includes?: string[] }>());
export const loadRiskCategoriesCompleted = createAction('[RiskCategories] Load RiskCategories Completed', props<{ riskCategories: RiskCategoryDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: RiskCategoryFilters, includes?: string[] }>());
export const loadRiskCategoriesFailed = createAction('[RiskCategories] Load RiskCategories Failed', props<{ error: any }>());

export const changePage = createAction('[RiskCategories] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[RiskCategories] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[RiskCategories] Change filters', props<{ filters: RiskCategoryFilters }>());

export const editRiskCategory = createAction('[RiskCategories] Edit riskCategory', props<{ riskCategory: RiskCategory }>());
export const riskCategoryDialogOpened = createAction('[RiskCategories] Detail dialog opened', props<{ dialogId: string }>());
export const closeRiskCategoryDialog = createAction('[RiskCategories] Close detail dialog');

export const saveRiskCategory = createAction('[RiskCategories] Save riskCategory', props<{ riskCategory: RiskCategory }>());
export const saveRiskCategoryCompleted = createAction('[RiskCategories] Save riskCategory completed', props<{ riskCategory: RiskCategoryDTO }>());
export const saveRiskCategoryFailed = createAction('[RiskCategories] Save riskCategory failed', props<{ error: any }>());

export const deleteRiskCategory = createAction('[RiskCategories] Delete riskCategory', props<{ riskCategory: RiskCategoryDTO }>());
export const deleteRiskCategoryCompleted = createAction('[RiskCategories] Delete riskCategory completed', props<{ riskCategory: RiskCategoryDTO }>());
export const deleteRiskCategoryCancelled = createAction('[RiskCategories] Delete riskCategory cancelled');
export const deleteRiskCategoryFailed = createAction('[RiskCategories] Delete riskCategory failed', props<{ error: any }>());

export const selectRiskCategory = createAction('[RiskCategories] Select riskCategory', props<{ filters?: RiskCategoryFilters }>());
export const selectionDialogOpened = createAction('[RiskCategories] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[RiskCategories] Close selection dialog');
export const riskCategorySelected = createAction('[RiskCategories] RiskCategory selected', props<{ riskCategory: RiskCategoryDTO }>());

import { createSelector } from '@ngrx/store';

import { selectProjectAttachmentState } from '../reducers';
import { ProjectAttachmentState } from '../reducers/project-attachment.reducer';

// ProjectAttachment Selectors

export const getProjectAttachmentsTableState = createSelector(
  selectProjectAttachmentState,
  (state: ProjectAttachmentState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getProjectAttachments = createSelector(
  selectProjectAttachmentState,
  (state: ProjectAttachmentState) => state.list
);

export const getTotalProjectAttachments = createSelector(
  getProjectAttachmentsTableState,
  (tableState) => tableState.total
);

export const getProjectAttachmentsCurrentPage = createSelector(
  getProjectAttachmentsTableState,
  (tableState) => tableState.currentPage
);

export const getProjectAttachmentsPerPage = createSelector(
  getProjectAttachmentsTableState,
  (tableState) => tableState.perPage
);

export const getProjectAttachmentsOrder = createSelector(
  getProjectAttachmentsTableState,
  (tableState) => tableState.order
);

export const getProjectAttachmentsDirection = createSelector(
  getProjectAttachmentsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getProjectAttachmentsTableState,
  (tableState) => tableState.filters
);

export const getProjectAttachmentDialogId = createSelector(
  selectProjectAttachmentState,
  (state: ProjectAttachmentState) => state.dialogId
);

export const getSelectedProjectAttachment = createSelector(
  selectProjectAttachmentState,
  (state: ProjectAttachmentState) => state.projectAttachment
);
export const getSelectionDialogId = createSelector(
  selectProjectAttachmentState,
  (state: ProjectAttachmentState) => state.selectionDialogId
);

<router-outlet></router-outlet>
<ngx-ui-loader [fgsTemplate]="foregroundSpinner"></ngx-ui-loader>

<ng-template #foregroundSpinner>
  <div class="la-pacman la-2x">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>
import {AbstractControl} from '@angular/forms';
import {formatDateForBackend} from 'src/app/helpers/time.utils';

import {Activity, ActivityDTO} from './activity.model';
import {Base, BaseDTO} from './base.model';
import {Expense, ExpenseDTO} from './expense.model';
import {Invoice, InvoiceDTO} from './invoice.model';
import {Project, ProjectDTO} from './project.model';

export interface BillingLineFilters {
  start?: Date;
  end?: Date;
  client_id?: number;
  project_id?: number;
  billed?: boolean;
  paid?: boolean;
  search?: string;
  promo?: boolean;
  type?: BillingLineType;
}

export type BillingLineType = 'Attività' | 'Spesa';
export const BILLING_LINE_TYPES: BillingLineType[] = ['Attività', 'Spesa'];

export interface BillingLineDTO extends BaseDTO {
  date: string;
  description: string;
  amount: number;
  invoice_id?: number;
  type?: BillingLineType;
  activities?: ActivityDTO[];
  expenses?: ExpenseDTO[];
  project?: ProjectDTO;
  invoice?: InvoiceDTO;
}

export class BillingLine extends Base {
  date: Date;
  description: string;
  amount: number;
  selected: boolean;
  type: BillingLineType;
  invoiceId?: number;

  activities?: Activity[];
  expenses?: Expense[];
  project?: Project;
  invoice?: Invoice;

  constructor(source: BillingLineDTO) {
    super(source);
    if (source) {
      this.date = new Date(source.date);
      this.description = source.description;
      this.amount = source.amount;
      this.selected = false;
      this.invoiceId = source.invoice_id;

      if (source.activities?.length > 0) {
        this.activities = source.activities.map((dto) => new Activity(dto));
      }
      if (source.expenses?.length > 0) {
        this.expenses = source.expenses.map((dto) => new Expense(dto));
      }
      this.type = source.type;
      if (source.project) {
        this.project = new Project(source.project);
      }
      if (source.invoice) {
        this.invoice = new Invoice(source.invoice);
      }
    }
  }

  toDTO(): BillingLineDTO {
    let result: BillingLineDTO = <BillingLineDTO>super.toDTO();
    result.date = formatDateForBackend(this.date);
    result.description = this.description;
    result.amount = this.amount;
    result.invoice_id = this.invoiceId;
    if (this.invoice) {
      result.invoice = this.invoice.toDTO();
      result.invoice_id = this.invoice.id;
    }
    result.type = this.type;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: BillingLine) {
    const formModel = formGroup.getRawValue();
    let billingLine: BillingLine = new BillingLine(null);
    billingLine.date = formModel.date;
    billingLine.description = formModel.description;
    billingLine.amount = formModel.amount;
    billingLine.id = formModel.id;

    if (original) {
      billingLine.id = original.id;
    }
    return billingLine;
  }

  setSelected() {
    this.selected = !this.selected;
  }

  get invoiced(): boolean {
    return !!this.invoice;
  }

  get hours(): number {
    return this.activities?.map(a => a.hours).reduce((sum, hours) => sum + hours, 0) ?? 0;
  }
}

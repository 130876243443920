<header mat-dialog-title class="u-flex-full">
  <span>
    <span *ngIf="ivaTypology">Modifica regime IVA: {{ivaTypology.code}}</span>
    <span *ngIf="!ivaTypology">Nuovo regime IVA</span>
  </span>
  <span *ngIf="ivaTypology">
    <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
      <button mat-menu-item (click)="deleteIvaTypology()">
        <mat-icon>delete</mat-icon>
        Elimina
      </button>
    </mat-menu>
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="ivaTypologyForm" novalidate>
    <!-- <div class="u-flex-full"> -->
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Codice" formControlName="code" type="text" >
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Percentuale" formControlName="percentage" type="number" min="0" max="100"  forceNumber>
        <mat-error>Percentuale non valida</mat-error>
      </mat-form-field>
      <mat-form-field class="u-full-width">
        <input matInput placeholder="Natura" formControlName="nature" type="text">
      </mat-form-field>
    <!-- </div> -->
  </form>
  <!-- {{ivaTypologyForm.value | json}} -->

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="ivaTypologyForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="save()"
      [disabled]="ivaTypologyForm.pristine || !ivaTypologyForm.valid">Salva</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>

import { createAction, props } from '@ngrx/store';
import { BillingLine, BillingLineDTO } from 'src/app/commons/models/billing-line.model';

import { Invoice, InvoiceDTO, InvoiceFilters } from '../../commons/models/invoice.model';

export const loadInvoice = createAction('[Invoices] Load invoice', props<{ invoice_id: number, includes?: string[] }>());
export const loadInvoiceCompleted = createAction('[Invoices] Load invoice Completed', props<{ invoice: InvoiceDTO }>());
export const loadInvoiceFailed = createAction('[Invoices] Load invoice Failed', props<{ error: any }>());

export const loadInvoices = createAction('[Invoices] Load invoices', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: InvoiceFilters, includes?: string[] }>());
export const loadInvoicesCompleted = createAction('[Invoices] Load invoices Completed', props<{ invoices: InvoiceDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: InvoiceFilters, includes?: string[] }>());
export const loadInvoicesFailed = createAction('[Invoices] Load invoices Failed', props<{ error: any }>());

export const canceledInvoices = createAction('[Invoices] Display canceled invoices', props<{ billingLine: BillingLine }>());

export const changePage = createAction('[Invoices] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Invoices] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Invoices] Change filters', props<{ filters: InvoiceFilters }>());

export const editInvoice = createAction('[Invoices] Edit invoice', props<{ invoice: Invoice }>());
export const editPaymentInvoice = createAction('[Invoices] Edit payment invoice', props<{ invoice: Invoice }>());
export const editCancelInvoice = createAction('[Invoices] Edit cancel invoice', props<{ invoice: Invoice }>());

export const invoiceDialogOpened = createAction('[Invoices] Detail dialog opened', props<{ dialogId: string }>());
export const closeInvoiceDialog = createAction('[Invoices] Close detail dialog');

export const saveInvoice = createAction('[Invoices] Save invoice', props<{ invoice: Invoice }>());
export const saveInvoiceCompleted = createAction('[Invoices] Save invoice completed', props<{ invoice: InvoiceDTO }>());
export const saveInvoiceFailed = createAction('[Invoices] Save invoice failed', props<{ error: any }>());

export const paymentInvoice = createAction('[Invoices] Payment invoice', props<{ invoice: Invoice }>());
export const paymentInvoiceCompleted = createAction('[Invoices] Payment invoice completed', props<{ invoice: InvoiceDTO }>());
export const paymentInvoiceFailed = createAction('[Invoices] Payment invoice failed', props<{ error: any }>());

export const cancelInvoice = createAction('[Invoices] Cancel invoice', props<{ invoice: Invoice }>());
export const cancelInvoiceCompleted = createAction('[Invoices] Cancel invoice completed', props<{ invoice: InvoiceDTO }>());
export const cancelInvoiceFailed = createAction('[Invoices] Cancel invoice failed', props<{ error: any }>());

export const generateInvoice = createAction('[Invoices] Generate invoice', props<{ invoice: InvoiceDTO }>());
export const generateInvoiceCancelled = createAction('[Invoices] Cancelled generate invoice');
export const generateInvoiceCompleted = createAction('[Invoices] Generate invoice completed', props<{ invoice: InvoiceDTO }>());
export const generateInvoiceFailed = createAction('[Invoices] Generate invoice failed', props<{ error: any }>());

export const cancelPaymentInvoice = createAction('[Invoices] Cancel payment invoice', props<{ invoice: Invoice }>());
export const cancelPaymentInvoiceCompleted = createAction('[Invoices] Cancel payment invoice completed', props<{ invoice: InvoiceDTO }>());
export const cancelPaymentInvoiceFailed = createAction('[Invoices] Cancel payment invoice failed', props<{ error: any }>());

export const deleteInvoice = createAction('[Invoices] Delete invoice', props<{ invoice: InvoiceDTO }>());
export const deleteInvoiceCompleted = createAction('[Invoices] Delete invoice completed', props<{ invoice: InvoiceDTO }>());
export const deleteInvoiceCancelled = createAction('[Invoices] Delete invoice cancelled');
export const deleteInvoiceFailed = createAction('[Invoices] Delete invoice failed', props<{ error: any }>());

export const selectInvoice = createAction('[Invoices] Select invoice', props<{ filters?: InvoiceFilters }>());
export const selectionDialogOpened = createAction('[Invoices] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Invoices] Close selection dialog');
export const invoiceSelected = createAction('[Invoices] Invoice selected', props<{ invoice: InvoiceDTO }>());

export const confirmNewInvoice = createAction('[Invoices] Confirm invoice generation');
export const newInvoice = createAction('[Invoices] New invoice', props<{ group: string, date?: Date }>());
export const newInvoiceCompleted = createAction('[Invoices] New invoice completed', props<{ invoices: InvoiceDTO[] }>());
export const newInvoiceFailed = createAction('[Invoices] New invoice failed', props<{ error: any }>());

export const invoicesExport = createAction('[Invoices] Export');
export const invoicesExportCompleted = createAction('[Invoices] Export Completed', props<{ data: string, type?: string }>());
export const invoicesExportFailed = createAction('[Invoices] Export Failed', props<{ error: any }>());

export const detachBillingLine = createAction('[Invoices] Detach billing line', props<{ billingLine: BillingLine }>());
export const detachBillingLineCompleted = createAction('[Invoices] Detach billing line completed', props<{ billingLine: BillingLineDTO }>());
export const detachBillingLineFailed = createAction('[Invoices] Detach billing lineinvoice failed', props<{ error: any }>());

export const generatePdfActivities = createAction('[Invoices] generate pdf activities', props<{ invoice: Invoice }>())
export const generatePdfExpenses = createAction('[Invoices] generate pdf expenses', props<{ invoice: Invoice }>())

import { createSelector } from '@ngrx/store';
import { selectBonusLineState } from '../reducers';
import { BonusLineState } from '../reducers/bonus-line.reducer';


import { BonusState } from '../reducers/bonus.reducer';

// BonusLine Selectors

export const getBonusLinesTableState = createSelector(
  selectBonusLineState,
  (state: BonusLineState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getBonusLines = createSelector(
  selectBonusLineState,
  (state: BonusLineState) => state.list
);
export const getTotalBonusLines = createSelector(
  getBonusLinesTableState,
  (tableState) => tableState.total
);
export const getBonusLinesCurrentPage = createSelector(
  getBonusLinesTableState,
  (tableState) => tableState.currentPage
);
export const getBonusLinesPerPage = createSelector(
  getBonusLinesTableState,
  (tableState) => tableState.perPage
);
export const getBonusLinesOrder = createSelector(
  getBonusLinesTableState,
  (tableState) => tableState.order
);

export const getBonusLinesDirection = createSelector(
  getBonusLinesTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getBonusLinesTableState,
  (tableState) => tableState.filters
);






import { createAction, props } from '@ngrx/store';
import { Action } from 'src/app/commons/models/action.model';

import { ActionAttachment, ActionAttachmentDTO, ActionAttachmentFilters } from '../../commons/models/action-attachment.model';

export const loadActionAttachments = createAction('[ActionAttachments] Load actionAttachments', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: ActionAttachmentFilters, includes?: string[] }>());
export const loadActionAttachmentsCompleted = createAction('[ActionAttachments] Load actionAttachments Completed', props<{ actionAttachments: ActionAttachmentDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ActionAttachmentFilters, includes?: string[] }>());
export const loadActionAttachmentsFailed = createAction('[ActionAttachments] Load actionAttachments Failed', props<{ error: any }>());

export const changePage = createAction('[ActionAttachments] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[ActionAttachments] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[ActionAttachments] Change filters', props<{ filters: ActionAttachmentFilters }>());

export const editActionAttachment = createAction('[ActionAttachments] Edit actionAttachment', props<{ actionAttachment: ActionAttachment, actionId?: number}>());
export const actionAttachmentDialogOpened = createAction('[ActionAttachments] Detail dialog opened', props<{ dialogId: string }>());
export const closeActionAttachmentDialog = createAction('[ActionAttachments] Close detail dialog');

export const openActionAttachment = createAction('[ActionAttachments] Open actionAttachment', props<{actionId?: number}>());
export const actionAttachmentsDialogOpened = createAction('[ActionAttachments] Attachments dialog opened', props<{ attachmentsDialogId: string }>());
export const closeActionAttachmentsDialog = createAction('[ActionAttachments] Close attachments dialog');

export const saveActionAttachment = createAction('[ActionAttachments] Save actionAttachment', props<{ actionAttachment: ActionAttachment }>());
export const saveActionAttachmentCompleted = createAction('[ActionAttachments] Save actionAttachment completed', props<{ actionAttachment: ActionAttachmentDTO }>());
export const saveActionAttachmentFailed = createAction('[ActionAttachments] Save actionAttachment failed', props<{ error: any }>());

export const deleteActionAttachment = createAction('[ActionAttachments] Delete actionAttachment', props<{ actionAttachment: ActionAttachmentDTO }>());
export const deleteActionAttachmentCompleted = createAction('[ActionAttachments] Delete actionAttachment completed', props<{ actionAttachment: ActionAttachmentDTO }>());
export const deleteActionAttachmentCancelled = createAction('[ActionAttachments] Delete actionAttachment cancelled');
export const deleteActionAttachmentFailed = createAction('[ActionAttachments] Delete actionAttachment failed', props<{ error: any }>());

export const selectActionAttachment = createAction('[ActionAttachments] Select actionAttachment', props<{ filters?: ActionAttachmentFilters }>());
export const selectionDialogOpened = createAction('[ActionAttachments] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[ActionAttachments] Close selection dialog');
export const actionAttachmentSelected = createAction('[ActionAttachments] ActionAttachment selected', props<{ actionAttachment: ActionAttachmentDTO }>());

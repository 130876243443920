import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Contact } from 'src/app/commons/models/contact.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as ContactActions from 'src/app/store/actions/contact.actions';
import { AppState } from 'src/app/store/reducers';
import * as ContactSelectors from 'src/app/store/selectors/contact.selectors';

import { ContactsColumn } from '../contact-list/contact-list.component';
import { ContactFilters } from './../../../commons/models/contact.model';

@Component({
  selector: 'app-contact-selection',
  templateUrl: './contact-selection.component.html',
  styleUrls: ['./contact-selection.component.scss'],
})
export class ContactSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  contacts: Observable<Contact[]>;
  filters: Observable<ContactFilters>;

  @Input()
  defaultFilters: ContactFilters = {
    search: '',
  };
  formControlName: string;

  displayedColumns: ContactsColumn[] = [
    'lastname',
    'name',
    'email',
    'phone',
    'mobile',
    'fax',
    'note',
    'actions',
  ];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  canAdd: boolean;

  total: Observable<number>;
  constructor(
    private store$: Store<AppState>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.contacts = this.store$.pipe(
      select(ContactSelectors.getContacts),
      takeUntil(this.unsubscribe$),
      map((dtos) => (dtos ? dtos.map((dto) => new Contact(dto)) : null))
    );
    this.total = this.store$.pipe(
      select(ContactSelectors.getTotalContacts),
      takeUntil(this.unsubscribe$)
    );
    this.filters = this.store$.pipe(
      select(ContactSelectors.getFilters),
      takeUntil(this.unsubscribe$)
    );
    if (data) {
      this.defaultFilters = data.defaultFilters;
      this.formControlName = data.formControlName;
      this.canAdd = data.canAdd;
    }
  }

  load() {
    this.store$.dispatch(
      ContactActions.loadContacts({
        page: 1,
        perPage: this.pageSizeOptions[0],
        order: 'lastname',
        direction: 'asc',
        filters: this.defaultFilters,
      })
    );
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(
      ContactActions.changeSort({
        order: sort.active,
        direction: sort.direction,
      })
    );
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(
      ContactActions.changePage({
        page: pageEvent.pageIndex + 1,
        size: pageEvent.pageSize,
      })
    );
  }

  filtersChange(filters: ContactFilters) {
    this.store$.dispatch(ContactActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectContact(contact: Contact) {
    if (contact) {
      this.store$.dispatch(
        ContactActions.contactSelected({
          contact: contact.toDTO(),
          formControlName: this.formControlName,
        })
      );
    }
  }

  addContact() {
    this.editContact();
  }

  editContact(contact?: Contact) {
    this.store$.dispatch(ContactActions.editContact({ contact }));
  }

  close() {
    this.store$.dispatch(ContactActions.closeSelectionDialog());
  }
}

import { Action, createReducer, on } from '@ngrx/store';

import { RiskCategory, RiskCategoryDTO } from '../../commons/models/risk-category.model';
import * as RiskCategoryActions from '../actions/risk-category.actions';
import { RiskCategoryFilters } from './../../commons/models/risk-category.model';


export interface RiskCategoryState {
  list: RiskCategoryDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: RiskCategoryFilters,
  dialogId: string,
  selectionDialogId: string,
  riskCategory: RiskCategory
};

const initialState: RiskCategoryState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  riskCategory: null
};

const riskCategoryReducer = createReducer(
  initialState,
  on(RiskCategoryActions.loadRiskCategoriesCompleted, (state, { riskCategories, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: riskCategories, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(RiskCategoryActions.riskCategoryDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(RiskCategoryActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(RiskCategoryActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: RiskCategoryState | undefined, action: Action) {
  return riskCategoryReducer(state, action);
}


import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Expense, ExpenseFilters } from 'src/app/commons/models/expense.model';
import { Project } from 'src/app/commons/models/project.model';
import { User, UserDTO } from 'src/app/commons/models/user.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as ExpenseActions from 'src/app/store/actions/expense.actions';
import { AppState } from 'src/app/store/reducers';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';
import * as ExpenseSelectors from 'src/app/store/selectors/expense.selectors';

export class DailyExpensesFilters {
  month?: Date
  client_id?: number
  project_id?: number
  head_project_id?: number
  professional?: UserDTO

  constructor() {
    this.month = new Date()
  }

  toExpenseFilter(): ExpenseFilters {
    const expenseFilters: ExpenseFilters = { }
    if (this.month) {
      expenseFilters.start = new Date(this.month.getFullYear(), this.month.getMonth(), 1)
      expenseFilters.end = new Date(this.month.getFullYear(), this.month.getMonth() + 1, 0)
    } else {
      const now = new Date()
      expenseFilters.start = new Date(now.getFullYear(), now.getMonth(), 1)
      expenseFilters.end = new Date(now.getFullYear(), now.getMonth() + 1, 0)
    }
    expenseFilters.client_id = this.client_id
    expenseFilters.project_id = this.project_id
    expenseFilters.head_project_id = this.head_project_id
    expenseFilters.professional = this.professional
    return expenseFilters
  }

  fromExpenseFilter(expenseFilters: ExpenseFilters): DailyExpensesFilters {
    const dailyExpenseFilters: DailyExpensesFilters = new DailyExpensesFilters()
    dailyExpenseFilters.month = new Date(expenseFilters.start.getFullYear(), expenseFilters.start.getMonth())
    dailyExpenseFilters.client_id = expenseFilters.client_id
    dailyExpenseFilters.head_project_id = expenseFilters.head_project_id
    dailyExpenseFilters.professional = expenseFilters.professional
    dailyExpenseFilters.project_id = expenseFilters.project_id
    return dailyExpenseFilters
  }
}

export class DailyExpense {
  date: Date
  project: Project
  ownCarAmount: number = 0
  carRentAmount: number = 0
  fuelAmount: number = 0
  tollAmount: number = 0
  flightAmount: number = 0
  trainAmount: number = 0
  hotelAmount: number = 0
  restaurantAmount: number = 0
  taxiAmount: number = 0
  parkingAmount: number = 0
  phoneAmount: number = 0
  miscellaneousAmount: number = 0

  constructor(date: Date, project: Project) {
    this.date = date
    this.project = project
  }

  sumExpense(expense: Expense) {
    switch (expense.type) {
      case 'Own Car': {
        this.ownCarAmount += expense.amount
        break;
      }
      case 'Car Rent': {
        this.carRentAmount += expense.amount
        break;
      }
      case 'Fuel': {
        this.fuelAmount += expense.amount
        break;
      }
      case 'Toll': {
        this.tollAmount += expense.amount
        break;
      }
      case 'Flight': {
        this.flightAmount += expense.amount
        break;
      }
      case 'Train': {
        this.trainAmount += expense.amount
        break;
      }
      case 'Hotel': {
        this.hotelAmount += expense.amount
        break;
      }
      case 'Restaurant': {
        this.restaurantAmount += expense.amount
        break;
      }
      case 'Taxi': {
        this.taxiAmount += expense.amount
        break;
      }
      case 'Parking': {
        this.parkingAmount += expense.amount
        break;
      }
      case 'Phone': {
        this.phoneAmount += expense.amount
        break;
      }
      case 'Miscellaneous': {
        this.miscellaneousAmount += expense.amount
        break;
      }
    }
  }

  get total(): number {
    return this.ownCarAmount + this.carRentAmount + this.fuelAmount + this.tollAmount + this.flightAmount + this.trainAmount + this.hotelAmount + this.restaurantAmount + this.taxiAmount + this.parkingAmount + this.phoneAmount + this.miscellaneousAmount
  }
}

@Component({
  selector: 'app-daily-expenses',
  templateUrl: './daily-expenses.component.html',
  styleUrls: ['./daily-expenses.component.scss']
})
export class DailyExpensesComponent {
  private unsubscribe$ = new Subject<void>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dailyExpenses: Observable<DailyExpense[]>;
  filters: Observable<DailyExpensesFilters>;

  defaultFilters: DailyExpensesFilters = new DailyExpensesFilters()

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  private _includes: string[] = [
    //" professional",
    //"billing_line",
    //"cost_line",
    //"validation_user"
  ];

  currentUser: User;

  constructor(private store$: Store<AppState>) {
    this.store$.pipe(select(AuthSelectors.getCurrentUser), takeUntil(this.unsubscribe$), map(dto => dto ? new User(dto) : null)).subscribe(user =>this.currentUser = user);
    this.store$.dispatch(ExpenseActions.resetSelectedExpenses())
    this.dailyExpenses = this.store$.pipe(select(ExpenseSelectors.getDailyExpenses), takeUntil(this.unsubscribe$))
    this.total = this.store$.pipe(select(ExpenseSelectors.getDailyExpenses), takeUntil(this.unsubscribe$), map(dailyExpenses => dailyExpenses ? dailyExpenses.length : 0));
    this.filters = this.store$.pipe(select(ExpenseSelectors.getFilters), takeUntil(this.unsubscribe$), map(expenseFilters => new DailyExpensesFilters().fromExpenseFilter(expenseFilters)));

    this.load();
  }

  load() {
    const expenseFilters = this.defaultFilters.toExpenseFilter()
    this.store$.dispatch(ExpenseActions.loadDailyExpenses(
      { page: 1, perPage: 9999999, filters: expenseFilters, includes: this._includes }))
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }


  // sortChange(sort: Sort) {
  //   this.store$.dispatch(ExpenseActions.changeSortByDailyExpenses({ order: sort.active, direction: sort.direction }));
  // }

  /*
  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(ExpenseActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }
  */

  filtersChange(filters: DailyExpensesFilters) {
    const expenseFilters = filters.toExpenseFilter()
    this.store$.dispatch(ExpenseActions.loadDailyExpenses(
      { page: 1, perPage: 9999999, filters: expenseFilters, includes: this._includes }));
  }

}

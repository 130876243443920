import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Component, forwardRef, HostBinding, Input, Optional, Self} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NgControl, Validators} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {filter, map, takeUntil, withLatestFrom} from 'rxjs/operators';
import {Risk, RiskFilters} from 'src/app/commons/models/risk.model';
import * as RiskActions from 'src/app/store/actions/risk.actions';
import {AppState} from 'src/app/store/reducers';
import * as RiskSelectors from 'src/app/store/selectors/risk.selectors';


export const RISK_FIELD_MAT_FORM_FIELD_CONTROL: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => RiskFieldComponent),
};

@Component({
  selector: "app-risk-field",
  templateUrl: "./risk-field.component.html",
  styleUrls: ["./risk-field.component.scss"],
  providers: [RISK_FIELD_MAT_FORM_FIELD_CONTROL],
  host: {
    "[class.floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class RiskFieldComponent
  implements ControlValueAccessor, MatFormFieldControl<Risk> {
  private unsubscribe$ = new Subject<void>();
  private _value: Risk;
  private _disabled = false;

  private _required = false;

  get errorState(): boolean {
    return !this.ngControl.valid;
  }
  focused: boolean; //TODO
  controlType?: string = "risk-selection-field";
  autofilled?: boolean;
  riskAriaDescribedBy?: string;
  currentFormControlName: string;

  @Input()
  riskFilters: RiskFilters;

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.actions$
      .pipe(
        ofType(RiskActions.riskSelected),
        takeUntil(this.unsubscribe$),
        withLatestFrom(this.store$.select(RiskSelectors.getCurrentFormControlName)),
        filter(([_, currentFormControlName]) => currentFormControlName == this.currentFormControlName),
        map(([{risk}, _]) => (risk ? new Risk(risk) : null))
      )
      .subscribe((risk) => (this.value = risk));
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  stateChanges = new Subject<void>();
  id: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return this._required || this.ngControl?.control?.hasValidator(Validators.required) || false;
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    //TODO
  }
  onContainerClick(event: MouseEvent): void {
    if (!this.disabled) {
      if ((event.target as Element).tagName.toLowerCase() != "mat-icon") {
        this.selectRisk();
      }
    }
  }

  ngAfterContentInit(): void {
    const control = this.ngControl && this.ngControl.control;
    if (control) {
      this.currentFormControlName = `${this.getControlName(control)}_${Math.random()}`;
    }
  }

  private getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stateChanges.complete();
  }

  selectRisk() {
    this.store$.dispatch(RiskActions.selectRisk({filters: this.riskFilters, currentFormControlName: this.currentFormControlName}));
  }

  clearRisk() {
    this.value = null;
  }

  set value(value: Risk) {
    this._value = value;
    this.notifyValueChange();
    this.stateChanges.next();
  }

  get value(): Risk {
    return this._value;
  }

  onChange: (value) => {};
  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: Risk): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

export function toFormData<T>(formValue: T, put = false) {
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
        const value = formValue[key];
        if (value) {
            formData.append(key, value);
        }
    }

    if (put) {
        formData.append('_method', 'PUT');
    }

    return formData;
}
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ProjectComment, ProjectCommentFilters } from '../../../commons/models/project-comment.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type ActivitiesColumn = "id" | "comment" | "attachment" | "actions";

@Component({
  selector: 'project-comment-list',
  templateUrl: './project-comment-list.component.html',
  styleUrls: ['./project-comment-list.component.scss'],
})
export class ProjectCommentListComponent {

  @Input()
  projectComments: ProjectComment[];

  @Input()
  defaultFilters: ProjectCommentFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: ProjectComment | null;
  baseUrl = environment.baseUrl;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ActivitiesColumn[] = ["id", "comment", "attachment", "actions"];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ProjectCommentFilters> = new EventEmitter<ProjectCommentFilters>();

  @Output()
  onSelectProjectComment: EventEmitter<ProjectComment> = new EventEmitter<ProjectComment>();
  @Output()
  onAddProjectComment: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditProjectComment: EventEmitter<ProjectComment> = new EventEmitter<ProjectComment>();
  @Output()
  onDuplicateProjectComment: EventEmitter<ProjectComment> = new EventEmitter<ProjectComment>();
  @Output()
  onChangeBillable: EventEmitter<ProjectComment> = new EventEmitter<ProjectComment>();
  @Output()
  onChangePayable: EventEmitter<ProjectComment> = new EventEmitter<ProjectComment>();

  constructor() { }

  addProjectComment() {
    this.onAddProjectComment.emit();
  }

  editProjectComment(projectComment: ProjectComment) {
    this.onEditProjectComment.emit(projectComment);
  }

  duplicateProjectComment(projectComment: ProjectComment) {
    this.onDuplicateProjectComment.emit(projectComment);
  }

  selectProjectComment(projectComment: ProjectComment) {
    this.onSelectProjectComment.emit(projectComment);
  }

  onFilter(filters: ProjectCommentFilters) {
    this.onFilterChange.emit(filters);
  }

  changeBillable(projectComment: ProjectComment) {
    this.onChangeBillable.emit(projectComment);
  }

  changePayable(projectComment: ProjectComment) {
    this.onChangePayable.emit(projectComment);
  }
}

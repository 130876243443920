import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Budget } from 'src/app/commons/models/budget.model';
import { AlertService } from 'src/app/commons/services/alert.service';

@Component({
  selector: 'app-budget-edit',
  templateUrl: './budget-edit.component.html',
  styleUrls: ['./budget-edit.component.scss']
})
export class BudgetEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _budget: Budget;

  @Output()
  onSave: EventEmitter<Budget> = new EventEmitter<Budget>();
  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();

  budgetForm: FormGroup;
  constructor(private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data) {
      this.budget = this.data.budget;
    }

    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private ngOnChanges() {
    if (this.budgetForm) {
      this.budgetForm.reset();
      this._initFormValues(this.budget);
    }
  }

  get budget(): Budget {
    return this._budget;
  }

  @Input() set budget(budget: Budget) {
    this._budget = budget;
    this.ngOnChanges();
  }

  private _createForm() {
    let group = {
      name: ["", Validators.required],
      budget: ["", Validators.required],
      hours: ["", Validators.required],
      start: [],
      end: [],
      notes: [],
    }
    this.budgetForm = this.fb.group(group);
  }

  private _initFormValues(budget?: Budget) {
    if (budget) {
      this.budgetForm.patchValue({
        name: budget.name,
        budget: budget.budget,
        hours: budget.hours,
        start: budget.start,
        end: budget.end,
        notes: budget.notes,
      });
    }
  }

  private _prepareSaveBudget(): Budget {
    let savingBudget: Budget = Budget.fromFormGroup(this.budgetForm, this.budget);
    return savingBudget;
  }

  closeEdit() {
    this.onClose.emit();
    this.revert();
  }

  save() {
    let unsavedEntity = this._prepareSaveBudget();
    this.onSave.emit(unsavedEntity);
    this.closeEdit();
  }

  close() {
    if (this.budgetForm.pristine) {
      this.closeEdit();
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.closeEdit();
          }
        });
    }
  }

  revert() {
    this.ngOnChanges();
  }
}

import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { ProjectAttachment, ProjectAttachmentDTO } from './project-attachment.model';
import { Project, ProjectDTO } from './project.model';
import { User, UserDTO } from './user.model';


export interface ProjectCommentFilters {
  search?: string;
  project_id?: number;
  user_id?: number;
}

export interface ProjectCommentDTO extends BaseDTO {
  project_id: number;
  comment: string;
  project_attachment_id?: number;
  user_id: number;

  project: ProjectDTO;
  user: UserDTO;
  project_attachment: ProjectAttachmentDTO;
}

export class ProjectComment extends Base {
  projectId: number;
  comment: string;
  projectAttachmentId?: number;

  project: Project;
  user: User;
  projectAttachment?: ProjectAttachment;

  constructor(source: ProjectCommentDTO) {
    super(source);
    if (source) {
      this.projectId = source.project_id;
      this.comment = source.comment;
      this.projectAttachmentId = source.project_attachment_id;

      this.project = new Project(source.project);
      this.user = new User(source.user);
      this.projectAttachment = new ProjectAttachment(source.project_attachment);
    }
  }

  toDTO(): ProjectCommentDTO {
    let result: ProjectCommentDTO = <ProjectCommentDTO>super.toDTO();
    result.project_id = this.projectId;
    result.comment = this.comment;
    result.project_attachment_id = this.projectAttachmentId;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, projectId: number, original?: ProjectComment) {
    const formModel = formGroup.value;
    let projectComment: ProjectComment = new ProjectComment(null);
    projectComment.projectId = projectId;
    projectComment.comment = formModel.comment;
    projectComment.projectAttachmentId = formModel.projectAttachment?.id;

    if (original) {
      projectComment.id = original.id;
    }
    return projectComment;
  }

}

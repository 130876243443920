import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Action } from 'src/app/commons/models/action.model';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as ActionActions from 'src/app/store/actions/action.actions';
import { AppState } from 'src/app/store/reducers';
import * as ActionSelectors from 'src/app/store/selectors/action.selectors';
import * as AuthSelectors from 'src/app/store/selectors/auth.selectors';

import { ActionFilters } from '../../../commons/models/action.model';
import { ActionsColumn } from '../action-list/action-list.component';



@Component({
  selector: 'app-action-selection',
  templateUrl: './action-selection.component.html',
  styleUrls: ['./action-selection.component.scss']
})
export class ActionSelectionComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  actions: Observable<Action[]>;
  filters: Observable<ActionFilters>;

  @Input()
  offer_id: number;

  defaultFilters: ActionFilters = {
    description: "",
    start: new Date(),
    end: new Date(),
  }

  displayedColumns: ActionsColumn[] = ["id", "date", "description", "offer", "actions"];

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.defaultFilters['offer_id'] = this.offer_id;

    this.actions = this.store$.pipe(select(ActionSelectors.getActions), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new Action(dto)) : null));
    this.total = this.store$.pipe(select(ActionSelectors.getTotalActions), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(ActionSelectors.getFilters), takeUntil(this.unsubscribe$));
    if (data) {
      this.defaultFilters = data.defaultFilters;
    }
  }

  load() {
    this.store$.dispatch(ActionActions.loadActions(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters }));
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(ActionActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(ActionActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: ActionFilters) {
    this.store$.dispatch(ActionActions.changeFilters({ filters }));
  }

  ngOnInit() {
    this.load();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectAction(action: Action) {
    if (action) {
      this.store$.dispatch(ActionActions.actionSelected({ action: action.toDTO() }));
    }
  }

  close() {
    this.store$.dispatch(ActionActions.closeSelectionDialog())
  }
}

import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Currency } from 'src/app/commons/models/currency.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as CurrencyActions from 'src/app/store/actions/currency.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'app-currency-edit',
  templateUrl: './currency-edit.component.html',
  styleUrls: ['./currency-edit.component.scss']
})
export class CurrencyEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _currency: Currency;

  currentCurrency: Currency;

  currencyForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data) {
      this.currency = this.data.currency;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      currencyCode: ["", Validators.required],
      name: ["", Validators.required],
      //exchangeRate: [1, Validators.required],
    }
    this.currencyForm = this.fb.group(group);
  }

  private ngOnChanges() {
    if (this.currencyForm) {
      this.currencyForm.reset();
      if (this.currency) {
        this._initFormValues(this.currency);
      }
    }

  }

  private _initFormValues(currency: Currency) {
    if (currency) {
      this.currencyForm.patchValue({
        currencyCode: currency.currencyCode,
        name: currency.name,
        exchangeRate: currency.exchangeRate,
      });
    }else{
      this.currencyForm.patchValue({exchangeRate: 1});
    }
  }

  get currency(): Currency {
    return this._currency;
  }

  set currency(currency: Currency) {
    this._currency = currency;
    this.ngOnChanges();
  }

  private _prepareSaveCurrency(): Currency {
    let savingCurrency: Currency = Currency.fromFormGroup(this.currencyForm, this.currency);
    return savingCurrency;
  }

  save() {
    let unsavedEntity = this._prepareSaveCurrency();
    this.store$.dispatch(CurrencyActions.saveCurrency({ currency: unsavedEntity }));
  }

  close() {
    if (this.currencyForm.pristine) {
      this.store$.dispatch(CurrencyActions.closeCurrencyDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(CurrencyActions.closeCurrencyDialog())
          }
        });
    }
  }

  deleteCurrency() {
    if (this.currency) {
      this.store$.dispatch(CurrencyActions.deleteCurrency({ currency: this.currency.toDTO() }))
    }
  }

  revert() {
    this.ngOnChanges();
  }
}

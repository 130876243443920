import { Action, createReducer, on } from '@ngrx/store';

import { RiskArea, RiskAreaDTO } from '../../commons/models/risk-area.model';
import * as RiskAreaActions from '../actions/risk-area.actions';
import { RiskAreaFilters } from './../../commons/models/risk-area.model';

export interface RiskAreaState {
  list: RiskAreaDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: RiskAreaFilters,
  dialogId: string,
  selectionDialogId: string,
  riskArea: RiskArea
};

const initialState: RiskAreaState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  riskArea: null
};

const riskAreaReducer = createReducer(
  initialState,
  on(RiskAreaActions.loadRiskAreasCompleted, (state, { riskAreas, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: riskAreas, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(RiskAreaActions.riskAreaDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(RiskAreaActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(RiskAreaActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: RiskAreaState | undefined, action: Action) {
  return riskAreaReducer(state, action);
}


import {AbstractControl} from '@angular/forms';
import {User, UserDTO} from 'src/app/commons/models/user.model';
import {dateFromDTO, formatDateForBackend} from 'src/app/helpers/time.utils';

import {BankAccount, BankAccountDTO} from './bank-account.model';
import {Base, BaseDTO} from './base.model';
import {ClientBonus, ClientBonusDTO} from './client-bonus.model';
import {Contact, ContactDTO} from './contact.model';
import {Currency, CurrencyDTO} from './currency.model';
import {IvaTypology, IvaTypologyDTO} from './iva-typology.model';
import {Payment, PaymentDTO} from './payment.model';


export interface ClientFilters {
  search?: string;
  clientLeader?: UserDTO;
}

export interface ClientDTO extends BaseDTO {
  contacts: ContactDTO[];
  //adviser_id: number;
  //adviser: ContactDTO;
  iva_typology_id: number;
  iva_typology: IvaTypologyDTO;
  bank_account_id: number;
  bank_account: BankAccountDTO;
  currency_id: number;
  currency: CurrencyDTO;
  payment_id: number;
  payment: PaymentDTO;
  client_leaders: UserDTO[];
  name: string;
  company_name: string;
  address: string;
  city: string;
  province: string;
  region: string;
  CAP: string;
  country: string;
  VAT: string;
  identifier: string;
  PEC: string;
  email: string;
  admin_email: string;
  //start: string;
  //end: string;
  //percentage: number;
  bonus_client: number;
  recipient_code: string;
  country_code: string;
  reference: string;
  reference_date: string;
  note: string;
  client_bonuses: ClientBonusDTO[];
}
export class Client extends Base {
  contacts: Contact[];
  //adviser: Contact;
  ivaTypology: IvaTypology;
  bankAccount: BankAccount;
  currency: Currency;
  payment: Payment;
  clientLeaders: User[];
  name: string;
  companyName: string;
  address: string;
  city: string;
  province: string;
  region: string;
  CAP: string;
  country: string;
  VAT: string;
  identifier: string;
  PEC: string;
  //start: Date;
  //end: Date;
  //percentage: number;
  email: string;
  adminEmail: string;
  bonusClient: number;
  recipientCode: string;
  countryCode: string;
  reference: string;
  referenceDate: Date;
  note: string;
  clientBonuses: ClientBonus[];
  constructor(source: ClientDTO) {
    super(source);
    if (source) {
      if (source.client_bonuses) {
        this.clientBonuses = source.client_bonuses.map((dto) => new ClientBonus(dto));
        this.addLoadedRelation('client_bonuses');
      }
      if (source.currency) {
        this.currency = new Currency(source.currency);
        this.addLoadedRelation('currency');
      }
      if (source.contacts) {
        this.contacts = source.contacts.map((dto) => new Contact(dto));
        this.addLoadedRelation('contacts');
      }
      if (source.payment) {
        this.payment = new Payment(source.payment);
        this.addLoadedRelation('payment');
      }
      if (source.iva_typology) {
        this.ivaTypology = new IvaTypology(source.iva_typology);
        this.addLoadedRelation('iva_typology');
      }
      if (source.bank_account) {
        this.bankAccount = new BankAccount(source.bank_account);
        this.addLoadedRelation('bank_account');
      }
      /*
      if (source.adviser) {
        this.adviser = new Contact(source.adviser);
        this.addLoadedRelation('adviser');
      }
      */
      if (source.client_leaders) {
        this.clientLeaders = source.client_leaders.map((dto) => new User(dto));
        this.addLoadedRelation('client_leaders');
      }
      this.name = source.name;
      this.companyName = source.company_name;
      this.address = source.address;
      this.city = source.city;
      this.province = source.province;
      this.region = source.region;
      this.country = source.country;
      this.CAP = source.CAP;
      this.VAT = source.VAT;
      this.identifier = source.identifier;
      this.PEC = source.PEC;
      //this.start = source.start ? new Date(source.start) : null;
      //this.end = source.end ? new Date(source.end) : null;
      //this.percentage = source.percentage;
      this.email = source.email;
      this.adminEmail = source.admin_email;
      this.bonusClient = source.bonus_client;
      this.recipientCode = source.recipient_code;
      this.countryCode = source.country_code;
      this.reference = source.reference;
      if (source.reference_date) {
        this.referenceDate = dateFromDTO(source.reference_date);
      }
      this.note = source.note;
    }
  }

  toDTO(): ClientDTO {
    let result: ClientDTO = <ClientDTO>super.toDTO();
    result.name = this.name;
    result.company_name = this.companyName;
    result.address = this.address;
    result.city = this.city;
    result.province = this.province;
    result.region = this.region;
    result.country = this.country;
    result.CAP = this.CAP;
    result.VAT = this.VAT;
    result.identifier = this.identifier;
    result.PEC = this.PEC;
    result.email = this.email;
    result.admin_email = this.adminEmail;
    //result.start = this.start ? formatDateForBackend(this.start) : null,
    //result.end = this.end ? formatDateForBackend(this.end) : null,
    //result.percentage = this.percentage;
    result.bonus_client = this.bonusClient;
    result.recipient_code = this.recipientCode;
    result.country_code = this.countryCode;
    result.reference = this.reference;
    result.reference_date = this.referenceDate ? formatDateForBackend(this.referenceDate) : null;
    result.note = this.note;
    result.currency = this.currency ? this.currency.toDTO() : null;
    result.currency_id = this.currency ? this.currency.id : null;
    result.payment = this.payment ? this.payment.toDTO() : null;
    result.payment_id = this.payment ? this.payment.id : null;
    //result.adviser = this.adviser ? this.adviser.toDTO() : null;
    //result.adviser_id = this.adviser ? this.adviser.id: null;
    result.client_leaders = this.clientLeaders
      ? this.clientLeaders.map((clientLeader) => clientLeader.toDTO())
      : null;
    result.contacts = this.contacts
      ? this.contacts.map((contact) => contact.toDTO())
      : null;
    result.client_bonuses = this.clientBonuses
      ? this.clientBonuses.map((clientBonus) => clientBonus.toDTO())
      : null;
    result.iva_typology = this.ivaTypology ? this.ivaTypology.toDTO() : null;
    result.iva_typology_id = this.ivaTypology ? this.ivaTypology.id : null;
    result.bank_account = this.bankAccount ? this.bankAccount.toDTO() : null;
    result.bank_account_id = this.bankAccount ? this.bankAccount.id : null;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Client) {
    const formModel = formGroup.getRawValue();
    let client: Client = new Client(null);
    client.name = formModel.name;
    client.contacts = formModel.contacts;
    //client.adviser =  formModel.adviser;
    client.currency = formModel.currency;
    client.payment = formModel.payment;
    client.clientLeaders = formModel.clientLeaders;
    client.companyName = formModel.companyName;
    client.address = formModel.address;
    client.city = formModel.city;
    client.province = formModel.province;
    client.region = formModel.region;
    client.CAP = formModel.CAP;
    client.country = formModel.country;
    client.VAT = formModel.VAT;
    client.identifier = formModel.identifier;
    client.PEC = formModel.PEC;
    client.email = formModel.email;
    client.adminEmail = formModel.adminEmail;
    //client.start = formModel.start;
    //client.end =  formModel.end;
    //client.percentage =  formModel.percentage;
    client.bonusClient = formModel.bonusClient;
    client.recipientCode = formModel.recipientCode;
    client.countryCode = formModel.countryCode;
    client.reference = formModel.reference;
    client.referenceDate = formModel.referenceDate;
    client.note = formModel.note;
    client.clientBonuses = formModel.clientBonuses;
    client.ivaTypology = formModel.ivaTypology;
    client.bankAccount = formModel.bankAccount;

    if (original) {
      client.id = original.id;
    }
    return client;
  }

  get fullAddress() {
    const parts = [this.address, this.city, this.CAP, this.province, this.region, this.country];
    return parts.join(' ');
  }

  get contactsLabel() {
    return this.contacts.map((contact) => `${contact.name} ${contact.lastname}`).join(', ');
  }

}

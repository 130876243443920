import { createAction, props } from '@ngrx/store';

import { Professional, ProfessionalDTO, ProfessionalFilters } from '../../commons/models/professional.model';

export const loadProfessionals = createAction('[Professionals] Load professionals', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: ProfessionalFilters, includes?: string[] }>());
export const loadProfessionalsCompleted = createAction('[Professionals] Load professionals Completed', props<{ professionals: ProfessionalDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ProfessionalFilters, includes?: string[] }>());
export const loadProfessionalsFailed = createAction('[Professionals] Load professionals Failed', props<{ error: any }>());

export const changePage = createAction('[Professionals] Change page', props<{ page: number, size: number, filters: ProfessionalFilters }>());
export const changeSort = createAction('[Professionals] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Professionals] Change filters', props<{ filters: ProfessionalFilters }>());

export const editProfessional = createAction('[Professionals] Edit professional', props<{ professional?: Professional }>());
export const professionalDialogOpened = createAction('[Professionals] Detail dialog opened', props<{ dialogId: string }>());
export const closeProfessionalDialog = createAction('[Professionals] Close detail dialog');

export const saveProfessional = createAction('[Professionals] Save professional', props<{ professional: Professional }>());
export const saveProfessionalCompleted = createAction('[Professionals] Save professional completed', props<{ professional: ProfessionalDTO}>());
export const saveProfessionalFailed = createAction('[Professionals] Save professional failed', props<{ error: any }>());

export const deleteProfessional = createAction('[Professionals] Delete professional', props<{ professional: ProfessionalDTO }>());
export const deleteProfessionalCompleted = createAction('[Professionals] Delete professional completed', props<{ professional: ProfessionalDTO }>());
export const deleteProfessionalCancelled = createAction('[Professionals] Delete professional cancelled');
export const deleteProfessionalFailed = createAction('[Professionals] Delete professional failed', props<{ error: any }>());


export const selectProfessional = createAction('[Professionals] Select professional', props<{ filters?: ProfessionalFilters }>());
export const selectionDialogOpened = createAction('[Professionals] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Professionals] Close selection dialog');
export const professionalSelected = createAction('[Professionals] Professional selected', props<{ professional: ProfessionalDTO }>());

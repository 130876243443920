import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { Currency } from '../../../commons/models/currency.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';
import { CurrencyFilters } from './../../../commons/models/currency.model';

export type CurrenciesColumn = "currencyCode" | "name" | "actions"; // "exchangeRate"

@Component({
  selector: 'currency-list',
  templateUrl: './currency-list.component.html',
  styleUrls: ['./currency-list.component.scss'],
})
export class CurrencyListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  currencies: Observable<Currency[]>;

  @Input()
  defaultFilters: CurrencyFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Currency | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: CurrenciesColumn[] = ["currencyCode", "name", "actions"]; // "exchangeRate",

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<CurrencyFilters> = new EventEmitter<CurrencyFilters>();

  @Output()
  onSelectCurrency: EventEmitter<Currency> = new EventEmitter<Currency>();
  @Output()
  onAddCurrency: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditCurrency: EventEmitter<Currency> = new EventEmitter<Currency>();

  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addCurrency() {
    this.onAddCurrency.emit();
  }

  editCurrency(currency: Currency) {
    this.onEditCurrency.emit(currency);
  }

  selectCurrency(currency: Currency) {
    this.onSelectCurrency.emit(currency);
  }

  onFilter(filters: CurrencyFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
}

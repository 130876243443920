import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {RiskArea } from 'src/app/commons/models/risk-area.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as RiskAreaActions from 'src/app/store/actions/risk-area.actions';
import { AppState } from 'src/app/store/reducers';

@Component({
  selector: 'app-risk-area-edit',
  templateUrl: './risk-area-edit.component.html',
  styleUrls: ['./risk-area-edit.component.scss']
})
export class RiskAreaEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _riskArea: RiskArea;

  currentRiskArea: RiskArea;

  riskAreaForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data) {
      this.riskArea = this.data.riskArea;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      name: ["", Validators.required],
    }
    this.riskAreaForm = this.fb.group(group);
  }

  private ngOnChanges() {
    if (this.riskAreaForm) {
      this.riskAreaForm.reset();
      if (this.riskArea) {
        this._initFormValues(this.riskArea);
      }
    }

  }

  private _initFormValues(riskArea: RiskArea) {
    if (riskArea) {
      this.riskAreaForm.patchValue({
        name: riskArea.name,
      });
    }
  }

  get riskArea(): RiskArea {
    return this._riskArea;
  }

  set riskArea(riskArea: RiskArea) {
    this._riskArea = riskArea;
    this.ngOnChanges();
  }

  private _prepareSaveRiskArea(): RiskArea {
    let savingRiskArea: RiskArea = RiskArea.fromFormGroup(this.riskAreaForm, this.riskArea);
    return savingRiskArea;
  }

  save() {
    let unsavedEntity = this._prepareSaveRiskArea();
    this.store$.dispatch(RiskAreaActions.saveRiskArea({ riskArea: unsavedEntity }));
  }

  close() {
    if (this.riskAreaForm.pristine) {
      this.store$.dispatch(RiskAreaActions.closeRiskAreaDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(RiskAreaActions.closeRiskAreaDialog())
          }
        });
    }
  }

  deleteRiskArea() {
    if (this.riskArea) {
      this.store$.dispatch(RiskAreaActions.deleteRiskArea({ riskArea: this.riskArea.toDTO() }))
    }
  }

  revert() {
    this.ngOnChanges();
  }
}

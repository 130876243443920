import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { EXPENSE_PAYERS, ExpenseFilters } from '../../../commons/models/expense.model';

@Component({
  selector: 'expense-report-filters',
  templateUrl: './expense-report-filters.component.html',
  styleUrls: ['./expense-report-filters.component.scss']
})
export class ExpenseReportFiltersComponent {
  @Output()
  onFilter: EventEmitter<ExpenseFilters> = new EventEmitter();

  private _defaultFilters: ExpenseFilters;
  filtersForm: FormGroup;

  expensePayers = EXPENSE_PAYERS;

  constructor(private fb: FormBuilder) {
    this._createForm();
    this.resetFilters();
  }

  private _createForm() {
    let group = {
      start: "",
      end: "",
      payer: null,
      payable: "",
      project: "",
      client: "",
      professional: ""
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getExpenseFilters());
  }

  private _getExpenseFilters(): ExpenseFilters {
    let filters: ExpenseFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.start = values.start;
      filters.end = values.end;
      filters.professional = values.professional;
      filters.project_id = values.project?.id;
      filters.client_id = values.client?.id;
      filters.payable = values.payable;
      filters.payer = values.payer;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): ExpenseFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: ExpenseFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
          start: this.defaultFilters.start,
          end: this.defaultFilters.end,
          payer: this.defaultFilters.payer
        })
      }
    }
  }
}

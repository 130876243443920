import {CommonModule, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {
  BankAccountSelectionComponent,
} from 'src/app/modules/shared/bank-account-selection/bank-account-selection.component';

import {
  ActionAttachmentEditComponent,
} from '../home/actions/action-attachments/action-attachment-edit/action-attachment-edit.component';
import {ActionAttachmentsComponent} from '../home/actions/action-attachments/action-attachments.component';
import {MaterialModule} from './../material/material.module';
import {ActionAttachmentFieldComponent} from './action-attachment-field/action-attachment-field.component';
import {ActionAttachmentFiltersComponent} from './action-attachment-filters/action-attachment-filters.component';
import {ActionAttachmentListComponent} from './action-attachment-list/action-attachment-list.component';
import {ActionAttachmentSelectionComponent} from './action-attachment-selection/action-attachment-selection.component';
import {ActionFieldComponent} from './action-field/action-field.component';
import {ActionFiltersComponent} from './action-filters/action-filters.component';
import {ActionListComponent} from './action-list/action-list.component';
import {ActionSelectionComponent} from './action-selection/action-selection.component';
import {ActivityFieldComponent} from './activity-field/activity-field.component';
import {ActivityFiltersComponent} from './activity-filters/activity-filters.component';
import {ActivityListComponent} from './activity-list/activity-list.component';
import {ActivityReportFiltersComponent} from './activity-report-filters/activity-report-filters.component';
import {ActivityReportListComponent} from './activity-report-list/activity-report-list.component';
import {ActivitySelectionComponent} from './activity-selection/activity-selection.component';
import {AttachmentUploadComponent} from './attachment-upload/attachment-upload.component';
import {BankAccountFieldComponent} from './bank-account-field/bank-account-field.component';
import {BankAccountFiltersComponent} from './bank-account-filters/bank-account-filters.component';
import {BankAccountListComponent} from './bank-account-list/bank-account-list.component';
import {BillingLineFieldComponent} from './billing-line-field/billing-line-field.component';
import {BillingLineFiltersComponent} from './billing-line-filters/billing-line-filters.component';
import {BillingLineListComponent} from './billing-line-list/billing-line-list.component';
import {BillingLineSelectionComponent} from './billing-line-selection/billing-line-selection.component';
import {BillingLinesFieldComponent} from './billing-lines-field/billing-lines-field.component';
import {BonusFiltersComponent} from './bonus-filters/bonus-filters.component';
import {BonusLineListComponent} from './bonus-line-list/bonus-line-list.component';
import {BonusListComponent} from './bonus-list/bonus-list.component';
import {BudgetFieldComponent} from './budget-field/budget-field.component';
import {BudgetFiltersComponent} from './budget-filters/budget-filters.component';
import {BudgetListComponent} from './budget-list/budget-list.component';
import {BudgetSelectionComponent} from './budget-selection/budget-selection.component';
import {BudgetEditComponent} from './budgets-edit/budget-edit.component';
import {BudgetsFieldComponent} from './budgets-field/budgets-field.component';
import {BusinessOpportunityFieldComponent} from './business-opportunity-field/business-opportunity-field.component';
import {BusinessOpportunityFiltersComponent} from './business-opportunity-filters/business-opportunity-filters.component';
import {BusinessOpportunityListComponent} from './business-opportunity-list/business-opportunity-list.component';
import {
  BusinessOpportunitySelectionComponent,
} from './business-opportunity-selection/business-opportunity-selection.component';
import {CanceledInvoiceListComponent} from './canceled-invoice-list/canceled-invoice-list.component';
import {ClientBonusEditComponent} from './client-bonus-edit/client-bonus-edit.component';
import {ClientBonusesFieldComponent} from './client-bonuses-field/client-bonuses-field.component';
import {ClientFieldComponent} from './client-field/client-field.component';
import {ClientFiltersComponent} from './client-filters/client-filters.component';
import {ClientListComponent} from './client-list/client-list.component';
import {ClientSelectionComponent} from './client-selection/client-selection.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {ContactFieldComponent} from './contact-field/contact-field.component';
import {ContactFiltersComponent} from './contact-filters/contact-filters.component';
import {ContactListComponent} from './contact-list/contact-list.component';
import {ContactSelectionComponent} from './contact-selection/contact-selection.component';
import {ContactsFieldComponent} from './contacts-field/contacts-field.component';
import {CostLineFiltersComponent} from './cost-line-filters/cost-line-filters.component';
import {CostLineListComponent} from './cost-line-list/cost-line-list.component';
import {CurrencyFieldComponent} from './currency-field/currency-field.component';
import {CurrencyFiltersComponent} from './currency-filters/currency-filters.component';
import {CurrencyListComponent} from './currency-list/currency-list.component';
import {CurrencySelectionComponent} from './currency-selection/currency-selection.component';
import {DaysDistributionFieldComponent} from './days-distribution-field/days-distribution-field.component';
import {DaysDistributionFiltersComponent} from './days-distribution-filters/days-distribution-filters.component';
import {DaysDistributionListComponent} from './days-distribution-list/days-distribution-list.component';
import {DaysDistributionSelectionComponent} from './days-distribution-selection/days-distribution-selection.component';
import {DefaultValuesListComponent} from './default-values-list/default-values-list.component';
import {DetailComponent} from './detail-component/detail.component';
import {DetailDialogComponent} from './detail-dialog/detail-dialog.component';
import {CurrencyDirective} from './directives/currency.directive';
import {ForceNumberDirective} from './directives/force-number.directive';
import {ShowForDirective} from './directives/show-for.directive';
import {ExpenseFieldComponent} from './expense-field/expense-field.component';
import {ExpenseFiltersComponent} from './expense-filters/expense-filters.component';
import {ExpenseListComponent} from './expense-list/expense-list.component';
import {ExpenseReportFiltersComponent} from './expense-report-filters/expense-report-filters.component';
import {ExpenseReportListComponent} from './expense-report-list/expense-report-list.component';
import {ExpenseSelectionComponent} from './expense-selection/expense-selection.component';
import {InvoiceFiltersComponent} from './invoice-filters/invoice-filters.component';
import {InvoiceListComponent} from './invoice-list/invoice-list.component';
import {IvaTypologyFieldComponent} from './iva-typology-field/iva-typology-field.component';
import {IvaTypologyFiltersComponent} from './iva-typology-filters/iva-typology-filters.component';
import {IvaTypologyListComponent} from './iva-typology-list/iva-typology-list.component';
import {IvaTypologySelectionComponent} from './iva-typology-selection/iva-typology-selection.component';
import {OfferAttachmentFieldComponent} from './offer-attachment-field/offer-attachment-field.component';
import {OfferAttachmentFiltersComponent} from './offer-attachment-filters/offer-attachment-filters.component';
import {OfferAttachmentListComponent} from './offer-attachment-list/offer-attachment-list.component';
import {OfferAttachmentSelectionComponent} from './offer-attachment-selection/offer-attachment-selection.component';
import {OfferFieldComponent} from './offer-field/offer-field.component';
import {OfferFiltersComponent} from './offer-filters/offer-filters.component';
import {OfferListComponent} from './offer-list/offer-list.component';
import {OfferSelectionComponent} from './offer-selection/offer-selection.component';
import {PaymentFieldComponent} from './payment-field/payment-field.component';
import {PaymentFiltersComponent} from './payment-filters/payment-filters.component';
import {PaymentListComponent} from './payment-list/payment-list.component';
import {PaymentSelectionComponent} from './payment-selection/payment-selection.component';
import {CustomCurrencyPipe} from './pipes/custom-currency.pipe';
import {DateFormatPipe} from './pipes/date-format.pipe';
import {OfferProbabilityPipe} from './pipes/offer-probability.pipe';
import {UserRolePipe} from './pipes/user-role.pipe';
import {ProfessionalFieldComponent} from './professional-field/professional-field.component';
import {ProfessionalFiltersComponent} from './professional-filters/professional-filters.component';
import {ProfessionalListComponent} from './professional-list/professional-list.component';
import {ProfessionalSelectionComponent} from './professional-selection/professional-selection.component';
import {ProfessionalsFieldComponent} from './professionals-field/professionals-field.component';
import {ProjectAttachmentFieldComponent} from './project-attachment-field/project-attachment-field.component';
import {ProjectAttachmentFiltersComponent} from './project-attachment-filters/project-attachment-filters.component';
import {ProjectAttachmentListComponent} from './project-attachment-list/project-attachment-list.component';
import {ProjectAttachmentSelectionComponent} from './project-attachment-selection/project-attachment-selection.component';
import {ProjectBonusEditComponent} from './project-bonus-edit/project-bonus-edit.component';
import {ProjectBonusesFieldComponent} from './project-bonuses-field/project-bonuses-field.component';
import {ProjectCommentListComponent} from './project-comment-list/project-comment-list.component';
import {ProjectFieldComponent} from './project-field/project-field.component';
import {ProjectFiltersComponent} from './project-filters/project-filters.component';
import {ProjectListComponent} from './project-list/project-list.component';
import {ProjectSelectionComponent} from './project-selection/project-selection.component';
import {RiskAreaFieldComponent} from './risk-area-field/risk-area-field.component';
import {RiskAreaFiltersComponent} from './risk-area-filters/risk-area-filters.component';
import {RiskAreaListComponent} from './risk-area-list/risk-area-list.component';
import {RiskAreaSelectionComponent} from './risk-area-selection/risk-area-selection.component';
import {RiskCategoryFieldComponent} from './risk-category-field/risk-category-field.component';
import {RiskCategoryFiltersComponent} from './risk-category-filters/risk-category-filters.component';
import {RiskCategoryListComponent} from './risk-category-list/risk-category-list.component';
import {RiskCategorySelectionComponent} from './risk-category-selection/risk-category-selection.component';
import {RiskFieldComponent} from './risk-field/risk-field.component';
import {RiskFiltersComponent} from './risk-filters/risk-filters.component';
import {RiskIdentificationEditComponent} from './risk-identification-edit/risk-identification-edit.component';
import {RiskIdentificationFiltersComponent} from './risk-identification-filters/risk-identification-filters.component';
import {RiskIdentificationListComponent} from './risk-identification-list/risk-identification-list.component';
import {
  RiskIdentificationSelectionComponent,
} from './risk-identification-selection/risk-identification-selection.component';
import {RiskIdentificationsFieldComponent} from './risk-identifications-field/risk-identifications-field.component';
import {RiskListComponent} from './risk-list/risk-list.component';
import {RiskOwnerFieldComponent} from './risk-owner-field/risk-owner-field.component';
import {RiskOwnerFiltersComponent} from './risk-owner-filters/risk-owner-filters.component';
import {RiskOwnerListComponent} from './risk-owner-list/risk-owner-list.component';
import {RiskOwnerSelectionComponent} from './risk-owner-selection/risk-owner-selection.component';
import {RiskSelectionComponent} from './risk-selection/risk-selection.component';
import {SalesBonusEditComponent} from './sales-bonus-edit/sales-bonus-edit.component';
import {SalesBonusesFieldComponent} from './sales-bonuses-field/sales-bonuses-field.component';
import {SnackBarComponent} from './snack-bar/snack-bar.component';
import {TargetFieldComponent} from './target-field/target-field.component';
import {TargetFiltersComponent} from './target-filters/target-filters.component';
import {TargetListComponent} from './target-list/target-list.component';
import {TargetSelectionComponent} from './target-selection/target-selection.component';
import {UserFieldComponent} from './user-field/user-field.component';
import {UserFiltersComponent} from './user-filters/user-filters.component';
import {UserListComponent} from './user-list/user-list.component';
import {UserSelectionComponent} from './user-selection/user-selection.component';
import {UsersFieldComponent} from './users-field/users-field.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatChipsModule,
    FlexLayoutModule,
    NgxMaterialTimepickerModule.setLocale('it-IT'),
    MatSlideToggleModule
  ],
  providers: [DecimalPipe],
  exports: [
    CommonModule,
    DetailComponent,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    UserRolePipe,
    DateFormatPipe,
    UserListComponent,
    UserFiltersComponent,
    CurrencyListComponent,
    CurrencyFiltersComponent,
    ShowForDirective,
    CurrencyDirective,
    ForceNumberDirective,
    CurrencySelectionComponent,
    CurrencyFieldComponent,
    ContactListComponent,
    ContactFiltersComponent,
    ClientListComponent,
    ClientFiltersComponent,
    ContactSelectionComponent,
    ContactFieldComponent,
    UserSelectionComponent,
    UserFieldComponent,
    UsersFieldComponent,
    BankAccountListComponent,
    BankAccountFiltersComponent,
    PaymentListComponent,
    PaymentFiltersComponent,
    PaymentFieldComponent,
    PaymentSelectionComponent,
    TargetListComponent,
    TargetFiltersComponent,
    DefaultValuesListComponent,
    RiskAreaListComponent,
    RiskAreaFiltersComponent,
    RiskCategoryListComponent,
    RiskCategoryFiltersComponent,
    RiskOwnerListComponent,
    RiskOwnerFiltersComponent,
    RiskListComponent,
    RiskFiltersComponent,
    RiskFieldComponent,
    RiskSelectionComponent,
    TargetFieldComponent,
    TargetSelectionComponent,
    RiskAreaFieldComponent,
    RiskAreaSelectionComponent,
    RiskCategoryFieldComponent,
    RiskCategorySelectionComponent,
    RiskOwnerFieldComponent,
    RiskOwnerSelectionComponent,
    BudgetFiltersComponent,
    BudgetListComponent,
    BudgetFieldComponent,
    BudgetSelectionComponent,
    BudgetEditComponent,
    IvaTypologyListComponent,
    IvaTypologyFiltersComponent,
    ProfessionalListComponent,
    ProfessionalFiltersComponent,
    ProfessionalSelectionComponent,
    RiskIdentificationListComponent,
    RiskIdentificationFiltersComponent,
    RiskIdentificationEditComponent,
    ProjectListComponent,
    ProjectFiltersComponent,
    ProjectFieldComponent,
    ProjectSelectionComponent,
    ActivityListComponent,
    ActivityFiltersComponent,
    ActivityFieldComponent,
    ActivitySelectionComponent,
    ActivityReportListComponent,
    ActivityReportFiltersComponent,
    ClientFieldComponent,
    ClientSelectionComponent,
    BankAccountSelectionComponent,
    BankAccountFieldComponent,
    IvaTypologyFieldComponent,
    IvaTypologySelectionComponent,
    ProfessionalsFieldComponent,
    ProfessionalFieldComponent,
    RiskIdentificationSelectionComponent,
    ExpenseFiltersComponent,
    ExpenseListComponent,
    ExpenseSelectionComponent,
    ExpenseFieldComponent,
    ExpenseReportListComponent,
    ExpenseReportFiltersComponent,
    ProjectAttachmentFiltersComponent,
    ProjectAttachmentListComponent,
    ProjectAttachmentFieldComponent,
    ProjectAttachmentSelectionComponent,
    ConfirmDialogComponent,
    DetailDialogComponent,
    SnackBarComponent,
    BudgetsFieldComponent,
    RiskIdentificationsFieldComponent,
    BusinessOpportunityListComponent,
    BusinessOpportunityFiltersComponent,
    BusinessOpportunityFieldComponent,
    BusinessOpportunitySelectionComponent,
    OfferListComponent,
    OfferFiltersComponent,
    OfferFieldComponent,
    OfferSelectionComponent,
    OfferProbabilityPipe,
    ProjectCommentListComponent,
    DaysDistributionListComponent,
    DaysDistributionFiltersComponent,
    DaysDistributionFieldComponent,
    DaysDistributionSelectionComponent,
    OfferAttachmentListComponent,
    OfferAttachmentFiltersComponent,
    OfferAttachmentFieldComponent,
    OfferAttachmentSelectionComponent,
    ActionListComponent,
    ActionFiltersComponent,
    ActionFieldComponent,
    ActionSelectionComponent,
    ActionAttachmentListComponent,
    ActionAttachmentFiltersComponent,
    ActionAttachmentFieldComponent,
    ActionAttachmentSelectionComponent,
    BillingLineListComponent,
    BillingLineFiltersComponent,
    BillingLineSelectionComponent,
    BillingLineFieldComponent,
    InvoiceListComponent,
    InvoiceFiltersComponent,
    SalesBonusEditComponent,
    SalesBonusesFieldComponent,
    ProjectBonusEditComponent,
    ProjectBonusesFieldComponent,
    CanceledInvoiceListComponent,
    CostLineListComponent,
    CostLineFiltersComponent,
    BonusListComponent,
    BonusFiltersComponent,
    ContactsFieldComponent,
    ClientBonusesFieldComponent,
    ClientBonusEditComponent,
    CustomCurrencyPipe,
    BillingLinesFieldComponent,
    BonusLineListComponent,
    AttachmentUploadComponent
  ],
  declarations: [
    DetailComponent,
    AttachmentUploadComponent,
    ActionAttachmentsComponent,
    ActionAttachmentEditComponent,
    ConfirmDialogComponent,
    DetailDialogComponent,
    SnackBarComponent,
    UserRolePipe,
    DateFormatPipe,
    UserListComponent,
    UserFiltersComponent,
    CurrencyListComponent,
    CurrencyFiltersComponent,
    CurrencySelectionComponent,
    CurrencyFieldComponent,
    ContactListComponent,
    ContactFiltersComponent,
    ClientListComponent,
    ClientFiltersComponent,
    ContactSelectionComponent,
    ContactFieldComponent,
    UserSelectionComponent,
    UserFieldComponent,
    UsersFieldComponent,
    BankAccountListComponent,
    BankAccountFiltersComponent,
    PaymentListComponent,
    PaymentFiltersComponent,
    PaymentFieldComponent,
    PaymentSelectionComponent,
    DefaultValuesListComponent,
    TargetListComponent,
    TargetFiltersComponent,
    RiskAreaListComponent,
    RiskAreaFiltersComponent,
    ShowForDirective,
    CurrencyDirective,
    ForceNumberDirective,
    RiskCategoryListComponent,
    RiskCategoryFiltersComponent,
    RiskOwnerListComponent,
    RiskOwnerFiltersComponent,
    RiskListComponent,
    RiskFiltersComponent,
    RiskFieldComponent,
    RiskSelectionComponent,
    TargetFieldComponent,
    TargetSelectionComponent,
    RiskAreaFieldComponent,
    RiskAreaSelectionComponent,
    RiskCategoryFieldComponent,
    RiskCategorySelectionComponent,
    RiskOwnerFieldComponent,
    RiskOwnerSelectionComponent,
    BudgetFiltersComponent,
    BudgetListComponent,
    BudgetFieldComponent,
    BudgetSelectionComponent,
    BudgetEditComponent,
    IvaTypologyListComponent,
    IvaTypologyFiltersComponent,
    ProfessionalListComponent,
    ProfessionalFiltersComponent,
    ProfessionalSelectionComponent,
    RiskIdentificationListComponent,
    RiskIdentificationFiltersComponent,
    RiskIdentificationEditComponent,
    ProjectListComponent,
    ProjectFiltersComponent,
    ProjectFieldComponent,
    ProjectSelectionComponent,
    ActivityListComponent,
    ActivityFiltersComponent,
    ActivityFieldComponent,
    ActivitySelectionComponent,
    ActivityReportListComponent,
    ActivityReportFiltersComponent,
    ClientFieldComponent,
    ClientSelectionComponent,
    BankAccountSelectionComponent,
    BankAccountFieldComponent,
    IvaTypologyFieldComponent,
    IvaTypologySelectionComponent,
    ProfessionalsFieldComponent,
    ProfessionalFieldComponent,
    RiskIdentificationSelectionComponent,
    ExpenseFiltersComponent,
    ExpenseListComponent,
    ExpenseFieldComponent,
    ExpenseSelectionComponent,
    ExpenseReportListComponent,
    ExpenseReportFiltersComponent,
    ProjectAttachmentFiltersComponent,
    ProjectAttachmentListComponent,
    ProjectAttachmentFieldComponent,
    ProjectAttachmentSelectionComponent,
    BudgetsFieldComponent,
    RiskIdentificationsFieldComponent,
    BusinessOpportunityListComponent,
    BusinessOpportunityFiltersComponent,
    BusinessOpportunityFieldComponent,
    BusinessOpportunitySelectionComponent,
    OfferListComponent,
    OfferFiltersComponent,
    OfferFieldComponent,
    OfferSelectionComponent,
    OfferProbabilityPipe,
    ProjectCommentListComponent,
    DaysDistributionListComponent,
    DaysDistributionFiltersComponent,
    DaysDistributionFieldComponent,
    DaysDistributionSelectionComponent,
    OfferAttachmentListComponent,
    OfferAttachmentFiltersComponent,
    OfferAttachmentFieldComponent,
    OfferAttachmentSelectionComponent,
    ActionListComponent,
    ActionFiltersComponent,
    ActionFieldComponent,
    ActionSelectionComponent,
    ActionAttachmentListComponent,
    ActionAttachmentFiltersComponent,
    ActionAttachmentFieldComponent,
    ActionAttachmentSelectionComponent,
    BillingLineListComponent,
    BillingLineFiltersComponent,
    BillingLineSelectionComponent,
    BillingLineFieldComponent,
    InvoiceListComponent,
    InvoiceFiltersComponent,
    SalesBonusEditComponent,
    SalesBonusesFieldComponent,
    ProjectBonusEditComponent,
    ProjectBonusesFieldComponent,
    CanceledInvoiceListComponent,
    CostLineListComponent,
    CostLineFiltersComponent,
    BonusListComponent,
    BonusFiltersComponent,
    ContactsFieldComponent,
    ClientBonusesFieldComponent,
    ClientBonusEditComponent,
    CustomCurrencyPipe,
    BillingLinesFieldComponent,
    BonusLineListComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    SnackBarComponent,
    DetailDialogComponent,
    ProjectAttachmentSelectionComponent,
    ExpenseSelectionComponent,
    OfferSelectionComponent,
    ActionAttachmentEditComponent,
    BonusLineListComponent
  ]
})
export class SharedModule { }

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { RiskIdentificationFilters } from '../../../commons/models/risk-identification.model';

@Component({
  selector: 'app-risk-identification-filters',
  templateUrl: './risk-identification-filters.component.html',
  styleUrls: ['./risk-identification-filters.component.scss']
})
export class RiskIdentificationFiltersComponent {
  @Output()
  onFilter: EventEmitter<RiskIdentificationFilters> = new EventEmitter();

  private _defaultFilters: RiskIdentificationFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getRiskIdentification());
  }

  private _getRiskIdentification(): RiskIdentificationFilters {
    let filters: RiskIdentificationFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): RiskIdentificationFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: RiskIdentificationFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
        })
      }
    }
  }
}

<!-- <app-action-attachment-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-action-attachment-filters> -->
<mat-card class="u-center-text" *ngIf="!actionAttachments?.length">
  <h4>Nessun allegato trovato</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addActionAttachment()">Aggiungi</button>
</mat-card>
<div class="table-container" [hidden]="!actionAttachments?.length">
  <table mat-table matSort [dataSource]="actionAttachments">
    <ng-container matColumnDef="actionAttachmentCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Codice valuta </th>
      <td mat-cell *matCellDef="let row">
        {{ row.actionAttachmentCode }}
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let row">
        {{row.id}}
      </td>
    </ng-container>

    <ng-container matColumnDef="activities">
      <th mat-header-cell *matHeaderCellDef> Attivita </th>
      <td mat-cell *matCellDef="let row">
        {{row.activities?.title}}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Nome </th>
      <td mat-cell *matCellDef="let row">
        {{row.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="file">
      <th mat-header-cell *matHeaderCellDef> File </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.file">
          <a [href]="row.fileUrl" target="_blank">
            <mat-icon color="primary">attachment</mat-icon>
          </a>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef> Note </th>
      <td mat-cell *matCellDef="let row">
        {{row.notes}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation();addActionAttachment()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-menu-item (click)="editActionAttachment(row)">
            <mat-icon matTooltip="Modifica">create</mat-icon>
          </button>
        </ng-container>
        <button *ngIf="canSelect" mat-raised-button color="primary"
          (click)="selectActionAttachment(row)">Seleziona</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
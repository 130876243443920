import { createAction, props } from '@ngrx/store';
import { CostLineDTO } from 'src/app/commons/models/cost-line.model';

import { Project, ProjectDTO, ProjectFilters } from '../../commons/models/project.model';

export const addProject = createAction('[Projects] Add project');
export const showProject = createAction('[Projects] Show project', props<{ currentProject: ProjectDTO }>());

export const loadCurrentProject = createAction('[Projects] Load current project', );
export const loadCurrentProjectCompleted = createAction('[Projects] Load current project completed', props<{ currentProject: ProjectDTO }>());
export const loadCurrentProjectFailed = createAction('[Projects] Load current project failed', props<{ error: any }>());

export const loadProject = createAction('[Projects] Load  project', props<{ id: number }>() );
export const loadProjectCompleted = createAction('[Projects] Load  project completed', props<{ project: ProjectDTO }>());
export const loadProjectFailed = createAction('[Projects] Load  project failed', props<{ error: any }>());


export const loadProjects = createAction('[Projects] Load projects', props<{ page?: number, perPage?: number, order?: string, direction?: string, filters?: ProjectFilters, includes?: string[] }>());
export const loadProjectsCompleted = createAction('[Projects] Load projects Completed', props<{ projects: ProjectDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: ProjectFilters, includes?: string[] }>());
export const loadProjectsFailed = createAction('[Projects] Load projects Failed', props<{ error: any }>());

export const changePage = createAction('[Projects] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Projects] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Projects] Change filters', props<{ filters: ProjectFilters }>());

export const saveProject = createAction('[Projects] Save project', props<{ project: Project }>());
export const saveProjectCompleted = createAction('[Projects] Save project completed', props<{ project: ProjectDTO }>());
export const saveProjectFailed = createAction('[Projects] Save project failed', props<{ error: any }>());

export const deleteProject = createAction('[Projects] Delete project', props<{ project: ProjectDTO }>());
export const deleteProjectCompleted = createAction('[Projects] Delete project completed', props<{ project: ProjectDTO }>());
export const deleteProjectCancelled = createAction('[Projects] Delete project cancelled');
export const deleteProjectFailed = createAction('[Projects] Delete project failed', props<{ project: ProjectDTO, error: any }>());


export const selectProject = createAction('[Projects] Select project', props<{ filters?: ProjectFilters, currentFormControlName?: string, showPromoFilter?: boolean, showClientFilter?: boolean }>());
export const selectionDialogOpened = createAction('[Projects] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Projects] Close selection dialog');
export const projectSelected = createAction('[Projects] Project selected', props<{ project: ProjectDTO }>());

export const bonusPayment = createAction('[Projects] Bonus payment', props<{ projectId: number }>());
export const bonusPaymentCompleted = createAction('[Projects] Bonus payment completed', props<{ costLines: CostLineDTO[] }>());
export const bonusPaymentFailed = createAction('[Projects] Bonus payment failed', props<{ error: any }>());
export const bonusPaymentCancelled = createAction('[Projects] Bonus payment cancelled');


export const editProject = createAction('[Projects] Edit project', props<{ project: Project }>());
export const projectDialogOpened = createAction('[Projects] Detail dialog opened', props<{ dialogId: string }>());
export const closeProjectDialog = createAction('[Projects] Close detail dialog');

export const confirmBonusPayment = createAction('[Projects] Confirm bonus payment', props<{ project: Project }>());
export const bonusPaymentDialogOpened = createAction('[Projects] Bonus payment dialog opened', props<{ bonusPaymentDialogId: string }>());
export const closeBonusPaymentDialog = createAction('[Projects] Close bonus payment dialog');

export const checkIfExists = createAction('[Projects] check If Exists', props<{ clientId: number, description: string }>());
export const checkIfExistsCompleted = createAction('[Projects] check If Exists completed', props<{ response: boolean }>());
export const checkIfExistsFailed = createAction('[Projects] check If Existse failed', props<{ error: any }>());


import { createSelector } from '@ngrx/store';

import { selectBillingLineState } from '../reducers';
import { BillingLineState } from '../reducers/billing-line.reducer';

// BillingLine Selectors

export const getBillingLinesTableState = createSelector(
  selectBillingLineState,
  (state: BillingLineState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getBillingLines = createSelector(
  selectBillingLineState,
  (state: BillingLineState) => state.list
);

export const getTotalBillingLines = createSelector(
  getBillingLinesTableState,
  (tableState) => tableState.total
);

export const getBillingLinesCurrentPage = createSelector(
  getBillingLinesTableState,
  (tableState) => tableState.currentPage
);

export const getBillingLinesPerPage = createSelector(
  getBillingLinesTableState,
  (tableState) => tableState.perPage
);

export const getBillingLinesOrder = createSelector(
  getBillingLinesTableState,
  (tableState) => tableState.order
);

export const getBillingLinesDirection = createSelector(
  getBillingLinesTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getBillingLinesTableState,
  (tableState) => tableState.filters
);

export const getBillingLineDialogId = createSelector(
  selectBillingLineState,
  (state: BillingLineState) => state.dialogId
);

export const getSelectedBillingLine = createSelector(
  selectBillingLineState,
  (state: BillingLineState) => state.billingLine
);
export const getSelectionDialogId = createSelector(
  selectBillingLineState,
  (state: BillingLineState) => state.selectionDialogId
);

export const getSelectedBillineLines = createSelector(
  selectBillingLineState,
  (state: BillingLineState) => state.selectedBillingLines
);

export const getSelectedBillineLinesCurrentPage = createSelector(
  selectBillingLineState,
  (state: BillingLineState) => {
    const selectedIds = state.selectedBillingLines.map(bl => bl.id);
    return state.list.filter(bl => selectedIds.includes(bl.id));
  }
);

import { state, style, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { User } from 'src/app/commons/models/user.model';

import { Activity, ActivityFilters } from '../../../commons/models/activity.model';
import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';

export type MultiSelectionMode = 'account' | 'removeAccount' | 'validate' | 'validated';
export type ActivitiesColumn =
  | 'multipleSelection'
  | 'id'
  | 'project'
  | 'advisoryPayment'
  | 'budget'
  | 'professional'
  | 'date'
  | 'hours'
  | 'notes'
  | 'billable'
  | 'payable'
  | 'actions'
  | 'client'
  | 'validated'
  | 'amount'
  | 'billable_amount'
  | 'payable_amount';

@Component({
  selector: 'activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', display: 'none' })
      ),
      state('expanded', style({ height: '*' })),
    ]),
  ],
})
export class ActivityListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  activities: Activity[];

  @Input()
  defaultFilters: ActivityFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Activity | null;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: ActivitiesColumn[] = [
    'multipleSelection',
    'project',
    'client',
    'budget',
    'professional',
    'date',
    'hours',
    'amount',
    'validated',
    'billable',
    'billable_amount',
    'payable',
    'payable_amount',
    'notes',
    'actions',
  ];

  @Input()
  canAdd: boolean;
  @Input()
  canEdit: boolean;
  @Input()
  canSelect: boolean;

  @Input()
  currentUser: User;

  @Input()
  selectedActivities: Activity[];
  @Input()
  selectedActivitiesCurrentPage: Activity[];

  @Input()
  selectionMode?: MultiSelectionMode;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<ActivityFilters> =
    new EventEmitter<ActivityFilters>();

  @Output()
  onSelectActivity: EventEmitter<Activity> = new EventEmitter<Activity>();
  @Output()
  onAddActivity: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onEditActivity: EventEmitter<Activity> = new EventEmitter<Activity>();
  @Output()
  onDuplicateActivity: EventEmitter<Activity> = new EventEmitter<Activity>();
  @Output()
  onChangeBillable: EventEmitter<Activity> = new EventEmitter<Activity>();
  @Output()
  onChangePayable: EventEmitter<Activity> = new EventEmitter<Activity>();
  @Output()
  onAccount: EventEmitter<Activity> = new EventEmitter<Activity>();
  @Output()
  onRemoveAccount: EventEmitter<Activity> = new EventEmitter<Activity>();

  @Output()
  onAddSelectedActivity: EventEmitter<Activity> = new EventEmitter<Activity>();
  @Output()
  onRemoveSelectedActivity: EventEmitter<Activity> =
    new EventEmitter<Activity>();
  @Output()
  onChangeMultipleBillable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onChangeMultiplePayable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onValidateActivity: EventEmitter<Activity> = new EventEmitter<Activity>();
  @Output()
  onUnvalidateActivity: EventEmitter<Activity> = new EventEmitter<Activity>();
  @Output()
  onMultipleValidate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onMultipleAccount: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }

  addActivity() {
    this.onAddActivity.emit();
  }

  editActivity(activity: Activity) {
    this.onEditActivity.emit(activity);
  }

  duplicateActivity(activity: Activity) {
    this.onDuplicateActivity.emit(activity);
  }

  selectActivity(activity: Activity) {
    this.onSelectActivity.emit(activity);
  }

  onFilter(filters: ActivityFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }

  changeBillable(activity: Activity) {
    this.onChangeBillable.emit(activity);
  }

  changePayable(activity: Activity) {
    this.onChangePayable.emit(activity);
  }
  validateActivity(activity: Activity) {
    this.onValidateActivity.emit(activity);
  }

  toggleSelectedActivity(activity: Activity): void {
    if (this.isSelected(activity)) {
      this.removeSelectedActivity(activity);
    } else {
      this.addSelectedActivity(activity);
    }
  }
  isSelected(activity: Activity): boolean {
    return this.selectedActivities
      .map((selectedActivity) => selectedActivity.id)
      .includes(activity.id);
  }
  private removeSelectedActivity(activity: Activity): void {
    this.onRemoveSelectedActivity.emit(activity);
  }
  private addSelectedActivity(activity: Activity): void {
    if (this.isSelectable(activity)) {
      this.onAddSelectedActivity.emit(activity);
    }
  }

  isSelectable(activity: Activity): boolean {
    if (this.selectionMode === "account") {
      return activity?.accountable;
    }
    if (this.selectionMode === "removeAccount") {
      return activity?.accountableCancellable;
    }
    if (this.selectionMode === "validate") {
      return !activity?.validated;
    }
    if (this.selectionMode === "validated") {
      return activity?.validated;
    }
    return true
  }

  tooltip(activity: Activity): string {
    if (this.selectionMode === "removeAccount") {
      return activity?.notAccountableCancellableTooltip;
    }
  }

  get isSelectedActivities(): boolean {
    return this.selectedActivities.length > 0;
  }

  changeMultipleBillable() {
    this.onChangeMultipleBillable.emit();
  }
  changeMultiplePayable() {
    this.onChangeMultiplePayable.emit();
  }
  multipleValidate() {
    this.onMultipleValidate.emit();
  }

  unvalidateActivity(activity: Activity): void {
    this.onUnvalidateActivity.emit(activity);
  }

  isAllSelected() {
    const numSelected = this.selectedActivitiesCurrentPage.length;
    const numRows = this.activities.filter(a => this.isSelectable(a)).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selectedActivitiesCurrentPage.forEach(a => this.removeSelectedActivity(a)) :
      this.activities.forEach(a => this.addSelectedActivity(a));
  }
}

import { createSelector } from '@ngrx/store';

import { selectActivityState } from '../reducers';
import { ActivityState } from '../reducers/activity.reducer';
import { getActionsTableState } from './action.selectors';

// Activity Selectors

export const getActivitiesTableState = createSelector(
  selectActivityState,
  (state: ActivityState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getActivitiesReportTableState = createSelector(
  selectActivityState,
  (state: ActivityState) => { return { total: state.totalReport, currentPage: state.currentPageReport, perPage: state.perPage, order: state.orderReport, direction: state.directionReport, filters: state.filtersReport } }
);

export const getActivities = createSelector(
  selectActivityState,
  (state: ActivityState) => state.list
);

export const getActivitiesReport = createSelector(
  selectActivityState,
  (state: ActivityState) => state.listReport
);

export const getTotalActivities = createSelector(
  getActivitiesTableState,
  (tableState) => tableState.total
);

export const getTotalActivitiesReport = createSelector(
  getActivitiesReportTableState,
  (tableState) => tableState.total
);

export const getActivitiesCurrentPage = createSelector(
  getActivitiesTableState,
  (tableState) => tableState.currentPage
);

export const getActivitiesCurrentPageReport = createSelector(
  getActivitiesReportTableState,
  (tableState) => tableState.currentPage
);

export const getActivitiesPerPage = createSelector(
  getActivitiesTableState,
  (tableState) => tableState.perPage
);

export const getActivitiesPerPageReport = createSelector(
  getActivitiesReportTableState,
  (tableState) => tableState.perPage
);

export const getActivitiesOrder = createSelector(
  getActivitiesTableState,
  (tableState) => tableState.order
);

export const getActivitiesOrderReport = createSelector(
  getActivitiesReportTableState,
  (tableState) => tableState.order
);

export const getActivitiesDirection = createSelector(
  getActivitiesTableState,
  (tableState) => tableState.direction
);

export const getActivitiesDirectionReport = createSelector(
  getActivitiesReportTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getActionsTableState,
  (tableState) => tableState.filters
);

export const getFiltersReport = createSelector(
  getActivitiesReportTableState,
  (tableState) => tableState.filters
);

export const getActivityDialogId = createSelector(
  selectActivityState,
  (state: ActivityState) => state.dialogId
);

export const getSelectedActivity = createSelector(
  selectActivityState,
  (state: ActivityState) => state.activity
);
export const getSelectionDialogId = createSelector(
  selectActivityState,
  (state: ActivityState) => state.selectionDialogId
);
export const getSelectedActivities = createSelector(
  selectActivityState,
  (state: ActivityState) => state.selectedActivities
);
export const getSelectedActivitiesCurrentPage = createSelector(
  selectActivityState,
  (state: ActivityState) => {
    const selectedIds = state.selectedActivities.map(bl => bl.id);
    return state.list.filter(bl => selectedIds.includes(bl.id));
  }
);

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { OfferAttachmentFilters } from '../../../commons/models/offer-attachment.model';

@Component({
  selector: 'app-offer-attachment-filters',
  templateUrl: './offer-attachment-filters.component.html',
  styleUrls: ['./offer-attachment-filters.component.scss']
})
export class OfferAttachmentFiltersComponent {


  @Output()
  onFilter: EventEmitter<OfferAttachmentFilters> = new EventEmitter();

  private _defaultFilters: OfferAttachmentFilters;
  filtersForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this._createForm();
  }

  private _createForm() {
    let group = {
      search: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getOfferAttachmentFilters());
  }

  private _getOfferAttachmentFilters(): OfferAttachmentFilters {
    let filters: OfferAttachmentFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.search = values.search;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): OfferAttachmentFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: OfferAttachmentFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          search: this.defaultFilters.search,
        })
      }
    }
  }
}

import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { Offer, OfferDTO } from './offer.model';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { BusinessOpportunity, BusinessOpportunityDTO } from './business-opportunity.model';


export interface ActionFilters {
  description?: string;
  start?: Date;
  end?: Date;
  offer_id?: number;
  business_opportunity_id?: number;
}

export interface ActionDTO extends BaseDTO {
  offer_id?: number;
  business_opportunity_id: number;
  date: string;
  description: string;

  offer: OfferDTO;
  business_opportunity: BusinessOpportunityDTO;
}

export class Action extends Base {
  offerId?: number;
  businessOpportunityId: number;
  date: Date;
  description: string;

  offer: Offer;
  businessOpportunity: BusinessOpportunity;

  constructor(source: ActionDTO) {
    super(source);
    if (source) {
      this.offerId = source.offer_id;
      this.businessOpportunityId = source.business_opportunity_id;
      this.date = new Date(source.date);
      this.description = source.description;

      this.offer = new Offer(source.offer);
      this.businessOpportunity = new BusinessOpportunity(source.business_opportunity);
    }
  }

  toDTO(): ActionDTO {
    let result: ActionDTO = <ActionDTO>super.toDTO();
    result.offer_id = this.offerId;
    result.business_opportunity_id = this.businessOpportunityId;
    result.date = formatDateForBackend(this.date);
    result.description = this.description;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Action) {
    const formModel = formGroup.value;
    let action: Action = new Action(null);
    action.date = formModel.date;
    action.description = formModel.description;
    action.offerId = formModel.offer?.id;
    action.businessOpportunityId = formModel.businessOpportunity?.id;
    action.id = formModel.id;

    if (original) {
      action.id = original.id;
    }
    return action;
  }

}

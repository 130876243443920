import { exportRecords } from './../actions/bonus-line.actions';
import { getCurrentProject } from './../selectors/project.selectors';

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AlertService } from '../../commons/services/alert.service';
import * as BonusLineActions from '../actions/bonus-line.actions';

import { AppState } from '../reducers';
import { LaravelBonusLineService } from 'src/app/commons/services/backend/laravel-bonus-line.service';
import { getBonusLinesTableState } from '../selectors/bonus-line.selectors';
import { saveAs } from 'file-saver';



@Injectable()
export class BonusLineEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusLineActions.loadBonusLinesFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

    loadBonusLines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusLineActions.loadBonusLines),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.bonusLineService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              BonusLineActions.loadBonusLinesCompleted({ bonusLines: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(BonusLineActions.loadBonusLinesFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusLineActions.changePage),
      withLatestFrom(this.store$.select(getBonusLinesTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => BonusLineActions.loadBonusLines({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusLineActions.changeSort),
      withLatestFrom(this.store$.select(getBonusLinesTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => BonusLineActions.loadBonusLines({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(BonusLineActions.changeFilters),
      withLatestFrom(this.store$.select(getBonusLinesTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => BonusLineActions.loadBonusLines({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  exportRecords$ = createEffect(() =>
  this.actions$.pipe(
    ofType(BonusLineActions.exportRecords),
    switchMap(( {filters}) =>
      this.bonusLineService.exportBonusLines(filters).pipe(
        map((result) =>
        BonusLineActions.exportRecordsCompleted({
            blob: new Blob([result], { type: "text/csv" }),
          })
        ),
        tap(() =>
          this.alertService.showConfirmMessage(
            `Esportazione righe di bonus generata con successo`
          )
        ),
        catchError((error) =>
          of(BonusLineActions.exportRecordsFailed({ error }))
        )
      )
    )
  )
);

downloadrecords$ = createEffect(
  () =>
    this.actions$.pipe(
      ofType(BonusLineActions.exportRecordsCompleted),
      tap(({ blob }) => {
        saveAs(blob, `righe_bonus.csv`);
      })
    ),
  { dispatch: false }
);


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private bonusLineService: LaravelBonusLineService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}

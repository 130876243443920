import { createSelector } from '@ngrx/store';

import { selectDaysDistributionState } from '../reducers';
import { DaysDistributionState } from '../reducers/days-distribution.reducer';

// daysDistribution Selectors

export const getDaysDistributionsTableState = createSelector(
  selectDaysDistributionState,
  (state: DaysDistributionState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getDaysDistributions = createSelector(
  selectDaysDistributionState,
  (state: DaysDistributionState) => state.list
);

export const getTotalDaysDistributions = createSelector(
  getDaysDistributionsTableState,
  (tableState) => tableState.total
);

export const getDaysDistributionsCurrentPage = createSelector(
  getDaysDistributionsTableState,
  (tableState) => tableState.currentPage
);

export const getDaysDistributionsPerPage = createSelector(
  getDaysDistributionsTableState,
  (tableState) => tableState.perPage
);

export const getDaysDistributionsOrder = createSelector(
  getDaysDistributionsTableState,
  (tableState) => tableState.order
);

export const getDaysDistributionsDirection = createSelector(
  getDaysDistributionsTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getDaysDistributionsTableState,
  (tableState) => tableState.filters
);

export const getDaysDistributionDialogId = createSelector(
  selectDaysDistributionState,
  (state: DaysDistributionState) => state.dialogId
);

export const getSelectedDaysDistribution = createSelector(
  selectDaysDistributionState,
  (state: DaysDistributionState) => state.daysDistribution
);
export const getSelectionDialogId = createSelector(
  selectDaysDistributionState,
  (state: DaysDistributionState) => state.selectionDialogId
);

import { Action, createReducer, on } from '@ngrx/store';

import { Budget, BudgetDTO, BudgetFilters } from '../../commons/models/budget.model';
import * as BudgetActions from '../actions/budget.actions';

export interface BudgetState {
  list: BudgetDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: BudgetFilters,
  dialogId: string,
  selectionDialogId: string,
  budget: Budget
};

const initialState: BudgetState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  budget: null
};

const budgetReducer = createReducer(
  initialState,
  on(BudgetActions.loadBudgetsCompleted, (state, { budgets, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: budgets, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(BudgetActions.budgetDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(BudgetActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(BudgetActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: BudgetState | undefined, action: Action) {
  return budgetReducer(state, action);
}


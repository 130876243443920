import { AbstractControl } from '@angular/forms';

import { Base, BaseDTO } from './base.model';

export interface CurrencyFilters {
  search?: string;
}

export interface CurrencyDTO extends BaseDTO {
  currency_code: string;
  name: string;
  exchange_rate: number;
}

export class Currency extends Base {
  currencyCode: string;
  name: string;
  exchangeRate: number;
  constructor(source: CurrencyDTO) {
    super(source);
    if (source) {
      this.currencyCode = source.currency_code;
      this.name = source.name;
      this.exchangeRate = source.exchange_rate;

    }
  }

  toDTO(): CurrencyDTO {
    let result: CurrencyDTO = <CurrencyDTO>super.toDTO();
    result.currency_code = this.currencyCode;
    result.name = this.name;
    result.exchange_rate = this.exchangeRate
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Currency) {
    const formModel = formGroup.value;
    let currency: Currency = new Currency(null);
    currency.currencyCode = formModel.currencyCode;
    currency.name = formModel.name;
    currency.exchangeRate = formModel.exchangeRate;

    if (original) {
      currency.id = original.id;
    }
    return currency;
  }

}

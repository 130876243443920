import { createAction, props } from '@ngrx/store';

import { Currency, CurrencyDTO, CurrencyFilters } from '../../commons/models/currency.model';

export const loadCurrencies = createAction('[Currencies] Load currencies', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: CurrencyFilters, includes?: string[] }>());
export const loadCurrenciesCompleted = createAction('[Currencies] Load currencies Completed', props<{ currencies: CurrencyDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: CurrencyFilters, includes?: string[] }>());
export const loadCurrenciesFailed = createAction('[Currencies] Load currencies Failed', props<{ error: any }>());

export const changePage = createAction('[Currencies] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[Currencies] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[Currencies] Change filters', props<{ filters: CurrencyFilters }>());

export const editCurrency = createAction('[Currencies] Edit currency', props<{ currency: Currency }>());
export const currencyDialogOpened = createAction('[Currencies] Detail dialog opened', props<{ dialogId: string }>());
export const closeCurrencyDialog = createAction('[Currencies] Close detail dialog');

export const saveCurrency = createAction('[Currencies] Save currency', props<{ currency: Currency }>());
export const saveCurrencyCompleted = createAction('[Currencies] Save currency completed', props<{ currency: CurrencyDTO }>());
export const saveCurrencyFailed = createAction('[Currencies] Save currency failed', props<{ error: any }>());

export const deleteCurrency = createAction('[Currencies] Delete currency', props<{ currency: CurrencyDTO }>());
export const deleteCurrencyCompleted = createAction('[Currencies] Delete currency completed', props<{ currency: CurrencyDTO }>());
export const deleteCurrencyCancelled = createAction('[Currencies] Delete currency cancelled');
export const deleteCurrencyFailed = createAction('[Currencies] Delete currency failed', props<{ error: any }>());


export const selectCurrency = createAction('[Currencies] Select currency', props<{ filters?: CurrencyFilters }>());
export const selectionDialogOpened = createAction('[Currencies] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[Currencies] Close selection dialog');
export const currencySelected = createAction('[Currencies] Currency selected', props<{ currency: CurrencyDTO }>());

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'detail-dialog',
  templateUrl: './detail-dialog.component.html',
  styleUrls: ['./detail-dialog.component.scss'],
})
export class DetailDialogComponent {
  constructor(public dialogRef: MatDialogRef<DetailDialogComponent>) {}

  public confirmMessage: string;
  public title: string;

  get supportEmail(): string {
    return environment.supportEmail;
  }
}

<header mat-dialog-title class="u-flex-full">
  <span>
    Seleziona rieschio identificativo
  </span>
</header>
<mat-dialog-content>
  <risk-identification-list [riskIdentifications]="riskIdentifications" [defaultFilters]="defaultFilters" [displayedColumns]="displayedColumns" [total]="total"
    [canSelect]="true" (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)"
    (onFilterChange)="filtersChange($event)" (onSelectRiskIdentification)="selectRiskIdentification($event)"></risk-identification-list>
</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>

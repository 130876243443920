import { Action, createReducer, on } from '@ngrx/store';

import { Invoice, InvoiceDTO } from '../../commons/models/invoice.model';
import * as InvoiceActions from '../actions/invoice.actions';
import { InvoiceFilters } from './../../commons/models/invoice.model';

export interface InvoiceState {
  list: InvoiceDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: InvoiceFilters,
  dialogId: string,
  selectionDialogId: string,
  invoice: Invoice
};

const initialState: InvoiceState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  invoice: null
};

const invoiceReducer = createReducer(
  initialState,
  on(InvoiceActions.loadInvoicesCompleted, (state, { invoices, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: invoices, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(InvoiceActions.invoiceDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(InvoiceActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(InvoiceActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: InvoiceState | undefined, action: Action) {
  return invoiceReducer(state, action);
}


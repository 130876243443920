import { createSelector } from '@ngrx/store';

import { selectRiskOwnerState } from '../reducers';
import { RiskOwnerState } from '../reducers/risk-owner.reducer';

// RiskOwner Selectors

export const getRiskOwnersTableState = createSelector(
  selectRiskOwnerState,
  (state: RiskOwnerState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getRiskOwners = createSelector(
  selectRiskOwnerState,
  (state: RiskOwnerState) => state.list
);

export const getTotalRiskOwners = createSelector(
  getRiskOwnersTableState,
  (tableState) => tableState.total
);

export const getRiskOwnersCurrentPage = createSelector(
  getRiskOwnersTableState,
  (tableState) => tableState.currentPage
);

export const getRiskOwnersPerPage = createSelector(
  getRiskOwnersTableState,
  (tableState) => tableState.perPage
);

export const getRiskOwnersOrder = createSelector(
  getRiskOwnersTableState,
  (tableState) => tableState.order
);

export const getRiskOwnersDirection = createSelector(
  getRiskOwnersTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getRiskOwnersTableState,
  (tableState) => tableState.filters
);

export const getRiskOwnerDialogId = createSelector(
  selectRiskOwnerState,
  (state: RiskOwnerState) => state.dialogId
);

export const getSelectedRiskOwner = createSelector(
  selectRiskOwnerState,
  (state: RiskOwnerState) => state.riskOwner
);
export const getSelectionDialogId = createSelector(
  selectRiskOwnerState,
  (state: RiskOwnerState) => state.selectionDialogId
);

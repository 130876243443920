import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { OfferFilters } from '../../../commons/models/offer.model';

@Component({
  selector: 'app-offer-filters',
  templateUrl: './offer-filters.component.html',
  styleUrls: ['./offer-filters.component.scss']
})
export class OfferFiltersComponent {
  @Output()
  onFilter: EventEmitter<OfferFilters> = new EventEmitter();


  private _defaultFilters: OfferFilters;
  filtersForm: FormGroup;
  businessOpportunityId: number;

  constructor(private fb: FormBuilder,private _route: ActivatedRoute) {
    this._createForm();
  }
  ngOnInit() {
    this.businessOpportunityId = +this._route.snapshot.paramMap.get('id');
  }

  private _createForm() {
    let group = {
      version: "",
      title: "",
      start: "",
      end: "",
      businessOpportunity: "",
      client: "",
    }

    this.filtersForm = this.fb.group(group);
  }

  applyFilters() {
    this.onFilter.emit(this._getFilters());
  }

  private _getFilters(): OfferFilters {
    let filters: OfferFilters = {}
    if (this.filtersForm) {
      const values = this.filtersForm.value;
      filters.version = values.version;
      filters.title = values.title;
      filters.start = values.start;
      filters.end = values.end;
      filters.business_opportunity_id = values.businessOpportunity?.id;
      filters.client_id = values.client?.id;
    }
    return filters;
  }

  resetFilters() {
    this.filtersForm.reset();
    this.filtersForm.patchValue({
      search: "",
    });
    this._setDefaultValues();
    this.applyFilters();
  }

  get defaultFilters(): OfferFilters {
    return this._defaultFilters;
  }

  @Input()
  set defaultFilters(defaultFilters: OfferFilters) {
    this._defaultFilters = defaultFilters;
    this._setDefaultValues();
  }

  private _setDefaultValues() {
    if (this.defaultFilters) {
      if (this.filtersForm) {
        this.filtersForm.patchValue({
          version: this.defaultFilters.version,
          title: this.defaultFilters.title,
          start: this.defaultFilters.start,
          end: this.defaultFilters.end,
          businessOpportunity: this.defaultFilters.business_opportunity_id,
          client: this.defaultFilters.client_id,
        })
      }
    }
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { removeObjectFromArray } from 'src/app/helpers/array.utils';

import { CostLine, CostLineDTO, CostLineFilters } from '../../commons/models/cost-line.model';
import * as CostLineActions from '../actions/cost-line.actions';

export interface CostLineState {
  list: CostLineDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: CostLineFilters,
  dialogId: string,
  selectionDialogId: string,
  costLine: CostLineDTO,
  selectedCostLines: CostLine[]
};

const initialState: CostLineState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  costLine: null,
  selectedCostLines: []
};

const costLineReducer = createReducer(
  initialState,
  on(CostLineActions.loadCostLinesCompleted, (state, { costLines, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: costLines, currentPage, total, perPage, order, direction, filters, includes, costLine: null };
  }),
  on(CostLineActions.costLineDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(CostLineActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters, selectedCostLines: [] };
  }),
  on(CostLineActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  }),
  on(CostLineActions.showCostLine, (state, { costLine }) => {
    return { ...state, costLine };
  }),
  on(CostLineActions.loadCostLineCompleted, (state, { costLine }) => {
    return { ...state, costLine };
  }),
  on(CostLineActions.addSelectCostLines, (state, { costLine }) => {
    if (state.selectedCostLines.find(line => costLine.id === line.id)) {
      return state;
    }
    return { ...state, selectedCostLines: [...state.selectedCostLines, costLine] }
  }),
  on(CostLineActions.removeSelectedCostLines, (state, { costLine }) => {
    const selectedCostLines = removeObjectFromArray<CostLine>(state.selectedCostLines, costLine);
    return { ...state, selectedCostLines }
  }),
  on(CostLineActions.resetSelectedCostLines, (state) => {
    return { ...state, selectedCostLines: [] }
  }),
);

export function reducer(state: CostLineState | undefined, action: Action) {
  return costLineReducer(state, action);
}


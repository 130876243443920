import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProjectBonus } from 'src/app/commons/models/project-bonus.model';
import { Project } from 'src/app/commons/models/project.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import { AppState } from 'src/app/store/reducers';
import * as ProjectSelectors from 'src/app/store/selectors/project.selectors';


@Component({
  selector: 'app-project-bonus-edit',
  templateUrl: './project-bonus-edit.component.html',
  styleUrls: ['./project-bonus-edit.component.scss']
})
export class ProjectBonusEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _projectBonus: ProjectBonus;
  project: Project;

  @Input()
  isBonusInPercentage: boolean;


  @Output()
  onSave: EventEmitter<ProjectBonus> = new EventEmitter<ProjectBonus>();
  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();


  projectBonusForm: FormGroup;
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.store$.pipe(select(ProjectSelectors.getCurrentProject), takeUntil(this.unsubscribe$), map(dto => dto ? new Project(dto) : null)).subscribe(currentProject => {
      this.project = currentProject;
    });
  }

  ngOnInit() {
    if (this.data) {
      this.projectBonus = this.data.projectBonus;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private ngOnChanges() {
    if (this.projectBonusForm ) {
      this.projectBonusForm.reset();
      this._initFormValues(this.projectBonus);
      if (!this.isBonusInPercentage) {
        this.projectBonusForm
          .get("percentage")
          .setValidators(Validators.required);
          this.projectBonusForm.markAllAsTouched();
      }
      else {
        this.projectBonusForm.get('percentage').clearValidators();
      }
      this.projectBonusForm.get('percentage').updateValueAndValidity();
    }
  }

  get projectBonus(): ProjectBonus {
    return this._projectBonus;
  }

  @Input() set projectBonus(projectBonus: ProjectBonus) {
    this._projectBonus = projectBonus;
    this.ngOnChanges();
  }

  private _createForm() {
    let group = {
      projectBonusUser: ["", Validators.required],
      percentage: [],

    }
    this.projectBonusForm = this.fb.group(group);

      }


  private _initFormValues(projectBonus?: ProjectBonus) {

    if (projectBonus) {
      this.projectBonusForm.patchValue({
        projectBonusUser: projectBonus.user,
        percentage: projectBonus.percentage
      });
    }
  }


  private _prepareSaveProjectBonus(): ProjectBonus {
    let savingProjectBonus: ProjectBonus = ProjectBonus.fromFormGroup(this.projectBonusForm, this.projectBonus);
    return savingProjectBonus;
  }

  save() {
    let unsavedEntity = this._prepareSaveProjectBonus();
    this.onSave.emit(unsavedEntity);
    this.closeEdit();
  }

  closeEdit() {
    this.onClose.emit();
    this.revert();
  }

  close() {
    if (this.projectBonusForm.dirty) {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.closeEdit();
          }
        });
    } else {
      this.closeEdit();
    }
  }

  revert() {
  this.ngOnChanges();
  }
}

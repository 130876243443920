import { Action, createReducer, on } from '@ngrx/store';

import { Professional, ProfessionalDTO } from '../../commons/models/professional.model';
import * as ProfessionalActions from '../actions/professional.actions';
import { ProfessionalFilters } from './../../commons/models/professional.model';

export interface ProfessionalState {
  list: ProfessionalDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: ProfessionalFilters,
  dialogId: string,
  selectionDialogId: string,
  professional: Professional
};

const initialState: ProfessionalState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  selectionDialogId: null,
  professional: null
};

const professionalReducer = createReducer(
  initialState,
  on(ProfessionalActions.loadProfessionalsCompleted, (state, { professionals, currentPage, total, perPage, order, direction, filters, includes }) => {
    return { ...state, list: professionals, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(ProfessionalActions.professionalDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  on(ProfessionalActions.changeFilters, (state, { filters }) => {
    return { ...state, currentPage: 1, filters };
  }),
  on(ProfessionalActions.selectionDialogOpened, (state, { selectionDialogId }) => {
    return { ...state, selectionDialogId };
  })
);

export function reducer(state: ProfessionalState | undefined, action: Action) {
  return professionalReducer(state, action);
}


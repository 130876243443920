import { AbstractControl } from '@angular/forms';
import { BillingLine, BillingLineDTO } from 'src/app/commons/models/billing-line.model';
import { CostLine, CostLineDTO } from 'src/app/commons/models/cost-line.model';
import { User, UserDTO } from 'src/app/commons/models/user.model';
import { formatDateForBackend } from 'src/app/helpers/time.utils';

import { Base, BaseDTO } from './base.model';
import { Budget, BudgetDTO } from './budget.model';
import { Professional, ProfessionalDTO } from './professional.model';
import { Project, ProjectDTO } from './project.model';

export type ClientStatus = 'Non prevista' | 'Fatturabile' | 'Fatturata' | 'Pagata';
export type ProfessionalStatus = 'Non prevista' | 'Contabilizzata' | 'Fatturabile' | 'Fatturata' | 'Pagata';

export interface ActivityFilters {
  search?: string;
  start?: Date;
  end?: Date;
  client_id?: number;
  payable?: boolean;
  billable?: boolean;
  project_id?: number;
  professional?: UserDTO;
  head_project_id?: number;
  validated?: boolean;
  promo?: boolean;
  client_invoice_id?: string;
  professional_invoice_id?: string;
  accounted?: boolean;
}

export interface ActivityReportDTO extends BaseDTO {
  month: number;
  year: number;
  hours: number;
  project_id: number;
  professional_id: number;

  professional: ProfessionalDTO;
  project: ProjectDTO;
}

export class ActivityReport extends Base {
  date: string;
  hours: number;
  projectId: number;
  professionalId: number;

  professional: Professional;
  project: Project;

  constructor(data: ActivityReportDTO) {
    super(data);
    if (data) {
      this.date = `${data.month}/${data.year}`;
      this.hours = data.hours;
      this.projectId = data.project_id;
      this.professionalId = data.professional_id;

      this.professional = new Professional(data.professional);
      this.project = new Project(data.project);
    }
  }
}

export interface ActivityDTO extends BaseDTO {
  project_id: number;
  budget_id: number;
  professional_id: number;
  billing_line_id: number;
  cost_line_id: number;
  date: string;
  hours: number;
  notes?: string;
  project: ProjectDTO;
  budget: BudgetDTO;
  professional: ProfessionalDTO;
  billing_line: BillingLineDTO;
  cost_line: CostLineDTO;
  validation_date: string;
  validated: boolean;
  validation_user: UserDTO;
  needs_billing_line: boolean;
  needs_cost_line: boolean;
}

export class Activity extends Base {
  projectId: number;
  budgetId: number;
  professionalId: number;
  billingLineId: number;
  costLineId: number;
  date: Date;
  hours: number;
  notes?: string;
  project: Project;
  budget: Budget;
  professional: Professional;
  billingLine: BillingLine;
  costLine: CostLine;
  validationDate: Date;
  validated: boolean;
  validationUser: User;
  needsBillingLine: boolean;
  needsCostLine: boolean;

  constructor(source: ActivityDTO) {
    super(source);
    if (source) {
      this.projectId = source.project_id;
      this.budgetId = source.budget_id;
      this.professionalId = source.professional_id;
      this.billingLineId = source.billing_line_id;
      this.costLineId = source.cost_line_id;
      this.validated = source.validated;
      if (source.date) {
        this.date = new Date(source.date);
      }
      if (source.validation_date) {
        this.validationDate = new Date(source.validation_date);
      }
      this.hours = source.hours;
      this.notes = source.notes;

      if (source.project) {
        this.project = new Project(source.project);
        this.addLoadedRelation('project');
      }
      if (source.budget) {
        this.budget = new Budget(source.budget);
        this.addLoadedRelation('budget');
      }
      if (source.professional) {
        this.professional = new Professional(source.professional);
        this.addLoadedRelation('professional');
      }
      if (source.billing_line) {
        this.billingLine = new BillingLine(source.billing_line);
        this.addLoadedRelation('billing_line');
      }
      if (source.cost_line) {
        this.costLine = new CostLine(source.cost_line);
        this.addLoadedRelation('cost_line');
      }
      if (source.validation_user) {
        this.validationUser = new User(source.validation_user);
        this.addLoadedRelation('validation_user');
      }
      this.needsBillingLine = source.needs_billing_line;
      this.needsCostLine = source.needs_cost_line;
    }
  }

  toDTO(): ActivityDTO {
    let result: ActivityDTO = <ActivityDTO>super.toDTO();
    result.project_id = this.projectId;
    result.budget_id = this.budgetId;
    result.professional_id = this.professionalId;
    result.billing_line_id = this.billingLineId;
    result.cost_line_id = this.costLineId;
    result.date = formatDateForBackend(this.date);
    result.validation_date = formatDateForBackend(this.validationDate);
    result.hours = this.hours;
    result.notes = this.notes;
    result.validated = this.validated;
    result.needs_billing_line = this.needsBillingLine;
    result.needs_cost_line = this.needsCostLine;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Activity) {
    const formModel = formGroup.value;
    let activity: Activity = new Activity(null);
    activity.projectId = formModel.project?.id;
    activity.budgetId = formModel.budget?.id;
    activity.billingLineId = formModel.BillingLine?.id;
    activity.professionalId = formModel.professional?.id;
    activity.costLineId = formModel.costLine?.id;
    activity.date = formModel.date;
    activity.hours = formModel.hours;
    activity.notes = formModel.notes;
    activity.validationDate = formModel.validationDate;

    if (original) {
      activity.id = original.id;
    }
    return activity;
  }

  get amount(): number {
    if (this.project && this.professional) {
      switch (this.project.advisoryDebitType) {
        case 'A ore':
          return (this.hours * this.professional.dailyRevenue) / 8;
        case 'A giornata':
          return this.professional.dailyRevenue;
        case 'Mensile':
        case 'Fisso totale':
          return this.project.value;
      }
    }
    return 0;
  }

  get accountable(): boolean {
    return (this.needsBillingLine && !this.billingLineId) || (this.needsCostLine && !this.costLineId);
  }

  get hasGeneatedLInes(): boolean {
    return !!this.billingLineId || !!this.costLineId;
  }

  get accountableCancellable(): boolean {
    return (this.needsBillingLine && this.clientStatus === 'Fatturabile') || (this.needsCostLine && this.professionalStatus === 'Contabilizzata');
  }

  get notAccountableCancellableTooltip(): string {
    if (!this.accountableCancellable) {
      let result = 'Impossibile annullare la contabilizzazione';
      if (this.needsBillingLine && this.clientStatus !== 'Fatturabile') {
        result += '\nRiga di fatturazione già fatturata';
      }
      if (this.needsCostLine && this.professionalStatus !== 'Contabilizzata') {
        result += '\nRiga di costo già impostata come pagabile'
      }
      return result;
    }
    return '';
  }

  get clientStatus(): ClientStatus {
    if (this.billingLine?.invoice?.paymentDate) {
      return 'Pagata';
    }
    if (this.billingLine?.invoiceId) {
      return 'Fatturata';
    }
    if (this.billingLineId) {
      return 'Fatturabile';
    }
    if (!this.needsBillingLine) {
      return 'Non prevista';
    }
  }

  get professionalStatus(): ProfessionalStatus {
    if (this.costLine?.invoicePaymentDate) {
      return 'Pagata';
    }
    if (this.costLine?.invoiceDate) {
      return 'Fatturata';
    }
    if (this.costLine?.accountable) {
      return 'Fatturabile'
    }
    if (this.costLineId) {
      return 'Contabilizzata';
    }
    if (!this.needsCostLine) {
      return 'Non prevista';
    }
  }
}

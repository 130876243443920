
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { TargetEditComponent } from 'src/app/modules/home/risks/targets/target-edit/target-edit.component';
import { TargetSelectionComponent } from 'src/app/modules/shared/target-selection/target-selection.component';
import { AlertService } from '../../commons/services/alert.service';
import { LaravelTargetService } from '../../commons/services/backend/laravel-target.service';
import * as TargetActions from '../actions/target.actions';
import { AppState } from '../reducers';
import { getTargetDialogId, getTargetsTableState, getSelectionDialogId } from '../selectors/target.selectors';



@Injectable()
export class TargetEffects {

  error$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.saveTargetFailed, TargetActions.deleteTargetFailed),
      tap(({ error }) => {
        if (error) {
          this.alertService.showErrorMessage('Errore', error);
        }
      })
    ), { dispatch: false }
  );

  loadTargets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.loadTargets),
      switchMap(({ page, perPage, order, direction, filters, includes }) => {
        return this.targetService.list(page, perPage, order, direction, filters, includes)
          .pipe(
            map(result =>
              TargetActions.loadTargetsCompleted({ targets: result.data, currentPage: page, total: result.total, perPage, order, direction, filters, includes })
            ),
            catchError(error => {
              return of(TargetActions.loadTargetsFailed({ error }))
            })
          )
      })
    )
  );

  changePage = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.changePage),
      withLatestFrom(this.store$.select(getTargetsTableState)),
      map(([{ page, size }, { total, currentPage, perPage, direction, order, filters, includes }]) => TargetActions.loadTargets({ page: page, perPage: size, order, direction, filters, includes }))
    )
  );

  changeSort = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.changeSort),
      withLatestFrom(this.store$.select(getTargetsTableState)),
      map(([action, { total, currentPage, perPage, direction, order, filters, includes }]) => TargetActions.loadTargets({ page: currentPage, perPage: perPage, order: action.order, direction: action.direction, filters, includes }))
    )
  );

  changeFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.changeFilters),
      withLatestFrom(this.store$.select(getTargetsTableState)),
      map(([{ filters }, { total, currentPage, perPage, direction, order, includes }]) => TargetActions.loadTargets({ page: currentPage, perPage: perPage, order, direction, filters, includes }))
    )
  );

  editTarget$ = createEffect(() => this.actions$.pipe(
    ofType(TargetActions.editTarget),
    map(({ target }) => {
      let dialogRef = this.dialog.open(TargetEditComponent, {
        data: {
          target
        }
      });
      return TargetActions.targetDialogOpened({ dialogId: dialogRef.id });
    }))
  );

  saveTarget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.saveTarget),
      mergeMap(({ target }) =>
        this.targetService.upsert(target.toDTO())
          .pipe(
            map(result =>
              TargetActions.saveTargetCompleted({ target: result })
            ),
            catchError(error => of(TargetActions.saveTargetFailed({ error })))
          )
      )
    )
  );

  onSaveCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.saveTargetCompleted),
      map(action => action.target),
      tap(target => this.alertService.showConfirmMessage(`${target.name} salvato con successo`)),
      map(() => TargetActions.closeTargetDialog())
    )
  );


  deleteTarget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.deleteTarget),
      switchMap(({ target }) =>
        this.alertService.showConfirmDialog('Conferma eliminazione', `Sei sicuro di voler eliminare l'obiettivo ${target.name}?`)
          .pipe(
            mergeMap((confirm) => {
              return confirm ?
                this.targetService.delete(target.id)
                  .pipe(
                    map(() => TargetActions.deleteTargetCompleted({ target })),
                    catchError(error => of(TargetActions.deleteTargetFailed({ error })))
                  )

                : of(TargetActions.deleteTargetCancelled());
            })
          )
      )
    )
  );

  onDeleteCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.deleteTargetCompleted),
      tap(({ target }) => this.alertService.showConfirmMessage(` ${target.name} eliminato con successo`)),
      map(() => TargetActions.closeTargetDialog())
    )
  );


  closeDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.closeTargetDialog),
      withLatestFrom(this.store$.select(getTargetDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }
      })
    ), { dispatch: false }
  );

  reloadAfterSave = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.saveTargetCompleted),
      withLatestFrom(this.store$.select(getTargetsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => TargetActions.loadTargets({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  reloadAfterDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.deleteTargetCompleted),
      withLatestFrom(this.store$.select(getTargetsTableState)),
      map(([_, { currentPage, perPage, direction, order, filters, includes }]) => TargetActions.loadTargets({ page: currentPage, perPage, order, direction, filters, includes }))
    )
  );

  selectTarget$ = createEffect(() => this.actions$.pipe(
    ofType(TargetActions.selectTarget),
    map(({ filters }) => {
      let dialogRef = this.dialog.open(TargetSelectionComponent, {
        data: {
          defaultFilters: filters
        }
      });
      return TargetActions.selectionDialogOpened({ selectionDialogId: dialogRef.id });
    }))
  );

  closeSelectionDialog = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.closeSelectionDialog),
      withLatestFrom(this.store$.select(getSelectionDialogId)),
      tap(([_, dialogId]) => {
        if (dialogId) {
          this.dialog.getDialogById(dialogId).close();
        }

      })
    ), { dispatch: false }
  );

  targetsSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TargetActions.targetSelected),
      map(() => TargetActions.closeSelectionDialog())
    ))


  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private targetService: LaravelTargetService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) { }
}

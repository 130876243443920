import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { SalesBonus } from 'src/app/commons/models/sales-bonus.model';
import { AlertService } from 'src/app/commons/services/alert.service';


@Component({
  selector: 'app-sales-bonus-edit',
  templateUrl: './sales-bonus-edit.component.html',
  styleUrls: ['./sales-bonus-edit.component.scss']
})
export class SalesBonusEditComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  private _salesBonus: SalesBonus;

  @Output()
  onSave: EventEmitter<SalesBonus> = new EventEmitter<SalesBonus>();
  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();


  salesBonusForm: FormGroup;
  constructor(private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data) {
      this.salesBonus = this.data.salesBonus;
    }
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private ngOnChanges() {
    if (this.salesBonusForm) {
      this.salesBonusForm.reset();
      this._initFormValues(this.salesBonus);
      if(!this.salesBonus) {
        this.salesBonusForm.markAllAsTouched();
      }
    }
  }

  get salesBonus(): SalesBonus {
    return this._salesBonus;
  }

  @Input() set salesBonus(salesBonus: SalesBonus) {
    this._salesBonus = salesBonus;
    this.ngOnChanges();
  }

  private _createForm() {
    let group = {
      salesBonusUser: ["", Validators.required],
      percentage: ["", Validators.required],

    }
    this.salesBonusForm = this.fb.group(group);
  }

  private _initFormValues(salesBonus?: SalesBonus) {

    if (salesBonus) {
      this.salesBonusForm.patchValue({
        salesBonusUser: salesBonus.user,
        percentage: salesBonus.percentage
      });
    }
  }

  private _prepareSaveSalesBonus(): SalesBonus {
    let savingSalesBonus: SalesBonus = SalesBonus.fromFormGroup(this.salesBonusForm, this.salesBonus);
    return savingSalesBonus;
  }

  save() {
    let unsavedEntity = this._prepareSaveSalesBonus();
    this.onSave.emit(unsavedEntity);
    this.closeEdit();
  }

  closeEdit() {
    this.onClose.emit();
    this.revert();
  }

  close() {
    if (this.salesBonusForm.dirty) {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.closeEdit();
          }
        });
    } else {
      this.closeEdit();
    }
  }

  revert() {
    this.ngOnChanges();
  }
}

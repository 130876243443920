import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { OfferAttachment, OfferAttachmentFilters } from 'src/app/commons/models/offer-attachment.model';
import { AppState } from 'src/app/store/reducers';
import * as OfferAttachmentSelectors from 'src/app/store/selectors/offer-attachment.selectors';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as OfferAttachmentActions from 'src/app/store/actions/offer-attachment.actions';
import { ActivatedRoute } from '@angular/router';
import * as OfferSelectors from 'src/app/store/selectors/offer.selectors';
import { Offer } from 'src/app/commons/models/offer.model';
import * as ActionOfferSelectors from 'src/app/store/selectors/offer-attachment.selectors';


@Component({
  selector: 'app-offer-attachments',
  templateUrl: './offer-attachments.component.html',
  styleUrls: ['./offer-attachments.component.scss']
})
export class OfferAttachmentsComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  offerAttachments: OfferAttachment[];

  @Input()
  filters: OfferAttachmentFilters;

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  private _offerId: number;
  offer: Offer;

  defaultFilters: OfferAttachmentFilters = {
    search: "",
  }

  constructor(private store$: Store<AppState>, private _route: ActivatedRoute) {
    this.total = this.store$.pipe(select(OfferAttachmentSelectors.getTotalOfferAttachments), takeUntil(this.unsubscribe$));
    this.store$.pipe(select(OfferAttachmentSelectors.getOfferAttachments), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new OfferAttachment(dto)) : null))
    .subscribe(attachments => this.offerAttachments = attachments)
  }

  ngOnInit() {
    this.load();
  }

  load() {
    this.store$.dispatch(OfferAttachmentActions.loadOfferAttachments(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.filters }));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(OfferAttachmentActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(OfferAttachmentActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: OfferAttachmentFilters) {
    this.store$.dispatch(OfferAttachmentActions.changeFilters({ filters }));
  }

  addOfferAttachment() {
    this.editOfferAttachment();
  }

  editOfferAttachment(offerAttachment?: OfferAttachment) {
    this.store$.dispatch(OfferAttachmentActions.editOfferAttachment({ offerAttachment, offerId: this._offerId }));
  }

  get displayedColumns(): string[] {
    return ["name", "file", "actions"];
  }

}



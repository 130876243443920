import { AbstractControl } from '@angular/forms';
import { Base, BaseDTO } from './base.model';
import { BonusLine, BonusLineDTO } from './bonus_line.model';

export interface BonusFilters {
    year?: number,
  }

export interface BonusDTO extends BaseDTO {
    year: number;
    amount: number;
    bonus_client: number;
    project_bonus: number;
    bonus_sales: number;
    margin: number;

    bonus_lines: BonusLineDTO[];
}

export class Bonus extends Base {
    year: number;
    amount: number;
    bonus_client: number;
    project_bonus: number;
    bonus_sales: number;
    margin: number;
    bonusLines: BonusLine[];

    constructor(source: BonusDTO) {
        super(source);
        if (source) {
            this.year = source.year;
            this.amount = source.amount;
            this.bonus_client = source.bonus_client
            this.project_bonus = source.project_bonus
            this.bonus_sales = source.bonus_sales
            this.margin = source.margin
            if (source.bonus_lines) {
                this.bonusLines = source.bonus_lines.map(dto => new BonusLine(dto));
                this.addLoadedRelation('bonus_lines');
              }
        }
    }

    toDTO(): BonusDTO {
        let result: BonusDTO = <BonusDTO>super.toDTO();

        result.year = this.year;
        result.amount = this.amount;
        result.bonus_client = this.bonus_client
        result.project_bonus = this.project_bonus
        result.bonus_sales = this.bonus_sales
        result.margin = this.margin
        result.bonus_lines = this.bonusLines
        ? this.bonusLines.map((bonusLine) => bonusLine.toDTO())
        : null;

        return result;
    }

    static fromFormGroup(formGroup: AbstractControl, original?: Bonus) {
        const formModel = formGroup.value;
        let bonus: Bonus = new Bonus(null);

        bonus.year = formModel.year;
        bonus.amount = formModel.amount;
        bonus.bonus_client = formModel.bonus_client
        bonus.project_bonus = formModel.project_bonus
        bonus.bonus_sales = formModel.bonus_sales
        bonus.margin = formModel.margin

        if (original) {
            bonus.id = original.id;
        }
        return bonus;
    }

}

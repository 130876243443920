<ng-container [formGroup]="form">
  <ng-container
    *ngFor="let control of form.controls; let index = index"
    [formGroupName]="index"
  >
    <div class="u-full-width risk">
      <button
        mat-icon-button
        mat-raised-button
        color="accent"
        (click)="removeRiskIdentification(index)"
        matTooltip="Rimuovi rischio"
      >
        <mat-icon>delete</mat-icon>
      </button>
      <mat-form-field style="flex: 1">
        <mat-label>Rischio</mat-label>
        <app-risk-field formControlName="risk"></app-risk-field>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Probabilità</mat-label>
        <input
          matInput
          placeholder="Probabilità"
          formControlName="probability"
          type="number"
          min="0"
          max="100"
          forceNumber
        />
        <mat-icon matSuffix>percent</mat-icon>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Impatto"
          formControlName="impact"
          type="number"
          forceNumber
        />
        <mat-icon matSuffix>euro</mat-icon>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Risk Owner</mat-label>
        <app-user-field formControlName="riskOwner"></app-user-field>
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          placeholder="Risk Actionee"
          formControlName="actionee"
          type="text"
        />
        <mat-error>Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>
    <!-- <button mat-raised-button (click)="removeProfessional(index)">REMOVE</button> -->
  </ng-container>
</ng-container>
<div class="u-center-text">
  <button
    mat-raised-button
    type="button"
    color="accent"
    (click)="addRiskIdentification()"
    [disabled]="form.invalid"
  >
    <span>Aggiungi rischio</span>
  </button>
</div>

import { BusinessOpportunity } from 'src/app/commons/models/business-opportunity.model';
import { Component, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ActionAttachment, ActionAttachmentFilters } from 'src/app/commons/models/action-attachment.model';
import { AppState } from 'src/app/store/reducers';
import * as ActionAttachmentSelectors from 'src/app/store/selectors/action-attachment.selectors';
import { PAGE_SIZE_OPTIONS } from 'src/app/helpers/table.helper';
import * as ActionAttachmentActions from 'src/app/store/actions/action-attachment.actions';
import { Action } from 'src/app/commons/models/action.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-action-attachments',
  templateUrl: './action-attachments.component.html',
  styleUrls: ['./action-attachments.component.scss']
})
export class ActionAttachmentsComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  actionAttachments: ActionAttachment[];

  actionId: number;

  @Input()
  action: Action;

  filters: Observable<ActionAttachmentFilters>;

  pageSizeOptions = PAGE_SIZE_OPTIONS;

  total: Observable<number>;
  businessOpportunity: BusinessOpportunity;



  defaultFilters: ActionAttachmentFilters = {
    search: "",
  }

  constructor(private store$: Store<AppState>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

    this.total = this.store$.pipe(select(ActionAttachmentSelectors.getTotalActionAttachments), takeUntil(this.unsubscribe$));
    this.filters = this.store$.pipe(select(ActionAttachmentSelectors.getFilters), takeUntil(this.unsubscribe$));
    this.store$.pipe(select(ActionAttachmentSelectors.getActionAttachments), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new ActionAttachment(dto)) : null))
    .subscribe(attachments => this.actionAttachments = attachments)
  }

  ngOnInit() {
    if(this.data) {
      this.actionId = this.data.actionId;
      this.defaultFilters = {
        action_id: this.actionId
      }
    }

    this.load();
  }

  load() {
    this.store$.dispatch(ActionAttachmentActions.loadActionAttachments(
      { page: 1, perPage: this.pageSizeOptions[0], filters: this.defaultFilters }));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  sortChange(sort: Sort) {
    this.store$.dispatch(ActionAttachmentActions.changeSort({ order: sort.active, direction: sort.direction }));
  }

  pageChange(pageEvent: PageEvent) {
    this.store$.dispatch(ActionAttachmentActions.changePage({ page: pageEvent.pageIndex + 1, size: pageEvent.pageSize }))
  }

  filtersChange(filters: ActionAttachmentFilters) {
    filters = { ...filters, ...this.defaultFilters }
    this.store$.dispatch(ActionAttachmentActions.changeFilters({ filters }));
  }

  addActionAttachment() {
    this.editActionAttachment();
  }

  editActionAttachment(actionAttachment?: ActionAttachment) {
    this.store$.dispatch(ActionAttachmentActions.editActionAttachment({ actionAttachment, actionId: this.actionId }));
  }
  close() {
  
    this.store$.dispatch(ActionAttachmentActions.closeActionAttachmentsDialog())
   
  }
}



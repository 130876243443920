import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListResultDTO } from 'src/app/helpers/listResult.interface';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { environment } from 'src/environments/environment';
import { BonusDTO, BonusFilters } from '../../models/bonus.model';
import { BonusLineDTO } from '../../models/bonus_line.model';

import { ProjectDTO } from '../../models/project.model';
import { ProjectFilters } from './../../models/project.model';

@Injectable({
  providedIn: "root"
})
export class LaravelBonusService {


  constructor(private httpBonus: HttpClient) { }

  private get ROUTES() {
    return {
      list: environment.baseUrl + "/api/bonuses",
      generateBonus: environment.baseUrl + "/api/bonus",
      cancelBonus: environment.baseUrl + "/api/bonus",
    };
  }
  public list(page: number,
    per_page: number,
    order: string,
    direction: string,
    filters: BonusFilters,
    includes?: string[]
  ): Observable<ListResultDTO<BonusDTO>> {
    let params = {};
    if (order) params["order"] = "" + order;
    if (direction) params["direction"] = "" + direction;
    if (includes) params["includes[]"] = includes;
    if (filters) {
      if (filters.year) params["year"] = "" + filters.year;
    }
    if (per_page) {
      params["per_page"] = "" + per_page;
      if (page) params["page"] = "" + page;
      return this.httpBonus.get<ListResultDTO<BonusDTO>>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      });
    } else {
      return this.httpBonus.get<BonusDTO[]>(this.ROUTES.list, {
        params: new HttpParams({ fromObject: params })
      }).pipe(
        map(results => {
          return {
            data: results,
            total: results.length
          };
        })
      );
    }
  }
  public generateBonus(year: number, bonus:number): Observable<BonusLineDTO[]> {
    let params = {};
    if (year) params["year"] = "" + year;
    if (bonus) params["bonus"] = "" + bonus;
    return this.httpBonus.get<BonusLineDTO[]>(this.ROUTES.generateBonus, {
      params: new HttpParams({
        fromObject: params
      })
    });

  }
  public cancelBonus(year: number): Observable<any> {
    let params = { year: "" + year};
    return this.httpBonus.delete(this.ROUTES.cancelBonus, {
      params: new HttpParams({
        fromObject: params
      })
    });
  }
}

import { createAction, props } from '@ngrx/store';

import { OfferAttachment, OfferAttachmentDTO, OfferAttachmentFilters } from '../../commons/models/offer-attachment.model';

export const loadOfferAttachments = createAction('[OfferAttachments] Load offerAttachments', props<{ page: number, perPage: number, order?: string, direction?: string, filters?: OfferAttachmentFilters, includes?: string[] }>());
export const loadOfferAttachmentsCompleted = createAction('[OfferAttachments] Load offerAttachments Completed', props<{ offerAttachments: OfferAttachmentDTO[], currentPage: number, total: number, perPage: number, order?: string, direction?: string, filters?: OfferAttachmentFilters, includes?: string[] }>());
export const loadOfferAttachmentsFailed = createAction('[OfferAttachments] Load offerAttachments Failed', props<{ error: any }>());

export const changePage = createAction('[OfferAttachments] Change page', props<{ page: number, size: number }>());
export const changeSort = createAction('[OfferAttachments] Change sort', props<{ order: string, direction: string }>());
export const changeFilters = createAction('[OfferAttachments] Change filters', props<{ filters: OfferAttachmentFilters }>());

export const editOfferAttachment = createAction('[OfferAttachments] Edit offerAttachment', props<{ offerAttachment: OfferAttachment, offerId: number }>());
export const offerAttachmentDialogOpened = createAction('[OfferAttachments] Detail dialog opened', props<{ dialogId: string }>());
export const closeOfferAttachmentDialog = createAction('[OfferAttachments] Close detail dialog');

export const saveOfferAttachment = createAction('[OfferAttachments] Save offerAttachment', props<{ offerAttachment: OfferAttachment, offerId: number }>());
export const saveOfferAttachmentCompleted = createAction('[OfferAttachments] Save offerAttachment completed', props<{ offerAttachment: OfferAttachmentDTO, offerId: number }>());
export const saveOfferAttachmentFailed = createAction('[OfferAttachments] Save offerAttachment failed', props<{ error: any }>());

export const deleteOfferAttachment = createAction('[OfferAttachments] Delete offerAttachment', props<{ offerAttachment: OfferAttachmentDTO, offerId: number }>());
export const deleteOfferAttachmentCompleted = createAction('[OfferAttachments] Delete offerAttachment completed', props<{ offerAttachment: OfferAttachmentDTO, offerId: number }>());
export const deleteOfferAttachmentCancelled = createAction('[OfferAttachments] Delete offerAttachment cancelled');
export const deleteOfferAttachmentFailed = createAction('[OfferAttachments] Delete offerAttachment failed', props<{ error: any }>());

export const selectOfferAttachment = createAction('[OfferAttachments] Select offerAttachment', props<{ filters?: OfferAttachmentFilters }>());
export const selectionDialogOpened = createAction('[OfferAttachments] Selection dialog opened', props<{ selectionDialogId: string }>());
export const closeSelectionDialog = createAction('[OfferAttachments] Close selection dialog');
export const offerAttachmentSelected = createAction('[OfferAttachments] OfferAttachment selected', props<{ offerAttachment: OfferAttachmentDTO }>());

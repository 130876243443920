import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, forwardRef, HostBinding, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Payment, PaymentFilters } from 'src/app/commons/models/payment.model';
import * as PaymentActions from 'src/app/store/actions/payment.actions';
import { AppState } from 'src/app/store/reducers';


export const PAYMENT_FIELD_MAT_FORM_FIELD_CONTROL: any = {
  provide: MatFormFieldControl,
  useExisting: forwardRef(() => PaymentFieldComponent),
};

@Component({
  selector: "app-payment-field",
  templateUrl: "./payment-field.component.html",
  styleUrls: ["./payment-field.component.scss"],
  providers: [PAYMENT_FIELD_MAT_FORM_FIELD_CONTROL],
  host: {
    "[class.floating]": "shouldLabelFloat",
    "[id]": "id",
  },
})
export class PaymentFieldComponent
  implements ControlValueAccessor, MatFormFieldControl<Payment> {
  private unsubscribe$ = new Subject<void>();
  private _value: Payment;
  private _disabled = false;

  private _required = false;

  get errorState(): boolean {
    return !this.ngControl.valid;
  }
  focused: boolean; //TODO
  controlType?: string = "payment-selection-field";
  autofilled?: boolean;
  paymentAriaDescribedBy?: string;


  @Input()
  paymentFilters: PaymentFilters;

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.actions$
      .pipe(
        ofType(PaymentActions.paymentSelected),
        takeUntil(this.unsubscribe$),
        map(({ payment }) => (payment ? new Payment(payment) : null))
      )
      .subscribe((payment) => (this.value = payment));
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  stateChanges = new Subject<void>();
  id: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;

  get empty() {
    return !this.value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get required() {
    return this._required || this.ngControl?.control?.hasValidator(Validators.required) || false;
  }

  @Input()
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    //TODO
  }
  onContainerClick(event: MouseEvent): void {
    if (!this.disabled) {
      if ((event.target as Element).tagName.toLowerCase() != "mat-icon") {
        this.selectPayment();
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stateChanges.complete();
  }

  selectPayment() {
    this.store$.dispatch(PaymentActions.selectPayment({ filters: this.paymentFilters }));
  }

  clearPayment() {
    this.value = null;
  }

  set value(value: Payment) {
    this._value = value;
    this.notifyValueChange();
    this.stateChanges.next();
  }

  get value(): Payment {
    return this._value;
  }

  onChange: (value) => {};
  onTouched: () => {};

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(obj: Payment): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

<div *ngIf="!projectComments?.length" class="u-center-text" layout="row" layout-align="center">
    <h4>Nessun commento presente</h4>
      <button mat-raised-button type="button" color="accent" (click)="addProjectComment()">
        <span>Aggiungi</span>
      </button>
</div>

<div *ngIf="projectComments?.length > 0">
  <div *ngFor="let comment of projectComments" class="u-flex-full comment">
    <span (click)="editProjectComment(comment)">
      <strong>{{comment.user?.fullName}}</strong> il {{comment.createdAt | date : "dd/MM/yy HH:mm"}}: {{comment.comment}}
    </span>
    <!-- <button *ngIf="(this.currentUser | async).objectId == comment.author?.objectId" mat-icon-button type="button" -->

    <ng-container *ngIf="comment.projectAttachment?.file">
      <a [href]="baseUrl + '/' + comment.projectAttachment?.file" target="_blank" matTooltip="Scarica allegato">
        <mat-icon color="primary">link</mat-icon>
      </a>
    </ng-container>
  </div>
  <div class="u-center-text">
    <button mat-raised-button type="button" color="accent" (click)="addProjectComment()">
      <span>Aggiungi commento</span>
    </button>
  </div>
</div>

<ng-container *ngIf="false">


<mat-card class="u-center-text" *ngIf="!projectComments?.length">
  <h4>Nessun commento trovato</h4>
  <button *ngIf="canAdd" mat-raised-button (click)="addProjectComment()">Aggiungi</button>
</mat-card>
<div class="table-container" [hidden]="!projectComments?.length">
  <table mat-table matSort [dataSource]="projectComments">
    <ng-container matColumnDef="projectCommentCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Codice valuta </th>
      <td mat-cell *matCellDef="let row">
        {{ row.projectCommentCode }}
      </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let row">
        {{row.id}}
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef> Commessa </th>
      <td mat-cell *matCellDef="let row">
        {{row.project?.identifier}}
      </td>
    </ng-container> -->

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef> Commento </th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.comment" [matTooltip]="row.comment">description</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="attachment">
      <th mat-header-cell *matHeaderCellDef> Allegato </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="row.projectAttachment?.file">
          <a [href]="baseUrl + '/' + row.projectAttachment?.file" target="_blank">
            <mat-icon color="primary">link</mat-icon>
          </a>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation();addProjectComment()">
          <mat-icon>playlist_add</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="canEdit">
          <button mat-icon-button [mat-menu-trigger-for]="actionMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
            <button mat-menu-item (click)="editProjectComment(row)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
          </mat-menu>
        </ng-container>

        <button *ngIf="canSelect" mat-raised-button color="primary"
          (click)="selectProjectComment(row)">Seleziona</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
</ng-container>

import { createSelector } from '@ngrx/store';

import { selectBonusState } from '../reducers';
import { BonusState } from '../reducers/bonus.reducer';

// Bonus Selectors

export const getBonusesTableState = createSelector(
  selectBonusState,
  (state: BonusState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getBonuses = createSelector(
  selectBonusState,
  (state: BonusState) => state.list
);

export const getTotalBonuses = createSelector(
  getBonusesTableState,
  (tableState) => tableState.total
);

export const getBonusesCurrentPage = createSelector(
  getBonusesTableState,
  (tableState) => tableState.currentPage
);

export const getBonusesPerPage = createSelector(
  getBonusesTableState,
  (tableState) => tableState.perPage
);

export const getBonusesOrder = createSelector(
  getBonusesTableState,
  (tableState) => tableState.order
);

export const getBonusesDirection = createSelector(
  getBonusesTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getBonusesTableState,
  (tableState) => tableState.filters
);

export const getBonusDialogId = createSelector(
  selectBonusState,
  (state: BonusState) => state.dialogId
);

export const getCancelDialogId = createSelector(
  selectBonusState,
  (state: BonusState) => state.cancelDialogId
);

export const getBonusLineDialogId = createSelector(
  selectBonusState,
  (state: BonusState) => state.bonusLineDialogId
);


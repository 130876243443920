import { AbstractControl, FormArray } from '@angular/forms';

import { Base, BaseDTO } from './base.model';
import { Project, ProjectDTO } from './project.model';
import { Budget, BudgetDTO } from './budget.model';
import { User, UserDTO } from './user.model';
import { formatDateForBackend } from 'src/app/helpers/time.utils';
import { BusinessOpportunity, BusinessOpportunityDTO } from './business-opportunity.model';
import { OfferAttachment, OfferAttachmentDTO } from './offer-attachment.model';
import { DaysDistribution, DaysDistributionDTO } from './days-distribution.model';


export interface OfferFilters {
  version?: number;
  title?: string;
  start?: Date;
  end?: Date;
  business_opportunity_id?: number;
  client_id?: number;
  professional_id?: number;

}

export type ExpenseType = 'Piè di lista' | 'Giornaliero' | 'Totale' | 'Nessuno';
export const EXPENSES: ExpenseType[] = ['Piè di lista', 'Giornaliero', 'Totale', 'Nessuno'];

export type OfferProbability = 10 | 40 | 70 | 90 | 100;
export const PROBABILITIES: OfferProbability[] = [10, 40, 70, 90, 100];

export interface OfferDTO extends BaseDTO {
  version: number;
  business_opportunity_id: number;
  date: string;
  title: string;
  total_amount: number;
  probability: OfferProbability;
  notes?: string;
  days?: number;
  value_per_day?: number;
  expense_type: ExpenseType;
  km_cost?: number;
  estimated_value?: number;
  project_id: number;

  business_opportunity: BusinessOpportunityDTO;
  days_distributions: DaysDistributionDTO[];
  project: ProjectDTO;
  attachments?: OfferAttachmentDTO[];
}

export class Offer extends Base {
  version: number;
  businessOpportunityId: number;
  date: Date;
  title: string;
  totalAmount: number;
  probability: OfferProbability;
  notes?: string;
  days?: number;
  valuePerDay?: number;
  expenseType: ExpenseType;
  kmCost?: number;
  estimatedValue?: number;
  projectId: number;

  project: Project;
  businessOpportunity: BusinessOpportunity;
  attachments?: OfferAttachment[];
  daysDistributions?: DaysDistribution[];

  constructor(source: OfferDTO) {
    super(source);
    if (source) {
      this.version = source.version;
      this.businessOpportunityId = source.business_opportunity_id;
      this.date = new Date(source.date);
      this.title = source.title;
      this.totalAmount = source.total_amount;
      this.probability = source.probability;
      this.notes = source.notes;
      this.days = source.days;
      this.valuePerDay = source.value_per_day;
      this.expenseType = source.expense_type;
      this.kmCost = source.km_cost;
      this.estimatedValue = source.estimated_value;
      this.projectId = source.project_id;

      this.project = new Project(source.project);
      this.businessOpportunity = new BusinessOpportunity(source.business_opportunity);
      this.attachments = source.attachments?.map(a => new OfferAttachment(a));
      if(source.days_distributions) {
        this.daysDistributions = source.days_distributions?.map(a => new DaysDistribution(a));
        this.addLoadedRelation('daysDistributions');
      }
  
    }
  }

  toDTO(): OfferDTO {
    let result: OfferDTO = <OfferDTO>super.toDTO();
    result.version = this.version;
    result.business_opportunity_id = this.businessOpportunityId;
    result.date = formatDateForBackend(this.date);
    result.title = this.title;
    result.total_amount = this.totalAmount;
    result.probability = this.probability;
    result.notes = this.notes;
    result.days = this.days;
    result.value_per_day = this.valuePerDay;
    result.expense_type = this.expenseType;
    result.km_cost = this.kmCost;
    result.estimated_value = this.estimatedValue;
    result.project_id = this.projectId;
    result.days_distributions = this.daysDistributions
    ? this.daysDistributions.map((daysDistribution) => daysDistribution.toDTO())
    : null;
    return result;
  }

  static fromFormGroup(formGroup: AbstractControl, original?: Offer) {
    const formModel = formGroup.value;
    let offer: Offer = new Offer(null);
    offer.version = formModel.version;
    offer.businessOpportunityId = formModel.businessOpportunity?.id;
    offer.date = formModel.date;
    offer.title = formModel.title;
    offer.totalAmount = formModel.totalAmount;
    offer.probability = formModel.probability;
    offer.notes = formModel.notes;
    offer.days = formModel.days;
    offer.valuePerDay = formModel.valuePerDay;
    offer.expenseType = formModel.expenseType;
    offer.kmCost = formModel.kmCost;
    offer.estimatedValue = formModel.estimatedValue;
    offer.projectId = formModel.project?.id;

    const daysDistributionCtrls: FormArray = formGroup.get('daysDistributions') as FormArray;
    offer.daysDistributions = daysDistributionCtrls?.controls.map(model => DaysDistribution.fromFormGroup(model));

    if (original) {
      offer.id = original.id;
    }
    return offer;
  }

}

import { createSelector } from '@ngrx/store';

import { selectCurrencyState } from '../reducers';
import { CurrencyState } from '../reducers/currency.reducer';

// Currency Selectors

export const getCurrenciesTableState = createSelector(
  selectCurrencyState,
  (state: CurrencyState) => { return { total: state.total, currentPage: state.currentPage, perPage: state.perPage, order: state.order, direction: state.direction, filters: state.filters, includes: state.includes } }
);

export const getCurrencies = createSelector(
  selectCurrencyState,
  (state: CurrencyState) => state.list
);

export const getTotalCurrencies = createSelector(
  getCurrenciesTableState,
  (tableState) => tableState.total
);

export const getCurrenciesCurrentPage = createSelector(
  getCurrenciesTableState,
  (tableState) => tableState.currentPage
);

export const getCurrenciesPerPage = createSelector(
  getCurrenciesTableState,
  (tableState) => tableState.perPage
);

export const getCurrenciesOrder = createSelector(
  getCurrenciesTableState,
  (tableState) => tableState.order
);

export const getCurrenciesDirection = createSelector(
  getCurrenciesTableState,
  (tableState) => tableState.direction
);

export const getFilters = createSelector(
  getCurrenciesTableState,
  (tableState) => tableState.filters
);

export const getCurrencyDialogId = createSelector(
  selectCurrencyState,
  (state: CurrencyState) => state.dialogId
);

export const getSelectedCurrency = createSelector(
  selectCurrencyState,
  (state: CurrencyState) => state.currency
);
export const getSelectionDialogId = createSelector(
  selectCurrencyState,
  (state: CurrencyState) => state.selectionDialogId
);
